import { isNumber } from "lodash";
import moment from "moment";

const isValidDate = (date) => moment(date).isValid();
const toLowerCaseStr = (str) => (str || "").toString().toLowerCase();

export const getFilteredRows = (rows = [], selectedFilter = {}) => {
  const filterKeyValues = Object.entries(selectedFilter);

  const filterRows = rows.filter((row) => {
    let matched = true;
    for (let i = 0; i < Object.keys(filterKeyValues).length; i += 1) {
      if (!matched) break;
      const [key, value] = filterKeyValues[i];
      const rowValue = toLowerCaseStr(row[key]);
      if (key in row) {
        if (Array.isArray(value)) {
          if (value.length)
            matched = value.map(toLowerCaseStr).includes(rowValue);
        } else {
          matched = rowValue.includes(toLowerCaseStr(value));
        }
      }
    }
    return matched;
  });

  return filterRows;
};

export const getSortedRow = (rows = [], sortBy = "", order = "asc") => {
  if (!sortBy) return rows;

  const sortedRows = [...rows].sort((rowA, rowB) => {
    let valueA = rowA[sortBy];
    let valueB = rowB[sortBy];

    if (isValidDate(valueA) || isValidDate(valueB)) {
      valueA = new Date(valueA).getTime();
      valueB = new Date(valueB).getTime();
      if (order === "asc") return valueA - valueB;
      return valueB - valueA;
    }

    if (isNumber(valueA) || isNumber(valueB)) {
      if (order === "asc") return valueA - valueB;
      return valueB - valueA;
    }

    const strValueA = toLowerCaseStr(valueA);
    const strValueB = toLowerCaseStr(valueB);

    if (order === "asc") return strValueA.localeCompare(strValueB);
    return strValueB.localeCompare(strValueA);
  });

  return sortedRows;
};
