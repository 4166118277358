import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  getPrevMutationSelector,
  notifyError,
} from "../../../../utils/helpers";
import LocalLoader from "../../../../components/Loaders/Local";
import {
  useApplyFixtureMutation,
  useAutoFillFormMutation,
  useCreateFixtureMutation,
  useDeleteFixtureMutation,
  useGetFixturesMutation,
  useUpdateFixtureMutation,
} from "../../../../api/fixture.api.slice";
import { showNotification } from "../../../core/slices/notification.slice";
import FixtureDialogPopup from "./FixtureDialogPopup";
import { methodRefs } from "../../../../components/Form/context/formSlice";
import {
  getFormattedFormData,
  saveForm,
} from "../../../../components/Form/services/form.service";

const paperSx = {
  minWidth: { xs: "calc(100% - 32px)", sm: "400px" },
  maxHeight: "min(calc(100vh - 32px), 300px)",
  "&::-webkit-scrollbar": { width: "0.25em", padding: "0.1em" },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: (theme) =>
      theme.palette.mode !== "light" ? "#FFFFFF20" : "#00000020",
    borderRadius: "99px",
  },
  "& button.MuiButtonBase-root": {
    "&:hover": { color: (theme) => theme.palette.primary.light },
  },
};

function Fixtures() {
  const dispatch = useDispatch();
  const updateForm = methodRefs?.applicationDetailUpdateForm;
  const { applicationId, applicationType } = useParams();
  const getApplicationMutation = useSelector((state) =>
    getPrevMutationSelector(state, "getApplication")
  );
  const showFixtures =
    getApplicationMutation?.status === "fulfilled" &&
    getApplicationMutation?.data?._id === applicationId;

  const [anchorEl, setAnchorEl] = useState(null);
  const [fixtureId, setFixtureId] = useState("");
  const [actionType, setActionType] = useState("");
  const [openFixtureDialog, setOpenFixtureDialog] = useState(false);
  const [fixtureName, setFixtureName] = useState("");
  const [triggerMutation, setTriggerMutation] = useState(false);

  const [applyFixture, { error: applyFixtureError }] =
    useApplyFixtureMutation();
  const [createFixture, { error: createFixtureError }] =
    useCreateFixtureMutation();
  const [updateFixture, { error: updateFixtureError }] =
    useUpdateFixtureMutation();
  const [deleteFixture, { error: deleteFixtureError }] =
    useDeleteFixtureMutation();
  const [uploadFixture, { error: uploadFixtureError }] =
    useAutoFillFormMutation();

  const [
    getFixtures,
    { data: fixtures, error: fixturesError, isLoading: fixturesLoading },
  ] = useGetFixturesMutation();

  const getFixturesData = async () => {
    await getFixtures(applicationType).unwrap();
  };

  const handleFixtureApi = async () => {
    const formProperties = methodRefs?.formMethods?.getValues() || {};

    const fixturesMutationNPayloadObj = {
      apply: {
        mutation: applyFixture,
        payload: fixtureId,
      },
      create: {
        mutation: createFixture,
        payload: {
          fixtureName,
          applicationType,
          formProperties,
        },
        msg: "Fixture Created Successfully",
      },
      update: {
        mutation: updateFixture,
        payload: {
          id: fixtureId,
          body: { formProperties },
        },
        msg: "Fixture Updated Successfully",
      },
      delete: {
        mutation: deleteFixture,
        payload: fixtureId,
        msg: "Fixture Deleted Successfully",
      },
      upload: {
        mutation: uploadFixture,
        payload: {
          applicationType,
          body: { applicationId, formProperties, requiredDocuments: true },
        },
        msg: "Documents will be uploaded shortly. Please reload the page after 5 minutes.",
      },
    };

    const { mutation, payload, msg } = fixturesMutationNPayloadObj[actionType];
    try {
      const { data, error } = await mutation(payload);

      if (actionType === "apply") {
        const formPayload = {
          id: applicationId,
          formProperties: getFormattedFormData(data),
          updateForm,
          notifyError,
          dispatch,
          showNotification,
        };
        const result = await saveForm(formPayload);
        if (result) {
          methodRefs?.formMethods?.reset(data);
          // first form reset is not reflected on UI.
          methodRefs?.formMethods?.reset(data);
        }
      }

      if (!error && actionType !== "apply") {
        dispatch(showNotification({ type: "success", msg, show: true }));
      }
    } catch (err) {
      notifyError(dispatch, showNotification, err);
    }
  };

  useEffect(() => {
    if (triggerMutation) {
      handleFixtureApi();
      setTriggerMutation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerMutation]);

  useEffect(() => {
    const errorObj =
      fixturesError ||
      applyFixtureError ||
      createFixtureError ||
      deleteFixtureError ||
      updateFixtureError ||
      uploadFixtureError;
    if (errorObj) notifyError(dispatch, showNotification, errorObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fixturesError,
    applyFixtureError,
    createFixtureError,
    deleteFixtureError,
    updateFixtureError,
    uploadFixtureError,
    dispatch,
  ]);

  const handleOnOk = (name) => {
    setFixtureName(name);
    setTriggerMutation(true);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
    if (showFixtures) getFixturesData();
  };

  const handleClosePopover = () => setAnchorEl(null);

  const handleCloseFixtureModal = () => {
    handleClosePopover();
    setOpenFixtureDialog(false);
  };

  const handleButtonClick = (event, type, id) => {
    event.stopPropagation();
    if (!showFixtures) {
      const msg = "Please save the application before creating a fixture.";
      dispatch(showNotification({ type: "warning", msg, show: true }));
      return;
    }

    setActionType(type);
    setFixtureId(id);

    if (type === "apply" || type === "upload") {
      handleOnOk();
      handleCloseFixtureModal();
      return;
    }

    setOpenFixtureDialog(true);
    handleClosePopover();
  };

  if (
    getApplicationMutation?.data?.status !== "Open" &&
    applicationId !== "new"
  )
    return null;

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? "fixture-popover" : undefined;

  return (
    <div>
      {showFixtures && (
        <Tooltip title="Auto fill documents" sx={{ mr: 1 }}>
          <IconButton onClick={(event) => handleButtonClick(event, "upload")}>
            <CloudUploadIcon />
          </IconButton>
        </Tooltip>
      )}
      <Button
        aria-describedby={popoverId}
        onClick={handleOpenPopover}
        startIcon={<AutoFixHighIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        Fixtures
      </Button>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { ...paperSx, minHeight: fixturesLoading ? "120px" : undefined },
        }}
      >
        <List>
          <ListItem
            disablePadding
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode !== "light" ? "#FFFFFF20" : "#00000020",
            }}
          >
            <ListItemButton onClick={(evt) => handleButtonClick(evt, "create")}>
              <AddIcon color="primary" sx={{ ml: "auto", mr: 1 }} />
              <Typography color="primary" fontWeight="bold">
                Add Fixture
              </Typography>
            </ListItemButton>
          </ListItem>
          {showFixtures &&
            fixtures &&
            fixtures.map(({ _id: id, fixtureName: name }) => (
              <ListItem disablePadding key={id}>
                <ListItemButton
                  onClick={(evt) => handleButtonClick(evt, "apply", id)}
                >
                  <ListItemText primary={name} />
                  <Tooltip title="Apply">
                    <IconButton
                      onClick={(evt) => handleButtonClick(evt, "apply", id)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Update" sx={{ mx: 0.25 }}>
                    <IconButton
                      onClick={(evt) => handleButtonClick(evt, "update", id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={(evt) => handleButtonClick(evt, "delete", id)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <LocalLoader progress={fixturesLoading} />
      </Popover>
      <FixtureDialogPopup
        isOpen={openFixtureDialog}
        type={actionType}
        fixtureId={fixtureId}
        onClose={handleCloseFixtureModal}
        onOk={handleOnOk}
      />
    </div>
  );
}

export default Fixtures;
