/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useFormState, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import {
  customDateValidation,
  generateFormFieldRules,
  getParsedValue,
} from "../services/form.service";

function FormDateField(props) {
  const {
    fieldProps,
    formProps,
    control,
    disabled,
    resetHandler,
    trigger,
    getValues,
    setValue,
  } = props;
  const { key, label, validate, defaultDate } = fieldProps;
  const { errors } = useFormState({ control, name: key });
  const [dateError, setDateError] = useState(null);

  const initialValue =
    defaultDate === "today" && !formProps[key]
      ? moment().format("MM/DD/YYYY")
      : formProps[key] || null;

  const condition = validate?.custom && getParsedValue(validate.custom, key);

  const errorMsg = {
    minDate: "Please select later date",
    maxDate: "Please select former date",
    invalidDate: "Invalid date",
    dob: "Age of applicant is not within acceptable range.",
    expectedDate: "Cannot be same as licensed date",
  };

  const triggerValidation = () => {
    setTimeout(() => trigger(key), 5);
  };

  const handleDateChange = (dateObj, onChange) => {
    const date = dateObj ? moment(dateObj.$d).format("MM/DD/YYYY") : null;
    onChange(date);
    resetHandler();
    triggerValidation();
  };

  const dateValidation = (dateCondition, type, value) => {
    const result = customDateValidation(dateCondition, type, value);
    return result;
  };

  const rules = generateFormFieldRules(fieldProps, getValues, dateError);
  React.useEffect(() => {
    if (formProps[key] === "") {
      setValue(key, initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth margin="dense" sx={{ height: "95px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={key}
          control={control}
          defaultValue={initialValue}
          render={({ field: { onChange, ref, ...field } }) => (
            <DatePicker
              id={key}
              name={key}
              label={label}
              onChange={(dateObj) => handleDateChange(dateObj, onChange)}
              minDate={condition ? dateValidation(condition, "min") : undefined}
              maxDate={condition ? dateValidation(condition, "max") : undefined}
              onError={(error) => setDateError(error)}
              disabled={disabled}
              views={["year", "month", "day"]}
              onClose={triggerValidation}
              {...field}
              renderInput={(prop) => (
                <TextField
                  id={key}
                  name={key}
                  inputRef={ref}
                  required={validate?.required}
                  variant="standard"
                  onBlur={triggerValidation}
                  {...prop}
                />
              )}
            />
          )}
          rules={rules}
        />
      </LocalizationProvider>
      {(errors[key] || dateError) && (
        <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
          {(dateError && errorMsg[dateError]) ||
            errors[key]?.message ||
            "This is required."}
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormDateField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
      customMessage: PropTypes.string,
    }),
    defaultDate: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};
export default FormDateField;
