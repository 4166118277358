import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RenewIcon from "@mui/icons-material/Autorenew";
import { styled } from "@mui/material/styles";

export const Styled = styled(Box)(({ theme }) => ({
  "& .paper": {
    padding: "0.8rem",
    borderTop: "4px solid",
    borderTopColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
  },
  "& .verifyLink": {
    margin: "1rem 0.5rem",
    "& .MuiSvgIcon-root": {
      marginRight: "2px",
      verticalAlign: "sub",
      fontSize: "1.2rem",
    },
  },
  "& .item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .header": { fontSize: "1.25rem" },
  "& .message": { fontSize: ".9rem" },
}));

function StatusPage() {
  return (
    <Styled>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: "5rem" }}
      >
        <Grid item xs={11} sm={10} md={5.5}>
          <Paper elevation={3} square className="paper">
            <Grid item container justifyContent="center" className="verifyLink">
              <Button
                component={Link}
                variant="text"
                className="customLink"
                to="/resendVerifyAccountEmail"
              >
                <RenewIcon />
                {`If you didn't receive your verification email, please
                      click here.`}
              </Button>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item xs={2.5} className="item">
                <CheckCircleIcon sx={{ fontSize: "4.3rem" }} color="success" />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" className="header">
                  You have been registered successfully. Please check your email
                  to verify your account.
                </Typography>
                <Divider sx={{ my: "1rem" }} />

                <Typography className="message">
                  Our system just sent you an email verification. You will NOT
                  be able to login to your account until you click the link in
                  this message. Your account will not be accessible until you
                  click this link.
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: ".7rem" }} />
          </Paper>
        </Grid>
      </Grid>
    </Styled>
  );
}

export default StatusPage;
