import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";
import { showNotification } from "../../../../features/core/slices/notification.slice";
import { verifyLicenseNumber } from "../../services/form.service";

function VerifyLicenseNumberBtn(props) {
  const { fieldProps, getValues, setValue, trigger, disabled } = props;
  const dispatch = useDispatch();

  const onBtnClick = () => {
    const [success, error] = verifyLicenseNumber({
      fieldProps,
      getValues,
      setValue,
      trigger,
    });
    if (success) {
      dispatch(showNotification({ type: "success", msg: success, show: true }));
    } else if (error) {
      dispatch(showNotification({ type: "error", msg: error, show: true }));
    }
    if (fieldProps.properties?.source) {
      trigger(fieldProps.properties.source);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={onBtnClick}
      startIcon={<Check />}
      disabled={disabled}
    >
      {fieldProps.label}
    </Button>
  );
}
VerifyLicenseNumberBtn.propTypes = {
  fieldProps: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default VerifyLicenseNumberBtn;
