import React from "react";
import PropTypes from "prop-types";

import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  width: "100%",
  position: "fixed",
  top: "0",
  zIndex: "10001",
  "& > * + *": {
    marginTop: 2,
  },
});

export default function GlobalLoader(props) {
  const { custom } = props;
  if (custom) {
    return <LinearProgress color="primary" sx={{ marginTop: "-4px" }} />;
  }
  return (
    <StyledDiv>
      <LinearProgress color="primary" />
    </StyledDiv>
  );
}

GlobalLoader.propTypes = {
  custom: PropTypes.bool,
};
GlobalLoader.defaultProps = {
  custom: false,
};
