import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import DialogPopup from "../../../DialogPopup";
import Layout from "../../Layouts/Layout";
import { ReviewPropsProvider } from "../../context/reviewItemContext";
import { updateDialogActionPanel } from "../../context/dialogFormSlice";
import { requireFieldsInfo } from "../../context/formSlice";
import {
  mergeMainFormAndActionPanelData,
  updateFormConditions,
} from "../../services/form.service";

function FormRecordEditDialog(props) {
  const { methods: formMethods, actionsTemplate } = props;
  const dispatch = useDispatch();
  const actionPanelInfo = useSelector(
    (state) => state.dialogFormProps?.actionPanel || {}
  );

  const {
    fieldProps = {},
    parentLicense,
    subLicense,
    panel = "",
    readonly,
  } = actionPanelInfo;

  const { key, subLicensesKey = "subLicenses" } = fieldProps;

  const methods = useForm({ mode: "onChange" });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  const handleCancel = () => {
    dispatch(updateDialogActionPanel({ show: false }));
  };

  const selectedPanel = (actionsTemplate?.components || []).find(
    (component) => component.key === panel
  );

  const extraProperties = {};

  if (parentLicense)
    Object.keys(parentLicense).forEach((parentKey) => {
      const newKey = `parentLicense${parentKey
        .slice(0, 1)
        .toUpperCase()}${parentKey.slice(1)}`;
      if (parentKey !== "properties")
        extraProperties[newKey] = parentLicense[parentKey];
    });

  if (subLicense)
    Object.keys(subLicense).forEach((subLicenseKey) => {
      const newKey = `subLicense${subLicenseKey
        .slice(0, 1)
        .toUpperCase()}${subLicenseKey.slice(1)}`;
      if (subLicenseKey !== "properties")
        extraProperties[newKey] = subLicense[subLicenseKey];
    });

  const onSubmit = async (_payload) => {
    const payload = {};
    const keysNotToInclude = Object.keys(extraProperties);
    Object.entries(_payload).forEach(([payloadKey, payloadValue]) => {
      if (!keysNotToInclude.includes(payloadKey))
        payload[payloadKey] = payloadValue;
    });
    const licenses = formMethods.getValues(key) || [];
    const licenseIndex = licenses.findIndex(
      ({ licenseNumber }) => licenseNumber === parentLicense.licenseNumber
    );
    const licenseFormKey = `${key}[${licenseIndex}].properties`;
    const subLicenses = licenses[licenseIndex].properties[subLicensesKey] || [];

    if (!subLicense) {
      const newProperties = {
        ...payload,
        [subLicensesKey]: subLicenses,
      };
      formMethods.setValue(licenseFormKey, newProperties);
    } else {
      let subLicenseIndex = subLicenses.findIndex(
        ({ licenseNumber }) => licenseNumber === subLicense.licenseNumber
      );
      if (subLicenseIndex === -1) subLicenseIndex = subLicenses.length;
      const subLicenseFormKey = `${licenseFormKey}.${subLicensesKey}[${subLicenseIndex}].properties`;
      formMethods.setValue(subLicenseFormKey, payload);
    }
    handleCancel();
  };

  useEffect(() => {
    let properties = {
      ...parentLicense?.properties,
    };
    if (subLicense)
      properties = {
        ...subLicense.properties,
      };
    delete properties[subLicensesKey];
    if (actionPanelInfo?.show) {
      const mergedData = mergeMainFormAndActionPanelData(
        properties,
        requireFieldsInfo,
        formMethods
      );
      reset({ ...extraProperties, ...mergedData });
    } else reset({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionPanelInfo]);

  const updateBtn = {
    id: 1,
    name: "Update",
    icon: undefined,
    variant: "contained",
    onClickHandler: handleSubmit(onSubmit),
    show: true,
    disabled: !isValid || !isDirty,
  };

  const form = useMemo(() => {
    const editPanelComponents = [
      ...(selectedPanel?.components || []),
      ...(requireFieldsInfo.actionPanels[panel] || []),
    ];
    const updatedComponents = updateFormConditions(editPanelComponents);
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...methods} isDialogForm>
          <Layout components={updatedComponents} />
        </FormProvider>
      </form>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panel]);

  if (!(subLicense?.licenseNumber || parentLicense?.licenseNumber)) return null;

  return (
    <DialogPopup
      showTitle
      openPopup={Boolean(actionPanelInfo?.show)}
      maxWidth="md"
      onCancel={() => handleCancel()}
      showActionBtn={!readonly}
      title={`Edit License - ${
        subLicense?.licenseNumber || parentLicense.licenseNumber
      }`}
      buttonArray={[updateBtn]}
      content={
        <Box>
          {readonly && <ReviewPropsProvider>{form}</ReviewPropsProvider>}
          {!readonly && form}
        </Box>
      }
    />
  );
}

export default FormRecordEditDialog;
