import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { filter as lodashFilter } from "lodash";
import { useLocation } from "react-router-dom";

import { lighten } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Reset from "@mui/icons-material/Autorenew";
import Collapse from "@mui/material/Collapse";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useUpdatePanelHeight from "../Form/hooks/useUpdatePanelHeight";

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    onFilter,
    filterConfig,
    filterModels,
    req,
    open,
    allRowsExpanded,
    hasSubTable,
    expandAllRows,
    resetSelected,
  } = props;
  const location = useLocation();
  const { updateHeight } = useUpdatePanelHeight();
  const [showFilter, setShowFilter] = useState(open);
  const [_filterConfig, setFilterConfig] = useState(filterConfig?.common || []);
  const conditionalFilterRef = React.useRef(null);
  if (conditionalFilterRef.current === null) {
    conditionalFilterRef.current = filterConfig?.common?.some(
      (filter) => filter.conditional !== undefined
    );
  }

  const defaultProps = {};
  if (filterConfig && filterConfig.common.length > 0) {
    filterConfig.common.forEach((filter) => {
      defaultProps[filter.field] = filter.defaultValues;
    });
  }

  Object.keys(filterModels || {}).forEach((key) => {
    const config = filterConfig?.common?.find((obj) => obj.field === key) || {};
    const aliases = config.aliases || {};
    let filterModel = filterModels[key];
    if (Array.isArray(filterModel)) {
      // add filters display name
      filterModel = filterModel.map((model) => ({
        ...model,
        displayName: aliases[model.key] || model.name,
      }));
      filterModels[key] = filterModel;
    }
  });

  const [filterProps, setFilterProps] = useState(req);

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
    setTimeout(() => updateHeight(), 400);
  };

  const handleFilterChange = (event) => {
    const reqPayload = {
      ...filterProps,
      [event.target.name]: event.target.value,
    };
    if (conditionalFilterRef.current && filterConfig?.common) {
      filterConfig.common?.forEach((item) => {
        const { field, conditional } = item || {};
        if (conditional) {
          const { when, eq } = conditional || {};
          if (reqPayload[when] && reqPayload[when].indexOf(eq) < 0) {
            const key = field.indexOf(".") >= 0 ? field.split(".")[1] : field;
            delete reqPayload[key];
          }
        }
      });
    }
    setFilterProps(reqPayload);
    onFilter(reqPayload);
  };

  useEffect(() => {
    const prevConfigName = _filterConfig.map((item) => item?.field).join("");
    if (conditionalFilterRef.current && filterConfig?.common) {
      const configs = filterConfig.common.filter((item) => {
        if (!item.conditional) return true;
        const { when, eq } = item.conditional || {};
        return filterProps[when] && filterProps[when].indexOf(eq) >= 0;
      });
      const newConfigName = configs.map((item) => item?.field).join("");
      if (prevConfigName !== newConfigName) setFilterConfig(configs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps]);

  useEffect(() => {
    if (location?.search === "") {
      // to reset the filters when switching accounts
      const shouldReset = JSON.stringify(req) !== JSON.stringify(filterProps);
      if (shouldReset) setFilterProps(req);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, req]);

  const handleNameResolver = (selectedKeys, defaultObject) => {
    const selectedValues = selectedKeys.map((key) => {
      const [matchedValues] = lodashFilter(defaultObject, { key });
      return matchedValues?.displayName || "";
    });
    return selectedValues.join(",");
  };

  return (
    <div>
      <Toolbar
        sx={(theme) => ({
          color:
            // eslint-disable-next-line no-nested-ternary
            numSelected > 0
              ? theme.palette.mode === "light"
                ? "secondary.main"
                : "text.primary"
              : undefined,
          backgroundColor:
            // eslint-disable-next-line no-nested-ternary
            numSelected > 0
              ? theme.palette.mode === "light"
                ? lighten(theme.palette.primary.dark, 0.85)
                : "primary.dark"
              : undefined,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            &nbsp;
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Clear all selection">
            <IconButton
              aria-label="clear all selection"
              size="large"
              onClick={resetSelected}
            >
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {filterConfig && (
              <>
                {showFilter && (
                  <Tooltip title="Reset Filter">
                    <IconButton
                      size="large"
                      color="primary"
                      aria-label="Reset Filter"
                      disabled={
                        Object.keys(filterProps).filter(
                          (field) => filterProps[field]
                        ).length === 0
                      }
                      onClick={() => {
                        setFilterProps(defaultProps);
                        onFilter(defaultProps);
                      }}
                    >
                      <Reset />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Filter list">
                  <IconButton
                    aria-label="filter list"
                    onClick={handleFilterToggle}
                    sx={{ color: showFilter ? "primary.main" : undefined }}
                    size="large"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                {hasSubTable && (
                  <Tooltip
                    title={`${allRowsExpanded ? "Collapse" : "Expand"} all`}
                  >
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={expandAllRows}
                    >
                      {allRowsExpanded ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
      </Toolbar>
      <Collapse in={showFilter} unmountOnExit>
        <Grid
          container
          spacing={2}
          sx={{
            padding: "10px",
            "& .MuiSelect-outlined": { pb: "14px" },
            "& .MuiInput-input": { mt: "-3px", pb: "8px" },
          }}
        >
          {_filterConfig.map((filter) => {
            const fieldName =
              filter.field?.indexOf(".") !== -1
                ? filter.field.split(".")[1]
                : filter.field;
            return (
              <Grid item xs={12} sm={6} md={3} key={filter.filterId}>
                <FormControl variant="outlined" fullWidth>
                  {filter.type === "multiSelect" && (
                    <>
                      <InputLabel id={`${filter.filterId}Label`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={`${filter.filterId}Label`}
                        id={filter.filterId}
                        name={fieldName}
                        value={filterProps[fieldName] || []}
                        label={filter.label}
                        onChange={handleFilterChange}
                        multiple
                        renderValue={() =>
                          handleNameResolver(
                            filterProps[fieldName],
                            filterModels[fieldName]
                          )
                        }
                        MenuProps={{
                          sx: (theme) => ({
                            "& .MuiMenuItem-root": {
                              "& .MuiSvgIcon-root": {
                                fontSize: "15px",
                                marginRight: "6px",
                                display: "none",
                              },
                              "&.Mui-selected": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? "#f1f1f1"
                                    : "#595959",
                                "& .MuiSvgIcon-root": {
                                  display: "inline",
                                },
                              },
                            },
                          }),
                        }}
                      >
                        {filterModels[fieldName] &&
                          filterModels[fieldName]
                            .filter(
                              (option) =>
                                !filter.condition ||
                                option[filter.condition.key] ===
                                  filter.condition.value
                            )
                            .map((option) => (
                              <MenuItem
                                value={option.value ? option.value : option.key}
                                key={option.key}
                              >
                                <CheckCircleIcon color="primary" />
                                {option.displayName}
                              </MenuItem>
                            ))}
                      </Select>
                    </>
                  )}

                  {filter.type === "select" && (
                    <>
                      <InputLabel id={`${filter.filterId}Label`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={`${filter.filterId}Label`}
                        id={filter.filterId}
                        name={fieldName}
                        value={filterProps[fieldName] || ""}
                        label={filter.label}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {filterModels[fieldName] &&
                          filterModels[fieldName]
                            .filter(
                              (option) =>
                                !filter.condition ||
                                option[filter.condition.key] ===
                                  filter.condition.value
                            )
                            .map((option) => (
                              <MenuItem
                                value={option.value ? option.value : option.key}
                                key={option.key}
                              >
                                {option.displayName}
                              </MenuItem>
                            ))}
                      </Select>
                    </>
                  )}

                  {filter.type === "input" && (
                    <InputMask
                      mask={filter?.mask}
                      value={filterProps[fieldName] || ""}
                      onChange={handleFilterChange}
                      autoComplete="off"
                      maskChar=""
                    >
                      {() => (
                        <TextField
                          label={filter.label}
                          name={fieldName}
                          id={filter.filterId}
                          value={filterProps[fieldName] || ""}
                          onChange={handleFilterChange}
                          autoComplete="off"
                          variant="standard"
                        />
                      )}
                    </InputMask>
                  )}
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
      </Collapse>
    </div>
  );
}
EnhancedTableToolbar.whyDidYouRender = true;
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onFilter: PropTypes.func,
  filterConfig: PropTypes.oneOfType([PropTypes.object]),
  filterModels: PropTypes.oneOfType([PropTypes.object]),
  req: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  allRowsExpanded: PropTypes.bool,
  hasSubTable: PropTypes.bool,
  expandAllRows: PropTypes.func,
  resetSelected: PropTypes.func,
};
EnhancedTableToolbar.defaultProps = {
  filterConfig: null,
  filterModels: null,
  onFilter: () => {},
  req: {},
  open: false,
  allRowsExpanded: false,
  hasSubTable: false,
  expandAllRows: () => null,
  resetSelected: () => null,
};

export default EnhancedTableToolbar;
