import React from "react";
import PropTypes from "prop-types";

import PreviewDialog from "./PreviewDialog";
import DocumentDialog from "./DocumentDialog";

function DialogComponent(props) {
  const {
    dialogProps,
    selectedDocument,
    setDialogProps,
    resetDialogProps,
    ...rest
  } = props;
  const { type, show } = dialogProps;

  if (show && type === "preview") {
    return (
      <PreviewDialog
        resetDialogProps={resetDialogProps}
        selectedDocument={selectedDocument}
      />
    );
  }
  if (show) {
    return (
      <DocumentDialog
        dialogProps={dialogProps}
        resetDialogProps={resetDialogProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    );
  }
  return "";
}
DialogComponent.propTypes = {
  dialogProps: PropTypes.shape({
    type: PropTypes.string,
    show: PropTypes.bool,
  }).isRequired,
  resetDialogProps: PropTypes.func.isRequired,
  selectedDocument: PropTypes.shape({}),
};
DialogComponent.defaultProps = {
  selectedDocument: null,
};
export default DialogComponent;
