const isElevatedEnv = /-sandbox[.]/.test(window.location.host);
const isLowerEnv =
  /-dev[.]|-development[.]|-test[.]|-qa[.]|-local[.]|localhost/.test(
    window.location.host
  );

function parseUrlParam(str) {
  let queryObj = {};
  try {
    queryObj = JSON.parse(str);
  } catch (err) {
    return queryObj;
  }
  return queryObj;
}
/* drill down through data return final value */
function getPropertyValue(data, path) {
  if (path.indexOf(".") === -1) return data[path];
  const parts = path.split(".");
  let value = data;
  if (parts.length > 1) {
    parts.forEach((part) => {
      if (typeof value === "object") {
        value = value[part];
      }
    });
  }
  return typeof value === "object" ? "" : value;
}

function getformattedCellValue(value, condition, format, rowsInfo) {
  if (!format || !rowsInfo[condition]) return value;

  let formattedValue = format;
  let hasDynamicVariable = true;
  while (hasDynamicVariable) {
    const startIndex = formattedValue.indexOf("{{");
    if (startIndex === -1 || startIndex === undefined) {
      hasDynamicVariable = false;
      break;
    }
    const endIndex = formattedValue.indexOf("}}");
    const dynamicVariable = formattedValue.substring(startIndex + 2, endIndex);
    formattedValue = formattedValue.replace(
      `{{${dynamicVariable}}}`,
      rowsInfo[dynamicVariable]
    );
  }
  return formattedValue;
}

/* construct app navigation url based on data and config */
function getNavLink(data, config) {
  if (!data || !config.linkTo) return null;
  const parts = config.linkTo.split("/");
  if (parts.length < 3) return config.linkTo;
  let navLink = "";
  parts.forEach((part) => {
    if (part) {
      if (part.indexOf(":") === -1) {
        navLink = navLink === "" ? `/${part}/` : `${navLink}${part}/`;
      } else {
        navLink =
          navLink === ""
            ? `/${data[part.substring(part, 1)]}/`
            : `${navLink}${data[part.substring(1)]}/`;
      }
    }
  });
  return navLink;
}

function notifyError(dispatch, showNotification, error) {
  const msg =
    error?.data?.message && typeof error.data?.message === "string"
      ? error.data.message
      : "Could not process request, please try later.";
  dispatch(showNotification({ type: "error", msg, show: true }));
}

const getValue = (field, defaultValues, searchParams, splitVal) => {
  const value = searchParams.get(field);
  if (value) return splitVal ? value.split(",") : value;
  if (Array.isArray(defaultValues) && !splitVal) return defaultValues.join(",");
  return defaultValues;
};

function getAppliedFilters(listConfig, searchParams) {
  const { filters, columns } = listConfig || {};
  const defaultProps = {
    page: searchParams.get("page") || 1,
    limit: searchParams.get("limit") || 10,
    sort: searchParams.get("sort") || "",
  };
  const reqInitState = { ...defaultProps };
  const payload = { ...defaultProps };
  filters?.common?.forEach((filter) => {
    const { field, defaultValues } = filter || {};
    if (field.indexOf(".") === -1) {
      reqInitState[field] = getValue(field, defaultValues, searchParams, true);
      payload[field] =
        getValue(field, defaultValues, searchParams) || undefined;
    } else {
      const props = field.split(".");
      const rootProp = props[0];
      const key = props[1];
      const value = getValue(key, defaultValues, searchParams);
      if (value) {
        payload[rootProp] = { ...(payload[rootProp] || {}), [key]: value };
        reqInitState[key] = getValue(key, defaultValues, searchParams, true);
      }
    }
  });
  columns?.forEach((filter) => {
    if (filter?.defaultSort) {
      reqInitState.sort = searchParams.get("sort")
        ? searchParams.get("sort").split(",")
        : filter.sortBy;
      payload.sort = searchParams.get("sort") || filter.sortBy;
    }
  });
  return [reqInitState, payload];
}
function constructUrlObject(q) {
  const stringObject = q;
  if (stringObject.invoiceId?.length !== 4) {
    stringObject.invoiceId = "";
  }
  Object.keys(stringObject).forEach((key) => {
    stringObject[key] = stringObject[key] ? String(stringObject[key]) : "";
  });
  return stringObject;
}

function validatePasswords(pwdA, pwdB, type) {
  if (pwdA !== pwdB) {
    return type === "register"
      ? "Password doesn't match."
      : "Both new password doesn't match.";
  }
  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(pwdA)) {
    return false;
  }
  return "All passwords must be at least eight characters long and contain at least one uppercase letter, one lowercase letter, and one number.";
}

// for digital card dropdown
function getDisplayTitle(titleMapping, recordType, data) {
  let displayTitle = "";
  const type = titleMapping.find((item) => item.key === recordType);
  if (type && type.title && type.title.length) {
    displayTitle += " (";
    type.title.forEach((item, index) => {
      displayTitle += data[item];
      const separator = index + 1 === type.title.length ? "" : " ";
      displayTitle += separator;
    });
    displayTitle += ")";
  }
  return displayTitle;
}

const getMaskedSocialSecurity = (value) => {
  if (!value) return value;
  const inputValueLength = value.toString().length;
  let maskInputLength = inputValueLength;
  if (inputValueLength > 3) maskInputLength += 1;
  if (inputValueLength > 5) maskInputLength += 1;
  return "XXX-XX-XXXX".slice(0, maskInputLength);
};

const formatSocialSecurity = (value) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{3})/, "$1-")
    .replace(/-(\d{2})/, "-$1-")
    .replace(/(\d)-(\d{4}).*/, "$1-$2");
};

const getUnmaskedSocialSecurity = ({
  fieldProps,
  value,
  isFormatted = true,
}) => {
  if (fieldProps?.isDecrypted === true) {
    if (isFormatted) return formatSocialSecurity(value);
    return value;
  }
  return getMaskedSocialSecurity(value);
};

const getSocialSecurityNumber = ({
  isMasked = true,
  value = "",
  fieldProps = {},
  isFormatted = false,
}) =>
  isMasked
    ? getMaskedSocialSecurity(value)
    : getUnmaskedSocialSecurity({
        fieldProps,
        value,
        isFormatted,
      });

function getGeocodeLatLong(data) {
  if (data && data.geocodes) {
    const geocodeData = data.geocodes.primary;
    if (geocodeData && geocodeData.code === "OK" && geocodeData.location)
      return {
        lat: geocodeData.location.coordinates[1],
        long: geocodeData.location.coordinates[0],
        accuracy: (geocodeData.confidenceLevel || 0) * 100,
      };

    return {
      lat: "No information available.",
      long: "No information available.",
      accuracy: null,
    };
  }
  return null;
}

function convertOunce(input, type) {
  const ozToGmFactor = 28.34952;
  if (Number.isNaN(+input)) return input;
  if (type === "OzToMg") {
    const milligramsPerOunce = ozToGmFactor * 1000;
    return (input * milligramsPerOunce).toFixed(1);
  }
  const gms = Number(input) * ozToGmFactor;
  return gms.toFixed(1);
}

function getPrevMutationSelector(state, endpointName) {
  const mutations = Object.values(state.api.mutations).filter(
    (mutation) => mutation.endpointName === endpointName
  );
  if (!mutations.length) return null;
  return mutations.reduce((accumulator, currentMutation) => {
    if (currentMutation.startedTimeStamp > accumulator.startedTimeStamp)
      return currentMutation;
    return accumulator;
  });
}

function convertNumberToOrdinal(inputNumber) {
  if (typeof inputNumber !== "number") return "Input is not a valid";
  if (inputNumber % 1 !== 0) return "Input is not an integer";
  if (inputNumber < 0) return "Input must be a non-negative integer";

  const lastDigit = inputNumber % 10;
  const lastTwoDigits = inputNumber % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return `${inputNumber}th`;

  switch (lastDigit) {
    case 1:
      return `${inputNumber}st`;
    case 2:
      return `${inputNumber}nd`;
    case 3:
      return `${inputNumber}rd`;
    default:
      return `${inputNumber}th`;
  }
}

export {
  isLowerEnv,
  isElevatedEnv,
  parseUrlParam,
  getPropertyValue,
  getformattedCellValue,
  getNavLink,
  notifyError,
  getAppliedFilters,
  constructUrlObject,
  validatePasswords,
  getDisplayTitle,
  getGeocodeLatLong,
  getMaskedSocialSecurity,
  getUnmaskedSocialSecurity,
  getSocialSecurityNumber,
  convertOunce,
  convertNumberToOrdinal,
  getPrevMutationSelector,
};
