import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function PrivacyPolicy() {
  // Privacy Act Statement
  return (
    <Grid
      container
      spacing={2}
      sx={{ "& .MuiTypography-root": { fontSize: "0.88rem" } }}
    >
      <Grid item xs={12}>
        <Typography>
          <span>
            <b>1.Authority:</b> The FBI’s acquisition, preservation, and
            exchange of fingerprints and associated information is generally
            authorized under 28 U.S.C. 534. Depending on the nature of your
            application, supplemental authorities include Federal statutes,
            State statutes pursuant to Pub. L. 92-544, Presidential Executive
            Orders, and federal. Providing your fingerprints and associated
            information is voluntary; however, failure to do so may affect
            completion or approval of your application.
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <span>
            <b>2.Social Security Account Number (SSAN).</b> Your SSAN is needed
            to keep records accurate because other people may have the same name
            and birth date. Pursuant to the Federal Privacy Act of 1974 (5 USC
            552a), the requesting agency is responsible for informing you
            whether disclosure is mandatory or voluntary, by what statutory or
            other authority your SSAN is solicited, and what uses will be made
            of it. Executive Order 9397 also asks Federal agencies to use this
            number to help identify individuals in agency records.{" "}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <span>
            <b>3.Principal Purpose:</b> Certain determinations, such as
            employment, licensing, and security clearances, may be predicated on
            fingerprint-based background checks. Your fingerprints and
            associated information/biometrics may be provided to the employing,
            investigating, or otherwise responsible agency, and/or the FBI for
            the purpose of comparing your fingerprints to other fingerprints in
            the FBI’s Next Generation Identification (NGI) system or its
            successor systems (including civil, criminal, and latent fingerprint
            repositories) or other available records of the employing,
            investigating, or otherwise responsible agency. The FBI may retain
            your fingerprints and associated information/biometrics in NGI after
            the completion of this application and, while retained, your
            fingerprints may continue to be compared against other fingerprints
            submitted to or retained by NGI.{" "}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <span>
            <b>4.Routine Uses:</b> During the processing of this application and
            for as long thereafter as your fingerprints and associated
            information/biometrics are retained in NGI, your information may be
            disclosed pursuant to your consent, and may be disclosed without
            your consent as permitted by the Privacy Act of 1974 and all
            applicable Routine Uses as may be published at any time in the
            Federal Register, including the Routine Uses for the NGI system and
            the FBI’s Blanket Routine Uses. Routine uses include, but are not
            limited to, disclosures to: employing, governmental or authorized
            non-governmental agencies responsible for employment, contracting
            licensing, security clearances, and other suitability
            determinations; local, state, tribal, or federal law enforcement
            agencies; criminal justice agencies; and agencies responsible for
            national security or public safety.{" "}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <span>
            <b>5.Additional Information:</b> The requesting agency and/or the
            agency conducting the applicationinvestigation will provide you
            additional information pertinent to the specific circumstances of
            this application, which may include identification of other
            authorities, purposes, uses, and consequences of not providing
            requested information. In addition, any such agency in the Federal
            Executive Branch has also published notice in the Federal Register
            describing any systems(s) of records in which that agency may also
            maintain your records, including the authorities, purposes, and
            routine uses for the system(s).{" "}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ color: "grey" }}>
          <i>
            <small>Privacy Policy 1.0; Published on 9/9/2013 </small>
          </i>{" "}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;
