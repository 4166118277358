import React, { forwardRef } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import { ActivateDeactivateButton, EditButton } from "./FormRecordActionBtns";
import DataTable from "../../../DataTable";
import { useTableConfigQuery } from "../../../../api/applications.api.slice";

const FormRecordSubTable = forwardRef((props, ref) => {
  const { license, subLicenses, onSelect, onSelectAll, readonly, fieldProps } =
    props;
  const { selectableLicenses = "Approved,Expired" } =
    fieldProps.properties || {};
  const { applicationType, recordType } = useParams();
  const { data: tablesConfig, isSuccess } = useTableConfigQuery(
    applicationType || recordType
  );

  const actionButtonResolver = (data) => (
    <Box>
      <EditButton
        license={license}
        subLicense={data}
        fieldProps={fieldProps}
        readonly={readonly}
      />
      <ActivateDeactivateButton license={license} />
    </Box>
  );

  if (!isSuccess) return null;

  const columns = tablesConfig?.subTable?.columns || [];

  return (
    <Box className="subTable" sx={{ margin: 1 }}>
      <DataTable
        ref={ref}
        progress={false}
        config={{
          rowSelect: true,
          compareBy: "licenseNumber",
          onSelect,
          onSelectAll,
          hideToolbar: true,
          isRowSelectable: (row) => selectableLicenses.includes(row.status),
        }}
        rows={subLicenses}
        updateList={() => null}
        tableHeaders={columns}
        filters={tablesConfig?.subTable?.filters}
        elevation={0}
        models={{ ...(tablesConfig?.subTable?.filterModels || {}) }}
        selectedRows={subLicenses.filter((x) => x.isSelected)}
        resolvers={{ action: actionButtonResolver }}
        readonly={readonly}
      />
    </Box>
  );
});

FormRecordSubTable.propTypes = {
  license: PropTypes.shape({
    licenseExpiryDate: PropTypes.string,
    licenseNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recordType: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  subLicenses: PropTypes.arrayOf(
    PropTypes.shape({
      licenseExpiryDate: PropTypes.string,
      licenseNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      recordType: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
};

FormRecordSubTable.defaultProps = {
  readonly: false,
};

export default FormRecordSubTable;
