import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Template(props) {
  const { record, properties, selectedRecordId, handleRecordSelection } = props;

  const {
    template,
    physicianFNameKey,
    physicianLNameKey,
    physicianExamDateKey,
  } = properties || {};

  const getBgColor = (theme, selected) => {
    const backgroundColor =
      selected && theme.palette.mode === "light"
        ? "#c2eaa1"
        : selected && theme.palette.mode !== "light" && "#659e36";
    return { backgroundColor };
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      handleRecordSelection(record);
    }
  };

  const titleMap = {
    physicianRecommendation: `Physician Name: ${
      record.properties[physicianFNameKey] || ""
    } ${record.properties[physicianLNameKey] || ""}`,
    offerLetter: `Associated Business Name: ${
      record.properties?.genInfoEntityName || ""
    }`,
  };
  return (
    <Card
      elevation={2}
      onClick={() => handleRecordSelection(record)}
      onKeyDown={handleKeyDown}
      className="card"
      sx={(theme) => getBgColor(theme, selectedRecordId === record._id)}
      tabIndex={0}
    >
      <CardHeader
        title={titleMap[template] || ""}
        action={
          selectedRecordId === record._id && (
            <CheckCircleIcon fontSize="small" />
          )
        }
      />
      <Divider />
      {template === "physicianRecommendation" && (
        <CardContent sx={{ display: "flex", flexWrap: "wrap" }}>
          <Typography variant="body1" className="body1">
            Recommendation ID: {record.recordId}
          </Typography>
          <Typography variant="body1">
            Examination Date: {record.properties[physicianExamDateKey] || ""}
          </Typography>
        </CardContent>
      )}
      {template === "offerLetter" && (
        <CardContent sx={{ display: "flex", flexWrap: "wrap" }}>
          <Typography variant="body1" className="body1">
            Offer Letter ID: {record.properties?.recordId}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}
Template.propTypes = {
  record: PropTypes.shape({
    properties: PropTypes.shape({
      recordId: PropTypes.number,
      genInfoEntityName: PropTypes.string,
    }),
  }).isRequired,
  properties: PropTypes.shape({
    template: PropTypes.string,
    physicianFNameKey: PropTypes.string,
    physicianLNameKey: PropTypes.string,
    physicianExamDateKey: PropTypes.string,
  }).isRequired,
  selectedRecordId: PropTypes.string,
  handleRecordSelection: PropTypes.func.isRequired,
};
Template.defaultProps = {
  selectedRecordId: null,
};
export default Template;
