import React from "react";
// eslint-disable-next-line import/no-unresolved
import TermsConditions from "@regional/templates/TermsConditions";
import GridContainer from "../../../components/GridContainer";

function TermsConditionPage() {
  const title = "Terms and Conditions";
  return (
    <GridContainer title={title} width="full">
      <TermsConditions />
    </GridContainer>
  );
}

export default TermsConditionPage;
