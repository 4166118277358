import React, { useEffect, useMemo, useRef } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import RemoveIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";

import Table from "../../Table";
import DgDialog from "../DgDialog";
import DgDivider from "../DgDivider";

import { useDatagridContextValue } from "../../../context/datagridContext";
import { updateMethodRef, methodRefs } from "../../../context/formSlice";
import { showNotification } from "../../../../../features/core/slices/notification.slice";
import useScrollPosition from "../../../../../hooks/useScrollPosition";
import useUpdatePanelHeight from "../../../hooks/useUpdatePanelHeight";
import useDatagridState from "../../../hooks/useDatagridState";

import {
  getDgPayload,
  relationalDgCheck,
  updateMainFormDgList,
  checkForUniqueRecord,
  removeAssocRecFromDgPayload,
  hasInvalidControllingField,
} from "../../../services/form.relationalDg.service";
import { notifyError } from "../../../../../utils/helpers";
import Styled from "../styles";

function EditableRecord(props) {
  const {
    record,
    gridItem,
    recordIndex,
    appendRecord,
    updateRecord,
    removeRecord,
    records,
    allowBtnAction,
    updateForm,
    dgConfigRef,
    isCategorizedView,
  } = props;
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext();
  const { getDgState } = useDatagridState();
  const [captureCurrentPosition, scrollToCapturedPosition] =
    useScrollPosition();

  const gridRecord = useSelector(
    (state) => state.formProps[`${gridItem.key}${record.id}`]
  );
  const formConfig = useSelector((state) => state.formProps.formConfig);
  const formData = useSelector((state) => state.formProps.formData);
  const applicationData = useSelector(
    (state) => state.formProps.applicationData
  );
  const { datagridFormState, dispatch: dgDispatch } = useDatagridContextValue();
  const { updateHeight } = useUpdatePanelHeight();

  const datagridForm = useForm({
    defaultValues: record,
    mode: "onBlur",
  });

  const [dialogProps, setDialogProps] = React.useState(null);
  const recordDataRef = useRef(null);

  const { maxRecordCount, allowedActions } = dgConfigRef.current || {};

  const memoizedTable = useMemo(
    () =>
      gridItem.components.map((tableGrid) =>
        tableGrid.type === "table" ? (
          <Table key={tableGrid.key} components={tableGrid} />
        ) : null
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datagridFormState]
  );
  const renderTable =
    gridItem &&
    datagridFormState &&
    datagridFormState.methods &&
    gridRecord &&
    memoizedTable;

  const { properties } = gridItem || {};
  const getCommonProps = () => ({
    key: gridItem.key,
    recordIndex,
    properties,
    getValues,
  });

  const triggerNotification = (type, msg) => {
    const toastMsgMap = {
      update: "Application Form Updated Successfully",
      save: "Record saved successfully",
      error:
        "Please enter all required fields and complete address verification.",
      warn: "Please save all the record(s) which are currently being edited before performing this action.",
      doc: "Please enter all required fields, upload all required documents and complete address verification.",
    };
    dispatch(showNotification({ type, msg: toastMsgMap[msg], show: true }));
  };

  const handleUpdateForm = async (formProperties) => {
    const payload = {
      id: applicationData.id,
      body: { formProperties },
      originalFormProps: formData,
    };
    try {
      await updateForm(payload).unwrap();
      return true;
    } catch (error) {
      notifyError(dispatch, showNotification, error);
      return false;
    }
  };

  const onSubmit = async (data, shouldRemoveAssocRecord = false) => {
    const payload = {
      ...getCommonProps(),
      getDgState,
      type: "submit",
      record: data ?? recordDataRef.current,
      shouldRemoveAssocRecord,
    };
    const { recordData, formProperties } = getDgPayload(payload);
    const result = await handleUpdateForm(formProperties);
    if (result) {
      updateRecord(recordIndex, { ...recordData, status: "saved" });
      triggerNotification("success", "save");
      scrollToCapturedPosition();
      if (shouldRemoveAssocRecord && recordDataRef.current)
        updateMainFormDgList(properties, setValue, formProperties, getDgState);
    }
    recordDataRef.current = null;
    updateHeight();
  };

  const validateAndSubmit = (data) => {
    const payload = {
      ...getCommonProps(),
      getDgState,
      record: data,
    };
    if (properties && properties.associatedDatagrids) {
      const status = checkForUniqueRecord(payload);
      if (!status.isValid && status.errorMsg) {
        const [type, msg] = ["warning", status.errorMsg];
        dispatch(showNotification({ type, msg, show: true }));
        return;
      }
      if (gridItem.validate?.custom) {
        const { custom } = gridItem.validate;
        const result = hasInvalidControllingField(payload, custom);
        if (result) {
          recordDataRef.current = data;
          setDialogProps({ type: "invalidControllingField", custom });
          return;
        }
      }
    }
    onSubmit(data);
  };

  const onError = () => {
    setTimeout(() => updateHeight(), 100);
    updateHeight();
    // to check if there is any document inside record
    if (datagridForm.getValues("docRefId"))
      triggerNotification("warning", "doc");
    else triggerNotification("warning", "error");
  };

  const handleAppendRecord = () => {
    if (allowBtnAction.current) {
      appendRecord();
      updateHeight();
      setTimeout(() => updateHeight(), 50);
    } else triggerNotification("warning", "warn");
  };

  const handleEditRecord = () => {
    if (allowBtnAction.current) {
      updateHeight();
      captureCurrentPosition();
      dgDispatch({
        type: "update",
        payload: {
          key: "isEditable",
          value: true,
        },
      });
      // to track the editable mode record(required for verifyDgFields method), we update the "status" prop in the formValue
      methodRefs[`${gridItem.key}${record.id}`].register("status");
      methodRefs[`${gridItem.key}${record.id}`].setValue("status", "editing");
      allowBtnAction.current = false;
      setTimeout(() => updateHeight(), 100);
    } else triggerNotification("warning", "warn");
  };

  const handleCancelAction = async () => {
    const formProperties = { ...getValues() };
    const result = await handleUpdateForm(formProperties);
    if (result) {
      updateRecord(recordIndex, { ...record });
      triggerNotification("success", "update");
      scrollToCapturedPosition();
    }
    updateHeight();
  };

  const handleRemoveRecord = async (shouldRemoveAssocRec = false) => {
    if (allowBtnAction.current || [undefined, "new"].includes(record.status)) {
      captureCurrentPosition();
      let formProperties;
      const payload = { ...getCommonProps(), type: "remove" };
      if (
        !shouldRemoveAssocRec &&
        relationalDgCheck(properties, record, getValues, getDgState)
      ) {
        setDialogProps({ type: "removeRecord" });
      } else if (!shouldRemoveAssocRec) {
        formProperties = getDgPayload(payload);
      } else {
        formProperties = removeAssocRecFromDgPayload(
          {
            ...payload,
            record,
            getDgState,
          },
          getDgPayload(payload)
        );
        setDialogProps(null);
      }

      if (formProperties) {
        const result = await handleUpdateForm(formProperties);
        if (result) {
          removeRecord(recordIndex);
          if (shouldRemoveAssocRec)
            updateMainFormDgList(
              properties,
              setValue,
              formProperties,
              getDgState
            );
          triggerNotification("success", "update");
          scrollToCapturedPosition();
        }
      }
    } else triggerNotification("warning", "warn");
    updateHeight();
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(
        updateMethodRef({
          key: `${gridItem.key}${record.id}`,
          methodRef: datagridForm,
        })
      );
      dgDispatch({
        type: "update",
        payload: {
          key: "methods",
          value: datagridForm,
        },
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.id]);

  const { isEditable } = datagridFormState || {};

  const showRemoveBtn =
    ((isEditable && record.status !== "saved") ||
      (!isEditable && record.status === "saved")) &&
    (records.length > 1 || gridItem.initEmpty) &&
    (!allowedActions || allowedActions.includes("remove"));

  const showEditBtn =
    !isEditable && (!allowedActions || allowedActions.includes("edit"));

  const showCancelBtn = isEditable && record.status === "saved";

  const showAddBtn =
    !isCategorizedView &&
    !isEditable &&
    records.length === recordIndex + 1 &&
    (!maxRecordCount || maxRecordCount > records.length) &&
    (!allowedActions || allowedActions.includes("add"));

  return (
    <Styled>
      {renderTable}
      {!formConfig.readOnly && (
        <Grid container className="gridContainer">
          {showRemoveBtn && (
            <Button
              onClick={() => handleRemoveRecord()}
              startIcon={<RemoveIcon />}
              color="error"
            >
              Remove Record
            </Button>
          )}
          {showEditBtn && (
            <Button onClick={handleEditRecord} startIcon={<EditIcon />}>
              Edit Record
            </Button>
          )}
          {showCancelBtn && (
            <Button
              onClick={handleCancelAction}
              startIcon={<BlockIcon sx={{ fontSize: "15px !important" }} />}
              color="error"
            >
              Cancel
            </Button>
          )}
          {isEditable && (
            <Button
              variant="outlined"
              onClick={datagridForm.handleSubmit(validateAndSubmit, onError)}
              startIcon={<SaveIcon />}
              className="saveBtnOutlined"
            >
              Save Record
            </Button>
          )}
          {showAddBtn && (
            <Button onClick={handleAppendRecord} startIcon={<AddIcon />}>
              Add New Record
            </Button>
          )}
        </Grid>
      )}
      <DgDivider />
      {Boolean(dialogProps) && (
        <DgDialog
          dialogProps={dialogProps}
          setDialogProps={setDialogProps}
          saveRecord={onSubmit}
          removeRecord={handleRemoveRecord}
        />
      )}
    </Styled>
  );
}
EditableRecord.propTypes = {
  gridItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
    components: PropTypes.oneOfType([PropTypes.array]).isRequired,
    initEmpty: PropTypes.bool,
  }).isRequired,
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
  }).isRequired,
  isCategorizedView: PropTypes.bool.isRequired,
  recordIndex: PropTypes.number.isRequired,
  appendRecord: PropTypes.func.isRequired,
  updateRecord: PropTypes.func.isRequired,
  removeRecord: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  records: PropTypes.oneOfType([PropTypes.array]).isRequired,
  allowBtnAction: PropTypes.shape({ current: PropTypes.bool }).isRequired,
  dgConfigRef: PropTypes.shape({ current: PropTypes.shape({}) }).isRequired,
};

export default EditableRecord;
