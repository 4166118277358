/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";

import LocalLoader from "../../components/Loaders/Local";
import loginClasses from "../Login/Login.module.css";
import { useResendEmailMutation } from "../../api/auth.api.slice";
import { showNotification } from "../../features/core/slices/notification.slice";
import { notifyError } from "../../utils/helpers";

function VerifyEmail() {
  const dispatch = useDispatch();
  const [verifyEmail, { isLoading }] = useResendEmailMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [respMsg, setRespMsg] = React.useState("");

  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
    };

    try {
      const res = await verifyEmail(payload).unwrap();
      if (res && res.message) setRespMsg(res.message);
    } catch (error) {
      notifyError(dispatch, showNotification, error);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={loginClasses.loginGrid}
        alignItems="center"
      >
        <Grid
          item
          xs={11}
          sm={8}
          md={6}
          sx={{ my: "1rem", position: "relative" }}
        >
          <Card raised>
            <form onSubmit={(e) => e.preventDefault()}>
              <CardHeader
                title="Resend Verify Account Email"
                className={loginClasses.cardHeader}
              >
                Resend Verify Account Email
              </CardHeader>
              <CardContent>
                <TextField
                  label="Enter your registered email"
                  id="email"
                  name="email"
                  required
                  type="text"
                  margin="normal"
                  variant="standard"
                  autoComplete="off"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email address",
                    },
                    maxLength: {
                      value: 50,
                      message: "Email must not exceed 50 characters",
                    },
                  })}
                  fullWidth
                  error={errors?.email !== undefined}
                  helperText={errors?.email ? errors.email.message : ""}
                />
              </CardContent>
              <CardActions disableSpacing>
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "center",
                    mb: "1rem",
                    "& .MuiSvgIcon-root": {
                      transform: "rotate(-45deg)",
                      mt: "-7px",
                    },
                  }}
                >
                  <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                    <SendIcon />
                    Resend Verify Account Email
                  </Button>
                </Grid>
              </CardActions>
              {respMsg && (
                <Typography
                  variant="subtitle1"
                  color="primary"
                  align="center"
                  sx={{ m: "0 .5rem 1em", fontSize: "0.9rem" }}
                >
                  {respMsg}
                </Typography>
              )}
            </form>
          </Card>
          <LocalLoader progress={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
}
export default VerifyEmail;
