import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

function getStyle(state, theme, value) {
  if (state.selectedItem !== value) {
    if (theme.palette.mode === "light") return "#b1e580";
    return "rgb(39, 142, 35)";
  }
  return "none";
}

const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "dialogState",
})(({ dialogState, theme }) => ({
  "& .gridContainer": {
    padding: "1rem 1.5rem",
    justifyContent: "center",
    position: "relative",
    "& .MuiAlert-icon": { display: "none" },
    "& .MuiCard-root": {
      minHeight: "200px",
      cursor: "pointer",
    },
    "& .MuiCardHeader-root": {
      padding: "8px 15px 8px 12px",
      "& .MuiTypography-root": {
        fontSize: "1rem",
        fontWeight: theme.palette.mode === "light" ? 600 : 500,
      },
    },
    "& .MuiCardHeader-action": {
      color:
        theme.palette.mode === "light" ? theme.palette.primary.main : "#fff",
    },
    "& .MuiCardContent-root": {
      padding: "8px 15px",
      verticalAlign: "middle",
    },
    "& .userAddress": {
      wordWrap: "break-word",
      backgroundColor:
        dialogState.selectedItem === 1 && theme.palette.mode === "light"
          ? "#c2eaa1"
          : dialogState.selectedItem === 1 &&
            theme.palette.mode !== "light" &&
            "rgb(39 142 35)",

      "&:hover": {
        // backgroundColor: theme.palette.mode === "light" ? "#b1e580" : "#6B8E23",
        backgroundColor: getStyle(dialogState, theme, 1),
      },
    },
    "& .verifyCard": {
      cursor: dialogState.isError ? "default !important" : "pointer",
      wordWrap: "break-word",
      backgroundColor:
        dialogState.selectedItem === 2 && theme.palette.mode === "light"
          ? "#c2eaa1"
          : dialogState.selectedItem === 2 &&
            theme.palette.mode !== "light" &&
            "#659e36",
      "&:hover": {
        backgroundColor: dialogState.isError
          ? "none !important"
          : getStyle(dialogState, theme, 2),
      },
    },
  },
}));
export default Styled;
