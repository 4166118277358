/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormState, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import { showFormDialog } from "../context/dialogSlice";
import { generateFormFieldRules } from "../services/form.service";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";

const Styled = styled(Box)(({ theme }) => ({
  "& .MuiFormControl-root": {
    minHeight: "75px",
  },
  "& .MuiFormLabel-root": {
    color:
      theme.palette.mode === "light"
        ? "#000 !important"
        : "rgba(255, 255, 255, 0.7)",
    fontSize: "0.88rem",
    "&.Mui-focused": {
      color:
        theme.palette.mode === "light"
          ? "#000 !important"
          : "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFormControlLabel-root": {
      margin: "0px",
      "& .MuiTypography-root": { fontSize: "0.88rem" },
    },
  },
}));

function FormRadioField(props) {
  const {
    fieldProps,
    formProps,
    control,
    disabled,
    resetHandler,
    getValues,
    trigger,
  } = props;
  const { updateHeight } = useUpdatePanelHeight();
  const { errors } = useFormState({ control, name: fieldProps.key });
  const dispatch = useDispatch();
  const defaultValue =
    formProps[fieldProps.key] || fieldProps.defaultValue || "";

  const rules = generateFormFieldRules(fieldProps, getValues);

  const onChangeHandler = (e, onChange) => {
    const { value } = e.target;
    onChange(value);
    resetHandler();
    trigger(fieldProps.key);
  };

  useEffect(() => {
    if (updateHeight) setTimeout(() => updateHeight(), 400);
  }, [errors, updateHeight]);

  const label = parse(fieldProps?.label);
  return (
    <Styled>
      <FormControl
        fullWidth
        margin="dense"
        required={fieldProps.validate?.required}
      >
        <Box>
          <FormLabel id={`${fieldProps.key}Label`}>{label}</FormLabel>
          {fieldProps?.tags && fieldProps?.tags.includes("proTip") && (
            <IconButton
              aria-label="Pro Tip"
              onClick={() => {
                dispatch(
                  showFormDialog({
                    show: true,
                    fieldKey: fieldProps.key,
                    type: "proTip",
                  })
                );
              }}
            >
              <InfoTwoToneIcon fontSize="small" sx={{ cursor: "pointer" }} />
            </IconButton>
          )}
        </Box>
        <Controller
          name={fieldProps.key}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, ...field } }) => (
            <RadioGroup
              {...field}
              aria-labelledby={`${fieldProps.key}Label`}
              id={fieldProps.key}
              label={fieldProps.label}
              onChange={(e) => onChangeHandler(e, onChange)}
            >
              {fieldProps.values &&
                fieldProps.values.map((item) => (
                  <FormControlLabel
                    sx={{ width: "max-content" }}
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    disabled={disabled}
                  />
                ))}
            </RadioGroup>
          )}
          rules={rules}
        />
        {errors[fieldProps.key] && (
          <FormHelperText
            error
            id={`${fieldProps.key}-radio`}
            sx={{ margin: 0 }}
          >
            {errors[fieldProps.key].message ||
              fieldProps.validate?.customMessage ||
              "This is required."}
          </FormHelperText>
        )}
      </FormControl>
    </Styled>
  );
}
FormRadioField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
      customMessage: PropTypes.string,
    }),
    defaultValue: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

export default FormRadioField;
