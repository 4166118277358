import React from "react";
import { useDispatch } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { updateDialogActionPanel } from "../../context/dialogFormSlice";
import { requireFieldsInfo } from "../../context/formSlice";

export function ActivateDeactivateButton(props) {
  const { license } = props;
  const data = license;
  const showActivatedDeactivateBtn = false;

  if (!showActivatedDeactivateBtn) return null;
  return (
    <>
      {data.status === "Deactivated" && (
        <Tooltip title="Activate" color="primary" sx={{ mx: 0.25 }}>
          <IconButton onClick={undefined}>
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
      )}
      {data.status === "Activated" && (
        <Tooltip title="Deactivate" color="error">
          <IconButton
            aria-label={`Deactivate ${data.licenseNumber}`}
            size="large"
            onClick={undefined}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export function EditButton(props) {
  const { license, subLicense, fieldProps, readonly } = props;
  const dispatch = useDispatch();
  const data = subLicense || license;
  const { editConfig = [] } = fieldProps;

  const config = editConfig.find(({ eq, when }) =>
    eq.split(",").includes(data[when])
  );
  const panel = config?.show || null;

  let isManuallyUpdated = false;
  Object.values(requireFieldsInfo.actionPanelFields.parent || {}).forEach(
    (panelFields) => {
      if (
        data.properties &&
        data.properties[panelFields.overrideIndicator] === "Yes"
      )
        isManuallyUpdated = true;
    }
  );
  let showEditIcon = (readonly && isManuallyUpdated) || !readonly;

  if (subLicense) {
    showEditIcon =
      (readonly &&
        Object.entries(data.properties || {}).filter((x) => x?.toString())
          .length) ||
      !readonly;
  }

  const handleEdit = () => {
    dispatch(
      updateDialogActionPanel({
        show: true,
        fieldProps,
        parentLicense: license,
        subLicense,
        readonly,
        panel,
      })
    );
  };

  return (
    panel &&
    showEditIcon && (
      <Tooltip
        title={readonly ? "View Updates" : `Edit ${data.licenseNumber} license`}
      >
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleEdit();
          }}
          sx={(theme) => ({
            ...(readonly && {
              color: theme.palette.mode === "light" ? "#4b9214" : "#98f354",
            }),
          })}
          size="small"
        >
          {readonly ? <PlaylistAddCheckIcon /> : <EditIcon />}
        </IconButton>
      </Tooltip>
    )
  );
}

export function RemoveButton(props) {
  const { license, readonly, onDelete } = props;

  if (readonly || !onDelete) return null;
  return (
    <Tooltip title="Remove">
      <IconButton
        aria-label={`Remove ${license.licenseNumber}`}
        size="large"
        onClick={() => onDelete(license)}
      >
        <HighlightOffIcon />
      </IconButton>
    </Tooltip>
  );
}
