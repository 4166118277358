import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import parse from "html-react-parser";

import ProTip from "../../../ProTip";
import classes from "./formInfo.module.css";

function FormInfo(props) {
  const { fieldProps } = props;
  const { key, content, cssClass, tags, label, link, isExternalLink } =
    fieldProps;
  const theme = useTheme();
  const { highlight, center } = classes;
  const style = clsx({
    [classes["text-note-info-panel"]]: cssClass?.includes(
      "text-note-info-panel"
    ),
    [classes["text-note-left-red-small"]]: cssClass?.includes(
      "text-note-left-red-small"
    ),
    [classes["text-note-left-red-medium"]]: cssClass?.includes(
      "text-note-left-red-medium"
    ),
    [classes["text-note-info-panel-dark"]]:
      theme.palette.mode === "dark" &&
      cssClass?.includes("text-note-info-panel"),
    [highlight]: cssClass?.includes("highlight"),
    [center]: cssClass?.includes("center"),
  });

  const parsedContent = content ? parse(content) : null;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {link ? (
        <Box sx={{ margin: "1rem 0.5rem" }}>
          <Typography
            tabIndex="0"
            sx={{ fontWeight: "600", fontSize: "0.9rem" }}
          >
            {label}:{" "}
            {tags && tags.includes("proTip") && <ProTip fieldKey={key} />}
            <Link
              sx={{ fontWeight: "normal" }}
              href={isExternalLink ? link : `${process.env.PUBLIC_URL}/${link}`}
              target="_blank"
              underline="always"
            >
              {content}
            </Link>
          </Typography>
        </Box>
      ) : (
        <Typography tabIndex="0" className={style}>
          {parsedContent}{" "}
          {tags && tags.includes("proTip") && <ProTip fieldKey={key} />}
        </Typography>
      )}
    </>
  );
}
FormInfo.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
    cssClass: PropTypes.string,
    link: PropTypes.string,
    isExternalLink: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
export default FormInfo;
