import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

function FormAlert(props) {
  const { severity, message, marginBtm } = props;
  return (
    <Alert
      role="definition"
      tabIndex="0"
      severity={severity}
      elevation={2}
      sx={(theme) => ({
        marginBottom: marginBtm,
        "& .MuiAlert-icon": { display: "none" },
        ".MuiTypography-root": {
          color: theme.palette.mode === "light" ? undefined : "#fff",
          fontSize: "0.9rem",
        },
        display: "flex",
        justifyContent: "center",
      })}
    >
      <Typography align="center">{message}</Typography>
    </Alert>
  );
}
FormAlert.propTypes = {
  severity: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  marginBtm: PropTypes.string,
};
FormAlert.defaultProps = {
  marginBtm: undefined,
};
export default FormAlert;
