import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};
const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    showLoader(state) {
      // eslint-disable-next-line no-param-reassign
      state.loading = true;
    },
    hideLoader(state) {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
    },
  },
});

export const { showLoader, hideLoader } = coreSlice.actions;
export default coreSlice.reducer;
