import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PreviewDialogSkeleton } from "../../../Skeleton";
import StyledPdfContainer from "./styles";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PageWithObserver({ pageNumber, setPageVisibility, ...otherProps }) {
  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      setPageVisibility(pageNumber);
    }
  }, [inView, pageNumber, setPageVisibility]);

  return (
    <Page
      canvasRef={ref}
      pageNumber={pageNumber}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
}
PageWithObserver.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  setPageVisibility: PropTypes.func.isRequired,
};

function PdfViewer({ fileLink }) {
  const muiTheme = useTheme();
  const isSmall = useMediaQuery(muiTheme.breakpoints.down("md"));
  const isExtraSmall = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [totalPages, setTotalPages] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
    setIsLoading(false);
  }
  const onZoomOut = () => {
    if (pageScale > 0.5) {
      setPageScale((prev) => prev - 0.3);
    }
  };
  const onZoomIn = () => {
    if (pageScale < 10) {
      setPageScale((prev) => prev + 0.3);
    }
  };

  const getWidth = (xs, md) => {
    if (xs) return 300;
    if (md) return 600;
    return 900;
  };

  const setPageVisibility = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
  };

  const errorContent = (
    <Alert
      severity="error"
      elevation={2}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Typography align="center">
        Failed to load PDF file, Please try again later
      </Typography>
    </Alert>
  );

  return (
    <StyledPdfContainer isLoading={isLoading}>
      <Document
        file={fileLink}
        loading={<PreviewDialogSkeleton />}
        onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
        error={errorContent}
        noData={errorContent}
      >
        {Array.from(new Array(totalPages), (el, index) => (
          <PageWithObserver
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={getWidth(isExtraSmall, isSmall)}
            scale={pageScale}
            setPageVisibility={setPageVisibility}
          />
        ))}
      </Document>
      {!isLoading && (
        <Box className="footer">
          <Tooltip title="Zoom Out">
            <IconButton onClick={onZoomOut}>
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
          <Typography>
            Page {currentPageNumber} / {totalPages}
          </Typography>
          <Tooltip title="Zoom In">
            <IconButton onClick={onZoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </StyledPdfContainer>
  );
}
PdfViewer.propTypes = {
  fileLink: PropTypes.string.isRequired,
};
export default PdfViewer;
