import React, { useContext, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import EditableRecord from "./EditableRecord";
import ReviewRecord from "./ReviewRecord";
import { ReviewContext } from "../../../context/reviewItemContext";
import { DatagridPropsProvider } from "../../../context/datagridContext";
import useUpdatePanelHeight from "../../../hooks/useUpdatePanelHeight";

function Record(props) {
  const { record, recordIndex, gridItem } = props;
  const { reviewProps } = useContext(ReviewContext);
  const data = {
    record,
    recordIndex,
    datagridKey: gridItem.key,
    isEditable: record?.status !== "saved",
  };
  const { updateHeight } = useUpdatePanelHeight();

  useLayoutEffect(() => {
    updateHeight();
    return () => {
      updateHeight();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatagridPropsProvider initialState={data}>
      {!reviewProps ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <EditableRecord {...props} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ReviewRecord {...props} />
      )}
    </DatagridPropsProvider>
  );
}
Record.propTypes = {
  record: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  recordIndex: PropTypes.number.isRequired,
  gridItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
};
export default Record;
