/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Controller, useFormState } from "react-hook-form";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { generateFormFieldRules } from "../services/form.service";

function FormTextAreaField(props) {
  const { fieldProps, formProps, control, disabled } = props;
  const { key, label, defaultValue, validate } = fieldProps;

  const { errors } = useFormState({ control, name: key });

  const initialValue = formProps[key] || defaultValue || "";
  const errorMap = {
    maxLength: `Maximum ${validate?.maxLength} characters allowed.`,
    required: "This is required",
  };

  const rules = generateFormFieldRules(fieldProps);

  return (
    <FormControl fullWidth margin="dense" sx={{ minHeight: "150px" }}>
      <Controller
        name={key}
        control={control}
        defaultValue={initialValue}
        render={({ field }) => (
          <TextField
            {...field}
            id={key}
            name={key}
            label={label}
            multiline
            rows={4}
            variant="standard"
            required={validate?.required}
            disabled={disabled}
          />
        )}
        rules={rules}
      />
      {errors[key] && (
        <FormHelperText error id={`${key}-text-area`} sx={{ m: 0 }}>
          {(errors[key].type && errorMap[errors[key].type]) ||
            "This is required"}
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormTextAreaField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    defaultValue: PropTypes.string,
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      maxLength: PropTypes.number,
    }),
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default FormTextAreaField;
