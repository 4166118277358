/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ForgotPwdIcon from "@mui/icons-material/LockOpen";
import SignInIcon from "@mui/icons-material/Input";

import loginClasses from "../Login/Login.module.css";
import { useResetPasswordMutation } from "../../api/auth.api.slice";
import { notifyError, validatePasswords } from "../../utils/helpers";
import LocalLoader from "../../components/Loaders/Local";
import { showNotification } from "../../features/core/slices/notification.slice";

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { resetToken } = Object.fromEntries([...searchParams]);
  const [notification, setNotification] = useState({
    message: "",
    success: false,
    error: false,
  });

  const validationSchema = yup.object().shape({
    newPassword: yup.string().required("Please enter new password."),
    confirmPassword: yup.string().required("Please enter password again."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const { success, error, message } = notification;

  const onPasswordReset = async (payload) => {
    try {
      const data = await resetPassword(payload).unwrap();
      if (data) {
        const msg = "Your password has been reset successfully, please login";
        setNotification({ error: false, success: true, message: msg });
      }
    } catch (err) {
      notifyError(dispatch, showNotification, err);
    }
  };

  const onSubmit = (data) => {
    const { newPassword, confirmPassword } = data;
    const errorMsg = validatePasswords(newPassword, confirmPassword);
    if (errorMsg) {
      const stateVal = { success: false, error: true, message: errorMsg };
      setNotification(stateVal);
    } else {
      setNotification({ success: false, error: false, message: "" });
      const payload = {
        password: newPassword,
        resetToken,
      };
      onPasswordReset(payload);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={loginClasses.loginGrid}
        alignItems="center"
      >
        <Grid item xs={11} sm={8} md={5}>
          <Card raised>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <CardHeader
                title="Reset Password"
                className={loginClasses.cardHeader}
              >
                Reset Password
              </CardHeader>
              {!notification.success && (
                <>
                  <CardContent>
                    <TextField
                      label="New Password"
                      id="newPassword"
                      name="newPassword"
                      required
                      type="password"
                      margin="normal"
                      variant="outlined"
                      {...register("newPassword")}
                      error={errors?.newPassword !== undefined}
                      helperText={errors?.newPassword?.message || ""}
                      fullWidth
                    />
                    <TextField
                      label="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      required
                      type="password"
                      margin="normal"
                      variant="outlined"
                      {...register("confirmPassword")}
                      fullWidth
                      error={errors?.confirmPassword !== undefined}
                      helperText={errors?.confirmPassword?.message || ""}
                    />
                  </CardContent>
                  <CardActions className={loginClasses.cardActions}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ mb: "1rem" }}
                    >
                      <ForgotPwdIcon />
                      RESET PASSWORD
                    </Button>
                  </CardActions>
                </>
              )}
              {success && (
                <Grid sx={{ mt: "3rem" }}>
                  <CardActions className={loginClasses.cardActions}>
                    <Button variant="contained" component={Link} to="/login">
                      <SignInIcon sx={{ mr: "5px" }} />
                      Sign In
                    </Button>
                  </CardActions>
                </Grid>
              )}
            </form>

            {(success || error) && (
              <CardContent sx={{ textAlign: "center" }}>
                <Typography color={success ? "primary" : "error"}>
                  {message}
                </Typography>
              </CardContent>
            )}
          </Card>
          <LocalLoader progress={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
}
export default ResetPasswordPage;
