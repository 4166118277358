import { createTheme } from "@mui/material/styles";

const keyboardFocusStyle = {
  "&:focus-visible": {
    outline: "5px auto -webkit-focus-ring-color",
    outlineOffset: "-2px",
  },
};

function getTheme(darkMode) {
  return createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "rgba(67, 183, 63, 1)",
      },
      secondary: {
        main: "rgba(52, 60, 62, 1)",
      },
      error: {
        main: darkMode ? "#FF5F5C" : "#a94442",
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            "&.MuiAlert-standardInfo": {
              color: darkMode ? "#254250" : undefined,
              background: darkMode ? "#b2c3ca" : undefined,
            },
            "&.MuiAlert-standardError": {
              color: "#ea7371",
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: keyboardFocusStyle,
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: keyboardFocusStyle,
        },
      },
      MuiChip: {
        styleOverrides: {
          root: keyboardFocusStyle,
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "rgba(52, 60, 62, 1)",
            color: "white",
            backgroundImage: "none",
          },
          paperAnchorDockedLeft: {
            top: "auto",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableFocusRipple: true,
        },
        styleOverrides: {
          contained: {
            background: darkMode
              ? "linear-gradient(45deg, #25a222 0%, #25a022 100%)"
              : "linear-gradient(45deg, #2fca2a 0%,#39d32e 100%)",
            color: "white",
            margin: "0 5px",
            padding: "7px 15px",
            "&:hover": {
              background: darkMode
                ? "linear-gradient(45deg, #399837 0%,#64D55E 100%)"
                : "linear-gradient(45deg, #3ec13c 0%,#56e251 100%)",
            },
          },
          root: {
            ...keyboardFocusStyle,
            "&.customLink": {
              color: darkMode ? "#71cbf5" : "blue",
              textDecoration: "none",
              display: "inline-block",
              textTransform: "none",
              paddingLeft: 0,
              margin: "-8px 0",
              fontWeight: "400",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1em",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "& strong": {
              fontSize: "1.1rem",
              fontWeight: darkMode ? 500 : 600,
              textShadow: "none",
            },
            "& i": {
              color: darkMode ? "#fff !important" : "grey",
            },
            "& small>b": {
              color: darkMode ? "#fff !important" : "#000",
            },
            "& .customLink": {
              color: darkMode ? "#71cbf5" : "blue",
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            },
          },
          h2: {
            fontSize: "1.6rem",
            fontWeight: darkMode ? 500 : 600,
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            "& p>a, li>a, .customLink": {
              color: darkMode ? "#71cbf5" : "blue",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "transparent",
              },
            },
            "& p>i": {
              color: darkMode ? "#fff !important" : "grey",
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            "& a": {
              color: darkMode ? "#71cbf5" : "blue",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& div:hover:not(.Mui-disabled):before": {
              borderBottomColor: darkMode
                ? "rgba(255, 255, 255, 0.7)"
                : "rgba(0, 0, 0, 0.4)",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: darkMode ? undefined : "rgba(0, 0, 0, 0.8)",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&.Mui-disabled": {
              textFillColor: darkMode ? undefined : "rgba(0, 0, 0, 0.58)",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&": {
              color: darkMode ? undefined : "rgba(0, 0, 0, 0.7)",
            },
            "&.MuiInputLabel-shrink": {
              fontWeight: "bold",
            },
            "&.Mui-disabled": {
              color: darkMode ? undefined : "rgba(0, 0, 0, 0.58)",
            },
          },
        },
      },
    },
  });
}

export default getTheme;
