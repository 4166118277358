import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Styled = styled(Box)(({ theme }) => ({
  "& .cardHeader": {
    backgroundColor: "rgba(52, 60, 62, 1)",
    padding: "20px 20px",
    color: "white",
    borderRadius: "4px 4px 0 0",
    textAlign: "center",
  },
  "& .btnContainer": {
    justifyContent: "center",
    marginTop: "0.5em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
    "& .MuiButton-root": {
      margin: "0.8rem 0.5rem",
      "& .MuiSvgIcon-root": {
        marginRight: "0.2em",
      },
    },
  },
  "& .verifyLink": {
    margin: "1rem 0.5rem",
    "& .MuiSvgIcon-root": {
      marginRight: "2px",
      verticalAlign: "sub",
      fontSize: "1.2rem",
    },
  },
  "& .errorItem": {
    marginBottom: "1rem",
    "& span": {
      fontWeight: 600,
      fontSize: "0.9rem",
    },
  },
}));

export default Styled;
