import { useEffect, useRef } from "react";

let capturedPosition = 0;
const useScrollPosition = () => {
  const scrollPosition = useRef();

  useEffect(() => {
    const updatePosition = () => {
      scrollPosition.current = window.pageYOffset;
    };
    window.addEventListener("scroll", updatePosition);
    // updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const captureCurrentPosition = () => {
    capturedPosition = scrollPosition.current;
  };

  const scrollToCapturedPosition = (customPosition) => {
    if (customPosition !== undefined) {
      window.scrollTo(0, parseInt(customPosition, 10));
    } else {
      window.scrollTo(0, parseInt(capturedPosition, 10));
    }
  };
  return [captureCurrentPosition, scrollToCapturedPosition];
};

export default useScrollPosition;
