import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { useGetRejectedApplicationsQuery } from "../../../../api/applications.api.slice";
import { notifyError } from "../../../../utils/helpers";
import { showNotification } from "../../slices/notification.slice";

export const Styled = styled(Box)(() => ({
  "& .MuiAlert-root": {
    zIndex: "10001",
    position: "fixed",
    top: "30px",
    left: "50%",
    transform: "translate(-50%, 0)",
    color: "#fff",
    maxHeight: "300px",
    overflowY: "auto",
    background:
      "linear-gradient(to bottom,rgba(237,105,4,.85) 0,rgba(237,105,4,.85) 100%)",
    "& .MuiAlert-icon": { display: "none" },
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: 700,
      fontSize: "1rem",
      color: "#fff",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));

function RejectionAlert() {
  const dispatch = useDispatch();
  const { navConfig } = useSelector((state) => state.navConfig);
  const [show, setShow] = React.useState(false);
  const rejectedAlias =
    navConfig?.rejectAliasLanguage ?? navConfig?.rejectAlias ?? "rejected";

  const payload = {
    limit: 50,
    sort: "dateSubmitted",
    status: "Rejected",
  };

  const {
    data: rejectedApplications,
    error: applicationListError,
    isFetching,
  } = useGetRejectedApplicationsQuery(payload);
  const { tenantData: currentTenant } = useSelector((state) => state.auth);

  useEffect(() => {
    if (show) setShow(false);
    if (
      !isFetching &&
      rejectedApplications &&
      rejectedApplications.docs.length
    ) {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant?._id, rejectedApplications]);

  useEffect(() => {
    if (applicationListError) {
      notifyError(dispatch, showNotification, applicationListError);
    }
  }, [applicationListError, dispatch]);

  return show ? (
    <Styled>
      <Alert
        onClose={() => setShow(false)}
        severity="warning"
        elevation={2}
        sx={{
          width: { xs: "70%", sm: "50%", md: "28%" },
        }}
      >
        <Typography>
          The following applications are {rejectedAlias} and require your
          review. Please select your application below, make the necessary
          changes, and resubmit. Do NOT create a new application.
        </Typography>
        <ul>
          {rejectedApplications.docs.map((item) => (
            <li key={item._id}>
              <Typography
                component={Link}
                to={`/applications/${item.applicationType}/${item._id}`}
                onClick={() => setShow(false)}
              >
                {item.applicationFormId && item.applicationFormId}
              </Typography>
            </li>
          ))}
        </ul>
      </Alert>
    </Styled>
  ) : null;
}
export default RejectionAlert;
