/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Controller, useFormState } from "react-hook-form";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const StyledDiv = styled(Box)(({ theme }) => ({
  "& .radioGroup": {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  "& .formControlLabel": {
    border: "2px solid",
    borderRadius: "5px",
    borderColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: ".5rem 10px .5rem 10px",
    width: "100%",
    position: "relative",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.primary.light
          : theme.palette.primary.dark,
      "& .MuiTypography-root,.MuiSvgIcon-root": {
        color: "#fff !important",
      },
    },
    "& .accountTypeBox": {
      padding: "0.5rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
    },
  },
}));

function RadioButtonsGroup(props) {
  const {
    radioButtonList,
    name,
    required,
    control,
    onItemChange,
    icon: Icon,
    errorMsg,
  } = props;
  const { errors } = useFormState({ control });

  const getColor = (val, itemVal) => (val === itemVal ? "#fff" : undefined);

  return (
    <StyledDiv>
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, ...field } }) => (
            <RadioGroup
              {...field}
              aria-labelledby="radio-buttons-group-label"
              name="radio-buttons-group"
              className="radioGroup"
              tabIndex="0"
            >
              <Grid container justifyContent="center" sx={{ display: "flex" }}>
                {radioButtonList.length > 0 &&
                  radioButtonList.map((item) => (
                    <Grid
                      item
                      xs={6.5}
                      sm={5}
                      md={2.5}
                      key={item.label}
                      sx={{ marginRight: "1.8rem" }}
                    >
                      <Tooltip title={item.tooltip || ""}>
                        <FormControlLabel
                          key={item.label}
                          value={item.value}
                          className="formControlLabel"
                          sx={(theme) => ({
                            backgroundColor:
                              value === item.value
                                ? theme.palette.mode === "light"
                                  ? theme.palette.primary.light
                                  : theme.palette.primary.dark
                                : undefined,
                          })}
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <Box>
                              {value === item.value && (
                                <CheckCircleOutlineIcon
                                  color="primary"
                                  fontSize="small"
                                  sx={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    color: getColor(value, item.value),
                                  }}
                                />
                              )}
                              <Box className="accountTypeBox">
                                <Icon
                                  color="primary"
                                  sx={{
                                    color: getColor(value, item.value),
                                    fontSize: "35px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    color: getColor(value, item.value),
                                    textAlign: "center",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          onChange={(e) => {
                            if (onItemChange) onItemChange(item);
                            onChange(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              if (value !== item.value) {
                                if (onItemChange) onItemChange(item);
                                onChange(item.value);
                              }
                            }
                          }}
                          tabIndex="0"
                        />
                      </Tooltip>
                    </Grid>
                  ))}
              </Grid>
            </RadioGroup>
          )}
          rules={{ required }}
        />
        {errors && errors[name] && (
          <FormHelperText error sx={{ textAlign: "center" }}>
            <Typography component="span">{errorMsg}</Typography>
          </FormHelperText>
        )}
      </FormControl>
    </StyledDiv>
  );
}

RadioButtonsGroup.propTypes = {
  radioButtonList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  onItemChange: PropTypes.func,
  errorMsg: PropTypes.string,
};
RadioButtonsGroup.defaultProps = {
  radioButtonList: [],
  onItemChange: undefined,
  errorMsg: "",
};

export default RadioButtonsGroup;
