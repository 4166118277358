import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, index, customMargin } = props;
  return (
    <div
      role="tabpanel"
      id={`form-tabpanel-${index}`}
      aria-labelledby={`form-tab-${index}`}
      tabIndex={index}
    >
      <Box sx={{ padding: customMargin ? "1rem" : "1rem 0 2rem 0" }}>
        {children}
      </Box>
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  customMargin: PropTypes.bool,
  index: PropTypes.number.isRequired,
};
TabPanel.defaultProps = {
  children: null,
  customMargin: false,
};

export default TabPanel;
