import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import { useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridIcon from "@mui/icons-material/Apps";
import FormRecordCards from "./FormRecordCard";
import FormRecordTable from "./FormRecordTable";

import { resetFormData } from "../../context/formSlice";
import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";
import { getParsedValue } from "../../services/form.service";

import StyledBox from "./styles";

const toggleButtonSx = {
  position: "absolute",
  top: 0,
  right: 0,
  transform: "translateY(-0.8rem)",
};

const validateEmptyArray = (value) => {
  if (!value || value.length === 0)
    return "At least one item should be selected.";
  return undefined;
};
function FormRecordList(props) {
  const {
    fieldProps,
    formProps,
    control,
    setValue,
    trigger,
    readonly,
    getValues,
    register,
  } = props;
  const dispatch = useDispatch();
  const applicationStatus = useSelector(
    (state) => state.formProps?.applicationData?.status || ""
  );
  const selectedLicenses = useWatch({
    name: fieldProps.key,
    defaultValue: formProps[fieldProps.key] || [],
    control,
    exact: !fieldProps.hasSubLicenses,
  });
  const isTableView = useWatch({
    name: `${fieldProps.key}TableView__DUMMY`,
    defaultValue:
      JSON.parse(localStorage.getItem(`${fieldProps.key}TableView`)) ?? false,
    control,
    exact: true,
  });
  const { updateHeight } = useUpdatePanelHeight();

  const handleLayoutChange = () => {
    localStorage.setItem(
      `${fieldProps.key}TableView`,
      JSON.stringify(!isTableView)
    );
    setValue(`${fieldProps.key}TableView__DUMMY`, !isTableView);
  };

  const handleDelete = (licenseToDelete) => {
    const rows = selectedLicenses.filter(
      (license) => license.licenseNumber !== licenseToDelete.licenseNumber
    );
    setValue(fieldProps.key, rows);
    setTimeout(() => updateHeight(), 400);
  };

  useEffect(() => {
    if (fieldProps.hasSubLicenses && register)
      register(fieldProps.key, {
        value: cloneDeep(selectedLicenses),
        ...(fieldProps.validate?.required && { validate: validateEmptyArray }),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { key, validate = {} } = fieldProps;
    trigger(fieldProps.key);
    if (selectedLicenses && selectedLicenses.length === 0 && validate.custom) {
      const parsedCondition = getParsedValue(validate.custom, key);
      const condition = { reset: parsedCondition.resetOnEmpty };
      const payload = { condition, setValue, trigger, key, getValues };
      if (parsedCondition.resetOnEmpty) dispatch(resetFormData(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLicenses]);

  const disableActions =
    !!applicationStatus &&
    (fieldProps.properties?.disableActions || "").includes(applicationStatus);

  return (
    <StyledBox width="100%" readonly={readonly}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!readonly && (
          <Typography color="text.secondary">
            {fieldProps.label}{" "}
            {`( ${(selectedLicenses && selectedLicenses.length) || 0} )`}
          </Typography>
        )}
        <Tooltip
          sx={readonly ? toggleButtonSx : {}}
          title={`Switch to ${isTableView ? "card" : "table"} view`}
        >
          <IconButton size="large" onClick={handleLayoutChange}>
            {!isTableView && <TableRowsIcon />}
            {isTableView && <GridIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      {selectedLicenses && selectedLicenses.length > 0 && !isTableView && (
        <FormRecordCards
          fieldProps={fieldProps}
          setValue={setValue}
          trigger={trigger}
          licenses={selectedLicenses}
          onDelete={readonly || disableActions ? undefined : handleDelete}
          readonly={readonly || disableActions}
        />
      )}
      {selectedLicenses && selectedLicenses.length > 0 && isTableView && (
        <FormRecordTable
          fieldProps={fieldProps}
          licenses={selectedLicenses}
          onDelete={readonly || disableActions ? undefined : handleDelete}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          readonly={readonly || disableActions}
          hideFilters={readonly || !fieldProps.hasSubLicenses}
        />
      )}
      {fieldProps.validate?.required &&
        (!selectedLicenses || selectedLicenses.length === 0) && (
          <FormHelperText error id={`${fieldProps.key}-recordList`}>
            At least one item should be selected.
          </FormHelperText>
        )}
    </StyledBox>
  );
}

FormRecordList.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
  formProps: PropTypes.shape({
    dataGridKey: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

FormRecordList.defaultProps = {
  readonly: false,
};

export default FormRecordList;
