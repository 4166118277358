/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useFormState, Controller, useWatch } from "react-hook-form";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getSocialSecurityNumber } from "../../../utils/helpers";
import { generateFormFieldRules } from "../services/form.service";

function FormEncryptedField(props) {
  const { fieldProps, control, formProps, disabled, register, setValue } =
    props;
  const { errors } = useFormState({ control, name: fieldProps.key });
  const isMasked = useWatch({
    name: `${fieldProps.key}isMasked__DUMMY`,
    defaultValue: true,
    control,
  });

  const toggleFieldMasked = async (toggleValue) => {
    const value = typeof toggleValue === "boolean" ? toggleValue : !isMasked;
    setValue(`${fieldProps.key}isMasked__DUMMY`, value);
  };

  useEffect(() => {
    register(`${fieldProps.key}isMasked__DUMMY`);
    setValue(`${fieldProps.key}isMasked__DUMMY`, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rules = generateFormFieldRules(fieldProps);

  return (
    <FormControl fullWidth margin="dense" sx={{ height: "75px" }}>
      <Controller
        name={fieldProps.key}
        control={control}
        defaultValue={formProps[fieldProps.key]}
        render={({ field: { onChange, onBlur, ref, value, ...field } }) => (
          <InputMask
            mask="***-**-****"
            maskChar={null}
            {...field}
            value={getSocialSecurityNumber({ isMasked, value, fieldProps })}
            onChange={(e) => {
              onChange(e.target.value.replace(/[^\d]/g, ""));
            }}
            onFocus={() => toggleFieldMasked(false)}
            onBlur={(e) => {
              toggleFieldMasked(true);
              onBlur(e);
            }}
            disabled={disabled}
          >
            {(inputProps) => (
              <TextField
                inputRef={ref}
                name={fieldProps.key}
                label={fieldProps.label}
                id={fieldProps.key}
                disabled={disabled}
                required={fieldProps.validate?.required}
                variant="standard"
                {...inputProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle encrypted visibility"
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleFieldMasked();
                        }}
                        edge="end"
                      >
                        {isMasked ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </InputMask>
        )}
        rules={rules}
      />
      {errors[fieldProps.key] && (
        <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
          This is required.
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormEncryptedField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default FormEncryptedField;
