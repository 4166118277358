import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import DialogPopup from "../../../DialogPopup";
import PdfViewer from "../PdfViewer";
import { PreviewDialogSkeleton } from "../../../Skeleton";
import { StyledPreviewContainer } from "../styles";
import { getFileLink } from "../../services/formDocument.service";

function PreviewDialog(props) {
  const muiTheme = useTheme();
  const { domainName } = useSelector((state) => state.formProps.formConfig);
  const [isLoading, setIsLoading] = useState(true);

  const { resetDialogProps, selectedDocument } = props;
  const { fileName, mimeType, _id, versionSeriesId } = selectedDocument;
  const isImage =
    mimeType === "image" ||
    mimeType === "image/jpeg" ||
    mimeType === "image/png";
  const isPdf = mimeType === "pdf" || mimeType === "application/pdf";

  const filePayload = {
    domainName,
    versionSeriesId,
    id: _id,
  };
  const customColor = {
    header: muiTheme.palette.primary.main,
    body: muiTheme.palette.mode === "light" ? "#212121" : "#000",
  };

  const previewContent = (
    <>
      {isImage && (
        <StyledPreviewContainer isLoading={isLoading}>
          {isLoading && <PreviewDialogSkeleton />}
          <Box className="wrapper">
            <img
              src={getFileLink(filePayload)}
              alt={fileName}
              onLoad={() => setIsLoading(false)}
            />
          </Box>
        </StyledPreviewContainer>
      )}
      {isPdf && <PdfViewer fileLink={getFileLink(filePayload)} />}
      {!isPdf && !isImage && (
        <Alert
          severity="info"
          elevation={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography align="center" sx={{ fontSize: ".9rem" }}>
            {`No preview available. Please download the document by clicking on 'Download' icon.`}
          </Typography>
        </Alert>
      )}
    </>
  );
  const maxWidth = isPdf ? "xl" : "lg";
  return (
    <Box sx={{ position: "relative" }}>
      <DialogPopup
        openPopup
        onCancel={resetDialogProps}
        title={fileName}
        maxWidth={!isPdf && !isImage ? "md" : maxWidth}
        height="95%"
        content={previewContent}
        customBgColor={customColor}
      />
    </Box>
  );
}
PreviewDialog.propTypes = {
  resetDialogProps: PropTypes.func.isRequired,
  selectedDocument: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    versionSeriesId: PropTypes.string.isRequired,
  }).isRequired,
};

export default PreviewDialog;
