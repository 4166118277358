import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)({
  "& .MuiPaper-root": {
    maxHeight: "250px",
    maxWidth: "350px",
    wordBreak: "break-word",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "11px",
      background: "transparent",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#bdb6b6",
      borderRadius: "4px",
    },
  },
  "& .MuiAlert-action": {
    padding: "4px 0 0 5px",
  },
  "& .MuiTypography-root": {
    fontSize: "0.9rem",
    fontWeight: 500,
  },
});

export default function SnackBar(props) {
  const { open, duration, type, msg, position, closeSnackBar, config } = props;
  const { listItems } = config || {};
  let message = msg;

  if (listItems?.length) {
    message = listItems.map((listItem) => (
      <div key={listItem.title}>
        <Typography>{listItem.title}</Typography>
        {(listItem.items || []).length > 0 && (
          <ul style={{ margin: "2px" }}>
            {listItem.items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        )}
      </div>
    ));
  }

  return (
    <StyledBox>
      <Snackbar
        aria-label="toast-notification"
        open={open}
        autoHideDuration={duration}
        onClose={closeSnackBar}
        anchorOrigin={position}
      >
        <Alert onClose={closeSnackBar} severity={type} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </StyledBox>
  );
}

SnackBar.propTypes = {
  open: PropTypes.bool,
  duration: PropTypes.number,
  type: PropTypes.string,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  closeSnackBar: PropTypes.func,
  position: PropTypes.instanceOf(Object),
  config: PropTypes.shape({
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    defaultMsg: PropTypes.string,
  }),
};
SnackBar.defaultProps = {
  open: false,
  duration: 4000,
  type: "success",
  msg: "Notification",
  closeSnackBar: () => {},
  position: {
    vertical: "bottom",
    horizontal: "right",
  },
  config: {},
};
