import React, { useEffect, useState } from "react";
import { matchRoutes, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MuiBreadCrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

import Fixtures from "../../features/application/components/Fixtures";
import { showNotification } from "../../features/core/slices/notification.slice";
import { useProgramConfigQuery } from "../../api/core.api.slice";
import { useGetRecordQuery } from "../../api/licenses.api.slice";
import { isElevatedEnv, isLowerEnv, notifyError } from "../../utils/helpers";
import routes from "../../routes-config";
import {
  generateCrumbs,
  mapCrumbLabel,
} from "../../services/breadcrumbs.service";

const Styled = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 1rem",
  margin: "0 0 1rem 0",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
  "& a": {
    textDecoration: "none",
    color: "#fff",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& .MuiTypography-root": {
    fontWeight: 500,
    color: "#fff",
  },
  "& .MuiButtonBase-root": {
    color: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

function BreadCrumbs() {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentRoute, setCurrentRoute] = useState({ path: "/" });
  const [locationHistory, setLocationHistory] = useState({ "/": null });
  const matchedRoutes = matchRoutes(routes, location);
  const permissionList = useSelector((state) => state.auth.permissionList);

  const { data: programConfig, error: programConfigError } =
    useProgramConfigQuery();

  const { licenseNumber, recordType, recordId } = params;
  const { data: recordData } = useGetRecordQuery(
    { licenseNumber, recordType, params: { recordId } },
    {
      skip: !(
        licenseNumber &&
        recordType &&
        recordId &&
        currentRoute.path === "licenses/:recordType/:licenseNumber/:recordId"
      ),
    }
  );

  useEffect(() => {
    setLocationHistory((prevState) => ({
      ...prevState,
      [location.pathname]: location.search,
    }));

    if (matchedRoutes && matchedRoutes.length) {
      const { route } = matchedRoutes[matchedRoutes.length - 1];

      let siblingsRoutes = [];
      if (matchedRoutes.length > 1) {
        siblingsRoutes =
          matchedRoutes[matchedRoutes.length - 2].route.children ?? [];
      }

      const crumbs = generateCrumbs(route, siblingsRoutes);
      const crumbsWithLabel = mapCrumbLabel(crumbs, programConfig, params);

      setBreadcrumbs(crumbsWithLabel);
      setCurrentRoute(route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, programConfig]);

  useEffect(() => {
    if (programConfigError) {
      notifyError(dispatch, showNotification, programConfigError);
    }
  }, [programConfigError, dispatch]);

  if (!matchedRoutes || !breadcrumbs || breadcrumbs.length === 0) return null;

  const enableAutoFill =
    isLowerEnv ||
    (isElevatedEnv && permissionList.includes("edit_locked_fields"));

  return (
    <Styled
      disableGutters
      sx={{
        minHeight: 30,
        borderRadius: currentRoute?.breadcrumb?.showBorder ? "7px" : 0,
      }}
      variant="dense"
      tabIndex={0}
    >
      <MuiBreadCrumbs maxItems={4} aria-label="breadcrumb">
        {breadcrumbs.map((crumb, index) => {
          let crumbLabel = crumb.label.displayName || "";
          if (
            crumb.label.type === "params" &&
            crumb.label.value === "recordType"
          )
            crumbLabel = `${crumbLabel} - ${recordData?.title || ""}`;

          if (index === breadcrumbs.length - 1) {
            return (
              <Typography
                variant="subtitle1"
                component="span"
                key={crumb.pathName}
              >
                {crumbLabel}
              </Typography>
            );
          }

          const query = locationHistory[crumb.pathName] || "";
          const linkTo = `${crumb.pathName}${query}`;
          return (
            <Link color="inherit" to={linkTo} key={linkTo}>
              <Typography variant="subtitle1" component="span">
                {crumbLabel}
              </Typography>
            </Link>
          );
        })}
      </MuiBreadCrumbs>
      {enableAutoFill && <Fixtures />}
    </Styled>
  );
}

export default BreadCrumbs;
