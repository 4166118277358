import { getSanitizedFormData } from "../components/Form/services/form.service";
import splitApi from "./emptySplitApi";

const fixtureApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    autoFillForm: build.mutation({
      query: ({ applicationType, body }) => ({
        url: `/api/applicationForms/fixtures/${applicationType}`,
        method: "POST",
        body: getSanitizedFormData(body),
      }),
    }),
    applyFixture: build.mutation({
      query: (id) => ({
        url: `/api/dynamicAppForms/${id}`,
        method: "GET",
      }),
    }),
    createFixture: build.mutation({
      query: (body) => ({
        url: "/api/dynamicAppForms",
        method: "POST",
        body: getSanitizedFormData(body),
      }),
      invalidatesTags: ["Fixtures"],
    }),
    getFixtures: build.mutation({
      query: (applicationType) => ({
        url: "/api/dynamicAppForms",
        method: "GET",
        params: { applicationType },
      }),
      providesTags: ["Fixtures"],
    }),
    updateFixture: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/dynamicAppForms/${id}`,
        method: "PUT",
        body: getSanitizedFormData(body),
      }),
    }),
    deleteFixture: build.mutation({
      query: (id) => ({
        url: `/api/dynamicAppForms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Fixtures"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAutoFillFormMutation,
  useApplyFixtureMutation,
  useCreateFixtureMutation,
  useDeleteFixtureMutation,
  useGetFixturesMutation,
  useUpdateFixtureMutation,
} = fixtureApi;
