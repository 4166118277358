import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const getBgColor = (theme, isDragActive) => {
  if (theme.palette.mode === "light") {
    if (isDragActive) return "#d4f9d3";
    return "#d8d8d8";
  }
  if (theme.palette.mode !== "light") {
    if (isDragActive) return "#4f6e4e";
    return "#696969";
  }
  return "";
};

export const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDragActive",
})(({ isDragActive, theme }) => ({
  border: "2px dotted",
  borderColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  padding: `${isDragActive ? "100px" : "10px"} 5px`,
  backgroundColor: getBgColor(theme, isDragActive),
  boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)",
  transition: "all 0.3s ease-out",
  "& .MuiTypography-root": {
    textTransform: "uppercase",
    fontSize: ".9rem",
    fontWeight: "500",
  },
  "&:hover": {
    cursor: "pointer",
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  "& .MuiTypography-root": {
    marginTop: "1rem",
    textOverflow: "ellipsis",
    fontSize: "0.9rem",
    fontWeight: "600",
  },
  "& .errorMsg": {
    color: theme.palette.error.main,
  },
}));
