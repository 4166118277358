export function generateCrumbs(route, routes) {
  const crumbs = [];

  if (!route || !route.breadcrumb || route.breadcrumb.hidden) return crumbs;
  crumbs.unshift({ ...route.breadcrumb, path: route.path });

  let { parentPath } = route.breadcrumb;
  while (parentPath) {
    // eslint-disable-next-line no-loop-func
    const parentRoute = routes.find((child) => child.path === parentPath);
    if (!parentRoute || !parentRoute.breadcrumb) parentPath = "";
    parentPath = parentRoute.breadcrumb.parentPath;
    crumbs.unshift({ ...parentRoute.breadcrumb, path: parentRoute.path });
  }

  const { parent } = route.breadcrumb;
  if (parent && parent.breadcrumb)
    crumbs.unshift({ ...parent, ...parent.breadcrumb });

  return crumbs;
}

export function mapCrumbLabel(crumbs, programConfig, params = {}) {
  const { applicationType, recordType } = params;
  const recordKey = applicationType || recordType;
  const recordTypes = programConfig?.recordTypes || [];
  let record = {};
  if (recordKey)
    record = recordTypes.find((rec) => rec?.key === recordKey) ?? {};

  return crumbs.map((route) => {
    const label = { ...route.label, displayName: route.label.value };
    if (route.label.type === "params")
      switch (route.label.value) {
        case "applicationType":
        case "recordType":
          label.displayName = record.name;
          break;
        case "id":
          label.displayName = params.id;
          break;
        default:
          label.displayName = label.value;
      }

    const pathName = route.path
      .split("/")
      .map((name) => {
        let path = name;
        if (name.startsWith(":")) path = params[name.slice(1)] || name;
        return path;
      })
      .join("/");

    return { ...route, label, pathName: `/${pathName}` };
  });
}
