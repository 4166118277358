import { useSelector } from "react-redux";

let datagridState = {
  dgKeys: [],
  dgKeysWithTabIndex: {},
  dgConfigs: {},
};

const useDatagridState = () => {
  const formProps = useSelector((state) => state.formProps);

  const updateDgState = (data) => {
    const { key, config } = data;
    const { dgKeys, dgConfigs } = datagridState;
    datagridState.dgKeys =
      dgKeys.indexOf(key) === -1 ? [...dgKeys, key] : [...dgKeys];

    // dgConfigs: {dgKey:{title:"Ownership Info, initEmpty:false, custom:"" }}
    if (key && config && !dgConfigs[key]) {
      datagridState.dgConfigs[key] = config;
    }
  };
  const setDgKeysWithTabIndex = (data = {}) => {
    datagridState.dgKeysWithTabIndex = data;
  };
  const getDgState = () => {
    const newDgState = { ...datagridState };
    datagridState.dgKeys.forEach((dgKey) => {
      if (formProps[dgKey]) newDgState[dgKey] = formProps[dgKey];
    });
    return newDgState;
  };

  // resetting the dg state to its initial state
  const resetDgState = () => {
    datagridState = {
      dgKeys: [],
      dgKeysWithTabIndex: {},
      dgConfigs: {},
    };
  };

  return { updateDgState, getDgState, resetDgState, setDgKeysWithTabIndex };
};

export default useDatagridState;
