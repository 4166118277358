import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import ErrorPage from "./pages/Error";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ChangePasswordPage from "./pages/ChangePassword";
import FAQPage from "./pages/Legal/FAQ";
import TermsConditionPage from "./pages/Legal/TermsConditions";
import PrivacyPolicyPage from "./pages/Legal/PrivacyPolicy";
import AccessibilityPage from "./pages/Legal/Accessibility";
import ResetPasswordPage from "./pages/ResetPassword";
import MaintenancePage from "./pages/Maintenance";
import UnauthorizedPage from "./pages/Unauthorized";
import BrowserSupportPage from "./pages/BrowserSupport";
import RequireAuth from "./features/auth/components/RequireAuth";
import {
  TableSkeleton,
  FormSkeleton,
  CardSkeleton,
  InvoiceDetailsSkeleton as PageDetailsSkeleton,
} from "./components/Skeleton";
import AppFormWithCustomConfig from "./pages/Application/AppFormWithCustomConfig";
import VerifyEmail from "./pages/Register/VerifyEmail";
import VerifyAccount from "./pages/VerifyAccount";

const LicenseDashboard = lazy(() => import("./pages/License/List"));
const LicenseDetailsPage = lazy(() => import("./pages/License/Details"));

const InvoicesDashboard = lazy(() => import("./pages/Invoices/List"));
const InvoiceDetailsPage = lazy(() => import("./pages/Invoices/Details"));
const ApplicationsPage = lazy(() => import("./pages/Application/List"));
const ApplicationDetailsPage = lazy(() =>
  import("./pages/Application/Details")
);
const PaymentStatusPage = lazy(() =>
  import("./features/invoices/components/PaymentStatus")
);
const AccountSettingsPage = lazy(() => import("./pages/AccountSettings"));
const ControlPanelAccountsPage = lazy(() =>
  import("./pages/ControlPanel/Accounts")
);
const ControlPanelRelationshipsPage = lazy(() =>
  import("./pages/ControlPanel/Relationships")
);
const ControlPanelUserListing = lazy(() =>
  import("./pages/ControlPanel/Users")
);
const ControlPanelUserProfile = lazy(() =>
  import("./pages/ControlPanel/Users/iamUsersProfile")
);

const loggedOutRoutes = [
  {
    path: "register",
    element: (
      <Suspense fallback={<FormSkeleton />}>
        <RegisterPage />
      </Suspense>
    ),
  },
  { path: "login", element: <LoginPage /> },
  { path: "forgot-password", element: <ForgotPasswordPage /> },
  { path: "resetPassword", element: <ResetPasswordPage /> },
  { path: "resendVerifyAccountEmail", element: <VerifyEmail /> },
  { path: "verifyAccount", element: <VerifyAccount /> },
];

const loggedInRoutes = [
  { path: "change-password", element: <ChangePasswordPage /> },
  {
    path: "applications",
    element: (
      <Suspense fallback={<TableSkeleton />}>
        <ApplicationsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Applications", type: "string" },
      hidden: true,
    },
  },
  {
    path: "applications/:applicationType/:applicationId",
    element: (
      <Suspense fallback={<PageDetailsSkeleton />}>
        <ApplicationDetailsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "applicationType", type: "params" },
      parentPath: "applications",
      showBorder: true,
    },
  },
  {
    exact: true,
    path: "application-forms/:applicationType/:applicationId/payment-status",
    element: (
      <Suspense fallback={<CardSkeleton />}>
        <PaymentStatusPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Payment Status", type: "string" },
      showBorder: true,
    },
  },
  {
    path: "licenses",
    element: (
      <Suspense fallback={<TableSkeleton />}>
        <LicenseDashboard />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Licenses", type: "string" },
      hidden: true,
    },
  },
  {
    path: "licenses/:recordType/:licenseNumber/:recordId",
    element: (
      <Suspense fallback={<PageDetailsSkeleton />}>
        <LicenseDetailsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "recordType", type: "params" },
      parentPath: "licenses",
      showBorder: true,
    },
  },
  {
    path: "/",
    element: <Navigate to="/licenses" />,
  },
];

const invoiceRoutes = [
  {
    path: "invoices",
    element: (
      <Suspense fallback={<TableSkeleton />}>
        <InvoicesDashboard />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Invoices", type: "string" },
      hidden: true,
    },
  },
  {
    path: "invoices/:id",
    exact: true,
    element: (
      <Suspense fallback={<PageDetailsSkeleton />}>
        <InvoiceDetailsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "id", type: "params" },
      parentPath: "invoices",
      showBorder: true,
    },
  },
  {
    path: "invoices/:type/:invoiceId/payment-status",
    exact: true,
    element: (
      <Suspense fallback={<CardSkeleton />}>
        <PaymentStatusPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Payment Status", type: "string" },
    },
  },
];

const accountRoutes = [
  {
    path: "accounts/:tenantId/:type",
    element: (
      <Suspense fallback={<FormSkeleton />}>
        <AccountSettingsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Details", type: "string" },
      parent: {
        path: "accounts/:tenantId/:type",
        breadcrumb: {
          label: { value: "Account Settings", type: "string" },
        },
      },
      showBorder: true,
    },
  },
  {
    path: "tenants/:tenantId/:type",
    element: (
      <Suspense fallback={<FormSkeleton />}>
        <AccountSettingsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Account Information", type: "string" },
      parent: {
        path: "tenants",
        breadcrumb: {
          label: { value: "Accounts", type: "string" },
        },
      },
    },
  },
];

const controlRoutes = [
  {
    path: "tenants",
    element: (
      <Suspense fallback={<TableSkeleton />}>
        <ControlPanelAccountsPage />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Accounts", type: "string" },
      hidden: true,
    },
  },
  {
    path: "users",
    element: (
      <Suspense fallback={<TableSkeleton />}>
        <ControlPanelRelationshipsPage />
      </Suspense>
    ),
  },
  {
    path: "iamusers",
    element: (
      <Suspense fallback={<TableSkeleton />}>
        <ControlPanelUserListing />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "Users", type: "string" },
      hidden: true,
    },
  },
  {
    path: "iamusers/:userHref",
    element: (
      <Suspense fallback={<FormSkeleton />}>
        <ControlPanelUserProfile />
      </Suspense>
    ),
    breadcrumb: {
      label: { value: "User Details", type: "string" },
      parentPath: "iamusers",
    },
  },
];

const routes = [
  { path: "app-form", element: <AppFormWithCustomConfig /> },
  //  public routes
  { path: "faq", element: <FAQPage /> },
  { path: "terms-conditions", element: <TermsConditionPage /> },
  { path: "privacy-policy", element: <PrivacyPolicyPage /> },
  { path: "accessibility", element: <AccessibilityPage /> },
  { path: "browser-support", element: <BrowserSupportPage /> },
  { path: "error", element: <ErrorPage /> },
  { path: "unauthorized", element: <UnauthorizedPage /> },

  // routes accessible only when user is logged out
  {
    element: <RequireAuth showWhenUserLoggedOut />,
    children: loggedOutRoutes,
  },

  // maintenance page route
  {
    element: <RequireAuth />,
    children: [
      { path: "maintenance", element: <MaintenancePage />, exact: true },
    ],
  },

  // routes accessible only when user is logged in
  {
    element: (
      <RequireAuth
        allowedItems={["allPermissions", "allTenantPermissions"]}
        navConfigItem
      />
    ),
    children: loggedInRoutes,
  },

  // invoice route
  {
    element: <RequireAuth conditionalNavConfigItem />,
    children: invoiceRoutes,
  },

  {
    element: (
      <RequireAuth allowedItems={["allPermissions", "allTenantPermissions"]} />
    ),
    children: accountRoutes,
  },

  {
    element: (
      <RequireAuth allowedItems={["allPermissions", "view_control_panel"]} />
    ),
    children: controlRoutes,
  },
];

export default routes;
