/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import IconButton from "@mui/material/IconButton";
import FormAutoCompleteField from "./FormAutoCompleteField";
import FormButton from "./FormButton";
import FormDateField from "./FormDateField";
import FormCheckBox from "./FormSwitchField";
import FormEmailField from "./FormEmailField";
import FormEncryptedField from "./FormEncryptedField";
import FormMultiSelect from "./FormMultiSelectField";
import FormPhoneField from "./FormPhoneField";
import FormRadioField from "./FormRadioField";
import FormSelect from "./FormSelect";
import FormTextAreaField from "./FormTextAreaField";
import FormTextField from "./FormTextField";
import FormInfoField from "./FormInfo";
import FormPasswordField from "./FormPasswordField";
import FormNumberField from "./FormNumberField";
import { showFormDialog } from "../context/dialogSlice";
import FormRecordList from "./FormRecordList";

export const Styled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

function FormEditableField(props) {
  const {
    fieldProps,
    formProps,
    register,
    control,
    getValues,
    disabled,
    trigger,
    watch,
    resetHandler,
    onOptionListChange,
    setValue,
    datagridFormState,
  } = props;

  const defaultProps = {
    fieldProps,
    formProps,
    control,
    getValues,
    disabled,
  };

  const dispatch = useDispatch();
  return (
    <>
      <Styled>
        {fieldProps.type === "textfield" && (
          <FormTextField
            resetHandler={resetHandler}
            trigger={trigger}
            {...defaultProps}
          />
        )}
        {fieldProps.type === "number" && (
          <FormNumberField
            {...defaultProps}
            trigger={trigger}
            setValue={setValue}
            resetHandler={resetHandler}
          />
        )}
        {fieldProps.type === "password" && (
          <FormPasswordField fieldProps={fieldProps} control={control} />
        )}
        {fieldProps.type === "textarea" && (
          <FormTextAreaField register={register} {...defaultProps} />
        )}
        {fieldProps.type === "email" && (
          <FormEmailField
            register={register}
            {...defaultProps}
            setValue={setValue}
          />
        )}
        {fieldProps.type === "phoneNumber" && (
          <FormPhoneField {...defaultProps} />
        )}
        {fieldProps.type === "encrypted" && (
          <FormEncryptedField
            register={register}
            setValue={setValue}
            watch={watch}
            {...defaultProps}
          />
        )}
        {fieldProps.type === "select" && (
          <FormSelect
            resetHandler={resetHandler}
            trigger={trigger}
            {...defaultProps}
            setValue={setValue}
            onOptionListChange={onOptionListChange}
            register={register}
          />
        )}
        {fieldProps.type === "selectboxes" && (
          <FormMultiSelect {...defaultProps} resetHandler={resetHandler} />
        )}

        {fieldProps.type === "datetime" && (
          <FormDateField
            resetHandler={resetHandler}
            trigger={trigger}
            setValue={setValue}
            {...defaultProps}
          />
        )}
        {fieldProps.type === "autocomplete" && (
          <FormAutoCompleteField
            onAutoCmpSelect={onOptionListChange}
            {...defaultProps}
          />
        )}
        {fieldProps.type === "recordList" && (
          <FormRecordList
            {...defaultProps}
            setValue={setValue}
            trigger={trigger}
            register={register}
          />
        )}
        {fieldProps.type === "button" && (
          <FormButton
            {...defaultProps}
            setValue={setValue}
            trigger={trigger}
            datagridFormState={datagridFormState}
          />
        )}
        {fieldProps?.tags &&
          fieldProps?.tags.includes("proTip") &&
          fieldProps.type !== "radio" &&
          fieldProps.type !== "checkbox" &&
          fieldProps.type !== "info" && (
            <IconButton
              aria-label="Pro Tip"
              onClick={() => {
                dispatch(
                  showFormDialog({
                    show: true,
                    fieldKey: fieldProps.key,
                    type: "proTip",
                  })
                );
              }}
            >
              <InfoTwoToneIcon
                fontSize="small"
                sx={{ cursor: "pointer", margin: "auto" }}
              />
            </IconButton>
          )}
      </Styled>
      {fieldProps.type === "radio" && (
        <FormRadioField
          resetHandler={resetHandler}
          trigger={trigger}
          {...defaultProps}
        />
      )}
      {(fieldProps.type === "info" || fieldProps.type === "note") && (
        <FormInfoField fieldProps={fieldProps} />
      )}

      {fieldProps.type === "checkbox" && (
        <FormCheckBox
          resetHandler={resetHandler}
          trigger={trigger}
          setValue={setValue}
          getValues={getValues}
          {...defaultProps}
        />
      )}
    </>
  );
}

FormEditableField.propTypes = {
  fieldProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    conditional: PropTypes.shape({}),
    tags: PropTypes.oneOfType([PropTypes.array]),
    key: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
  onOptionListChange: PropTypes.func.isRequired,
  datagridFormState: PropTypes.shape({}),
};
FormEditableField.defaultProps = {
  datagridFormState: undefined,
};

export default FormEditableField;
