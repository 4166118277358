import React from "react";

export default function ErrorPage() {
  return (
    <div style={{ marginTop: "100px", textAlign: "center" }}>
      <h2>Ooops!</h2>
      <p>Something bad happened ...</p>
    </div>
  );
}
