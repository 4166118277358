import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";

export const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "condition",
})(({ condition, theme }) => ({
  margin: `${condition.isDg ? "5px" : "1rem"} auto`,
  alignItems: "flex-start",
  display: "flex",
  "& .MuiTypography-root": {
    fontSize: "0.90rem",
    color: theme.palette.mode === "light" ? "#000" : undefined,
    "& span:first-of-type": {
      fontWeight: theme.palette.mode === "light" ? 600 : 500,
      textShadow: "none",
    },
    width: condition.hasMaxWidth ? "max-content" : "100%",
  },
  "& .customStyle": {
    padding: "2.5px 11px",
    marginLeft: "10px",
    borderRadius: "5px",
    backgroundColor: condition.errorBg
      ? theme.palette.error.main
      : theme.palette.primary.main,
    textTransform: "uppercase",
    fontWeight: "500 !important",
    color: "#fff",
  },
  "& .defaultStyle": {
    color: theme.palette.mode === "light" ? undefined : "#71cbf5",
    fontWeight: "400 !important",
  },
  "& .MuiLink-root": { fontSize: "0.90rem", color: "#31708f" },
  "& > .MuiSvgIcon-root": {
    marginRight: ".5rem",
  },
  "& .MuiIconButton-root": {
    padding: 0,
  },
}));

function Label(props) {
  const {
    label,
    value,
    customClass,
    isError,
    showIcon,
    isMasked,
    showMaskedIcon,
    handleMaskedToggle,
    isDg,
    singlePageLayoutIcon,
    isSinglePageLayout,
    errorBg,
    linkUrl,
    hasMaxWidth,
  } = props;

  const customStyle = { isDg, errorBg, hasMaxWidth };
  const applyPadding =
    showIcon || isDg || (!singlePageLayoutIcon && isSinglePageLayout);

  const getClassName = (cls, val) =>
    cls && val ? "customStyle" : "defaultStyle";
  const labelName = parse(label);
  return (
    <Styled condition={customStyle} tabIndex={0}>
      {showIcon && !isError && <CheckIcon color="success" />}
      {showIcon && isError && <ClearIcon color="error" />}
      <Typography sx={{ pl: applyPadding ? 0 : "2rem" }}>
        <span>{labelName}:&nbsp;</span>
        {!linkUrl && (
          <span className="wrapper">
            <span className={getClassName(customClass, value)}>{value}</span>
          </span>
        )}
        {linkUrl && (
          <Typography component={Link} href={linkUrl} target="_blank">
            {value}
          </Typography>
        )}
      </Typography>
      {showMaskedIcon && (
        <IconButton
          aria-label="toggle encrypted mask"
          onClick={handleMaskedToggle}
          size="small"
          sx={{ ml: applyPadding ? "0.5rem" : "-1.5rem" }}
        >
          {isMasked ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      )}
    </Styled>
  );
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  singlePageLayoutIcon: PropTypes.bool.isRequired,
  isSinglePageLayout: PropTypes.bool,
  customClass: PropTypes.bool,
  hasMaxWidth: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showIcon: PropTypes.bool,
  isError: PropTypes.bool,
  isDg: PropTypes.bool,
  isMasked: PropTypes.bool,
  showMaskedIcon: PropTypes.bool,
  handleMaskedToggle: PropTypes.func,
  linkUrl: PropTypes.string,
};
Label.defaultProps = {
  value: undefined,
  customClass: false,
  showIcon: undefined,
  isError: undefined,
  isDg: false,
  isMasked: false,
  showMaskedIcon: false,
  handleMaskedToggle: undefined,
  linkUrl: undefined,
  isSinglePageLayout: false,
  hasMaxWidth: false,
};
export default Label;
