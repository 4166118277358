import React, { useContext, useEffect } from "react";
import { useWatch, Controller, useFormState } from "react-hook-form";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

import { ReviewContext } from "../context/reviewItemContext";
import {
  generateFormFieldRules,
  getComputedValue,
  getFormattedComputedValue,
} from "../services/form.service";
import ReadOnlyField from "./FormReadOnlyField";
import ProTip from "../../ProTip";
import useGetFormStateValues from "../hooks/useGetFormProps";

const styleMap = {
  "font-weight-600": 600,
};

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "fontWeight",
})(({ fontWeight }) => ({
  marginTop: "1rem",
  fontWeight: fontWeight || 400,
  "& .protipIcon": {
    marginLeft: "1rem",
  },
}));

function ReadOnlyMappingField(props) {
  const { fieldProps, control, setValue, getValues, formProps } = props;
  const { key, label, validate = {}, cssClass, tags } = fieldProps;
  const { source } = validate?.custom || {};
  const { errors } = useFormState({ name: fieldProps.key });

  const watchFieldValues = useWatch({
    control,
    name: source,
    disabled: source === undefined,
    defaultValue:
      source?.length > 0
        ? source.map((fieldName) => formProps[fieldName])
        : undefined,
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && source) {
      let currentValues = watchFieldValues;
      if (currentValues?.every((item) => item === undefined)) {
        currentValues = source.map((item) => getValues(item));
      }
      const payload = {
        key,
        currentValues,
        customProps: validate.custom,
        setValue,
        getValues,
      };
      getComputedValue(payload);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFieldValues]);

  const rules = generateFormFieldRules(fieldProps, getValues);
  return (
    <FormControl fullWidth margin="dense" sx={{ minHeight: "60px" }}>
      <Controller
        name={key}
        control={control}
        defaultValue={formProps[key]}
        render={({ field }) => (
          <StyledTypography fontWeight={styleMap[cssClass]}>
            <span>{label}:&nbsp;</span>
            <span>
              {getFormattedComputedValue({
                value: field.value,
                customProps: validate.custom,
              })}
            </span>
            {tags && tags.includes("proTip") && <ProTip fieldKey={key} />}
          </StyledTypography>
        )}
        rules={rules}
      />
      {errors[key] && (
        <FormHelperText error id="{fieldProps.key}-number" sx={{ m: 0 }}>
          This is required
        </FormHelperText>
      )}
    </FormControl>
  );
}
ReadOnlyMappingField.propTypes = {
  fieldProps: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      custom: PropTypes.shape({}),
    }),
    cssClass: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

function ReadOnlyComponent(props) {
  const { fieldProps, control, setValue, getValues, newFormProps } = props;
  const { reviewProps } = useContext(ReviewContext);
  const { formConfig = {} } = useGetFormStateValues();
  const { domainName, isSinglePageLayout } = formConfig;

  const skipMapping = domainName === "LicenseForm" || isSinglePageLayout;

  return reviewProps || skipMapping ? (
    <ReadOnlyField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : (
    <ReadOnlyMappingField
      fieldProps={fieldProps}
      getValues={getValues}
      setValue={setValue}
      control={control}
      formProps={newFormProps}
    />
  );
}
ReadOnlyComponent.propTypes = {
  fieldProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  newFormProps: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
};
export default ReadOnlyComponent;
