import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Styled = styled(Box)(({ theme }) => ({
  "& .gridContainer": {
    justifyContent: "end",
    marginTop: "1rem",
    "& .MuiButton-root": {
      minWidth: "auto",
      marginRight: "1rem",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        marginRight: 0,
      },
      "&:hover": {
        backgroundColor:
          theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.1)" : undefined,
      },
    },
    "& .MuiButton-startIcon>*": {
      marginRight: "-5px",
    },
    "& .saveBtnOutlined": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "90% !important",
        marginRight: "12px",
      },
    },
  },
}));

export default Styled;

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .categorizedView .MuiPaper-root": {
    paddingTop: "8px",
  },
  "& .categoryTitle": {
    fontSize: "1.1rem",
    paddingLeft: ".5rem",
  },
  "& .addBtnContainer": {
    padding: "0 1rem",
    margin: ".5rem 0",
    "& .MuiButton-root:hover": {
      backgroundColor:
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.1)" : undefined,
    },
    "& .newRecordBtn": {
      borderWidth: "2px",
      "&:hover": {
        color: "#fff",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(45deg, #399837 0%,#64D55E 100%)"
            : "linear-gradient(45deg, #3ec13c 0%,#56e251 100%)",
      },
    },
  },
}));
