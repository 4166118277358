import React from "react";
import { useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Styled from "./styles";
import { useCoreApplicationConfigQuery } from "../../../../api/core.api.slice";

function FeesTable() {
  const { applicationType } = useParams();
  const { data: coreAppConfig } = useCoreApplicationConfigQuery();
  const formData = useSelector((state) => state.formProps.formData);
  const application = coreAppConfig?.applications.find(
    (app) => app.applicationType === applicationType
  );
  const applicationFees = application?.fees ?? [];
  const showFeesArray = coreAppConfig.officeInfo?.showFees || [];
  const paymentMethod = useWatch({
    name: "paymentMethod",
    defaultValue: formData?.paymentMethod,
  });
  const renderPaymentTable =
    applicationFees && showFeesArray.includes(paymentMethod);
  if (!renderPaymentTable || applicationFees.length === 0) return null;
  return (
    <Styled>
      <Divider />
      <Box className="table">
        <Typography variant="h6">Fee Details</Typography>
        <Table size="small" aria-label="fee details table">
          <TableBody>
            {applicationFees.map((row) => (
              <TableRow key={row.item}>
                <TableCell component="th" scope="row">
                  {row.item}
                </TableCell>
                <TableCell align="right" className="feeValue">
                  <strong>{row.fee}</strong>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Styled>
  );
}

export default FeesTable;
