import { updateFormConditions } from "../components/Form/services/form.service";
import splitApi from "./emptySplitApi";

export const tenantsApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    allTenants: build.query({
      query: (props) => ({
        url: `/api/tenants`,
        params: props,
      }),
      transformResponse(baseQueryReturnValue) {
        const { pages, total, limit, page } = baseQueryReturnValue;
        // eslint-disable-next-line no-param-reassign
        baseQueryReturnValue.pagination = { pages, total, limit, page };
        return baseQueryReturnValue;
      },
      providesTags: ["Tenant", "Accounts"],
    }),
    createAccount: build.mutation({
      query: (body) => ({
        url: "api/tenants",
        method: "POST",
        body,
      }),
    }),
    userTenants: build.query({
      query: (userHref) => ({
        url: `/api/auth/${userHref}/tenants`,
      }),
      providesTags: ["Tenants"],
    }),
    getTenant: build.query({
      query: (id) => `/api/tenants/${id}`,
      providesTags: ["Tenant"],
    }),
    getTenantUsers: build.query({
      query: (id) => `/api/tenants/${id}/users`,
      providesTags: ["Tenants"],
    }),
    createTenantUser: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/tenants/${id}/users`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tenants", "Tenant"],
    }),
    // to update the cache data which is already exist
    updateTenant: build.mutation({
      query: (body) => ({
        url: `/api/tenants/${body._id}`,
        method: "PUT",
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        let result;
        try {
          const { data: updatedTenantData } = await queryFulfilled;
          result = dispatch(
            tenantsApi.util.updateQueryData("getTenant", body._id, (draft) => {
              Object.assign(draft, updatedTenantData);
            })
          );
        } catch {
          result.undo();
        }
      },
      invalidatesTags: ["Accounts"],
    }),
    verifyTenant: build.mutation({
      query: ({ id, userHref, body }) => ({
        url: `/api/tenants/${id}/users/${userHref}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tenants"],
    }),
    deleteTenantUser: build.mutation({
      query: ({ id }) => ({
        url: `/api/tenantUsers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tenants"],
    }),
    getDeletedUsers: build.query({
      query: (id) => `/api/tenants/${id}/users/deleted`,
      providesTags: ["Tenants"],
    }),
    restoreTenantUser: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/tenantUsers/restore/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tenants"],
    }),

    // tenant users for relationships pages
    tenantUsers: build.query({
      query: (props) => ({
        url: "/api/tenantUsers",
        params: props,
      }),
      transformResponse(baseQueryReturnValue) {
        const { pages, total, limit, page } = baseQueryReturnValue;
        // eslint-disable-next-line no-param-reassign
        baseQueryReturnValue.pagination = { pages, total, limit, page };
        return baseQueryReturnValue;
      },
      providesTags: ["Tenant"],
    }),
    // tenant groups
    tenantGroups: build.query({
      query: () => "/api/tenantGroups",
    }),
    profileForm: build.query({
      query: () => ({
        url: `/dist/config/profileForm.json`,
      }),
      transformResponse(baseQueryReturnValue) {
        const updatedQueryReturnValue =
          updateFormConditions(baseQueryReturnValue);
        return updatedQueryReturnValue;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAllTenantsQuery,
  useCreateAccountMutation,
  useUserTenantsQuery,
  useGetTenantQuery,
  useUpdateTenantMutation,
  useGetTenantUsersQuery,
  useVerifyTenantMutation,
  useGetDeletedUsersQuery,
  useDeleteTenantUserMutation,
  useRestoreTenantUserMutation,
  useCreateTenantUserMutation,
  useTenantUsersQuery,
  useTenantGroupsQuery,
  useProfileFormQuery,
} = tenantsApi;
