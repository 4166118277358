import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

import { showFormDialog } from "../Form/context/dialogSlice";

function ProTip(props) {
  const { fieldKey, customClass } = props;
  const dispatch = useDispatch();

  const openProTipDialog = () => {
    dispatch(
      showFormDialog({
        show: true,
        fieldKey,
        type: "proTip",
      })
    );
  };

  return (
    <IconButton
      className={customClass || "protipIcon"}
      aria-label="Pro Tip"
      onClick={(e) => {
        e.stopPropagation();
        openProTipDialog();
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <InfoTwoToneIcon fontSize="small" sx={{ cursor: "pointer" }} />
    </IconButton>
  );
}
ProTip.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};
ProTip.defaultProps = {
  customClass: null,
};
export default ProTip;
