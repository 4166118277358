import React, { useState, useEffect, useMemo, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import SwipeableViews from "react-swipeable-views";

// eslint-disable-next-line import/no-unresolved
import ReviewPageBanner from "@regional/templates/Application/reviewPageBanner";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { ReviewPropsProvider } from "../context/reviewItemContext";
import Layout from "../Layouts/Layout";
import TabPanel from "./TabPanel";
import FormAlert from "../Field/FormAlert";
import FormDialogs from "../Dialogs";
import Styled from "./styles";

import {
  updateMethodRef,
  methodRefs,
  requireFieldsInfo,
} from "../context/formSlice";
import { showNotification } from "../../../features/core/slices/notification.slice";
import {
  createDatagridMap,
  getTabState,
  getWatchFieldName,
  hasUnsavedDgRecord,
  mergeDgRecordsToMainForm,
  verifyDgFields,
} from "../services/form.service";
import useDatagridState from "../hooks/useDatagridState";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import PaymentTable from "../../../features/application/components/PaymentTable";
import KeyboardNavLink from "../../KeyboardNavLink";
import { showFormDialog } from "../context/dialogSlice";
import { togglePreSubmitDialog } from "../context/dialogFormSlice";
import { recordPaymentMethods } from "../../../services/global.constant";

function Wizard({
  formTemplate,
  showReviewTab,
  onSubmit,
  onError,
  formSaveHandler,
  printApplicationBtn,
}) {
  const dispatch = useDispatch();
  const { applicationId, applicationType } = useParams();
  const [value, setValue] = useState(0);
  const [index, setIndex] = useState(0);
  const applicationStatus = useSelector(
    (state) => state.formProps.applicationData?.status
  );
  const { formDialog } = useSelector((state) => state.formDialog);
  const initialFormData = useSelector((state) => state.formProps.formData);
  const dgDocuments = useSelector(
    (state) => state.formProps.formDocuments?.dgDocuments
  );
  const { domainName } = useSelector((state) => state.formProps.formConfig);
  const { getDgState, resetDgState, setDgKeysWithTabIndex } =
    useDatagridState();
  const { updateHeight, setUpdateHeightMethodRef } = useUpdatePanelHeight();
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue: formSetValue,
  } = useFormContext();
  const isLicenseForm = domainName === "LicenseForm";
  const currentData = isLicenseForm ? initialFormData : { ...getValues() };

  // to track the disabled tabs and skip those tabs on clicking "save & next" btn
  const tabRef = useRef({});
  const tabIndexRef = useRef({});
  const warningMsg = <ReviewPageBanner applicationType={applicationType} />;
  useEffect(() => {
    // TODO: need to make it to work only for license update workflow
    let mounted = true;
    if (mounted && formTemplate && formTemplate.components.length > 0) {
      const panels = formTemplate.components.filter(
        (component) => component.type === "panel"
      );

      const dummyTextFields = panels.flatMap((panel) => {
        if (Array.isArray(panel.components)) {
          return panel.components.filter(
            (component) => component.type === "textfield"
          );
        }
        return [];
      });

      if (dummyTextFields.length) {
        dummyTextFields.forEach((field) => {
          register(field.key);
          const val =
            initialFormData[field.key] === undefined
              ? false
              : initialFormData[field.key];
          formSetValue(field.key, val);
        });
      }
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  const allowTabChange = (tabIndex, type, next) => {
    if (applicationStatus !== "Open" && applicationStatus !== "Rejected")
      return true;
    const unsavedDgList = hasUnsavedDgRecord(getDgState, value, methodRefs);
    tabIndexRef.current = { tabIndex, type, next, unsavedDgList };
    if (!unsavedDgList.length) return true;
    dispatch(showFormDialog({ show: true, type: "dgWarning" }));
    return false;
  };

  const handleTabChange = (event, newValue, skipDgCheck) => {
    if (skipDgCheck || allowTabChange(newValue, "tabChange")) {
      setValue(newValue);
      setIndex(newValue);
    }
  };

  const handleDisable = (tab, formData, tabIndex) => {
    if (applicationId === "new" && tabIndex !== 0) {
      tabRef.current = { ...tabRef.current, [tabIndex]: true };
      return true;
    }
    const isDisabled = getTabState(tab, formData);
    tabRef.current = { ...tabRef.current, [tabIndex]: isDisabled };
    return isDisabled;
  };

  // for updating the height of tabPanel based on action
  let swipeableActions;
  const updateSwipeableViewHeight = () => {
    if (swipeableActions) {
      swipeableActions.updateHeight();
    }
  };
  useEffect(() => {
    if (swipeableActions) {
      dispatch(
        updateMethodRef({
          key: "updateHeight",
          methodRef: updateSwipeableViewHeight,
        })
      );
      setUpdateHeightMethodRef(updateSwipeableViewHeight);
    }
    return () => {
      // reset the dgState, FormProps & MethodRefs
      resetDgState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeableActions]);

  const tabItems = useMemo(
    () =>
      formTemplate &&
      formTemplate.components?.filter((item) => item && item.components),
    [formTemplate]
  );

  const watchList = useMemo(() => {
    const list =
      tabItems.length > 0 &&
      tabItems
        .filter((tab) => tab.conditional && tab.conditional.when)
        .flatMap((item) =>
          item.validate ? getWatchFieldName(item) : item.conditional.when
        );
    return [...new Set(list)];
  }, [tabItems]);

  useEffect(() => {
    setDgKeysWithTabIndex(createDatagridMap(tabItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabItems]);

  const watchFields = useWatch({
    name: watchList,
    control,
  });

  const moveToNextValidTab = (changeIndexTo, skipDgCheck) => {
    if (skipDgCheck || allowTabChange(changeIndexTo, "moveToNext")) {
      const direction = changeIndexTo > value ? 1 : -1;
      const tabs = Object.keys(tabRef.current);

      let foundValidTab = false;
      let nextIndex = value;
      while (!foundValidTab) {
        nextIndex += direction;

        if (direction === 1) {
          if (
            nextIndex >= changeIndexTo &&
            tabs.length > nextIndex &&
            tabRef.current[nextIndex] !== true
          ) {
            foundValidTab = true;
            break;
          }
          if (nextIndex >= tabs.length) {
            nextIndex = -1;
            break;
          }
        } else if (direction === -1) {
          if (
            nextIndex <= changeIndexTo &&
            tabs.length > nextIndex &&
            tabRef.current[nextIndex] !== true
          ) {
            foundValidTab = true;
            break;
          }
          if (nextIndex < 0) {
            nextIndex = -1;
            break;
          }
        }
      }
      if (nextIndex === -1) {
        if (applicationId === "new") {
          const validTabIndex = Object.values(tabRef.current).findIndex(
            (isDisable) => isDisable !== true
          );
          // Not able to stop the Swipe onDrag if value === validTabIndex
          setValue(validTabIndex);
          setIndex(changeIndexTo);
          setTimeout(() => setIndex(validTabIndex), 0);
        }
        if (showReviewTab && applicationId !== "new") {
          setValue(tabItems.length);
          setIndex(tabItems.length);
        }
      } else {
        setValue(+nextIndex);
        setIndex(+nextIndex);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleFormSave = async (next, dgRecordCheck, skipDgMerging = false) => {
    tabIndexRef.current = { type: "save", next };
    if (dgRecordCheck && !allowTabChange(null, "save", next)) return null;
    if (!skipDgMerging) {
      // to merge the current dg(if present) items to main form
      mergeDgRecordsToMainForm({
        methodRefs,
        getDgState,
        setValue: formSetValue,
      });
    }
    const result = await formSaveHandler();
    if (result) {
      if (next) moveToNextValidTab(value + 1, true);
      const msg = "Application Form Updated Successfully";
      dispatch(showNotification({ type: "success", msg, show: true }));
    }
    return result;
  };

  const handleDialogAction = () => {
    const { type, tabIndex, next } = tabIndexRef.current || {};
    if (type === "tabChange") {
      handleTabChange(null, tabIndex, true);
    } else if (type === "save") {
      handleFormSave(next, false);
    } else if (type === "moveToNext") {
      moveToNextValidTab(true, tabIndex);
    }
    // tabIndex.current = {};
  };

  const tabComponent = useMemo(
    () => (
      <Tabs
        value={value}
        onChange={handleTabChange}
        TabIndicatorProps={{ sx: { bgcolor: "transparent" } }}
        variant="scrollable"
        allowScrollButtonsMobile
        aria-label="form tabs"
        className="tabList"
      >
        {tabItems.map(
          (tab, i) =>
            tab.type === "panel" &&
            tab.title !== "Actions" && (
              <Tab
                label={tab.title}
                // eslint-disable-next-line react/no-array-index-key
                key={`${tab.key}-${i}`}
                id={`form-tab-${i}`}
                aria-controls={`form-tabpanel-${i}`}
                disabled={handleDisable(tab, currentData, i)}
                wrapped={tab.title?.length > 30}
                disableFocusRipple
              />
            )
        )}
        {showReviewTab && (
          <Tab
            label="Review"
            id="form-tab-review"
            aria-controls={`form-tabpanel-${tabItems.length}`}
            disabled={applicationId === "new"}
            disableFocusRipple
          />
        )}
      </Tabs>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...watchFields, value, currentData, applicationId]
  );

  const memoReviewLayout = useMemo(
    () => (
      <Layout
        components={tabItems}
        handleTabChange={handleTabChange}
        formTemplate={formTemplate}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateAndSubmit = (data) => {
    const result = verifyDgFields(
      getDgState(),
      methodRefs,
      tabRef.current,
      requireFieldsInfo,
      dgDocuments
    );
    if (result.isError) {
      const { errMsg, multiErrMsg } = result;
      const listItems = multiErrMsg
        .filter((err) => err.dgLabels.length)
        .map((err) => ({
          title: err.errMsg,
          items: err.dgLabels,
        }));
      if (errMsg)
        dispatch(
          showNotification({ type: "warning", msg: errMsg, show: true })
        );
      else if (listItems.length) {
        const config = { listItems };
        dispatch(showNotification({ type: "warning", config, show: true }));
      }
    } else if (requireFieldsInfo.preSubmitActions.payCycleSelection) {
      dispatch(
        togglePreSubmitDialog({ dialog: "payCycleSelection", open: true })
      );
    } else onSubmit(data);
  };

  const handleError = (error) => {
    onError(error);
    setTimeout(() => updateHeight(), 400);
  };

  const reviewTabItems = useMemo(
    () => (
      <TabPanel value={value} index={tabItems.length} customMargin>
        <ReviewPropsProvider>
          <FormAlert severity="warning" message={warningMsg} marginBtm="1rem" />
          {printApplicationBtn}
          {memoReviewLayout}
          <PaymentTable />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            className="reviewTabBtnContainer"
          >
            <Grid item>
              <Button variant="contained" component={Link} to="/applications">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleSubmit(validateAndSubmit, handleError)}
                startIcon={<CheckCircleRoundedIcon />}
              >
                {applicationStatus === "Open" &&
                  currentData.paymentMethod &&
                  recordPaymentMethods.includes(currentData.paymentMethod) &&
                  "Pay & "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </ReviewPropsProvider>
      </TabPanel>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const buttonContainer = (
    <Grid container justifyContent="center" id="buttonContainer" tabIndex="0">
      <Grid item>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => handleFormSave(false, true)}
        >
          Save
        </Button>
      </Grid>
      {applicationId !== "new" && (
        <Grid item>
          <Button
            variant="contained"
            startIcon={<ArrowForwardIcon />}
            onClick={() => handleFormSave(true, true)}
          >
            Save & Next
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button variant="contained" component={Link} to="/applications">
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  const tabPanels = tabItems.map((tab, i) => (
    <TabPanel
      value={value}
      index={i}
      // eslint-disable-next-line react/no-array-index-key
      key={`${tab.key}-${i}`}
    >
      <Layout components={tab.components} nested />
    </TabPanel>
  ));

  return (
    <Styled
      isReviewTab={showReviewTab && tabItems.length === value}
      tabIndex="0"
      id="wizard"
    >
      {tabComponent}
      <Paper className="paper" elevation={2}>
        {showReviewTab ? (
          <SwipeableViews
            axis="x"
            index={index}
            action={(actions) => {
              swipeableActions = actions;
              return null;
            }}
            animateHeight
            onChangeIndex={moveToNextValidTab}
          >
            {tabPanels}
            {reviewTabItems}
          </SwipeableViews>
        ) : (
          <SwipeableViews
            axis="x"
            index={index}
            action={(actions) => {
              swipeableActions = actions;
              return null;
            }}
            animateHeight
            onChangeIndex={moveToNextValidTab}
          >
            {tabPanels}
          </SwipeableViews>
        )}
        {showReviewTab && tabItems.length !== value && buttonContainer}
        <KeyboardNavLink
          type="button"
          addMargin={showReviewTab && tabItems.length !== value}
        />
      </Paper>
      {formDialog.show && (
        <FormDialogs
          handleFormSave={handleFormSave}
          handleDialogAction={handleDialogAction}
          tabIndexRef={tabIndexRef}
        />
      )}
    </Styled>
  );
}
Wizard.propTypes = {
  formTemplate: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  showReviewTab: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  formSaveHandler: PropTypes.func,
};
Wizard.defaultProps = {
  formSaveHandler: undefined,
};
export default memo(Wizard);
