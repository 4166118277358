import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import find from "lodash/find";
import debounce from "lodash/debounce";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import TableToolbar from "./TableToolbar";
import TableHeader from "./TableHeader";
import {
  getPropertyValue,
  getNavLink,
  getformattedCellValue,
} from "../../utils/helpers";
import useUpdatePanelHeight from "../Form/hooks/useUpdatePanelHeight";
import { getFilteredRows, getSortedRow } from "./services/dataTable.service";

const DataTable = forwardRef((props, ref) => {
  const {
    rows,
    pagination,
    tableHeaders,
    updateList,
    progress,
    models,
    config,
    filters,
    resolvers,
    elevation,
    req,
    open,
    selectedRows,
    readonly,
    subTableRenderer,
    controlRenderer,
    onRowSelect,
    alertComponent,
  } = props;
  const [order, setOrder] = useState(req?.sort?.includes("-") ? "desc" : "asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(req);
  const [page, setPage] = useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    config.defaultLimit || pagination.limit
  );
  const [rowOpen, setRowOpen] = useState({});
  const [filterRows, setFilterRows] = useState([]);
  const { updateHeight } = useUpdatePanelHeight();

  if (!config.compareBy) config.compareBy = "_id";
  if (!config.maxSelectRow) config.maxSelectRow = Infinity;
  if (!config.isRowSelectable) config.isRowSelectable = () => true;
  if (!config.subLicensesKey) config.subLicensesKey = "subLicenses";

  config.isSelectable = (_row, _selectedRows) => {
    const canSelectRow = config.isRowSelectable(_row, _selectedRows);
    const selectedRow = _selectedRows.find(
      (_selectedRow) =>
        _selectedRow[config.compareBy] === _row[config.compareBy]
    );
    if (canSelectRow && selectedRow) return true;
    return canSelectRow && _selectedRows.length < config.maxSelectRow;
  };

  const rowsInfo = {
    currentPageSelectedRows: 0,
    currentPageDisabledRows: 0,
    allRowsExpanded: true,
    allSelectedSubRowsCount: 0,
    totalSubRowsCount: 0,
    subTablesInfo: {},
    hasSubTable: config.hasSubTable,
  };

  filterRows.forEach((row) => {
    const { compareBy, hasSubTable, isSelectable } = config;
    const selectedRow = selected.find((r) => r[compareBy] === row[compareBy]);
    if (selectedRow) rowsInfo.currentPageSelectedRows += 1;
    if (
      hasSubTable &&
      row.properties &&
      row.properties[config.subLicensesKey]
    ) {
      rowsInfo.subTablesInfo[row[compareBy]] = {
        total: row.properties[config.subLicensesKey].length,
        subTotal: 0,
      };
      row.properties[config.subLicensesKey].forEach((subLic) => {
        rowsInfo.totalSubRowsCount += 1;
        if (subLic.isSelected) {
          rowsInfo.allSelectedSubRowsCount += 1;
          rowsInfo.subTablesInfo[row[compareBy]].subTotal += 1;
        }
      });
    }
    if (!rowOpen[row[compareBy]] && hasSubTable)
      rowsInfo.allRowsExpanded = false;
    if (!isSelectable(row, selected)) rowsInfo.currentPageDisabledRows += 1;
  });

  const handleRequestSort = (event, property, defaultSort) => {
    const isAsc = (orderBy === property || defaultSort) && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    updateList({
      ...selectedFilter,
      limit: rowsPerPage,
      sort: `${isAsc ? "-" : ""}${property}`,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const _req = { ...selectedFilter, limit: rowsPerPage, page: newPage + 1 };
    if (orderBy) _req.sort = `${order === "desc" ? "-" : ""}${orderBy}`;
    updateList(_req);
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value, 10);
    const firstRowCount = page * rowsPerPage + 1;
    const newPage = Math.floor(firstRowCount / limit);
    setPage(newPage);
    setRowsPerPage(limit);
    const _req = { ...selectedFilter, page: newPage + 1, limit };
    if (orderBy) _req.sort = `${order === "desc" ? "-" : ""}${orderBy}`;
    updateList(_req);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((filterProps) => {
      setPage(0);
      updateList({
        ...filterProps,
        page: 1,
      });
    }, 500),
    []
  );
  const handleFilterChange = useCallback(
    (filterProps) => {
      const _req = { ...filterProps, limit: rowsPerPage };
      if (orderBy) _req.sort = `${order === "desc" ? "-" : ""}${orderBy}`;
      delayedQuery(_req);
      setSelectedFilter(_req);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delayedQuery, pagination, rowsPerPage, order, orderBy]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedsMap = selected.reduce(
        (accumulator, row) => ({
          ...accumulator,
          [row[config.compareBy]]: row,
        }),
        {}
      );
      filterRows.forEach((row) => {
        if (config.isSelectable(row, selected))
          newSelectedsMap[row[config.compareBy]] = row;
      });
      const newSelecteds = Object.values(newSelectedsMap);
      if (newSelecteds.length > config.maxSelectRow)
        newSelecteds.length = config.maxSelectRow;
      setSelected(newSelecteds);
      if (config.onSelectAll)
        config.onSelectAll(event.target.checked, newSelecteds);
      return newSelecteds;
    }

    const newSelecteds = [...selected];
    filterRows.forEach((row) => {
      const index = newSelecteds.findIndex(
        (n) => n[config.compareBy] === row[config.compareBy]
      );
      if (index > -1) newSelecteds.splice(index, 1);
    });
    setSelected(newSelecteds);
    if (config.onSelectAll)
      config.onSelectAll(event.target.checked, newSelecteds);
    return newSelecteds;
  };

  const handleExpandAllRows = () => {
    const isExpanded = !rowsInfo.allRowsExpanded;
    const _rowOpen = {};
    rows.forEach((row) => {
      _rowOpen[row[config.compareBy]] = isExpanded;
    });
    setRowOpen((prev) => ({ ...prev, ..._rowOpen }));
    setTimeout(() => updateHeight(), 700);
  };
  const handleExpandRow = (row) => {
    setRowOpen((prev) => ({
      ...prev,
      [row[config.compareBy]]: !prev[row[config.compareBy]],
    }));
    setTimeout(() => updateHeight(), 400);
  };
  const handleClick = (event, row) => {
    if (config.hasSubTable) handleExpandRow(row);
    if (!config.rowSelect || !config.isSelectable(row, selected) || readonly)
      return;
    const selectedIndex = selected.findIndex(
      (r) => r[config.compareBy] === row[config.compareBy]
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    const alreadySelected = selectedIndex >= 0;
    if (config.onSelect) config.onSelect(!alreadySelected, row);
    setSelected(newSelected);
  };
  const getCellData = (data, column, index) => {
    if (!data || !column) return false;
    switch (column.type) {
      case "text": {
        const value = getPropertyValue(data, column.systemName);
        const txtValue = (column.aliases && column.aliases[value]) || value;
        const { condition, dynamicCellValue } = column;
        if (!(dynamicCellValue && rowsInfo[condition]))
          return txtValue?.toString();
        const dynValues = {
          cellValue: txtValue,
          selectedRows:
            rowsInfo.subTablesInfo[data[config.compareBy]]?.subTotal ?? 0,
          totalRows: rowsInfo.subTablesInfo[data[config.compareBy]]?.total ?? 0,
          hasSubTable: config.hasSubTable,
        };
        return getformattedCellValue(
          txtValue,
          condition,
          dynamicCellValue,
          dynValues
        );
      }
      case "date": {
        const dateStr = getPropertyValue(data, column.systemName);
        return dateStr ? moment(dateStr).format("MMM D, YYYY") : null;
      }
      case "applicationType": {
        if (models && models.applicationType) {
          const type = find(models.applicationType, {
            key: data[column.systemName],
          });
          return type ? type.name : false;
        }
        return false;
      }
      case "link": {
        const navLink = getNavLink(data, column);
        const linkContent = getPropertyValue(data, column.systemName);
        return (
          <Button
            component={Link}
            variant="text"
            className="customLink"
            to={navLink}
          >
            {linkContent || "Not Applicable"}
          </Button>
        );
      }
      case "elem":
        if (resolvers) {
          return resolvers[column.systemName](data, index, column);
        }
        return false;
      default:
        return false;
    }
  };
  const isSelected = (row) =>
    selected.findIndex((r) => r[config.compareBy] === row[config.compareBy]) !==
    -1;
  useEffect(() => {
    setPage(pagination.page - 1);
  }, [pagination]);
  useEffect(() => {
    setSelected(selectedRows || []);
  }, [selectedRows]);
  useEffect(() => {
    if (config.localPagination) {
      const _sortedRows = getSortedRow(rows, orderBy, order);
      const _filterRows = getFilteredRows(_sortedRows, selectedFilter);
      setFilterRows(_filterRows);
    } else {
      setFilterRows(rows);
    }
  }, [config.localPagination, order, orderBy, rows, selectedFilter]);
  useEffect(() => {
    if (onRowSelect) onRowSelect(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const toolbar = useMemo(
    () => (
      <TableToolbar
        numSelected={selected.length}
        filterConfig={filters}
        filterModels={models}
        onFilter={handleFilterChange}
        req={req}
        open={open}
        hasSubTable={config.hasSubTable}
        expandAllRows={handleExpandAllRows}
        allRowsExpanded={rowsInfo.allRowsExpanded}
        resetSelected={() => setSelected([])}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected.length, rowsInfo.allRowsExpanded, rowsPerPage, models?.type, req]
  );
  useImperativeHandle(
    ref,
    () => ({
      getSelectedRows: () => selected,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, rows]
  );

  let paginationRows = rows || [];
  let count = pagination.total || 0;
  if (config.localPagination) {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    paginationRows = filterRows.slice(startIndex, endIndex);
    count = filterRows.length;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={elevation} sx={{ width: "100%", marginBottom: 2 }}>
        {(config.rowSelect || filters) && !config.hideToolbar && toolbar}
        {paginationRows && paginationRows.length > 0 ? (
          <TableContainer>
            <Table
              sx={{ minWidth: "750px" }}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHeader
                columns={tableHeaders}
                numSelected={rowsInfo.currentPageSelectedRows}
                order={order}
                orderBy={orderBy}
                rowSelect={config.rowSelect}
                maxSelectRow={config.maxSelectRow}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filterRows.length - rowsInfo.currentPageDisabledRows}
                req={req}
                hasSubTable={config.hasSubTable}
                controlRenderer={controlRenderer}
                readonly={readonly}
                rowsInfo={rowsInfo}
              />
              <TableBody>
                {paginationRows.map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isSelectable = config.isSelectable(row, selected);

                  return (
                    <Fragment key={row[config.compareBy]}>
                      <TableRow
                        hover={isSelectable}
                        onClick={(event) => handleClick(event, row)}
                        tabIndex={-1}
                        sx={{
                          ...((isSelectable || config.hasSubTable) && {
                            cursor: "pointer",
                          }),
                        }}
                      >
                        {config.rowSelect && (
                          <TableCell scope="row" padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              disabled={readonly || !isSelectable}
                            />
                          </TableCell>
                        )}
                        {config.hasSubTable && (
                          <TableCell padding="checkbox">
                            <IconButton aria-label="expand row">
                              {rowOpen[row[config.compareBy]] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        )}
                        {tableHeaders.map((column) => (
                          <TableCell
                            scope="row"
                            key={column.systemName + column.displayName}
                            padding="normal"
                          >
                            {["link", "elem"].includes(column?.type) ? (
                              getCellData(row, column, index) ||
                              "Not Applicable"
                            ) : (
                              <Box tabIndex="0" component="span">
                                {getCellData(row, column, index) ||
                                  "Not Applicable"}
                              </Box>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ padding: 0 }}
                          colSpan={
                            tableHeaders.length + (config.rowSelect ? 2 : 1)
                          }
                        >
                          <Collapse
                            in={rowOpen[row[config.compareBy]]}
                            timeout="auto"
                            unmountOnExit
                            sx={{ px: 1 }}
                          >
                            {subTableRenderer(row)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            className="infoGrid"
          >
            {!progress && (
              <Grid item xs={11} marginBottom={2}>
                {alertComponent || (
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    No records found.
                  </Alert>
                )}
              </Grid>
            )}
          </Grid>
        )}
        {config.pagination && (
          <TablePagination
            rowsPerPageOptions={config.limitRange || [5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage || 5}
            page={page || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
});

DataTable.displayName = "DataTable";
DataTable.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.array]).isRequired,
  pagination: PropTypes.oneOfType([PropTypes.object]),
  tableHeaders: PropTypes.oneOfType([PropTypes.array]).isRequired,
  updateList: PropTypes.func.isRequired,
  progress: PropTypes.bool.isRequired,
  models: PropTypes.oneOfType([PropTypes.object]),
  req: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  config: PropTypes.shape({
    rowSelect: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    pagination: PropTypes.bool,
    localPagination: PropTypes.bool,
    isRowSelectable: PropTypes.func,
    compareBy: PropTypes.string,
    hasSubTable: PropTypes.bool,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    limitRange: PropTypes.arrayOf(PropTypes.number),
    defaultLimit: PropTypes.number,
    maxSelectRow: PropTypes.number,
  }),
  controlRenderer: PropTypes.func,
  subTableRenderer: PropTypes.func,
  filters: PropTypes.oneOfType([PropTypes.object]),
  resolvers: PropTypes.oneOfType([PropTypes.object]),
  elevation: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
  readonly: PropTypes.bool,
  onRowSelect: PropTypes.func,
  alertComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
};
DataTable.defaultProps = {
  pagination: { page: 1, limit: 50 },
  models: null,
  req: {},
  open: false,
  config: {
    rowSelect: false,
    hideToolbar: false,
    pagination: false,
    localPagination: false,
    isRowSelectable: () => true,
    compareBy: "_id",
    hasSubTable: false,
    onSelect: () => null,
    onSelectAll: () => null,
    maxSelectRow: Infinity,
  },
  controlRenderer: () => null,
  subTableRenderer: () => null,
  filters: null,
  resolvers: null,
  elevation: 1,
  selectedRows: null,
  readonly: false,
  onRowSelect: undefined,
  alertComponent: null,
};
export default DataTable;
