import splitApi from "./emptySplitApi";

const licensesApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    licenses: build.query({
      query: (body) => ({
        url: "api/licenses",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        const { pages, total, limit, page } = baseQueryReturnValue;
        // eslint-disable-next-line no-param-reassign
        baseQueryReturnValue.pagination = { pages, total, limit, page };
        return baseQueryReturnValue;
      },
      providesTags: ["Licenses"],
    }),
    subLicenses: build.mutation({
      query: (body) => ({
        url: "api/licenses/subLicenses",
        method: "POST",
        body,
      }),
      providesTags: ["Licenses"],
    }),
    licensesConfig: build.query({
      query: () => ({
        url: "/dist/config/licenses.json",
      }),
      providesTags: ["License"],
    }),
    getApprovedLicenses: build.mutation({
      query: (params) => ({
        url: "api/licenses",
        method: "GET",
        params,
      }),
    }),
    getRecord: build.query({
      query: ({ recordType, licenseNumber, params }) => ({
        url: `api/licenses/${recordType}/${licenseNumber}`,
        params,
      }),
      providesTags: ["License"],
    }),
    license: build.mutation({
      query: ({ licenseType, licenseNumber, params }) => ({
        url: `api/licenses/${licenseType}/${licenseNumber}`,
        method: "GET",
        params,
      }),
    }),
    licenseLookup: build.mutation({
      query: ({ licenseType, licenseNumber, params }) => ({
        url: `api/licenses/public/${licenseType}/${licenseNumber}`,
        method: "GET",
        params,
      }),
    }),
    linkedLicenses: build.query({
      query: (params) => ({
        url: "api/licenses/linkedLicenses",
        params,
      }),
      providesTags: ["License"],
    }),
    metrcConfig: build.query({
      query: () => ({
        url: "/dist/config/metrc.config.json",
      }),
    }),
    metrcInfo: build.mutation({
      query: ({ licenseType, licenseNumber, params }) => ({
        url: `api/metrc/licenses/${licenseType}/${licenseNumber}/status`,
        method: "GET",
        params,
      }),
    }),
    formConfig: build.query({
      query: (params) => ({
        url: "api/config/formConfig",
        params,
      }),
      providesTags: ["License"],
    }),
    updateLicenseState: build.mutation({
      query: ({ licenseType, licenseNumber, type }) => ({
        url: `api/licenses/${licenseType}/${licenseNumber}/${type}`,
        method: "PUT",
      }),
      invalidatesTags: ["Licenses"],
    }),
    associatedLicenseConfig: build.query({
      query: () => ({
        url: "/dist/config/associatedLicenseWidget.config.json",
      }),
    }),
    getAssociatedLicenses: build.query({
      query: ({ licenseType, licenseNumber, associationType }) => ({
        url: `api/licenses/${licenseType}/${licenseNumber}/association/${associationType}`,
      }),
      providesTags: ["AssociatedLicenses"],
    }),
  }),
});

export const {
  useLicensesQuery,
  useSubLicensesMutation,
  useLicensesConfigQuery,
  useGetApprovedLicensesMutation,
  useGetRecordQuery,
  useLicenseMutation,
  useLicenseLookupMutation,
  useLinkedLicensesQuery,
  useMetrcConfigQuery,
  useMetrcInfoMutation,
  useFormConfigQuery,
  useUpdateLicenseStateMutation,
  useAssociatedLicenseConfigQuery,
  useGetAssociatedLicensesQuery,
} = licensesApi;
