/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserFromToken,
  getTenantFromToken,
  getUserPermissions,
  unauthorize,
} from "../../../services/auth.service";

const initialState = {
  userData: null,
  tenantData: null,
  permissionList: [],
};

export const getLogin = createAsyncThunk("auth/doLogin", async () => {
  const userData = getUserFromToken();
  const tenantData = getTenantFromToken();
  const permissionList = getUserPermissions();
  return {
    userData,
    tenantData,
    permissionList,
  };
});
export const logout = createAsyncThunk("auth/logout", async () => {
  unauthorize();
  return initialState;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getLogin.fulfilled, (state, { payload }) => {
      state.userData = payload.userData;
      state.tenantData = payload.tenantData;
      state.permissionList = payload.permissionList;
    });
    builder.addCase(getLogin.rejected, (state) => {
      state.userData = initialState.userData;
      state.tenantData = initialState.tenantData;
      state.permissionList = initialState.permissionList;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.userData = initialState.userData;
      state.tenantData = initialState.tenantData;
      state.permissionList = initialState.permissionList;
    });
  },
});

export default authSlice.reducer;
