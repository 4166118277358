import splitApi from "./emptySplitApi";
import { authorize } from "../services/auth.service";
import { updateFormConditions } from "../components/Form/services/form.service";

const authApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: ({ captchaToken, username, password }) => ({
        url: "/api/auth/login",
        method: "POST",
        headers: {
          "x-complia-captcha": captchaToken,
        },
        body: { username, password },
      }),
      invalidatesTags: [
        "BulkLicenses",
        "Licenses",
        "License",
        "Applications",
        "Application",
        "Invoices",
        "Invoice",
        "Tenants",
        "Tenant",
        "IamUser",
        "Accounts",
        "Config",
        "RejectedApplications",
        "SelectOptions",
        "AssociatedLicenses",
      ],
      transformResponse(baseQueryReturnValue) {
        authorize(baseQueryReturnValue.token, baseQueryReturnValue.permissions);
        return baseQueryReturnValue;
      },
    }),
    registerForm: build.query({
      query: () => ({
        url: `/dist/config/registerForm.json`,
      }),
      transformResponse(baseQueryReturnValue) {
        const updatedQueryReturnValue =
          updateFormConditions(baseQueryReturnValue);
        return updatedQueryReturnValue;
      },
    }),
    registerConfig: build.query({
      query: () => ({
        url: `/dist/config/registerConfig.json`,
      }),
    }),
    register: build.mutation({
      query: ({ captchaToken, body }) => ({
        url: "api/auth/register",
        method: "POST",
        headers: {
          "x-complia-captcha": captchaToken,
        },
        body,
      }),
    }),
    switchAccount: build.mutation({
      query: (id) => ({
        url: `/api/auth/switchAccount/${id}`,
        method: "GET",
      }),
      invalidatesTags: [
        "BulkLicenses",
        "Licenses",
        "Applications",
        "Invoices",
        "Tenants",
        "RejectedApplications",
        "SelectOptions",
        "AssociatedLicenses",
      ],
      transformResponse(baseQueryReturnValue) {
        authorize(baseQueryReturnValue.token, baseQueryReturnValue.permissions);
        return baseQueryReturnValue;
      },
    }),
    hasCompliaAccount: build.mutation({
      query: (body) => ({
        url: "/api/auth/hasCompliaAccount",
        method: "POST",
        body,
      }),
    }),
    captchaConfig: build.mutation({
      query: () => ({
        url: "/api/auth/captchaConfig",
        method: "GET",
      }),
    }),
    forgotPassword: build.mutation({
      query: ({ captchaToken, body }) => ({
        url: "/api/auth/forgotPassword",
        method: "POST",
        headers: {
          "x-complia-captcha": captchaToken,
        },
        body,
      }),
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: "api/auth/changePassword",
        method: "POST",
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: "api/auth/resetPasswordUsingToken",
        method: "POST",
        body,
      }),
    }),
    resendEmail: build.mutation({
      query: (body) => ({
        url: "api/verifyAccount/resendEmail",
        method: "POST",
        body,
      }),
    }),
    verifyAccount: build.mutation({
      query: (body) => ({
        url: "api/auth/verifyAccount",
        method: "POST",
        body,
        responseHandler: async (response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("text") !== -1) {
            const data = await response.text();
            return { parsedData: data };
          }
          return response.json();
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export const {
  useLoginMutation,
  useRegisterFormQuery,
  useRegisterConfigQuery,
  useRegisterMutation,
  useSwitchAccountMutation,
  useHasCompliaAccountMutation,
  useCaptchaConfigMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useResendEmailMutation,
  useResetPasswordMutation,
  useVerifyAccountMutation,
} = authApi;
