import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

export function TableSkeleton() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
        <Skeleton variant="text" height={60} />
      </Grid>
    </Grid>
  );
}

export function FormSkeleton() {
  return (
    <Grid container alignItems="center" spacing={2} sx={{ p: "1em" }}>
      <Grid item xs={10}>
        <Skeleton variant="text" height={60} width={160} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item xs={3} sx={{ mr: "1em", mb: "1em" }}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="text" height={60} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export function InvoiceDetailsSkeleton() {
  return (
    <Grid container alignItems="center" columnSpacing={2} sx={{ p: "1em" }}>
      <Grid item container rowSpacing={0}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            "& .MuiSkeleton-root": {
              height: 120,
            },
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Grid item xs={12} md={1.5}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={5}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={60} width={120} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={7} />
      </Grid>
      <Grid item container justifyContent="end">
        <Grid item xs={12} sm={7} md={7}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={12} sm={7} md={7}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={12} sm={7} md={7}>
          <Skeleton variant="text" height={60} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        sx={(theme) => ({
          mb: "1rem",
          "& .MuiSkeleton-root:first-of-type": {
            mr: "1em",
            [theme.breakpoints.down("sm")]: {
              mr: 0,
            },
          },
        })}
      >
        <Grid item xs={12} sm={1.5}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <Skeleton variant="text" height={60} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export function FormDetailsSkeleton() {
  return (
    <Grid container alignItems="center" columnSpacing={2} sx={{ p: "1em" }}>
      <Grid item container rowSpacing={0}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            "& .MuiSkeleton-root": {
              height: 120,
            },
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Grid item xs={12} sm={1.5}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sx={{ margin: " 0 0 20px -12px" }} columnSpacing={2}>
        <Grid item xs={2}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" height={60} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" height={60} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Skeleton variant="text" height={60} />
      </Grid>
    </Grid>
  );
}

export function CardSkeleton() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ mt: "5rem" }}
    >
      <Grid item xs={11} sm={10} md={8}>
        <Grid item container justifyContent="space-between">
          <Grid
            item
            xs={2.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton variant="circular" width={60} height={60} />
          </Grid>
          <Grid item xs={9}>
            <Skeleton variant="text" height={80} />
            <Skeleton variant="text" height={80} />
          </Grid>
          <Grid item container justifyContent="center">
            <Skeleton variant="text" height={60} width={150} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function RegisterFormSkeleton() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: "1rem" }}
      spacing={0}
    >
      <Grid item xs={12} sm={7.5} md={8.5}>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            sx={(theme) => ({
              m: "1rem 0",
              "& .MuiSkeleton-root:first-of-type": {
                mr: "1em",
                [theme.breakpoints.down("sm")]: {
                  mr: 0,
                },
              },
            })}
          >
            <Grid item xs={12} sm={2.5}>
              <Skeleton variant="text" height={60} />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Skeleton variant="text" height={60} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function NewAccountSkeleton() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: "1rem" }}
      spacing={0}
    >
      <Grid item xs={12} sm={10}>
        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="text" height={90} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function DigitalCardDialogSkeleton() {
  return (
    <Grid container justifyContent="center" alignItems="center" rowSpacing={3}>
      <Grid item xs={11} sm={8}>
        <Skeleton variant="text" height={85} />
      </Grid>
      <Grid item xs={11} sm={7}>
        <Skeleton variant="text" height={140} />
      </Grid>
    </Grid>
  );
}

export function PreviewDialogSkeleton() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "90%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Grid item xs={11} sm={8}>
        <Skeleton
          variant="rectangular"
          height={550}
          sx={{ bgcolor: "grey.800" }}
        />
      </Grid>
    </Grid>
  );
}
