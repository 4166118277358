import React from "react";
/* eslint-disable import/no-unresolved */
import { PayCycleSelection } from "@regional/components";
import { requireFieldsInfo } from "../../context/formSlice";

function PreSubmitDialog(props) {
  const { methods, onSubmit, onError } = props;
  const { payCycleSelection } = requireFieldsInfo.preSubmitActions;
  if (payCycleSelection && PayCycleSelection)
    return (
      <PayCycleSelection
        methods={methods}
        onSubmit={onSubmit}
        onError={onError}
      />
    );
  return null;
}

export default PreSubmitDialog;
