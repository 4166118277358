/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useFormState, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { generateFormFieldRules } from "../services/form.service";

function FormPhoneField(props) {
  const { fieldProps, control, formProps, disabled } = props;
  const { errors } = useFormState({ control, name: fieldProps.key });

  const defaultValue =
    formProps[fieldProps.key] || fieldProps.defaultValue || "";

  const rules = generateFormFieldRules(fieldProps);
  return (
    <FormControl fullWidth margin="dense" sx={{ height: "75px" }}>
      <Controller
        name={fieldProps.key}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ref, ...field } }) => (
          <InputMask
            mask="(999)999-9999"
            {...field}
            onChange={(e) => {
              const { value } = e.target;
              onChange(value.replace(/[^\d]/g, ""));
            }}
            disabled={disabled}
          >
            {(inputProps) => (
              <TextField
                inputRef={ref}
                name={fieldProps.key}
                label={fieldProps.label}
                id={fieldProps.key}
                disabled={disabled}
                required={fieldProps.validate?.required}
                variant="standard"
                {...inputProps}
              />
            )}
          </InputMask>
        )}
        rules={rules}
      />
      {errors[fieldProps.key] && (
        <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
          This is required.
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormPhoneField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
    defaultValue: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default FormPhoneField;
