import { difference } from "lodash";

export const saveOnCreateRequired = (applications, key) => {
  const application = applications.find((app) => app.applicationType === key);
  if (application) return application.saveOnCreate;
  return null;
};

export const isCloningRequired = (applications, key) => {
  const application = applications.find((app) => app.applicationType === key);
  if (application) return application.cloneOnCreate;
  return null;
};

export const getSubmitAction = (applications, key) => {
  const application = applications.find((app) => app.applicationType === key);
  if (application) return application.submitAction;
  return null;
};

export const validateRequiredCloneKeys = (
  applications,
  type,
  formData,
  requiredAnyOneKeyForCloning
) => {
  const application = applications.find((app) => app.applicationType === type);
  if (application) {
    const { requiredKeysForCloning } = application || {};
    if (requiredKeysForCloning && requiredAnyOneKeyForCloning.length) {
      return {
        shouldAllowCloning:
          requiredKeysForCloning.every((key) => formData[key]) &&
          requiredAnyOneKeyForCloning.some((key) => formData[key]),
        errorMsg:
          "Please enter a valid License Number with one of the question selected.",
      };
    }
    if (requiredKeysForCloning) {
      return {
        shouldAllowCloning: requiredKeysForCloning.every(
          (key) => formData[key]
        ),
        errorMsg: "Please enter a valid License Number.",
      };
    }
  }
  return { shouldAllowCloning: false };
};

export const getApplicationTypeTags = (filtered) => {
  let subTypes = [];
  if (filtered.length > 0) {
    filtered.forEach((app) => {
      if (app.tags) {
        const unique = difference(app.tags, subTypes);
        if (unique.length > 0) {
          subTypes = subTypes.concat(unique);
        }
      }
    });
  }
  return subTypes.length > 1 ? subTypes : null;
};

export const getUpdatedAppTypes = (types) =>
  types &&
  types?.map((type) => ({
    ...type,
    label: type.name,
    value: type.key,
    tooltip: type.description || "",
  }));

export const getFilteredAppTypes = (types, tenantType) =>
  types && tenantType
    ? types.filter((type) => type && type.tenantTypes?.includes(tenantType))
    : [];

export const generateApp = async (props) => {
  const {
    applicationType,
    navigate,
    notifyError,
    dispatch,
    showNotification,
    formData,
    generateApplication,
  } = props;
  const payload = {
    applicationType,
    formProperties: formData || {},
  };
  try {
    const data = await generateApplication(payload).unwrap();
    if (data && data._id) {
      const url = `/applications/${applicationType}/${data._id}`;
      navigate(url, { replace: true });
      const msg = "Application Form Created Successfully";
      dispatch(showNotification({ type: "success", msg, show: true }));
    }
  } catch (err) {
    notifyError(dispatch, showNotification, err);
  }
};
