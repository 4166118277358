import React from "react";
import Alert from "@mui/material/Alert";
import { useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useCoreApplicationConfigQuery } from "../../../../api/core.api.slice";

function MailPaymentBanner() {
  const { applicationType } = useParams();
  const { data: coreAppConfig } = useCoreApplicationConfigQuery();
  const formData = useSelector((state) => state.formProps.formData);

  const application = coreAppConfig?.applications?.find(
    (obj) => obj.applicationType === applicationType
  );
  const mailPaymentMessage =
    application?.mailPaymentMessage ?? coreAppConfig?.officeInfo?.message ?? "";
  const paymentMethod = useWatch({
    name: "paymentMethod",
    defaultValue: formData?.paymentMethod,
  });
  const isMailPayment = paymentMethod === "mailPayment";
  if (coreAppConfig.officeInfo?.hideAddress || !isMailPayment) return null;
  return (
    <Box>
      <Alert severity="info" elevation={3}>
        <Box>
          <Typography>Please make all checks payable to:</Typography>
          <strong>{coreAppConfig?.officeInfo?.mailingAddress?.title}</strong>
          <Typography> Please mail payment to:</Typography>
          {coreAppConfig.officeInfo?.mailingAddress.fields.map((field) => (
            <Box key={field}>
              <strong>{field}</strong>
            </Box>
          ))}
          <Typography>{mailPaymentMessage}</Typography>
        </Box>
      </Alert>
    </Box>
  );
}

export default MailPaymentBanner;
