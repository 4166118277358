/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

import Record from "./Record";
import CategoryTitle from "./CategoryTitle";

const paperStyle = {
  position: "relative",
  borderRadius: "10px",
  margin: "0.3rem 1rem 1rem",
  overflowX: "clip",
};

function CategorizedView(props) {
  const { gridItem, records, dgConfigRef } = props;
  const { associatedDgLabel } = gridItem.properties || {};
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const uniqueIdentifierList = (records || []).reduce((acc, record) => {
    if (!acc.includes(record.associatedIdentifier)) {
      acc.push(record.associatedIdentifier);
    }
    return acc;
  }, []);

  return uniqueIdentifierList.map((identifier, i) => {
    const groupRecords = (records || []).filter(
      (record) => record.associatedIdentifier === identifier
    );
    return (
      <Box
        // eslint-disable-next-line react/no-array-index-key
        key={identifier + i}
        className="categorizedView"
      >
        {identifier && (
          <>
            <CategoryTitle
              dgConfigRef={dgConfigRef}
              associatedDgLabel={associatedDgLabel || ""}
              identifier={identifier}
              recordCount={groupRecords.length}
            />
            <Paper
              elevation={isDarkMode ? 11 : 4}
              square={false}
              sx={paperStyle}
            >
              {groupRecords.map((record) => (
                <Record
                  {...props}
                  key={record.id}
                  record={record}
                  recordIndex={records.indexOf(record)}
                  isCategorizedView
                />
              ))}
            </Paper>
          </>
        )}
        {!identifier &&
          groupRecords.map((record) => (
            <Record
              {...props}
              key={record.id}
              record={record}
              recordIndex={records.indexOf(record)}
              isCategorizedView
            />
          ))}
      </Box>
    );
  });
}

function DefaultView(props) {
  const { records } = props;
  return (
    records?.length > 0 &&
    records.map((record, index) => (
      <Record {...props} key={record.id} record={record} recordIndex={index} />
    ))
  );
}

function RecordRenderer(props) {
  const { isCategorizedView } = props;

  return isCategorizedView ? (
    <CategorizedView {...props} />
  ) : (
    <DefaultView {...props} />
  );
}

export default RecordRenderer;
RecordRenderer.prototypes = {
  isCategorizedView: PropTypes.bool.isRequired,
};
DefaultView.prototypes = {
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
CategorizedView.defaultProps = {
  records: [],
  dgConfigRef: {},
};
CategorizedView.propTypes = {
  gridItem: PropTypes.shape({
    properties: PropTypes.shape({
      associatedDgLabel: PropTypes.string,
    }),
  }).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})),
  dgConfigRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};
