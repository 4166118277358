export default {
  9999: { mask: "0000" },
  99999: { mask: "00000" },
  9999999: { mask: "0000000" },
  PHY999999: { mask: "PHY000000" },
  "?99": { mask: "00" },
  "+99.9?9?9?9?9?9": { mask: " {+}00{.}[000000]" },
  "-99.9?9?9?9?9?9": { mask: " {-}00{.}[000000]" },
  "$?*?*?*?*?*?*?*?*?*": {
    mask: "{$}000[******]",
  },
  "999-99-9999": { mask: "000-00-0000" },
  "99-9999999": { mask: "00-0000000" },
  "999-99999-9": { mask: "000-00000-0" },
  "999-999-999": { mask: "000-000-000" },
  "+99.99?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9": {
    mask: "{+}00{.}00[000000000000000000]",
    minLength: 6,
  },
  "9?9?9": { mask: "[000]" },
  "-99.99?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?9": {
    mask: "{-}00{.}00[000000000000000000]",
    minLength: 6,
  },
  "*********": { mask: "*********" },
  "********": { mask: /^[a-zA-Z0-9]{1,8}$/ },
  "***********?*?*?*?*?*?*?*": {
    mask: "******************",
    minLength: 11,
  },
  "AAA-99999999-99?9": {
    mask: "AAA-00000000-00[0]",
    minLength: 15,
    customMessage: "Invalid field input",
  },
  "9999?9?9?9?9?9?9": { mask: "0000[000000]" },
  "****-****-****": { mask: "****-****-****", minLength: 14 },
  "**-****-****-****-****-****-**": {
    mask: "**-****-****-****-****-****-**",
    minLength: 30,
  },
  9999999999: { mask: "0000000000" },
  999999999: { mask: "000000000" },
  "$?9?9?9?9?9?9?9?9?9?9": {
    mask: "{$}0[000000000]",
    minLength: 2,
    customMessage: "This is required",
  },
  "******": { mask: "000000" },
  PHYS999999: { mask: "PHYS000000" },
  "99?9": { mask: "00[0]" },
  "9'9?9\"": { mask: "0'0[0]\"", minLength: 4, showPlaceholder: true },
};
