import { getParsedValue } from "./form.service";

const associatedIdLabel = "associatedIdLabel";
const associatedIdentifier = "associatedIdentifier";

export const getRelationalDgKey = () => "relationalDgLink";

export const getDgOptionValue = (data = {}) => data[getRelationalDgKey()];

export function getDgOptionLabel(identifierLabel, rec) {
  let label = "";
  if (!identifierLabel) return null;
  identifierLabel.forEach((item) => {
    const labels = item.indexOf(",") !== -1 ? item.split(",") : item;
    if (Array.isArray(labels) && labels.length > 0) {
      labels.forEach((labelItem, i) => {
        if (rec[labelItem]) {
          label = i === 0 ? rec[labelItem] : `${label} ${rec[labelItem]}`;
        }
      });
    } else if (rec[labels]) {
      label = rec[labels];
    }
  });
  return label;
}

const isFalsy = (value) => [null, undefined, ""].includes(value);

export function getDgIdentifier(config, data, isDropdown) {
  let uniqueId = null;
  if (config.conditional && isDropdown) {
    const { when, eq } = config.conditional;
    if (
      isFalsy(when) ||
      isFalsy(eq) ||
      !eq.includes(data[when]) ||
      data.status !== "saved"
    )
      return uniqueId;
  }
  config.uniqueIdentifier.forEach((id) => {
    if (data[id]) uniqueId = data[id];
  });
  return uniqueId;
}

export const getDgOptionList = (sourceDg, config) =>
  sourceDg
    .filter((rec) => getDgIdentifier(config, rec, true))
    .map((rec) => ({
      label: getDgOptionLabel(config.identifierLabel, rec),
      value: getDgOptionValue(rec),
      identifier: getDgIdentifier(config, rec),
    }));

export const isAssociateField = ({
  sourceDatagrid,
  uniqueIdentifier,
  identifierLabel,
} = {}) => Boolean(sourceDatagrid && uniqueIdentifier && identifierLabel);

// relational dg
export const isSourceDg = ({ associatedDatagrids, identifiers } = {}) =>
  Boolean(associatedDatagrids && identifiers);

export const addCustomPropsToRecord = (setValue, el) => {
  const { label, identifier } = el?.props?.item || {};
  setValue(associatedIdLabel, label);
  setValue(associatedIdentifier, identifier);
};

export const setDgLinkKey = (properties, record = {}) => {
  const relationalDgKey = getRelationalDgKey();
  if (!record[relationalDgKey] && properties) {
    const { identifiers = "" } = properties;
    if (identifiers && typeof identifiers === "string") {
      let uniqueKey = null;
      if (record[identifiers]) uniqueKey = record[identifiers];
      if (uniqueKey)
        // eslint-disable-next-line no-param-reassign
        record[relationalDgKey] = window.btoa(new Date().getTime() + uniqueKey);
    }
  }
};

export const getDgLinkKeys = (getDgState, associatedDgs = "") => {
  const { dgConfigs } = getDgState() || {};
  return associatedDgs.split(",").reduce((acc, cur) => {
    const { dgLinkKey = null } = dgConfigs[cur]?.custom
      ? getParsedValue(dgConfigs[cur]?.custom, "getDgLinkKeysMap")
      : {};
    return { ...acc, [cur]: dgLinkKey };
  }, {});
};

export const hasAssociatedDg = (
  properties,
  getValues,
  getDgState,
  record = {}
) => {
  const { associatedDatagrids } = properties || {};
  const relationalDgKey = getRelationalDgKey();
  const dgLinkKeyMap = getDgLinkKeys(getDgState, associatedDatagrids);
  if (!associatedDatagrids || typeof associatedDatagrids !== "string")
    return false;

  return associatedDatagrids.split(",").some((dgKey) => {
    const associatedDg = getValues(dgKey);
    if (!associatedDg || !associatedDg.length) return false;
    return associatedDg.some(
      (assocRec) =>
        record[relationalDgKey] &&
        record[relationalDgKey] === assocRec[dgLinkKeyMap[dgKey]]
    );
  });
};

function getUniqueRecordStatus(config, key, dgData, recId) {
  const status = { isValid: true, errorMsg: "" };
  if (dgData && dgData.length > 0 && config.uniqueIdentifier) {
    const filtered = dgData.filter(
      (record) =>
        record.id &&
        record.id !== recId &&
        config.uniqueIdentifier.some((id) => record[id] && record[id] === key)
    );
    if (filtered && filtered.length) {
      status.isValid = false;
      status.errorMsg = `There is already an existing record with ${key} in it.`;
    }
  }
  return status;
}

export const checkForUniqueRecord = (props) => {
  const { key, properties, getDgState, record } = props;
  const { identifiers } = properties || {};
  const dgState = getDgState() || {};
  let status = { isValid: true, errorMsg: "" };
  if (!identifiers || !dgState[key] || dgState[key].length <= 1) return status;
  const config = { uniqueIdentifier: identifiers.split(",") };
  const identifier = getDgIdentifier(config, record);
  status = getUniqueRecordStatus(config, identifier, dgState[key], record?.id);
  return status;
};

export const updateAssociatedDgFields = (
  formProperties,
  associatedDgs,
  getDgState,
  record
) => {
  const relationalDgKey = getRelationalDgKey();
  const dgLink = record[relationalDgKey];
  const dgLinkKeyMap = getDgLinkKeys(getDgState, associatedDgs);
  const { dgConfigs } = getDgState() || {};

  associatedDgs.split(",").forEach((dgKey) => {
    if (formProperties[dgKey] && formProperties[dgKey].length) {
      const config = dgConfigs[dgKey]?.custom
        ? getParsedValue(dgConfigs[dgKey]?.custom, "updateAssociatedDgFields")
        : {};
      formProperties[dgKey].forEach((assocRec) => {
        if (dgLink && dgLink === assocRec[dgLinkKeyMap[dgKey]]) {
          const { identifierLabel: label, uniqueIdentifier } = config;
          if (label && uniqueIdentifier) {
            // eslint-disable-next-line no-param-reassign
            assocRec[associatedIdLabel] = getDgOptionLabel(label, record);
            // eslint-disable-next-line no-param-reassign
            assocRec[associatedIdentifier] = getDgIdentifier(config, record);
          }
        }
      });
    }
  });
};

export const removeAssocRecFromDgPayload = (props, formData) => {
  const { properties, getDgState, record = {} } = props;
  const { associatedDatagrids } = properties || {};
  const relationalDgKey = getRelationalDgKey();
  const dgLinkKeyMap = getDgLinkKeys(getDgState, associatedDatagrids);
  const formProperties = formData || {};

  associatedDatagrids.split(",").forEach((dgKey) => {
    const associatedDg = formProperties[dgKey];
    if (associatedDg && associatedDg.length) {
      formProperties[dgKey] = associatedDg.filter(
        (assocRec) => record[relationalDgKey] !== assocRec[dgLinkKeyMap[dgKey]]
      );
    }
  });
  return formProperties;
};

export const getDgPayload = (props) => {
  const {
    key,
    properties,
    record,
    recordIndex,
    getValues,
    type,
    getDgState,
    shouldRemoveAssocRecord,
  } = props;

  const formProps = getValues();
  switch (type) {
    case "submit": {
      if (isSourceDg(properties)) {
        if (shouldRemoveAssocRecord) {
          removeAssocRecFromDgPayload(props, formProps);
        } else {
          setDgLinkKey(properties, record);
          if (hasAssociatedDg(properties, getValues, getDgState, record)) {
            const { associatedDatagrids: assocDgs } = properties;
            updateAssociatedDgFields(formProps, assocDgs, getDgState, record);
          }
        }
      }
      let dgList = getValues(key);
      if (Array.isArray(dgList))
        dgList = dgList.map((item, i) =>
          i === recordIndex ? { ...record, status: "saved" } : item
        );
      return {
        recordData: record,
        formProperties: { ...formProps, [key]: dgList },
      };
    }
    case "remove": {
      let filteredRecords = getValues(key);
      if (Array.isArray(filteredRecords))
        filteredRecords = filteredRecords.filter(
          (item, i) => i !== recordIndex
        );
      return { ...formProps, [key]: filteredRecords };
    }
    default:
      return null;
  }
};

export const relationalDgCheck = (props, record, getValues, getDgState) =>
  isSourceDg(props) && hasAssociatedDg(props, getValues, getDgState, record);

export const updateMainFormDgList = (
  props,
  setValue,
  formProps,
  getDgState
) => {
  const { dgConfigs = {} } = getDgState() || {};
  props.associatedDatagrids.split(",").forEach((dg) => {
    let val = formProps[dg];
    if (!dgConfigs[dg]?.initEmpty && val?.length === 0) val = [{}];
    setValue(dg, []);
    setValue(dg, val);
  });
};

export const getSourceRecordName = (sourceDg, config, identifier) =>
  sourceDg.reduce((acc, rec) => {
    const uniqueIdentifier = getDgIdentifier(config, rec);
    if (uniqueIdentifier === identifier) {
      return getDgOptionLabel(config.identifierLabel, rec);
    }
    return acc;
  }, "");

export const isAssocDgControllingFieldInvalid = (custom, record) => {
  const { conditional } =
    getParsedValue(custom, "isAssocDgControllingFieldValid") || {};
  if (!conditional) return false;
  const { when, eq } = conditional;
  return !isFalsy(when) && !isFalsy(eq) && !eq.includes(record[when]);
};

export const hasInvalidControllingField = (props, custom) => {
  const { record, properties, getValues, getDgState } = props;
  const isInvalid = isAssocDgControllingFieldInvalid(custom, record);
  return (
    isInvalid && hasAssociatedDg(properties, getValues, getDgState, record)
  );
};
