import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import FileCopy from "@mui/icons-material/FileCopyOutlined";

import {
  useLicenseLookupMutation,
  useLicenseMutation,
} from "../../../../api/licenses.api.slice";
import { fetchAndLoadRecord } from "../../services/form.service";
import { showNotification } from "../../../../features/core/slices/notification.slice";
import { hideFormLoader, showFormLoader } from "../../context/loaderSlice";

function LoadRecordDetailsBtn(props) {
  const dispatch = useDispatch();
  const [getLicense, { isLoading: licenseLoading }] = useLicenseMutation();
  const [licenseLookup, { isLoading }] = useLicenseLookupMutation();

  const { fieldProps, getValues, setValue, trigger, formProps, disabled } =
    props;
  const { properties, label } = fieldProps;

  useEffect(() => {
    if (licenseLoading || isLoading) dispatch(showFormLoader());
    else dispatch(hideFormLoader());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseLoading, isLoading]);

  const onBtnClick = () => {
    if (properties && properties.filterKey) {
      const payload = {
        licenseLookup,
        getLicense,
        dispatch,
        setValue,
        showNotification,
        trigger,
      };
      fetchAndLoadRecord({ fieldProps, getValues, payload, formProps });
    }
  };

  return (
    <Button
      variant="contained"
      onClick={onBtnClick}
      startIcon={<FileCopy />}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
LoadRecordDetailsBtn.propTypes = {
  fieldProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      filterKey: PropTypes.string,
    }),
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default LoadRecordDetailsBtn;
