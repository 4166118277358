import React from "react";
import { useDispatch } from "react-redux";
import { useFormState } from "react-hook-form";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import FileCopy from "@mui/icons-material/FileCopyOutlined";

import { showNotification } from "../../../../features/core/slices/notification.slice";
import {
  copyAddressFromSource,
  validateAddressFields,
} from "../../services/form.service";

function CopyAddressBtn(props) {
  const { fieldProps, getValues, setValue, control, trigger, disabled } = props;
  const dispatch = useDispatch();
  const { errors } = useFormState({ control });
  const { tags, label, properties } = fieldProps;

  const onBtnClick = () => {
    const source = properties?.source || "";
    if (tags && tags.length && properties) {
      tags.forEach((item) => trigger(item));
      setTimeout(() => {
        const errorMsg = validateAddressFields({
          tags,
          errors,
          source,
          btnType: "copy",
        });
        if (errorMsg)
          dispatch(
            showNotification({ type: "warning", msg: errorMsg, show: true })
          );
        else copyAddressFromSource(properties, getValues(), setValue, trigger);
      }, 10);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={onBtnClick}
      startIcon={<FileCopy />}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
CopyAddressBtn.propTypes = {
  fieldProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    properties: PropTypes.shape({
      source: PropTypes.string,
    }).isRequired,
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CopyAddressBtn;
