import React from "react";

import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

function TypeAheadSearch(props) {
  const { optionsListRef, setOptionList, isMultiSelect } = props;

  const handleSearchTextChange = (e) => {
    const { value } = e?.target || {};
    const searchValues = optionsListRef.current.filter((option) =>
      option?.label?.toLowerCase().includes(value?.toLowerCase())
    );
    setOptionList(searchValues);
    if (!isMultiSelect) e.preventDefault();
  };
  return (
    <ListSubheader tabIndex={0}>
      <TextField
        tabIndex={0}
        sx={{ mt: "0.5rem" }}
        fullWidth
        variant="standard"
        size="small"
        autoFocus
        placeholder="Type to search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearchTextChange}
        onKeyDown={(e) => {
          if (e.key !== "Escape") e.stopPropagation();
        }}
        onClick={isMultiSelect ? undefined : (e) => e.stopPropagation()}
      />
    </ListSubheader>
  );
}
export default TypeAheadSearch;

TypeAheadSearch.propTypes = {
  setOptionList: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  optionsListRef: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TypeAheadSearch.defaultProps = {
  isMultiSelect: false,
  optionsListRef: {},
};
