import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import VerifyAddress from "./VerifyAddress";
import SelectRecord from "./SelectRecord";
import FormProTip from "../Field/FormProTip";
import DgWarningDialog from "../Layouts/Datagrid/WarningDialog";

function FormDialogs({ handleFormSave, handleDialogAction, tabIndexRef }) {
  const {
    formDialog: { fieldProps, type, fieldKey },
  } = useSelector((state) => state.formDialog);

  if (fieldProps && fieldProps.custom === "verifyAddress")
    return <VerifyAddress handleFormSave={handleFormSave} />;

  if (fieldProps && fieldProps.custom === "selectRecord")
    return <SelectRecord handleFormSave={handleFormSave} />;

  if (fieldKey && type === "proTip") return <FormProTip fieldKey={fieldKey} />;

  if (type === "dgWarning")
    return (
      <DgWarningDialog
        handleDialogAction={handleDialogAction}
        tabIndexRef={tabIndexRef}
      />
    );
  return "";
}
FormDialogs.propTypes = {
  handleFormSave: PropTypes.func.isRequired,
  handleDialogAction: PropTypes.func.isRequired,
  tabIndexRef: PropTypes.shape({}).isRequired,
};
export default FormDialogs;
