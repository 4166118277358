import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { showFormDialog } from "../context/dialogSlice";
import { getTabState } from "../services/form.service";

function CustomTitle(props) {
  const {
    title,
    header,
    marginTop,
    showProTip,
    fieldKey,
    navigateFromReviewPage,
    handleTabChange,
    formTemplate,
    hideWhenDisabled,
  } = props;
  const dispatch = useDispatch();
  const applicationData = useSelector(
    (state) => state.formProps.applicationData
  );
  const { getValues } = useFormContext();
  const allowNavigation = navigateFromReviewPage.includes(
    applicationData?.status
  );

  const indexTab = formTemplate?.components?.findIndex(
    (item) => item?.title === title
  );
  const isTabDisabled =
    typeof indexTab === "number" && indexTab > -1
      ? getTabState(formTemplate?.components[indexTab], getValues())
      : null;

  function onTitleClick(value) {
    if (allowNavigation) {
      const tabIndex = formTemplate?.components?.findIndex(
        (item) => item.title === value
      );
      const isDisabled = getTabState(
        formTemplate?.components[tabIndex],
        getValues()
      );
      if (!isDisabled) {
        handleTabChange(null, tabIndex, true);
        window.scrollTo({ top: 0 });
      }
    }
  }

  if (isTabDisabled && hideWhenDisabled) return null;

  return (
    <Box sx={{ mt: marginTop ? "1rem" : undefined }} tabIndex={0}>
      <Typography
        sx={{
          paddingLeft: "10px",
          fontSize: `${header ? "1.25rem" : "1.12rem"} !important`,
          fontWeight: 500,
          cursor: allowNavigation && "pointer",
        }}
        onClick={() => {
          onTitleClick(title);
        }}
      >
        {title}
        {showProTip &&
          (applicationData.status === "Open" ||
            applicationData.status === "Rejected") && (
            <IconButton
              aria-label="Pro Tip"
              onClick={() => {
                dispatch(
                  showFormDialog({
                    show: true,
                    fieldKey,
                    type: "proTip",
                  })
                );
              }}
            >
              <InfoTwoToneIcon fontSize="small" sx={{ cursor: "pointer" }} />
            </IconButton>
          )}
      </Typography>

      <Divider
        sx={(theme) => ({
          borderBottomColor:
            theme.palette.mode === "light"
              ? theme.palette.primary.light
              : theme.palette.primary.dark,
          borderBottomStyle: "solid",
          borderBottomWidth: header ? "2px" : "1px",
          margin: "0.5rem 0",
        })}
      />
    </Box>
  );
}
CustomTitle.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.bool,
  marginTop: PropTypes.bool,
  showProTip: PropTypes.bool,
  hideWhenDisabled: PropTypes.bool,
  fieldKey: PropTypes.string,
  navigateFromReviewPage: PropTypes.oneOfType([PropTypes.array]),
  formTemplate: PropTypes.shape({}),
  handleTabChange: PropTypes.func,
};
CustomTitle.defaultProps = {
  header: false,
  marginTop: false,
  showProTip: false,
  hideWhenDisabled: false,
  fieldKey: "",
  navigateFromReviewPage: [],
  formTemplate: {},
  handleTabChange: () => {},
};

export default CustomTitle;
