import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import BlockIcon from "@mui/icons-material/Block";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function UnauthorizedPage() {
  const navigate = useNavigate();
  const { userData: user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);
  return (
    <Grid
      container
      spacing={4}
      sx={{ marginTop: "100px", textAlign: "center" }}
      flexDirection="column"
    >
      <Grid item x={12}>
        <Typography variant="h3" color="error">
          <BlockIcon color="error" size="large" />
          Unauthorized
        </Typography>
      </Grid>
      <Grid item x={12}>
        <Typography>You do not have access to this page</Typography>
      </Grid>
      <Grid container justifyContent="center" marginY="2em">
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/", { replace: true })}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}
