import React from "react";
import PropTypes from "prop-types";

import VerifyAddressBtn from "./VerifyAddressBtn";
import LoadRecordDetailsBtn from "./LoadRecordBtn";
import CopyAddressBtn from "./CopyAddressBtn";
import SelectRecordBtn from "./SelectRecordBtn";
import VerifyLicenseNumberBtn from "./VerifyLicenseNumberBtn";
import SelectLicenseBtn from "./SelectLicenseBtn";

function FormButton(props) {
  const {
    fieldProps,
    datagridFormState,
    control,
    getValues,
    setValue,
    trigger,
    formProps,
    disabled,
  } = props;

  switch (fieldProps.custom) {
    case "verifyAddress":
      return (
        <VerifyAddressBtn
          control={control}
          fieldProps={fieldProps}
          getValues={getValues}
          trigger={trigger}
          datagridFormState={datagridFormState}
          disabled={disabled}
        />
      );

    case "loadRecordDetails":
      return (
        <LoadRecordDetailsBtn
          fieldProps={fieldProps}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          formProps={formProps}
          disabled={disabled}
        />
      );
    case "copyAddress":
      return (
        <CopyAddressBtn
          control={control}
          fieldProps={fieldProps}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          disabled={disabled}
        />
      );
    case "selectRecord":
      return (
        <SelectRecordBtn
          fieldProps={fieldProps}
          getValues={getValues}
          disabled={disabled}
        />
      );
    case "verifyLicenseNumber":
      return (
        <VerifyLicenseNumberBtn
          fieldProps={fieldProps}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          disabled={disabled}
        />
      );
    case "selectRecordTable":
      return (
        <SelectLicenseBtn
          fieldProps={fieldProps}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
}
FormButton.propTypes = {
  fieldProps: PropTypes.shape({
    custom: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  datagridFormState: PropTypes.shape({}),
  control: PropTypes.shape({}),
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};
FormButton.defaultProps = {
  datagridFormState: undefined,
  control: undefined,
  getValues: undefined,
  setValue: undefined,
  trigger: undefined,
};
export default FormButton;
