const TOKEN_KEY = "JWT_TOKEN";
const PERMISSION_KEY = "PERMISSIONS";

function getJwtToken() {
  return localStorage.getItem(TOKEN_KEY);
}
function getPermissionToken() {
  return localStorage.getItem(PERMISSION_KEY);
}
function parseJwt() {
  if (getJwtToken()) {
    return JSON.parse(
      decodeURIComponent(window.escape(window.atob(getJwtToken())))
    );
  }
  return null;
}
function parsePermissions() {
  if (getPermissionToken()) {
    return JSON.parse(
      decodeURIComponent(window.escape(window.atob(getPermissionToken())))
    );
  }
  return null;
}
function getExp() {
  const decodedJwt = parseJwt();
  if (decodedJwt) {
    return decodedJwt.exp;
  }
  return 0;
}
function getUserInfo() {
  const decodedJwt = parseJwt();
  if (!decodedJwt) return null;
  return {
    firstName: decodedJwt.firstName,
    lastName: decodedJwt.lastName,
    userName: decodedJwt.userName,
    href: decodedJwt.href,
  };
}
function getTenantInfo() {
  const decodedJwt = parseJwt();
  if (!decodedJwt) return null;
  return decodedJwt.currentTenant;
}
function getPermissions() {
  const decodedPermissions = parsePermissions();
  if (!decodedPermissions) return null;
  return decodedPermissions;
}
function isAuthenticated() {
  const expiry = getExp();
  if (!expiry) return false;
  const millis = Math.round(new Date().getTime());
  return millis <= expiry;
}
function getUserFromToken() {
  if (isAuthenticated()) {
    return getUserInfo();
  }
  return null;
}
function getTenantFromToken() {
  if (isAuthenticated()) {
    return getTenantInfo();
  }
  return null;
}
function getUserPermissions() {
  if (isAuthenticated()) {
    return getPermissions();
  }
  return null;
}
function authorize(token, permissions) {
  if (token && permissions) {
    // api.defaults.headers.Authorization = `Bearer ${token}`;
    const strToken = window.btoa(
      window.unescape(encodeURIComponent(JSON.stringify(token)))
    );
    const strPermissions = window.btoa(
      window.unescape(encodeURIComponent(JSON.stringify(permissions)))
    );
    localStorage.setItem(TOKEN_KEY, strToken);
    localStorage.setItem(PERMISSION_KEY, strPermissions);
  }
}
function unauthorize() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(PERMISSION_KEY);
}

module.exports = {
  getUserFromToken,
  getTenantFromToken,
  getUserPermissions,
  isAuthenticated,
  authorize,
  unauthorize,
};
