import React, { useMemo, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line import/no-unresolved
import buildConfig from "@regional/config/build.config.json";

import Routes from "./routes";
import GlobalLoader from "./components/Loaders/Global";
import Notification from "./components/Notification";
import Header from "./features/core/components/Header";
import Footer from "./features/core/components/Footer";
import Navigation from "./features/core/components/Navigation";
import NewAccountDialog from "./features/core/components/NewAccount";
import AppHealthDialog from "./features/core/components/AppHealthDialog";
import NewApplicationDialog from "./features/core/components/NewApplication";

import { logout } from "./features/auth/slices/auth.slice";
import {
  resetNotification,
  showNotification,
} from "./features/core/slices/notification.slice";
import {
  resetMaintenance,
  setMaintenance,
} from "./features/core/slices/maintenance.slice";
import { isAuthenticated } from "./services/auth.service";
import IdleTimerComp from "./features/core/components/IdleTimer";
import {
  useIsOkayMutation,
  useMaintenanceMutation,
} from "./api/global.api.slice";
import {
  hideAppHealthDialog,
  showAppHealthDialog,
} from "./features/core/slices/appHealth.slice";
import KeyboardNavLink from "./components/KeyboardNavLink";

/* eslint-disable react/jsx-props-no-spreading */

// eslint-disable-next-line global-require
const whyDidYouRender = require("@welldone-software/why-did-you-render");

whyDidYouRender(React, {
  trackHooks: true,
  logOwnerReasons: true,
  trackAllPureComponents: true,
  // eslint-disable-next-line global-require
  trackExtraHooks: [[require("react-redux/lib"), "useSelector"]],
});

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [maintenance] = useMaintenanceMutation();
  const [isOkay] = useIsOkayMutation();
  const { userData: user, tenantData: currentTenant } = useSelector(
    (state) => state.auth
  );
  const { loading: isLoading } = useSelector((state) => state.core);
  const notification = useSelector((state) => state.notify);
  const newAccount = useSelector((state) => state.dialog);
  const { isMaintenance } = useSelector((state) => state.maintenance);
  const { appHealth } = useSelector((state) => state.appHealth);
  const newAppDialog = useSelector((state) => state.newApplication);

  const header = useMemo(
    () => <Header user={user} isMaintenance={isMaintenance} />,
    [user, isMaintenance]
  );
  const footer = useMemo(() => <Footer user={user} />, [user]);
  const nav = useMemo(
    () => <Navigation currentTenant={currentTenant} />,
    [currentTenant]
  );
  const wrapper = useMemo(() => <Routes user={user} />, [user]);

  useEffect(() => {
    const globalCalls = async () => {
      try {
        const [{ error }, { data: appHealthData }] = await Promise.all([
          maintenance(),
          isOkay(),
        ]);
        if (
          error?.data?.isMaintenance === true ||
          error?.data?.isMaintenance === "true"
        ) {
          dispatch(setMaintenance({ ...error.data, isMaintenance: true }));
          dispatch(logout());
          navigate("/maintenance", { replace: true });
        } else {
          dispatch(resetMaintenance());
        }
        if (appHealthData && !appHealthData.isOkay) {
          dispatch(showAppHealthDialog({ ...appHealthData }));
        } else dispatch(hideAppHealthDialog());
      } catch (error) {
        dispatch(
          showNotification({
            type: "error",
            msg:
              error?.data?.message ||
              "Could not process request, please try later.",
            show: true,
          })
        );
      }
    };
    globalCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && isAuthenticated()) {
      // setSkip(false);
      // dispatch(getProgramConfig());
    }
  }, [user]);

  const onNotificationClose = useCallback(() => {
    dispatch(resetNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.title = buildConfig.title;
  }, []);

  return (
    <div>
      {/* Having multiple loaders scattered across the page is a bad practice
          Have a single loader at a central place (usually top of the page) so
          the user can get used to the page layout quickly */}
      {user && <KeyboardNavLink />}
      {isLoading ? <GlobalLoader /> : ""}
      {user &&
        !pathname.includes("browser-support") &&
        isAuthenticated() &&
        nav}
      {header}
      {wrapper}
      {footer}
      {!appHealth.isOkay && <AppHealthDialog />}
      {/* component tracks the idle time only when user is logged in */}
      {user && <IdleTimerComp />}
      {notification.show && (
        <Notification
          type={notification.type}
          msg={notification.msg}
          open={notification.show}
          config={notification.config}
          closeSnackBar={onNotificationClose}
        />
      )}
      {newAccount.show && (
        <NewAccountDialog type={newAccount.type} openDialog={newAccount.show} />
      )}
      {newAppDialog.show && <NewApplicationDialog open={newAppDialog.show} />}
    </div>
  );
}

// this is HOC (Higher Order Component) wrapper
// it is a very common patter in combination with providers (see index.js file)
// a lot of react libs use this combination (redux, react-router-dom)
// it is mainly used to inject component props from somewhere external
// (like the global store in case of redux)
export default App;
