/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/icons-material/Description";
import TextField from "@mui/material/TextField";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import LocalLoader from "../../components/Loaders/Local";
import loginClasses from "../Login/Login.module.css";
import { useChangePasswordMutation } from "../../api/auth.api.slice";
import { notifyError, validatePasswords } from "../../utils/helpers";
import { showNotification } from "../../features/core/slices/notification.slice";

function ChangePasswordPage() {
  const dispatch = useDispatch();

  const [pwdError, setPwdError] = useState("");
  const [open, setOpen] = useState(false);
  const currentPwdRef = useRef(null);
  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required("Please enter password again."),
    newPassword: yup.string().required("Please enter new password."),
    verifyPassword: yup.string().required("Please enter new password."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  useEffect(() => {
    if (currentPwdRef?.current) {
      currentPwdRef.current.focus();
    }
  }, []);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const onPasswordChange = async (payload) => {
    try {
      const data = await changePassword(payload).unwrap();
      if (data) {
        reset();
        const msg = "Your password has been updated successfully.";
        dispatch(showNotification({ type: "success", msg, show: true }));
      }
    } catch (err) {
      notifyError(dispatch, showNotification, err);
    }
  };

  const onSubmit = (data) => {
    const { newPassword, verifyPassword, currentPassword } = data;
    const errorMsg = validatePasswords(newPassword, verifyPassword);
    if (errorMsg) {
      setPwdError(errorMsg);
    } else {
      if (pwdError) setPwdError("");
      const payload = {
        currentPassword,
        newPassword,
      };
      onPasswordChange(payload);
    }
  };

  const newPwdInstruction =
    "All passwords must be at least eight characters long and contain at least one uppercase letter, one lowercase letter, and one number.";

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={loginClasses.loginGrid}
        alignItems="center"
      >
        <Grid
          item
          xs={11}
          sm={8}
          md={6}
          sx={{ my: "1rem", position: "relative" }}
        >
          <Card raised>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <CardHeader
                title="Change Password"
                className={loginClasses.cardHeader}
              >
                Change Password
              </CardHeader>
              <CardContent>
                <TextField
                  label="Current Password"
                  id="currentPassword"
                  name="currentPassword"
                  required
                  type="password"
                  margin="normal"
                  variant="standard"
                  autoComplete="off"
                  {...register("currentPassword")}
                  fullWidth
                  error={errors?.currentPassword !== undefined}
                  helperText={errors?.currentPassword?.message || ""}
                  inputRef={currentPwdRef}
                />
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    title={newPwdInstruction}
                    arrow
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          marginTop: errors?.newPassword
                            ? "-15px !important"
                            : undefined,
                        },
                      },
                    }}
                    open={open}
                    onClose={handleTooltipClose}
                    disableHoverListener
                    disableTouchListener
                  >
                    <TextField
                      label="New Password"
                      id="newPassword"
                      name="newPassword"
                      required
                      type="password"
                      margin="normal"
                      variant="standard"
                      autoComplete="off"
                      onFocus={handleTooltipOpen}
                      {...register("newPassword")}
                      fullWidth
                      error={errors?.newPassword !== undefined}
                      helperText={errors?.newPassword?.message || ""}
                    />
                  </Tooltip>
                </ClickAwayListener>
                <TextField
                  label="Re-enter Password"
                  id="verifyPassword"
                  name="verifyPassword"
                  required
                  type="password"
                  margin="normal"
                  variant="standard"
                  autoComplete="off"
                  {...register("verifyPassword")}
                  fullWidth
                  error={errors?.verifyPassword !== undefined}
                  helperText={errors?.verifyPassword?.message || ""}
                />
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "center",
                    mt: "0.5em",
                    "& .MuiButton-root": {
                      my: 1,
                      "& .MuiSvgIcon-root": {
                        mr: "0.2em",
                      },
                    },
                  }}
                >
                  <Button variant="contained" type="submit">
                    <Icon />
                    Change Password
                  </Button>
                </Grid>
              </CardActions>
              {pwdError && (
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography color="error">{pwdError}</Typography>
                </CardContent>
              )}
            </form>
          </Card>
          <LocalLoader progress={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
}
export default ChangePasswordPage;
