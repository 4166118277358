/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormContext, useFormState, Controller } from "react-hook-form";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";
import { StyledDiv } from "../styles";

function ConfirmPhoto(props) {
  const { fieldProps, id, src } = props;
  const { confirmText, key } = fieldProps;

  const { setValue, trigger, control, getValues } = useFormContext();
  const { updateHeight } = useUpdatePanelHeight();
  const formData = useSelector((state) => state.formProps.formData);
  const { errors } = useFormState({ name: key });
  const [isError, setIsError] = useState(true);

  useEffect(() => {
    if (!formData[key]) setIsError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getValues(key)) {
      setIsError(true);
      setValue(key, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const callUpdateHeight = () => {
    setTimeout(() => updateHeight(), 400);
  };

  const onClickHandler = () => {
    setIsError(!isError);
    trigger(key);
    callUpdateHeight();
  };

  const memoImg = useMemo(
    () => (
      <img
        alt="Review"
        src={`${src}?timestamp=${Date.now()}`}
        onLoad={callUpdateHeight}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );
  return (
    <StyledDiv>
      <Box className="labelContainer">
        {!isError && <CheckIcon color="success" />}
        {isError && <ClearIcon color="error" />}
      </Box>
      <Box className="imgContainer">
        <Box sx={{ flex: 1 }}>
          <Typography className="imgLabel">Confirm Photo:</Typography>
          {memoImg}
        </Box>
        <Box sx={{ flex: 1 }}>
          <FormControl fullWidth margin="dense" required>
            <Controller
              name={key}
              control={control}
              defaultValue={!!formData[key]}
              render={({ field: { value, onChange, ...field } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(e.target.checked);
                        onClickHandler();
                      }}
                      checked={!!value}
                    />
                  }
                  {...field}
                  label={<Typography>{confirmText}</Typography>}
                />
              )}
              rules={{ required: true }}
            />
            {errors[key] && (
              <FormHelperText error>
                Please confirm by selecting the checkbox.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    </StyledDiv>
  );
}
ConfirmPhoto.propTypes = {
  fieldProps: PropTypes.shape({
    key: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};
export default ConfirmPhoto;
