import { configureStore } from "@reduxjs/toolkit";
import splitApi from "./api/emptySplitApi";
import authReducer from "./features/auth/slices/auth.slice";
import coreReducer from "./features/core/slices/core.slice";
import notificationReducer from "./features/core/slices/notification.slice";
import dialogReducer from "./features/core/slices/dialog.slice";
import maintenanceReducer from "./features/core/slices/maintenance.slice";
import appHealthReducer from "./features/core/slices/appHealth.slice";
import leftNavReducer from "./features/core/slices/leftNav.slice";
import formReducer from "./components/Form/context/formSlice";
import formDialogReducer from "./components/Form/context/dialogSlice";
import newAppReducer from "./features/core/slices/newApplication.slice";
import formLoaderReducer from "./components/Form/context/loaderSlice";
import dialogFormReducer from "./components/Form/context/dialogFormSlice";

export const reducerData = {
  auth: authReducer,
  core: coreReducer,
  notify: notificationReducer,
  dialog: dialogReducer,
  maintenance: maintenanceReducer,
  appHealth: appHealthReducer,
  navConfig: leftNavReducer,
  formProps: formReducer,
  formDialog: formDialogReducer,
  newApplication: newAppReducer,
  formLoader: formLoaderReducer,
  dialogFormProps: dialogFormReducer,
  [splitApi.reducerPath]: splitApi.reducer,
};

export const store = configureStore({
  reducer: reducerData,
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "formProps/updateMethodRef",
          "formProps/resetFormData",
        ],
      },
    }).concat(splitApi.middleware),
});
