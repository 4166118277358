/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import { useFormState, Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import {
  generateFormFieldRules,
  getMaskProps,
} from "../../services/form.service";

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, mask, showPlaceholder, prefix, ...other } = props;

  const removeWhiteSpace = (val) => val.replace(/\s+/g, "");

  const onChangeHandler = (val) => {
    onChange({ target: { value: removeWhiteSpace(val) } });
  };

  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      placeholderChar={showPlaceholder ? " " : undefined}
      lazy={!showPlaceholder}
      unmask={prefix !== "preserveFormatting"}
      onAccept={onChangeHandler}
    />
  );
});

TextMaskCustom.propTypes = {
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ test: PropTypes.func.isRequired }),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  showPlaceholder: PropTypes.bool.isRequired,
};

function FormTextField(props) {
  const { fieldProps, formProps, control, disabled, resetHandler, trigger } =
    props;
  const { errors } = useFormState({
    control,
    name: fieldProps.key,
    exact: true,
  });

  const { key, label, validate, prefix, description } = fieldProps;

  const defaultValue = formProps[key] || fieldProps.defaultValue || "";
  const textFieldValueRef = useRef("");
  const inputMaskRef = useRef(null);

  if (!inputMaskRef.current) inputMaskRef.current = getMaskProps(fieldProps);

  const inputMaskProps = inputMaskRef.current;
  const errorMap = {
    minLength: `${label} should contain minimum ${validate?.minLength} characters.`,
    maxLength: `Maximum ${validate?.maxLength} characters allowed.`,
    pattern: validate?.customMessage || "Invalid field input",
    required: "This is required",
    mask:
      validate?.customMessage ||
      inputMaskProps?.customMessage ||
      `${label} should contain minimum ${validate?.minLength} characters.`,
  };

  const maskProps = {
    ...(inputMaskProps?.mask && {
      inputComponent: TextMaskCustom,
      inputProps: {
        mask: inputMaskProps?.mask,
        showPlaceholder: inputMaskProps?.showPlaceholder || false,
        prefix,
      },
    }),
  };

  const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");

  const rules = generateFormFieldRules(fieldProps);

  return (
    <FormControl fullWidth margin="dense" sx={{ minHeight: "75px" }}>
      <Controller
        name={key}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ...field } }) => (
          <TextField
            {...field}
            id={fieldProps.key}
            name={fieldProps.key}
            label={fieldProps.label}
            required={fieldProps.validate?.required}
            onFocus={(e) => {
              textFieldValueRef.current = e.target.value;
            }}
            onBlur={(e) => {
              if (e.target.value !== textFieldValueRef.current) {
                onChange(removeExtraSpace(value));
                resetHandler();
                textFieldValueRef.current = e.target.value;
              }
              trigger(key);
            }}
            value={value}
            onChange={onChange}
            disabled={disabled}
            variant="standard"
            autoComplete="off"
            InputProps={inputMaskProps?.mask ? maskProps : undefined}
          />
        )}
        rules={rules}
      />
      {description && (
        <FormHelperText sx={{ m: 0, fontSize: "14px" }}>
          {description}
        </FormHelperText>
      )}
      {errors[key] && (
        <FormHelperText error id="{fieldProps.key}-text" sx={{ m: 0 }}>
          {(errors[key].type && errorMap[errors[key].type]) ||
            "This is required"}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormTextField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),

    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    description: PropTypes.string,
    prefix: PropTypes.string,
    inputMask: PropTypes.string,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
      pattern: PropTypes.string,
      customMessage: PropTypes.string,
    }),
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};
export default FormTextField;
