import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// eslint-disable-next-line import/no-unresolved
import buildConfig from "@regional/config/build.config.json";
import GridContainer from "../../../components/GridContainer";

function AccessibilityPage() {
  const title = "Accessibility Statement for NIC Inc. NLS";
  return (
    <GridContainer title={title} width="full">
      <Grid
        container
        spacing={2}
        sx={{ "& .MuiTypography-h4": { fontSize: "24px" } }}
      >
        <Grid item xs={12}>
          <Typography gutterBottom tabIndex="0">
            NIC Inc.’s NLS system has been developed in compliance with{" "}
            <a href="https://www.section508.gov/">Section 508</a> of the
            Rehabilitation Act and{" "}
            <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
              WCAG 2.0
            </a>
            . Our ongoing goal and commitment is to provide an accessible web
            experience for all visitors.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" tabIndex="0">
            Disclaimer
          </Typography>

          <ul>
            <li>
              <Typography tabIndex="0">
                It is important to note that our web accessibility efforts are
                ongoing to align with current and future ADA guidelines. We will
                continue to work on the implementation of improvements to meet
                WCAG guidelines over time.
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" tabIndex="0">
            Browser Recommendations
          </Typography>
          <ul>
            <li>
              <Typography tabIndex="0">Chrome – High Performance </Typography>
            </li>
            <li>
              <Typography tabIndex="0">Safari – High Performance</Typography>
            </li>
            <li>
              <Typography tabIndex="0">
                Firefox - Moderate Performance
              </Typography>
            </li>
            <li>
              <Typography tabIndex="0">
                IE Edge - Moderate Performance
              </Typography>
            </li>
            <li>
              <Typography tabIndex="0">
                NLS is also mobile responsive for mobile devices.
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" tabIndex="0">
            Assistive Technology Recommendations
          </Typography>

          <ul>
            <li>
              <Typography tabIndex="0">
                <a href="https://chrome.google.com/webstore/detail/read-aloud-a-text-to-spee/hdhinadidafjejdhmfkjgnolgimiaplp?hl=en">
                  Read Aloud
                </a>{" "}
                for Chrome{" "}
              </Typography>
            </li>
            <li>
              <Typography tabIndex="0">
                <a href="https://www.nvaccess.org/">NVDA</a> for Windows{" "}
              </Typography>
            </li>
            <li>
              <Typography tabIndex="0">
                {" "}
                <a href="https://www.freedomscientific.com/Products/software/JAWS/">
                  JAWS
                </a>{" "}
                for Windows{" "}
              </Typography>
            </li>
            <li>
              <Typography tabIndex="0">
                {" "}
                <a href="https://www.apple.com/accessibility/vision/">
                  VoiceOver
                </a>{" "}
                for Mac{" "}
              </Typography>
            </li>
            <li>
              {" "}
              <Typography tabIndex="0">Keyboard-Only Navigation</Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" tabIndex="0">
            Difficulty Accessing Material:
          </Typography>
          <ul>
            <li>
              <Typography tabIndex="0">
                If you have difficulty accessing any material on this site, have
                specific questions or feedback, please contact us in writing and
                we will work with you to make the information available -{" "}
                {/* <a href="mailto:nlssupport-ok@egov.com"> */}
                <a href={`mailto:${buildConfig.nlsSupportMail}`}>
                  {buildConfig.nlsSupportMail}
                </a>
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </GridContainer>
  );
}

export default AccessibilityPage;
