/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import SignInIcon from "@mui/icons-material/Input";
import ForgotPwdIcon from "@mui/icons-material/LockOpen";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import loginClasses from "../Login/Login.module.css";
import ReCaptcha from "../../features/auth/components/ReCaptcha";
import LocalLoader from "../../components/Loaders/Local";
import {
  useCaptchaConfigMutation,
  useForgotPasswordMutation,
} from "../../api/auth.api.slice";
import { showNotification } from "../../features/core/slices/notification.slice";
import { notifyError } from "../../utils/helpers";

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [captchaConfigData, setCaptchaConfigData] = useState({});

  const [captchaConfig] = useCaptchaConfigMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
  });

  const [respMsg, setRespMsg] = React.useState("");
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  useEffect(() => {
    const getCaptchaConfig = async () => {
      try {
        const { data } = await captchaConfig();
        if (data) {
          setCaptchaConfigData(data);
        }
      } catch (err) {
        notifyError(dispatch, showNotification, err);
      }
    };
    getCaptchaConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    const payload = {
      captchaToken: data.captchaToken,
      body: { email: data.email },
    };

    try {
      const res = await forgotPassword(payload).unwrap();
      if (res && res.message) setRespMsg(res?.message);
    } catch (error) {
      notifyError(dispatch, showNotification, error);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={loginClasses.loginGrid}
        alignItems="center"
      >
        <Grid
          item
          xs={11}
          sm={8}
          md={6}
          sx={{ my: "1rem", position: "relative" }}
        >
          <Card raised>
            <form onSubmit={(e) => e.preventDefault()}>
              <CardHeader
                title="Forgot Password"
                className={loginClasses.cardHeader}
              >
                Forgot Password
              </CardHeader>
              <CardContent>
                <TextField
                  label="Email"
                  id="email"
                  name="email"
                  required
                  type="text"
                  margin="normal"
                  variant="standard"
                  autoComplete="off"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email address",
                    },
                    maxLength: {
                      value: 50,
                      message: "Email must not exceed 50 characters",
                    },
                  })}
                  fullWidth
                  error={errors?.email !== undefined}
                  helperText={errors?.email ? errors.email.message : ""}
                />
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {captchaConfigData?.captchaForgotPassword && !respMsg && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ReCaptcha
                      name="captchaToken"
                      control={control}
                      theme={theme.palette.mode}
                      error={errors?.captchaToken !== undefined}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "center",
                    mt: "0.5em",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column-reverse",
                    },
                    "& .MuiButton-root": {
                      my: 1,
                      "& .MuiSvgIcon-root": {
                        mr: "0.2em",
                      },
                    },
                  }}
                >
                  <Button variant="contained" component={Link} to="/login">
                    <SignInIcon />
                    CANCEL
                  </Button>

                  {!respMsg && (
                    <Button
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <ForgotPwdIcon />
                      SEND FORGOT PASSWORD EMAIL
                    </Button>
                  )}
                </Grid>
              </CardActions>
              {respMsg && (
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ mb: "1em", textAlign: "center" }}
                >
                  {respMsg}
                </Typography>
              )}
            </form>
          </Card>
          <LocalLoader progress={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
}
export default ForgotPasswordPage;
