import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import FileCopy from "@mui/icons-material/FileCopyOutlined";

import { validQueryParams } from "../../services/form.service";
import { showNotification } from "../../../../features/core/slices/notification.slice";
import { showFormDialog } from "../../context/dialogSlice";

function SelectRecordBtn(props) {
  const dispatch = useDispatch();
  const { fieldProps, getValues, disabled } = props;
  const { properties, label } = fieldProps;

  const onBtnClick = () => {
    let msg = "";
    if (properties) {
      const isValid =
        !properties.queryParamKeys ||
        (properties.queryParamKeys &&
          validQueryParams(properties.queryParamKeys, getValues()));

      if (isValid) {
        dispatch(showFormDialog({ show: true, fieldProps }));
      } else {
        msg = `Insufficient information to get ${properties.title}`;
        dispatch(showNotification({ type: "warning", msg, show: true }));
      }
    }
  };

  return (
    <Button
      variant="contained"
      onClick={onBtnClick}
      startIcon={<FileCopy />}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
SelectRecordBtn.propTypes = {
  fieldProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      queryParamKeys: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  getValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default SelectRecordBtn;
