import axios from "axios";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { showLoader, hideLoader } from "../features/core/slices/core.slice";
import { logout } from "../features/auth/slices/auth.slice";

const api = axios.create({
  baseURL: "/",
  timeout: 20000,
});

api.interceptors.request.use((config) => config);
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      return null;
    }
    return Promise.reject(error);
  }
);
const baseQuery = fetchBaseQuery({ baseUrl: "/" });
const baseQueryWithInterceptors = async (args, baseApi, extraOptions) => {
  baseApi.dispatch(showLoader());
  const results = await baseQuery(args, baseApi, extraOptions);
  baseApi.dispatch(hideLoader());
  if (
    results.error &&
    results.error.status === 401
    // ((results.error.data &&
    //   results.error.data.code &&
    //   results.error.data.code === 401) ||
    //   results.error.status === 401)
  ) {
    baseApi.dispatch(logout());
  }
  return results;
};
export default api;
export { baseQueryWithInterceptors };

export const axiosUploadHelper = async (configObject) => {
  const { body, setUploadProgress, ...requestConfig } = configObject;
  try {
    let uploadProgress;
    const response = await axios({
      ...requestConfig,
      baseURL: "/",
      data: body,
      onUploadProgress: (upload) => {
        uploadProgress = Math.round((100 * upload.loaded) / upload.total);
        if (setUploadProgress) setUploadProgress(uploadProgress);
      },
    });
    return { data: response.data };
  } catch (err) {
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
