import splitApi from "./emptySplitApi";

const formsApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getDropdownList: build.query({
      query: (url) => url,
      providesTags: ["SelectOptions"],
      keepUnusedDataFor: 300,
    }),
    dropdownList: build.mutation({
      query: (url) => ({
        url,
        method: "GET",
      }),
    }),
    getAutoCompList: build.query({
      query: ({ url, params }) => ({
        url,
        params,
      }),
      // providesTags: ["License"],
    }),
    getApiData: build.query({
      query: ({ url, params }) => ({ url, params }),
      providesTags: ["License"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApiDataQuery,
  useLazyGetDropdownListQuery,
  useDropdownListMutation,
  useGetAutoCompListQuery,
} = formsApi;
