import React, { useLayoutEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import PropTypes from "prop-types";

import Table from "./Table";
import Datagrid from "./Datagrid";
import CustomTitle from "./FormTitle";

import { formConditional, getWatchFieldName } from "../services/form.service";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import useGetFormStateValues from "../hooks/useGetFormProps";

function NestedPanel(props) {
  const { tableGrid } = props;
  const { formData } = useGetFormStateValues();
  const { getValues } = useFormContext();
  const { updateHeight } = useUpdatePanelHeight();

  let watchFieldValue = useWatch({
    name: getWatchFieldName(tableGrid),
    disabled: !tableGrid.conditional || !tableGrid.conditional.when,
  });
  // hook form wont reference field not present in form
  if (
    watchFieldValue === undefined &&
    formData[tableGrid.conditional.when] !== undefined
  ) {
    watchFieldValue = formData[tableGrid.conditional.when];
  }

  const shouldDisplay = formConditional(
    tableGrid,
    watchFieldValue,
    getValues()
  );

  useLayoutEffect(() => {
    if (tableGrid.conditional) {
      updateHeight();
    }
    return () => {
      if (tableGrid.conditional) {
        updateHeight();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplay]);

  if (shouldDisplay) {
    const tableGrids =
      tableGrid.components &&
      tableGrid.components.filter(
        (component) =>
          component.type === "table" || component.type === "datagrid"
      );

    return (
      <div>
        {tableGrid.title && tableGrid.title !== "" && (
          <CustomTitle
            title={tableGrid.title}
            header
            showProTip={tableGrid?.tags && tableGrid?.tags.includes("proTip")}
            fieldKey={tableGrid.key}
          />
        )}
        {tableGrids.length > 0 &&
          tableGrids.map((tableGridItem) => {
            if (tableGridItem.type === "table") {
              return (
                <Table key={tableGridItem.key} components={tableGridItem} />
              );
            }
            if (tableGridItem.type === "datagrid") {
              return (
                <Datagrid key={tableGridItem.key} gridItem={tableGridItem} />
              );
            }
            return <div>Invalid element</div>;
          })}
      </div>
    );
  }
  return "";
}

NestedPanel.propTypes = {
  tableGrid: PropTypes.shape({
    components: PropTypes.oneOfType([PropTypes.array]).isRequired,
    type: PropTypes.string.isRequired,
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    title: PropTypes.string,
  }).isRequired,
};

export default NestedPanel;
