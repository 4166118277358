import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import DialogPopup from "../../../../components/DialogPopup";
import { logout } from "../../../auth/slices/auth.slice";
import { closeDialog } from "../../slices/dialog.slice";

function IdleTimerComp() {
  const dispatch = useDispatch();
  const [showPrompt, setShowPrompt] = useState();

  const handleOnIdle = () => {
    setShowPrompt(false);
    dispatch(closeDialog());
    dispatch(logout());
  };

  const handlePrompt = () => {
    setShowPrompt(true);
  };

  // hook - for capturing the idle state
  const { reset } = useIdleTimer({
    timeout: 1000 * 1800, // it accepts time in milliseconds
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
    promptTimeout: 1000 * 30,
    onPrompt: handlePrompt,
  });

  const buttonArray = [
    {
      id: 1,
      name: "EXTEND SESSION",
      variant: "contained",
      onClickHandler: () => {
        reset();
        setShowPrompt(false);
      },
      show: true,
    },
    {
      id: 2,
      name: "LOGOUT",
      variant: "contained",
      onClickHandler: () => dispatch(logout()),
      show: true,
    },
  ];

  const warningMsg = (
    <Typography variant="subtitle1">
      Your session will soon expire due to inactivity. You will be automatically
      logged out in 30 seconds. Please click extend session to continue your
      session.
    </Typography>
  );

  return (
    <DialogPopup
      title="Session Inactivity Warning"
      content={warningMsg}
      openPopup={showPrompt}
      buttonArray={buttonArray}
      showActionBtn
    />
  );
}

export default IdleTimerComp;
