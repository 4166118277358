import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  formConfig: {},
  applicationData: {},
  actionPanel: { show: false },
  preSubmitDialogs: {},
};

const dialogFormSlice = createSlice({
  name: "dialogFormProps",
  initialState,
  reducers: {
    updateDialogFormProps(state, action) {
      const { formData, formConfig, applicationData = {} } = action.payload;
      state.formData = formData;
      state.formConfig = formConfig;
      state.applicationData = applicationData;
    },
    resetDialogFormProps() {
      return initialState;
    },
    updateDialogActionPanel(state, action) {
      state.actionPanel = action.payload;
    },
    togglePreSubmitDialog(state, action) {
      if (action.payload.dialog)
        state.preSubmitDialogs[action.payload.dialog] = action.payload.open;
    },
  },
});

export const {
  updateDialogFormProps,
  resetDialogFormProps,
  updateDialogActionPanel,
  togglePreSubmitDialog,
} = dialogFormSlice.actions;

export default dialogFormSlice.reducer;
