import splitApi from "./emptySplitApi";

const coreApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    programConfig: build.query({
      query: () => ({
        url: `/api/config/programConfig`,
      }),
      providesTags: ["Config"],
    }),
    leftNavConfig: build.mutation({
      query: () => ({
        url: `/dist/config/navigation.config.json`,
        method: "GET",
      }),
    }),
    coreApplicationConfig: build.query({
      query: () => ({
        url: "/dist/config/coreApplication.json",
      }),
      providesTags: ["Config"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCoreApplicationConfigQuery,
  useProgramConfigQuery,
  useLeftNavConfigMutation,
} = coreApi;
