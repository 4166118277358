/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "success",
  msg: "",
  show: false,
  config: {},
};
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification(state, { payload }) {
      state.type = payload.type;
      state.msg = payload.msg;
      state.show = payload.show;
      state.config = payload.config;
    },
    resetNotification(state) {
      state.type = initialState.type;
      state.msg = initialState.msg;
      state.show = initialState.show;
      state.config = initialState.config;
    },
  },
});

export const { showNotification, resetNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
