/**
 * Created by nagib on 10/31/2018.
 */
import React from "react";
import { useLocation } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { FooterLinksLeft, FooterLinksRight } from "../FooterLinks";
import classes from "./footer.module.css";

function Footer(props) {
  const { user } = props;
  const { pathname } = useLocation();
  return (
    <footer className={classes.main}>
      <Toolbar>
        <Grid
          container
          sx={(theme) => ({
            display: "flex",
            flexDirection: { xs: "column", md: "column", lg: "row" },
            [theme.breakpoints.up("md")]: {
              ml: user && !pathname.includes("browser-support") ? "285px" : 0,
            },
            alignItems: "center",
            "& .MuiTypography-root": {
              my: { xs: "0.5em", md: undefined },
              fontSize: "0.88rem",
            },
          })}
        >
          <Grid sm={12} md={12} lg={6} item>
            <div className={classes.left}>
              <FooterLinksLeft />
            </div>
          </Grid>
          <Grid sm={12} md={12} lg={6} item>
            <div className={classes.right}>
              <FooterLinksRight />
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </footer>
  );
}
Footer.propTypes = {
  user: PropTypes.shape({
    stateful: PropTypes.bool,
  }),
};
Footer.defaultProps = {
  user: null,
};
export default Footer;
