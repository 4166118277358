/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Grid from "@mui/material/Grid";

function TermsConditions() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <p>
          <span>
            <b>Terms of Use</b>
          </span>
        </p>
        <p>AGREEMENT BETWEEN USER AND TYLER</p>
        <p>
          These Terms of Use (the “Agreement”) govern your use and access to the
          tylertech.com website, and any of our digital services (collectively
          the “Platform”) developed, operated and directly made available to you
          by Tyler Technologies, Inc. and its subsidiaries (together, “Tyler,”
          “we,” “us,” or “our”).
        </p>
        <p>
          The Platform is offered to you conditioned on your acceptance without
          modification of the terms, conditions, and notices contained herein.
          Your use of the Platform constitutes your agreement to all such terms,
          conditions, and notices.
        </p>
        <p>
          These Terms of Use are a legal agreement between you and Tyler.
          Additional or separate terms may apply to your use of the Tyler
          Platform. To the extent that the provisions of any additional terms
          conflict with these Terms of Use, the provisions of the additional
          terms will govern.
        </p>
        <p>
          THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION WHICH MAY BE
          ENFORCED BY THE PARTIES AND A WAIVER OF YOUR CLASS ACTION RIGHTS.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Modification of These Terms of Use</b>
          </span>
        </p>
        <p>
          Tyler reserves the right to change the terms, conditions, and notices
          under which the Platform is offered, including but not limited to the
          charges associated with the use of the Platform.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Links to Third Party Sites</b>
          </span>
        </p>
        <p>
          The Platform may contain links to other websites ("Linked Sites"). The
          Linked Sites are not under the control of Tyler and Tyler is not
          responsible for the contents of any Linked Site, including without
          limitation any link contained in a Linked Site, or any changes or
          updates to a Linked Site. Tyler is not responsible for webcasting or
          any other form of transmission received from any Linked Site. Tyler is
          providing these links to you only as a convenience, and the inclusion
          of any link does not imply endorsement by Tyler of the site or any
          association with its operators.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>No Unlawful or Prohibited Use</b>
          </span>
        </p>

        <p>
          As a condition of your use of the Platform, you warrant to Tyler that
          you will not use the Platform for any purpose that is unlawful or
          prohibited by these terms, conditions, and notices. You may not use
          the Platform in any manner which could damage, disable, overburden, or
          impair the Platform or interfere with any other party's use and
          enjoyment of the Platform. You may not obtain or attempt to obtain any
          materials or information through any means not intentionally made
          available or provided for through the Platform.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Restrictions on Use</b>
          </span>
        </p>
        <p>
          You agree to use the Platform only for lawful purposes and to follow
          all applicable laws and regulations of the United States and any state
          or country in which you reside that are in effect and that may be in
          effect in the future when using the Platform. While using the
          Platform, you will not:
        </p>
        <p>
          • Violate any laws, regulations, third-party rights (including
          intellectual property rights), or Tyler’s policies;
        </p>
        <p>
          • If any Platform feature allows you to post or upload content, post
          or upload any content that is libelous, defamatory, harmful,
          harassing, threatening, false, misleading, or discriminatory;
        </p>
        <p>
          • If the Platform feature allows you to post or upload content, post,
          use, or upload any content that misappropriates any trade secret or
          infringes any intellectual property rights, rights of publicity,
          rights of privacy, or proprietary right of any party;
        </p>
        <p>
          • Affect us adversely or reflect negatively on the Platform, Tyler,
          our goodwill, name, or reputation;
        </p>
        <p>
          • Impersonate anyone or any entity or falsely state or misrepresent
          your affiliation with a person or entity;
        </p>
        <p>• Display advertising or commercial content;</p>
        <p>
          • Collect or store personal data about other users or individuals;
        </p>
        <p>
          • Use any computer code, data mining software, "robot," "bot,"
          "spider," "scraper" or other automatic device, or program, algorithm
          or methodology having similar processes or functionality, or any
          manual process, to monitor or copy any of the Web pages, data or
          content found or accessed through the Platform;
        </p>
        <p>
          • Transmit any material that contains software viruses, trojan horses,
          worms, time bombs, cancelbots, or other malicious code;
        </p>
        <p>
          • Take any action to use the Platform in any manner that violates or
          is inconsistent with these Terms of Use or to circumvent, compromise
          or defeat any security measures implemented in the Platform, including
          to gain unauthorized access to: (i) Tyler’s services; (ii) other
          users’ accounts, names, passwords, personally identifiable
          information; or (iii) other computers, websites, or pages connected or
          linked to Tyler’s Platform;
        </p>
        <p>
          • Share your user account login credentials including username or
          password with another user, permit any other individual or user to
          access your account, access your account from more than one device or
          web browser concurrently, or utilize another user’s account or account
          credentials to gain access to such account;
        </p>
        <p>
          • Display, distribute, license, perform, publish, reproduce,
          duplicate, copy, create derivative works from, modify, sell, resell,
          exploit, transfer, or transmit for any commercial purposes, all or any
          portion of the Platform or use in-line or other linking to display
          content from the Platform without Tyler’s permission; or
        </p>
        <p>
          • Modify, disrupt, impair, alter, or interfere with the use, features,
          functions, operation, or maintenance of the Platform or the rights or
          use and enjoyment of the Platform by any other person, firm, or
          enterprise (collectively, “Person”); or collect, obtain, compile,
          transmit, reproduce, delete, revise, or display any material or
          information, whether personally identifiable or not, posted by or
          concerning any other Person in connection with use of the Platform,
          unless you have obtained the express, prior permission of such other
          Person.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>
              Data and Other Materials Provided to Tyler or Posted on the
              Platform
            </b>
          </span>
        </p>
        <p>
          Tyler does not claim ownership of the data and other materials you
          provide through or related to the Platform (including feedback and
          suggestions) or post, upload, input or submit to any Platform or its
          associated services (collectively "Submissions"). However, by posting,
          uploading, inputting, providing or submitting your Submission you are
          granting Tyler and its subsidiaries and necessary sublicensees
          permission to use your Submission in connection with our business
          operations including, without limitation, the rights to: copy,
          distribute, transmit, publicly display, publicly perform, reproduce,
          edit, translate and reformat your Submission; and to publish your name
          in connection with your Submission.
        </p>
        <p>
          No compensation will be paid with respect to the use of your
          Submission, as provided herein. Tyler is under no obligation to post
          or use any Submission you may provide.
        </p>
        <p>
          By posting, uploading, inputting, providing or submitting your
          Submission you warrant and represent that you own, are authorized to
          exercise, or otherwise control all of the rights to your Submission as
          described in this section including, without limitation, all the
          rights necessary for you to provide, post, upload, input or submit the
          Submissions.
        </p>
        <p>
          Tyler has no obligation to monitor the Submissions. However, Tyler
          reserves the right to review materials posted to the Platform and to
          remove any materials in its sole discretion.
        </p>
        <p>
          Tyler reserves the right at all times to disclose any information as
          necessary to satisfy any applicable law, regulation, legal process or
          governmental request, or to edit or refuse to post any information or
          materials, in whole or in part, in Tyler's sole discretion.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Liability Disclaimer</b>
          </span>
        </p>
        <p>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
          AVAILABLE THROUGH THE PLATFORM MAY INCLUDE INACCURACIES OR
          TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
          INFORMATION HEREIN. TYLER AND/OR ITS SUBSIDIARIES OR SUPPLIERS MAY
          MAKE IMPROVEMENTS AND/OR CHANGES IN THE PLATFORM AT ANY TIME. ADVICE
          RECEIVED VIA THE PLATFORM SHOULD NOT BE RELIED UPON FOR PERSONAL,
          MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN
          APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR
          SITUATION.
        </p>
        <p>
          TYLER AND/OR ITS SUBSIDIARIES OR SUPPLIERS MAKE NO REPRESENTATIONS
          ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
          ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
          GRAPHICS CONTAINED ON THE PLATFORM FOR ANY PURPOSE. TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
          PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
          WARRANTY OR CONDITION OF ANY KIND. TYLER AND/OR ITS SUBSIDIARIES OR
          SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO
          THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
          INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
        </p>
        <p>
          CUSTOMER AND USER ARE RESPONSIBLE FOR ALL LOGIN CREDENTIALS, INCLUDING
          USERNAMES AND PASSWORDS AND TYLER SHALL NOT BE LIABLE FOR ANY DAMAGES,
          LOSSES, OR TERMINATION OR INTERRUPTION OF ACCESS TO THE PLATFORM THAT
          MAY OCCUR AS A RESULT OF CUSTOMER’S FAILURE, OR THE FAILURE OF USERS,
          TO MAINTAIN THE CONFIDENTIALITY AND EXCLUSIVE USE OF THEIR LOGIN
          CREDENTIALS.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          TYLER AND/OR ITS SUBSIDIARIES OR SUPPLIERS BE LIABLE FOR ANY DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
          DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
          USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
          USE OR PERFORMANCE OF THE PLATFORM, WITH THE DELAY OR INABILITY TO USE
          THE PLATFORM OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
          PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES
          AND RELATED GRAPHICS OBTAINED THROUGH THE PLATFORM, OR OTHERWISE
          ARISING OUT OF THE USE OF THE PLATFORM, WHETHER BASED ON CONTRACT,
          TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF TYLER OR ANY
          OF ITS SUBSIDIARIES OR SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY
          OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
          DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
          DISSATISFIED WITH ANY PORTION OF THE PLATFORM, OR WITH ANY OF THESE
          TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
          THE PLATFORM.
        </p>
        <p>
          SERVICE CONTACT:
          <a href="mailTo:webadmin@tylertech.com"> webadmin@tylertech.com</a>
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Termination/Access Restriction</b>
          </span>
          <br />
        </p>
        <p>
          Tyler reserves the right, in its sole discretion, to terminate your
          access to the Platform and the related services or any portion thereof
          at any time, without notice.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Disputes</b>
          </span>
        </p>
        <p>
          Please contact Tyler in writing at 5101 Tennyson Pkwy, Plano, Texas
          75024, Attn: Legal Department, regarding any claim or controversy
          arising out of or relating to this Agreement, or any breach thereof,
          and Tyler will attempt in good faith to resolve the claim, or we can
          agree to engage in mediation. Each party agrees to pay for its own
          expenses in engaging in mediation. Any claim, dispute, or controversy
          that cannot be resolved through mediation within thirty (30) days
          following receipt of written notice will be settled by arbitration as
          set forth below.
        </p>
        <p>
          A. Arbitration. Except for claims or controversies for which Tyler may
          pursue injunctive relief, any claim, dispute, or controversy arising
          out of or relating to the Platform, this Agreement, or the breach,
          termination, enforcement, interpretation, or validity of this
          Agreement, will be resolved exclusively by binding arbitration
          administered by the American Arbitration Association (“AAA”) before a
          single arbitrator. Payment of all filing, administration, and
          arbitrator fees will be governed by AAA’s applicable rules. The
          arbitrator’s decision shall be controlled by this Agreement and any
          other agreements referenced herein. If the claim or controversy is for
          $10,000 or less, Tyler agrees that you may choose whether the
          arbitration will be conducted solely on the basis of documents
          submitted to the arbitrator through a telephonic hearing or by an
          in-person hearing as established by the AAA Rules. If the claim or
          controversy exceeds $10,000, the right to a hearing will be determined
          by the AAA Rules. Regardless of the manner in which the arbitration is
          conducted, the award of the arbitrator shall be accompanied by a
          statement of the reasons upon which the award is based. You understand
          that, in the absence of this provision, you would have had a right to
          litigate disputes through a court, including the right to litigate
          claims on a class-wide or class-action basis, and that you have
          expressly and knowingly waived those rights and agreed to resolve any
          disputes through binding arbitration in accordance with the provisions
          of this paragraph.
        </p>
        <p>
          B. Limitation on Time to File. YOU AGREE THAT IF YOU DO NOT FILE A
          DEMAND TO ARBITRATE A DISPUTE WITHIN ONE (1) YEAR AFTER THE DISPUTE
          ARISES, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <p>
          C. Arbitration on an Individual Basis Only; Class Arbitration Waiver.
          YOU AGREE THAT, BY ACCEPTING THIS AGREEMENT, YOU AND TYLER ARE EACH
          WAIVING THE RIGHT TO PARTICIPATE IN OR BRING A CLASS ACTION. TYLER AND
          YOU AGREE TO ARBITRATE SOLELY ON AN INDIVIDUAL BASIS AND AGREE THAT
          THIS AGREEMENT DOES NOT PERMIT CLASS ARBITRATION OR ANY CLAIMS BROUGHT
          AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
          ARBITRATION PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN
          ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
          REPRESENTATIVE OR CLASS PROCEEDING. THIS AGREEMENT EVIDENCES A
          TRANSACTION IN INTERSTATE COMMERCE, AND THUS THE FEDERAL ARBITRATION
          ACT (“FAA”) GOVERNS THE INTERPRETATION AND ENFORCEMENT OF THIS
          PROVISION. IF FOR ANY REASON, A CLAIM PROCEEDS IN COURT RATHER THAN
          ARBITRATION, TYLER AND YOU WAIVE ANY RIGHT TO A JURY TRIAL OR TO
          PARTICIPATE IN A CLASS ACTION AGAINST EACH OTHER.
        </p>
        <p>
          D. Applicable Law and Venue. The Platform is accessible over the
          Internet and therefore available worldwide, Access to and use of the
          Platform is at your own risk and subject to the laws of the United
          States of America in addition to the applicable laws in your state or
          country. You are responsible for compliance with any local laws and
          regulations. Nothing in the Platform should be interpreted as a
          promotion or solicitation for any product of any nature that is not
          authorized by the laws and regulations of the country where you are
          located. You agree that the laws of the State of Texas, without giving
          effect to any principles of conflicts of law, govern this Agreement
          and any dispute of any sort that may arise between you and us or any
          of our subsidiaries. Any arbitration related to this Agreement shall
          occur in Collin County, Texas. Tyler and you acknowledge that this
          Agreement evidences a transaction involving interstate commerce.
          Notwithstanding the foregoing paragraph with respect to applicable
          substantive law, any arbitration conducted pursuant to this Agreement
          shall be governed by the FAA, 9 U.S.C. §§ 1-16.
        </p>
        <p>
          E. Conflict. If any part of this arbitration provision is deemed to be
          invalid, unenforceable or illegal, or otherwise conflicts with the
          code of procedure established by the AAA, the balance of this
          arbitration provision shall remain in effect and shall be construed in
          accordance with its terms as if the invalid, unenforceable, illegal,
          or conflicting provision were not contained herein.
        </p>
        <p>
          F. Injunctive Relief. Notwithstanding anything to the contrary, Tyler
          retains the right to seek injunctive relief if you use the Platform in
          violation of the access rights or the restrictions on use, infringe
          upon or misappropriate Tyler’s intellectual property rights, or breach
          your obligation to maintain the confidentiality of Tyler’s
          confidential or proprietary information made available to you through
          the Platform.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Miscellaneous</b>
          </span>
        </p>
        <p>
          You agree that no joint venture, partnership, employment, or agency
          relationship exists between you and Tyler as a result of this
          Agreement or use of the Platform. Tyler's performance of this
          Agreement is subject to existing laws and legal process, and nothing
          contained in this Agreement is in derogation of Tyler's right to
          comply with governmental, court and law enforcement requests or
          requirements relating to your use of the Platform or information
          provided to or gathered by Tyler with respect to such use. If any part
          of this Agreement is determined to be invalid or unenforceable
          pursuant to applicable law including, but not limited to, the warranty
          disclaimers and liability limitations set forth above, then the
          invalid or unenforceable provision will be deemed superseded by a
          valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of the Agreement shall
          continue in effect. Unless otherwise specified herein, this Agreement
          constitutes the entire agreement between the user and Tyler with
          respect to the Platform and it supersedes all prior or contemporaneous
          communications and proposals, whether electronic, oral or written,
          between the user and Tyler with respect to the Platform. A printed
          version of this Agreement and of any notice given in electronic form
          shall be admissible in judicial or administrative proceedings based
          upon or relating to this Agreement to the same extent and subject to
          the same conditions as other business documents and records originally
          generated and maintained in printed form.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Copyright and Trademark Notices</b>
          </span>
        </p>
        <p>
          All contents of the Platform are: ©2019 Tyler Technologies, Inc. All
          rights reserved.
        </p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>Trademarks</b>
          </span>
        </p>
        <p>
          The names of actual companies and products mentioned herein may be the
          trademarks of their respective owners.
        </p>
        <p>
          The example companies, organizations, products, people and events
          depicted herein are fictitious. No association with any real company,
          organization, product, person, or event is intended or should be
          inferred.
        </p>
        <p>Any rights not expressly granted herein are reserved.</p>
      </Grid>
      <Grid item>
        <p>
          <span>
            <b>
              Notices and Procedure for Making Claims of Copyright Infringement
            </b>
          </span>
        </p>
        <p>
          If you believe the Platform contains elements that infringe your
          copyrights, please provide Tyler’s designated copyright agent below
          with the following information in writing:
        </p>
        <p>
          • A physical or electronic signature of person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </p>
        <p>
          • Identification of the copyrighted work claimed to have been
          infringed, or multiple copyrighted works at a single online site are
          covered by a single notification, a representative list of such works
          at that site;
        </p>
        <p>
          • Identification of the material that is claimed to be infringing or
          to be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit the service provider to locate the material;
        </p>
        <p>
          • Information reasonably sufficient to permit the service provider to
          contact the complaining party, such as an address, telephone number,
          and if available, an electronic mail address at which the complaining
          party may be contacted;
        </p>
        <p>
          • A statement that the complaining party has a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law; and
        </p>
        <p>
          • A statement that the information in the notification is accurate,
          and under penalty of perjury, that the complaining party is authorized
          to act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </p>
        <p>By mail:</p>
        <p>
          Tyler Technologies, Inc.
          <br />
          Attention: Chief Legal Officer
          <br />
          1 Tyler Drive
          <br />
          Yarmouth, ME 04096
          <br />
          <span
            style={{
              textDecoration: "underline",
              textUnderlinePosition: "under",
            }}
          >
            copyright@tylertech.com
          </span>
        </p>
        <p>
          For additional information regarding this procedure, please reference
          17 U.S.C. § 512.
        </p>
      </Grid>
    </Grid>
  );
}

export default TermsConditions;
