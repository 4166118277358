import React, { useLayoutEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import PropTypes from "prop-types";

import Table from "./Table";
import Datagrid from "./Datagrid";
import CustomTitle from "./FormTitle";
import NestedPanel from "./NestedPanel";

import { formConditional, getWatchFieldName } from "../services/form.service";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import useGetFormStateValues from "../hooks/useGetFormProps";

function Panel(props) {
  const { components: panelItem, handleTabChange, formTemplate } = props;

  const { formData, formConfig } = useGetFormStateValues();
  const { getValues } = useFormContext();
  const { updateHeight } = useUpdatePanelHeight();

  let watchFieldValue = useWatch({
    name: getWatchFieldName(panelItem),
    disabled: !panelItem.conditional || !panelItem.conditional.when,
  });
  // hook form wont reference field not present in form
  if (
    watchFieldValue === undefined &&
    formData[panelItem.conditional.when] !== undefined
  ) {
    watchFieldValue = formData[panelItem.conditional.when];
  }
  // const shouldDisplay = formConditional(panelItem, watchFieldValue);
  const shouldDisplay =
    formConfig?.isWizard ||
    formConditional(panelItem, watchFieldValue, getValues());

  useLayoutEffect(() => {
    if (panelItem.conditional) {
      updateHeight();
    }
    return () => {
      if (panelItem.conditional) {
        updateHeight();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (shouldDisplay) {
    const tableGrids =
      panelItem &&
      panelItem.components.filter(
        (components) =>
          components.type === "table" ||
          components.type === "datagrid" ||
          components.type === "panel"
      );

    return (
      <div>
        {panelItem.title && panelItem.title !== "" && (
          <CustomTitle
            title={panelItem.title}
            header
            handleTabChange={handleTabChange}
            navigateFromReviewPage={["Open", "Rejected"]}
            formTemplate={formTemplate}
            hideWhenDisabled={Boolean(panelItem?.properties?.hideWhenDisabled)}
          />
        )}
        {tableGrids.length > 0 &&
          tableGrids.map((tableGridItem) => {
            if (tableGridItem.type === "panel") {
              return (
                <NestedPanel
                  key={tableGridItem.key}
                  tableGrid={tableGridItem}
                />
              );
            }
            if (tableGridItem.type === "table") {
              return (
                <Table key={tableGridItem.key} components={tableGridItem} />
              );
            }
            if (tableGridItem.type === "datagrid") {
              return (
                <Datagrid key={tableGridItem.key} gridItem={tableGridItem} />
              );
            }
            return <div>Invalid element</div>;
          })}
      </div>
    );
  }
  return "";
}
Panel.whyDidYouRender = true;
Panel.propTypes = {
  formTemplate: PropTypes.shape({}),
  handleTabChange: PropTypes.func,
  components: PropTypes.shape({
    components: PropTypes.oneOfType([PropTypes.array]).isRequired,
    type: PropTypes.string.isRequired,
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    title: PropTypes.string,
  }).isRequired,
};
Panel.defaultProps = {
  formTemplate: {},
  handleTabChange: () => {},
};

export default Panel;
