/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const formLoaderSlice = createSlice({
  name: "formLoader",
  initialState,
  reducers: {
    showFormLoader(state) {
      state.loading = true;
    },
    hideFormLoader(state) {
      state.loading = false;
    },
  },
});

export const { showFormLoader, hideFormLoader } = formLoaderSlice.actions;
export default formLoaderSlice.reducer;
