import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import DialogPopup from "../../../DialogPopup";
import { getParsedValue } from "../../services/form.service";

function DgDialog({ dialogProps, setDialogProps, removeRecord, saveRecord }) {
  const { type, custom } = dialogProps;

  let qnLabel = "";
  if (type === "invalidControllingField") {
    const customProps = getParsedValue(custom, "DgDialog") || {};
    qnLabel = customProps.qnLabel || "";
  }

  const onClickHandler = () => {
    if (type === "removeRecord") removeRecord(true);
    else if (type === "invalidControllingField") saveRecord(null, true);
    setDialogProps(null);
  };

  const buttonArray = [
    {
      id: 1,
      name: "Cancel",
      variant: "contained",
      onClickHandler: () => setDialogProps(null),
      show: true,
    },
    {
      id: 2,
      name: "Continue",
      variant: "contained",
      onClickHandler,
      show: true,
    },
  ];

  const removeRecordWarningProps = {
    title: "Warning - Associated records found with this entry.",
    content: (
      <Typography>
        There are associated records with the entry you are trying to delete. If
        confirmed, the associated records will also be removed and this cannot
        be reverted. Please confirm.
      </Typography>
    ),
  };

  const invalidFieldWarningProps = {
    title: "Warning",
    content: (
      <>
        <Typography>
          You have updated the below listed item and this will cause the
          associated records to be removed permanently and cannot be reverted.
          Please confirm.
        </Typography>
        <ul>
          <li>
            <Typography>{qnLabel}</Typography>{" "}
          </li>
        </ul>
      </>
    ),
  };

  const dialogPropsMap = {
    removeRecord: removeRecordWarningProps,
    invalidControllingField: invalidFieldWarningProps,
  };

  const title = dialogPropsMap[type]?.title || "";
  const content = dialogPropsMap[type]?.content || "";

  return (
    <DialogPopup
      title={title}
      content={content}
      buttonArray={buttonArray}
      openPopup
      onCancel={undefined}
      showActionBtn
      maxWidth="md"
      hideIcon="none"
    />
  );
}

export default DgDialog;

DgDialog.propTypes = {
  dialogProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    custom: PropTypes.string,
  }).isRequired,
  setDialogProps: PropTypes.func.isRequired,
  removeRecord: PropTypes.func.isRequired,
  saveRecord: PropTypes.func.isRequired,
};
