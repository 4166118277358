import React from "react";
// eslint-disable-next-line import/no-unresolved
import PrivacyPolicy from "@regional/templates/PrivacyPolicy";
import GridContainer from "../../../components/GridContainer";

function PrivacyPolicyPage() {
  const title = "Privacy Act Statement";
  return (
    <GridContainer title={title} width="full">
      <PrivacyPolicy />
    </GridContainer>
  );
}

export default PrivacyPolicyPage;
