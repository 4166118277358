/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMaintenance: false,
  start: "",
  end: "",
};
const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setMaintenance(state, { payload }) {
      state.isMaintenance = payload.isMaintenance;
      state.start = payload.start;
      state.end = payload.end;
    },
    resetMaintenance(state) {
      state.isMaintenance = initialState.isMaintenance;
      state.start = initialState.start;
      state.end = initialState.end;
    },
  },
});

export const { setMaintenance, resetMaintenance } = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
