import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import Wizard from "./Wizard";
import Layout from "./Layouts/Layout";
import PaymentTable from "../../features/application/components/PaymentTable";

import {
  resetFormPropsAndMethodRefs,
  updateFormProps,
} from "./context/formSlice";
import useDgRenderState from "./hooks/useDgRenderState";
import {
  resetDialogFormProps,
  updateDialogFormProps,
} from "./context/dialogFormSlice";
import FormRecordEditDialog from "./Dialogs/FormRecordEdit";
import { getFormattedFormData } from "./services/form.service";
import PreSubmitDialog from "./Dialogs/PreSubmitDialog";

function Form(props) {
  const {
    formTemplate,
    formConfig,
    methods,
    onSubmit,
    onError,
    showReviewTab,
    isSinglePageLayout,
    applicationData,
    applicationType,
    formSaveHandler,
    domainName,
    isDialogForm,
    printApplicationBtn,
  } = props;
  const stateFormConfig = useSelector((state) => state.formProps.formConfig);
  const [stateUpdated, setStateUpdated] = useState(false);
  const dispatch = useDispatch();
  const { resetDgRenderState } = useDgRenderState();

  const isWizard = formTemplate?.display === "wizard";
  const isForm = formTemplate?.display === "form";

  useEffect(() => {
    setStateUpdated(!isEmpty(stateFormConfig) || isForm);
  }, [stateFormConfig, isForm]);

  let payload = {};
  if (isWizard) {
    payload = {
      formData: getFormattedFormData(
        showReviewTab || isSinglePageLayout
          ? applicationData.formProperties
          : applicationData.properties
      ),
      formConfig: {
        isSinglePageLayout,
        readOnly: formConfig.readOnly,
        isWizard,
        singlePageLayoutIcon: formConfig.singlePageLayoutIcon,
        domainName,
      },
      applicationData: {
        status: applicationData.status,
        id: applicationData._id,
        formDocuments:
          domainName !== "LicenseForm"
            ? applicationData.requiredDocuments
            : applicationData.documents,
        applicationType,
      },
    };
  } else if (isForm) {
    payload = {
      formData: getFormattedFormData(applicationData),
      formConfig,
      applicationData,
    };
  }

  useEffect(() => {
    if (isDialogForm) dispatch(updateDialogFormProps(payload));
    else dispatch(updateFormProps(payload));

    return () => {
      // reset the FormProps & MethodRefs
      if (isDialogForm) dispatch(resetDialogFormProps());
      else dispatch(resetFormPropsAndMethodRefs());
      resetDgRenderState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData._id]);

  const template = {
    ...formTemplate,
    components: [...(formTemplate ? formTemplate.components : [])],
  };

  let actionPanel = { components: [], key: "actions" };
  const actionsPanelIndex = template.components.findIndex(
    (panel) => panel.key === "actions"
  );
  if (actionsPanelIndex > -1)
    [actionPanel] = template.components.splice(actionsPanelIndex, 1);

  const memoWizard = useMemo(
    () => (
      <Wizard
        formTemplate={template}
        showReviewTab={showReviewTab}
        onSubmit={onSubmit}
        onError={onError}
        formSaveHandler={formSaveHandler}
        printApplicationBtn={printApplicationBtn}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateUpdated, formTemplate, applicationData._id]
  );

  return (
    stateUpdated && (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider isDialogForm={isDialogForm} {...methods}>
          <form>
            {isWizard && !isSinglePageLayout && memoWizard}
            {isWizard && isSinglePageLayout && (
              <Layout
                components={template.components}
                formTemplate={formTemplate}
              />
            )}
            {isForm && <Layout components={template.components} nested />}
            {applicationData && applicationData.status === "Submitted" && (
              <PaymentTable />
            )}
          </form>
        </FormProvider>
        <FormRecordEditDialog methods={methods} actionsTemplate={actionPanel} />
        <PreSubmitDialog
          methods={methods}
          onSubmit={onSubmit}
          onError={onError}
        />
      </>
    )
  );
}
Form.propTypes = {
  formTemplate: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired,
  formConfig: PropTypes.shape({
    readOnly: PropTypes.bool,
    singlePageLayoutIcon: PropTypes.bool,
  }),
  methods: PropTypes.shape({
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  showReviewTab: PropTypes.bool,
  isSinglePageLayout: PropTypes.bool,
  applicationData: PropTypes.shape({
    requiredDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    formProperties: PropTypes.shape({}),
    properties: PropTypes.shape({}),
    status: PropTypes.string,
    _id: PropTypes.string,
  }),
  applicationType: PropTypes.string,
  formSaveHandler: PropTypes.func,
  domainName: PropTypes.string,
  isDialogForm: PropTypes.bool,
};
Form.defaultProps = {
  onSubmit: () => {},
  onError: () => {},
  showReviewTab: true,
  isSinglePageLayout: false,
  formConfig: {},
  formSaveHandler: undefined,
  applicationData: {
    formProperties: {},
    requiredDocuments: [],
    documents: [],
  },
  applicationType: undefined,
  domainName: "ApplicationForm",
  isDialogForm: false,
};
/* Form.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "Wizard",
}; */
export default Form;
