import React from "react";
import { useDispatch } from "react-redux";
import { useFormState } from "react-hook-form";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";

import { showFormDialog } from "../../context/dialogSlice";
import { showNotification } from "../../../../features/core/slices/notification.slice";
import { validateAddressFields } from "../../services/form.service";

function VerifyAddressBtn(props) {
  const {
    fieldProps,
    control,
    getValues,
    datagridFormState,
    trigger,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const { errors } = useFormState({ control });
  const { tags, label } = fieldProps;

  const onBtnClick = () => {
    if (tags && tags.length) {
      tags.forEach((item) => trigger(item));
      setTimeout(() => {
        const errorMsg = validateAddressFields({
          tags,
          errors,
          btnType: "verify",
        });
        if (errorMsg)
          dispatch(
            showNotification({ type: "warning", msg: errorMsg, show: true })
          );
        else {
          const userEnteredAddress = tags
            .filter((key) => getValues(key))
            .map((key) => ({ key, value: getValues(key) }));
          const payload = {
            show: true,
            fieldProps,
            userEnteredAddress,
            datagridKey: datagridFormState && datagridFormState.datagridKey,
            recordId: datagridFormState && datagridFormState.record?.id,
          };
          dispatch(showFormDialog(payload));
        }
      }, 5);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={onBtnClick}
      startIcon={<Check />}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
VerifyAddressBtn.propTypes = {
  fieldProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  datagridFormState: PropTypes.shape({
    datagridKey: PropTypes.string.isRequired,
    record: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
  trigger: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
VerifyAddressBtn.defaultProps = {
  datagridFormState: undefined,
};
export default VerifyAddressBtn;
