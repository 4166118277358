/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormState, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListItemText from "@mui/material/ListItemText";
import TypeAheadSearch from "./FormSelect/TypeAheadSearch";
import {
  useDropdownListMutation,
  useLazyGetDropdownListQuery,
} from "../../../api/forms.api.slice";

import { disableOptions, getParsedValue } from "../services/form.service";
import { notifyError } from "../../../utils/helpers";
import { hideFormLoader, showFormLoader } from "../context/loaderSlice";
import { showNotification } from "../../../features/core/slices/notification.slice";

const getStyles = (theme) => ({
  "& .closeButton": {
    position: "sticky",
    bottom: 0,
    justifyContent: "center",
    display: "flex",
    background: theme.palette.mode === "dark" ? "#303030" : "white",
    color: "#43B73F",
    borderTop: "thin solid",
    "&:hover &:focus": {
      background: theme.palette.mode === "dark" ? "#303030" : "white",
    },
  },
  maxHeight: "300px",
  "& .MuiMenuItem-root": {
    "& .MuiSvgIcon-root": {
      fontSize: "15px",
      marginRight: "6px",
      display: "none",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.mode === "light" ? "#f1f1f1" : "#595959",
      "& .MuiSvgIcon-root": {
        display: "inline",
      },
    },
  },
});

function FormMultiSelect(props) {
  const { fieldProps, formProps, control, disabled, resetHandler, getValues } =
    props;
  const { key, label, values, validate, properties } = fieldProps;
  const dispatch = useDispatch();

  const [
    lazyTrigger,
    { data: lazyOptionsData, isFetching: lazyIsFetching, error: lazyError },
  ] = useLazyGetDropdownListQuery(); // for parallel api call
  const [
    mutationTrigger,
    {
      data: mutationOptionsData,
      isLoading: mutationIsFetching,
      error: mutationError,
    },
  ] = useDropdownListMutation();

  const { errors } = useFormState({ control, name: key });
  const [optionsList, setOptionsList] = useState(
    properties?.dataSrc ? [] : values
  );
  const [open, setOpen] = useState(false);
  const [shouldDisable, setShouldDisable] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const optionsListRef = React.useRef([]);
  // Temp Fix
  const skipCache =
    properties?.dataSrc && properties?.dataSrc.includes("licenses");
  const isFetching = lazyIsFetching || mutationIsFetching;
  const defaultValue =
    formProps[key] && Array.isArray(formProps[key]) ? formProps[key] : [];
  const condition = validate?.custom
    ? getParsedValue(validate.custom, key)
    : undefined;
  const maxSelection = condition?.maxSelection;

  useEffect(() => {
    if (properties?.dataSrc) {
      if (skipCache) {
        mutationTrigger(properties?.dataSrc);
      } else {
        lazyTrigger(properties?.dataSrc, true);
      }
    } else {
      setOptionsList(values);
      optionsListRef.current = values;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (properties?.dataSrc) {
      const optionsData = skipCache ? mutationOptionsData : lazyOptionsData;
      if (optionsData && !isFetching) {
        if (maxSelection && optionsData.length) {
          const maxAllowedOptionList = optionsData.map((item) => {
            if (
              !getValues(key).includes(item.value) &&
              getValues(key).length === maxSelection
            )
              return { ...item, disabled: true };
            return item;
          });
          setOptionsList(maxAllowedOptionList);
          optionsListRef.current = maxAllowedOptionList;
        } else {
          setOptionsList(optionsData);
          optionsListRef.current = optionsData;
        }
      }
      const error = lazyError || mutationError;
      if (error) {
        notifyError(dispatch, showNotification, error);
      }
      if (isFetching) dispatch(showFormLoader());
      else dispatch(hideFormLoader());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetching,
    lazyError,
    mutationError,
    lazyOptionsData,
    mutationOptionsData,
  ]);

  const onChangeHandler = (e, onChange) => {
    const { value = [] } = e?.target || {};
    const selectedValues = value.filter(Boolean);
    onChange(selectedValues);
    resetHandler();
    if (maxSelection) {
      const getUpdatedOptionList = (options) =>
        options.map((item) =>
          !selectedValues.includes(item.value) &&
          selectedValues.length === maxSelection
            ? { ...item, disabled: true }
            : { ...item, disabled: false }
        );
      setOptionsList(getUpdatedOptionList(optionsList));
      optionsListRef.current = getUpdatedOptionList(optionsListRef.current);
    }
    if (shouldDisable) {
      const [updatedValues, warningText] = disableOptions(
        fieldProps,
        getValues
      );
      setOptionsList(updatedValues);
      setWarningMessage(warningText);
    }
  };

  useEffect(() => {
    if (maxSelection && optionsList.length) {
      const maxAllowedOptionList = optionsList.map((item) => {
        if (
          !getValues(key).includes(item.value) &&
          getValues(key).length === maxSelection
        )
          return { ...item, disabled: true };
        return item;
      });
      setOptionsList(maxAllowedOptionList);
      optionsListRef.current = maxAllowedOptionList;
    }
    if (condition?.disableOptions) {
      const [updatedValues, warningText] = disableOptions(
        fieldProps,
        getValues
      );
      setOptionsList(updatedValues);
      setShouldDisable(true);
      setWarningMessage(warningText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHandler = () => {
    setOpen(false);
    if (properties?.enableSearch) {
      setOptionsList(optionsListRef.current);
    }
  };
  const handleOpen = () => setOpen(true);

  const getRenderValue = (selected) => {
    const listMap = optionsListRef.current.length
      ? optionsListRef.current.reduce(
          (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
          {}
        )
      : null;

    return (
      listMap &&
      selected.length &&
      selected.map((item) => listMap[item]).join(", ")
    );
  };
  return (
    <FormControl
      fullWidth
      variant="standard"
      margin="dense"
      required={validate?.required}
      sx={{
        height: "75px",
      }}
    >
      <Controller
        name={key}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ref, value, ...field } }) => (
          <>
            <InputLabel id={`${key}Label`}>{label}</InputLabel>
            <Select
              open={open}
              onOpen={handleOpen}
              name={key}
              labelId={`${key}Label`}
              multiple
              ref={ref}
              id={key}
              value={value || []}
              renderValue={getRenderValue}
              label={label}
              disabled={disabled}
              onChange={(e) => onChangeHandler(e, onChange)}
              onClose={onCloseHandler}
              MenuProps={{ sx: getStyles }}
              {...field}
            >
              {properties?.enableSearch && (
                <TypeAheadSearch
                  optionsListRef={optionsListRef}
                  setOptionList={setOptionsList}
                  isMultiSelect
                />
              )}
              {optionsList.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.value}
                  disabled={item.disabled}
                  tabIndex={0}
                >
                  <CheckCircleIcon color="primary" />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
              {isFetching && (
                <MenuItem value="" disabled>
                  Loading...
                </MenuItem>
              )}
              <MenuItem
                onClick={onCloseHandler}
                tabIndex={0}
                className="closeButton"
              >
                CLOSE
              </MenuItem>
            </Select>
          </>
        )}
        rules={{ required: validate?.required }}
      />
      {warningMessage && (
        <FormHelperText error id={`${key}-selectbox`}>
          {warningMessage}
        </FormHelperText>
      )}
      {errors[key] && (
        <FormHelperText error id={`${key}-selectbox`}>
          This is required.
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormMultiSelect.propTypes = {
  getValues: PropTypes.func,
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
  formProps: PropTypes.shape({
    dataGridKey: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
};
FormMultiSelect.defaultProps = {
  getValues: PropTypes.func,
};
export default FormMultiSelect;
