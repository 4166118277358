import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function HelpDialogContent() {
  const { navConfig } = useSelector((state) => state.navConfig);
  const { mailContact, faqLink, phoneContact, documentLink } = navConfig.help;
  return (
    <Box sx={{ mb: "3em" }}>
      <Typography>
        Please click&nbsp;
        <a target="_blank" href={documentLink} rel="noreferrer">
          here
        </a>{" "}
        to view the training material and find answers to Frequently Asked
        Questions.
      </Typography>
      <Typography>
        If you are a licensee and have a policy or procedural question, please
        contact the Arkansas Alcoholic Beverage Control at&nbsp;
        <a href={`mailto:${faqLink}`} target="_top">
          {faqLink}
        </a>
        {phoneContact && (
          <>
            <span> or </span>
            <a href={`tel:${phoneContact}`}>{phoneContact}</a>{" "}
          </>
        )}
      </Typography>
      <Typography>
        For technical support please contact&nbsp;
        <a href={`mailto:${mailContact}`} target="_top">
          {mailContact}
        </a>
        .
      </Typography>
    </Box>
  );
}

export default HelpDialogContent;
