import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledLicenseInfo = styled(Box)(({ theme }) => ({
  "& .header": {
    display: "flex",
    borderRadius: 0,
    borderBottomColor:
      theme.palette.mode === "light" ? "#76c836" : theme.palette.primary.dark,
    borderBottomStyle: "solid",
    borderBottomWidth: 3,
    overflowX: "auto",
    padding: "1rem 0",
    backgroundColor: "transparent",
  },
  "& .container": {
    margin: "0 0.5em",
  },
  "& .icon": {
    display: "flex",
    padding: "10px",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": { color: "#76c836" },
  },
  "& .flex-col": {
    display: "flex",
    flexDirection: "column",
  },
  "& .label": {
    marginRight: "0.5em",
  },
  "& .text": {
    fontWeight: "normal",
    color: theme.palette.mode === "light" ? "#000" : "white",
  },
  "& .bold": {
    fontWeight: 600,
    color: theme.palette.mode === "light" ? "#6a6969" : "white",
  },
  "& .dark": {
    fontWeight: 600,
    color: theme.palette.mode === "light" ? "#6a6969" : "white",
  },
  "& .light": {
    lineHeight: 1,
    fontSize: "0.9rem",
    fontWeight: 500,
    color: theme.palette.mode === "light" ? "#767676" : "white",
  },
  "& .left-column": {},
  "& .right-column": {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    height: "100%",
  },
}));
export default StyledLicenseInfo;
