import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import classes from "./navigation.module.css";
import DialogPopup from "../../../../components/DialogPopup";
import { useAllTenantsQuery } from "../../../../api/tenants.api.slice";
import { openNewAppDialog } from "../../slices/newApplication.slice";

const switchAccPermission = "switch_account";
const initialTenantProps = {
  name: "",
  limit: 10,
  sort: "name",
  page: 1,
};

function AccountSwitcher(props) {
  const { accountList, handleAccountSwitch, currentTenant, fetchOnDemand } =
    props;

  const [autoCmpText, setAutoCmpText] = useState("");
  const [autoCmpSelect, setAutoCmpSelect] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceType, setSourceType] = useState("");
  const [skip, setSkip] = useState(true);
  const [tenantProps, setTenantProps] = useState(initialTenantProps);

  const { currentData: allTenants, isFetching: loading } = useAllTenantsQuery(
    tenantProps,
    {
      skip: skip || tenantProps.name?.length < 2,
    }
  );
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(
    accountList.filter((account) => account._id !== currentTenant._id)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetTenantProps = useCallback(
    debounce((newTenantProps) => setTenantProps(newTenantProps), 700),
    []
  );

  const switchAccount = (ev, value) => {
    if (value && value._id) {
      setAutoCmpSelect(value);
      setShowConfirm(true);
    }
  };
  const handleInputChange = (event, value, source) => {
    if (value || source === "input") {
      setAutoCmpText(value);
    }
    setSourceType(source);
  };
  const onConfirmClose = () => {
    setAutoCmpText("");
    setAutoCmpSelect(null);
    setShowConfirm(false);
  };
  const onConfirmOk = () => {
    setShowConfirm(false);
    if (autoCmpSelect && autoCmpSelect._id) {
      handleAccountSwitch(autoCmpSelect._id);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => debouncedSetTenantProps.cancel(), []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoCmpText.length < 3 || !fetchOnDemand) {
      setSkip(true);
      setTenantProps(initialTenantProps);
      debouncedSetTenantProps.cancel();
      return undefined;
    }
    setSkip(false);
    if (sourceType !== "reset")
      debouncedSetTenantProps({ ...tenantProps, name: autoCmpText });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCmpText, fetchOnDemand]);
  useEffect(() => {
    let active = true;
    if (active && allTenants && allTenants.docs) {
      setOptions(
        allTenants.docs.filter((account) => account._id !== currentTenant._id)
      );
    }
    return () => {
      active = false;
    };
  }, [allTenants, currentTenant?._id]);
  useEffect(() => {
    if (!open && fetchOnDemand) {
      setOptions([]);
      setAutoCmpText("");
    }
  }, [fetchOnDemand, open]);
  useEffect(() => {
    setAutoCmpText("");
    setAutoCmpSelect(null);
    setOptions(
      accountList.filter((account) => account._id !== currentTenant?._id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant]);

  const dialogContent = (
    <Typography variant="h6">
      Are you sure you want to switch to a different account?
    </Typography>
  );

  return (
    <>
      <Autocomplete
        onChange={switchAccount}
        onInputChange={handleInputChange}
        sx={{ mt: "20px" }}
        id="combo-box-demo"
        clearOnBlur
        blurOnSelect
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        inputValue={autoCmpText}
        value={autoCmpSelect}
        options={options}
        getOptionLabel={(option) => (option.name ? option.name : option._id)}
        renderOption={(optionProps, option) => (
          <li
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...optionProps}
            key={option._id}
            style={{ wordBreak: "break-word" }}
          >
            {option.name}
          </li>
        )}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            className={classes.notchedOutline}
            label="Switch Account"
            color="primary"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <DialogPopup
        content={dialogContent}
        openPopup={showConfirm}
        onOk={onConfirmOk}
        onCancel={onConfirmClose}
        showTitle={false}
        maxWidth="xs"
      />
    </>
  );
}
AccountSwitcher.propTypes = {
  accountList: PropTypes.oneOfType([PropTypes.array]),
  handleAccountSwitch: PropTypes.func.isRequired,
  currentTenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchOnDemand: PropTypes.bool,
};
AccountSwitcher.defaultProps = {
  accountList: [],
  fetchOnDemand: false,
};
function AccountCard(props) {
  const { tenantList, switchTenantHandler, permissions, currentTenant } = props;
  const dispatch = useDispatch();

  return (
    <Card
      aria-label=" "
      sx={(theme) => ({
        width: 240,
        margin: "0 auto",
        boxShadow: "3px 3px 8px 0 #2b2b2b",
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.paper
            : "#ececec",
      })}
    >
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4}>
            <AssignmentIndIcon
              sx={(theme) => ({
                color: theme.palette.mode === "dark" ? "#ffff" : "#505050",
                height: "100%",
                width: "85%",
              })}
            />
          </Grid>
          <Grid item xs={8} tabIndex={0}>
            <Typography variant="body2" component="p">
              Selected Account:
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              sx={{ wordWrap: "break-word" }}
            >
              {currentTenant && currentTenant.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          {(tenantList.length > 0 ||
            permissions?.indexOf(switchAccPermission) !== -1) && (
            <AccountSwitcher
              accountList={tenantList}
              currentTenant={currentTenant}
              handleAccountSwitch={switchTenantHandler}
              fetchOnDemand={permissions?.indexOf(switchAccPermission) !== -1}
            />
          )}
        </Grid>
      </CardContent>

      <CardActions
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "dark" ? "#393939" : "#ffffff",
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          borderTopColor: theme.palette.mode === "dark" ? "#5e5e5e" : "#a6a5a5",
        })}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button size="small" onClick={() => dispatch(openNewAppDialog())}>
              <AddIcon color="primary" />
              Create New Application
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
AccountCard.propTypes = {
  tenantList: PropTypes.oneOfType([PropTypes.array]),
  currentTenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
  switchTenantHandler: PropTypes.func.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
AccountCard.defaultProps = {
  tenantList: [],
};
export default AccountCard;
