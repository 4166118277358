import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-unresolved
import SwitchAccountBanner from "@regional/templates/Application/switchAccountBanner";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ApplicationIcon from "@mui/icons-material/DescriptionOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";

import DialogPopup from "../../../../components/DialogPopup";
import RadioButtonsGroup from "../../../../components/RadioGroup";
import LocalLoader from "../../../../components/Loaders/Local";
import { closeNewAppDialog } from "../../slices/newApplication.slice";
import { useProgramConfigQuery } from "../../../../api/core.api.slice";
import { showNotification } from "../../slices/notification.slice";
import { notifyError } from "../../../../utils/helpers";
import {
  getApplicationTypeTags,
  getFilteredAppTypes,
  getUpdatedAppTypes,
} from "../../../../services/application.service";
import { methodRefs } from "../../../../components/Form/context/formSlice";
import useUpdatePanelHeight from "../../../../components/Form/hooks/useUpdatePanelHeight";

export const StyledDiv = styled(Box)(() => ({
  "& .MuiAlert-root": {
    "& .MuiAlert-icon": { display: "none" },
    justifyContent: "center",
  },
  "& .icon": { fontSize: "1.2rem", verticalAlign: "sub", marginRight: "5px" },
}));

function NewApplication({ open }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { tenantData: currentTenant } = useSelector((state) => state.auth);
  const { updateHeight } = useUpdatePanelHeight();
  const {
    data: programConfig,
    error: programConfigError,
    isLoading,
  } = useProgramConfigQuery();

  const [filteredSubTypes, setFilteredSubTypes] = useState(null);
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedAppType, setSelectedAppType] = useState(null);

  const { register, handleSubmit, control } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (programConfig?.recordTypes?.length && currentTenant?.type) {
      const filteredAppTypes = getFilteredAppTypes(
        programConfig.recordTypes,
        currentTenant.type
      );
      const result = getApplicationTypeTags(filteredAppTypes);
      if (result !== null) setFilteredSubTypes(result);

      const updatedAppTypes = getUpdatedAppTypes(filteredAppTypes);
      setFilteredTypes(updatedAppTypes);
    }
  }, [programConfig, currentTenant]);

  useEffect(() => {
    const errorObj = programConfigError;
    if (errorObj) {
      notifyError(dispatch, showNotification, errorObj);
    }
  }, [programConfigError, dispatch]);

  const onTagSelection = (e) => {
    const { value } = e.target;
    const { recordTypes } = programConfig;
    setSelectedTag(value);
    if (value === "all" && recordTypes?.length) {
      const result = getFilteredAppTypes(recordTypes, currentTenant.type);
      const updatedAppTypes = getUpdatedAppTypes(result);
      setFilteredTypes(updatedAppTypes);
    } else if (recordTypes?.length) {
      const applicationTypes = recordTypes.filter((type) =>
        type?.tags?.includes(value)
      );
      if (applicationTypes.length) {
        const updatedAppTypes = getUpdatedAppTypes(applicationTypes);
        setFilteredTypes(updatedAppTypes);
      } else setFilteredTypes([]);
    }
  };

  const handleCreateApplication = async (formData) => {
    const { applicationType } = formData;
    const url = `applications/${applicationType}/new`;
    if (pathname === `/${url}`) {
      methodRefs?.formMethods?.reset({});
      setTimeout(() => updateHeight(), 400);
    }
    navigate(url, { replace: true });
    dispatch(closeNewAppDialog());
  };

  const modalContent = (
    <form onSubmit={(e) => e.preventDefault()}>
      <StyledDiv>
        <Grid container justifyContent="center" sx={{ position: "relative" }}>
          <Grid item xs={12} sm={7.5} md={7}>
            <Alert role="definition" tabIndex={0} severity="info" elevation={2}>
              <Typography align="center">
                You are creating a new application for:{" "}
                <b>{currentTenant?.name}</b>
              </Typography>
            </Alert>
          </Grid>
          {filteredSubTypes && (
            <Grid item container xs={12} sm={6} md={8} sx={{ my: "2em" }}>
              <FormControl variant="standard" fullWidth required>
                <InputLabel id="select-subTypes-label">I am a</InputLabel>
                <Select
                  labelId="select-subTypes-label"
                  id="select-standard"
                  value={selectedTag}
                  onChange={onTagSelection}
                  label="I am a"
                >
                  {filteredSubTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  <MenuItem value="all">Show All</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {(!filteredSubTypes || selectedTag) && (
            <Grid item xs={12} sm={10}>
              <RadioButtonsGroup
                name="applicationType"
                radioButtonList={filteredTypes}
                register={register}
                control={control}
                onItemChange={setSelectedAppType}
                icon={ApplicationIcon}
                required
              />
            </Grid>
          )}
          <Grid item xs={12} sm={7.5} md={6}>
            {selectedAppType?.freezeType === "Partial" &&
              selectedAppType?.freezeMessage && (
                <Alert
                  role="definition"
                  tabIndex={0}
                  severity="error"
                  elevation={2}
                  sx={{ mb: "1rem" }}
                >
                  <Typography align="center">
                    <WarningIcon className="icon" />
                    {selectedAppType.freezeMessage}
                  </Typography>
                </Alert>
              )}
            <SwitchAccountBanner />
          </Grid>
          <LocalLoader progress={isLoading} />
        </Grid>
      </StyledDiv>
    </form>
  );

  const buttonArray = [
    {
      id: 1,
      name: "Create Application",
      variant: "contained",
      onClickHandler: handleSubmit(handleCreateApplication),
      show: true,
      disabled: !selectedAppType,
    },
  ];
  return (
    <DialogPopup
      title="New Application"
      content={modalContent}
      buttonArray={buttonArray}
      openPopup={open}
      onCancel={() => dispatch(closeNewAppDialog())}
      showActionBtn
      maxWidth="md"
    />
  );
}
NewApplication.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default NewApplication;
