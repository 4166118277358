/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormState, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { getParsedValue } from "../services/form.service";
import { showFormDialog } from "../context/dialogSlice";

function FormSwitchField(props) {
  const {
    fieldProps,
    formProps,
    control,
    disabled,
    resetHandler,
    trigger,
    setValue,
    getValues,
  } = props;
  const { key, label, defaultValue, validate, tags } = fieldProps;

  const { errors } = useFormState({ control, name: key });
  const dispatch = useDispatch();

  const booleanMap = {
    false: false,
    true: true,
  };

  const initialValue = booleanMap[defaultValue] || false;

  const defaultChecked =
    formProps[key] === undefined ? initialValue : formProps[key];

  useEffect(() => {
    if (validate?.custom) {
      const condition = getParsedValue(validate?.custom, key);
      if (condition?.selfReset && getValues(condition?.selfReset) === true) {
        setValue(key, "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      fullWidth
      margin="dense"
      required={validate?.required}
      sx={(theme) => ({
        height: "75px",
        "& .MuiFormHelperText-root": {
          marginLeft: 0,
        },
        "& .MuiFormLabel-root": {
          color:
            theme.palette.mode === "light"
              ? "#000 !important"
              : "rgba(255, 255, 255, 0.7)",
          fontSize: "0.88rem",
          "&.Mui-focused": {
            color:
              theme.palette.mode === "light"
                ? "#000 !important"
                : "rgba(255, 255, 255, 0.7)",
          },
        },
      })}
    >
      <Controller
        name={key}
        control={control}
        defaultValue={defaultChecked}
        render={({ field: { value, onChange, ...field } }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <FormLabel>{label} </FormLabel>
              {tags && tags.includes("proTip") && (
                <IconButton
                  aria-label="Pro Tip"
                  onClick={() => {
                    dispatch(
                      showFormDialog({
                        show: true,
                        fieldKey: key,
                        type: "proTip",
                      })
                    );
                  }}
                >
                  <InfoTwoToneIcon
                    fontSize="small"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
              )}
            </Box>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    onChange(e.target.checked);
                    resetHandler();
                    trigger(key);
                  }}
                  checked={!!value}
                />
              }
              // ref={ref}
              {...field}
              label={value ? "Yes" : "No"}
              disabled={disabled}
            />
          </Box>
        )}
        rules={{ required: validate?.required }}
      />
      {errors[key] && (
        <FormHelperText error id="{fieldProps.key}-text">
          This is required.
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormSwitchField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,

  control: PropTypes.shape({}).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default FormSwitchField;
