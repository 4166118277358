import React from "react";
import { Box } from "@mui/material";
import { BannerStyled } from "./styles";
import FeesTable from "./FeesTable";
import MailPaymentBanner from "./MailPaymentBanner";

function PaymentTable() {
  return (
    <Box>
      <BannerStyled>
        <MailPaymentBanner />
      </BannerStyled>
      <FeesTable />
    </Box>
  );
}

export default PaymentTable;
