/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import DialogPopup from "../../../../components/DialogPopup";

function FixtureDialogPopup({ isOpen, type, onClose, onOk }) {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm({ mode: "onChange" });

  const handleOnOk = (data) => {
    onOk(data?.fixtureName || "");
    onClose();
  };

  const createFixtureForm = (
    <form
      style={{ padding: "1rem 0.5rem", minHeight: "200px" }}
      onSubmit={handleSubmit(handleOnOk)}
    >
      <TextField
        label="Fixture Name"
        variant="standard"
        aria-describedby="text field"
        autoComplete="off"
        fullWidth
        required
        {...register("fixtureName", { required: true })}
      />
      {errors.fixtureName && (
        <FormHelperText error id="fixtureName-text" sx={{ margin: 0 }}>
          This is required.
        </FormHelperText>
      )}
    </form>
  );

  const createDialogPopupProps = {
    title: "Add Fixture",
    showTitle: true,
    buttonArray: [
      {
        id: 1,
        name: "Create New Fixtue",
        icon: undefined,
        variant: "contained",
        onClickHandler: handleSubmit(handleOnOk),
        show: true,
        disabled: Boolean(errors.fixtureName) || !isDirty,
      },
    ],
    content: createFixtureForm,
  };
  const updateDialogPopupProps = {
    title: "Are you sure you want to overwrite data to this fixture?",
    onOk: () => handleOnOk("update"),
  };

  const deleteDialogPopupProps = {
    title: "Are you sure you want to delete this fixture?",
    onOk: () => handleOnOk("delete"),
  };

  useEffect(() => {
    if (!isOpen) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isOpen]);

  return (
    <DialogPopup
      showTitle={false}
      openPopup={isOpen}
      maxWidth="sm"
      onCancel={onClose}
      showActionBtn
      {...(type === "create" && createDialogPopupProps)}
      {...(type === "update" && updateDialogPopupProps)}
      {...(type === "delete" && deleteDialogPopupProps)}
    />
  );
}

export default FixtureDialogPopup;
