import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import ListItemIcon from "@mui/material/ListItemIcon";
import PasswordKeyIcon from "@mui/icons-material/VpnKey";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";

import classes from "./header.module.css";
import { logout } from "../../../auth/slices/auth.slice";
import { CustomThemeContext } from "../../../../theme/CustomThemeProvider";

function UserMenu({ toggleHelpDialog }) {
  const { userData: user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { darkMode, setTheme } = useContext(CustomThemeContext);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const closeDropdown = () => {
    setOpen(false);
  };
  const updateMode = (e) => {
    setTheme(!darkMode);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleListKeyDown = useCallback(
    (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpen(false);
      }
    },
    [setOpen]
  );
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        color="primary"
        size="medium"
        className={classes.userMenu}
        variant="outlined"
        fullWidth
        endIcon={<ArrowDropDownCircleIcon className={classes.menuIcon} />}
        onClick={handleToggle}
        name="userMenu"
      >
        {user && user.firstName && (
          <Typography color="textSecondary">{user.firstName}</Typography>
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.userMenuList}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={(e) => {
                      navigate("/change-password", { replace: true });
                      handleClose(e);
                    }}
                  >
                    <ListItemIcon>
                      <PasswordKeyIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Change Password</Typography>
                  </MenuItem>
                  <Hidden smUp>
                    <MenuItem
                      onClick={() => {
                        toggleHelpDialog();
                        closeDropdown();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {process.env.REACT_APP_COMPLIA_INSTALL === "nj-crc"
                          ? "Help/FAQ"
                          : "Help"}
                      </Typography>
                    </MenuItem>
                  </Hidden>
                  <MenuItem onClick={updateMode}>
                    <ListItemIcon>
                      <SettingsBrightnessIcon
                        color="primary"
                        fontSize="small"
                      />
                    </ListItemIcon>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            name="darkMode"
                            checked={darkMode}
                            onChange={updateMode}
                          />
                        }
                        label="Dark Mode"
                        onClick={updateMode}
                      />
                    </FormGroup>
                  </MenuItem>
                  <MenuItem onClick={() => dispatch(logout())}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Logout</Typography>
                  </MenuItem>
                  <MenuItem onClick={closeDropdown}>
                    <Button color="primary" className={classes.closeBtn}>
                      Close
                    </Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
UserMenu.propTypes = {
  toggleHelpDialog: PropTypes.func.isRequired,
};

export default UserMenu;
