import React, { createContext, useMemo, useReducer, useContext } from "react";
import PropTypes from "prop-types";

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return { ...state };
  }
};
const defaultState = { isReviewItem: true };
const ReviewContext = createContext(defaultState);
function ReviewPropsProvider(props) {
  const { initialState, children } = props;
  const reviewState = initialState || defaultState;
  const [reviewProps, dispatch] = useReducer(reducer, reviewState);
  const obj = useMemo(
    () => ({
      reviewProps,
      dispatch,
    }),
    [reviewProps, dispatch]
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ReviewContext.Provider value={obj}>{children}</ReviewContext.Provider>
  );
}
ReviewPropsProvider.propTypes = {
  initialState: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node.isRequired,
};
ReviewPropsProvider.defaultProps = {
  initialState: null,
};

function useReviewContextValue() {
  return useContext(ReviewContext);
}

function useReviewPageCheck() {
  const { reviewProps } = useContext(ReviewContext);
  return Boolean(reviewProps);
}

export {
  ReviewContext,
  ReviewPropsProvider,
  useReviewContextValue,
  useReviewPageCheck,
};
