import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithInterceptors } from "./api";

// initialize an empty api service that we'll inject endpoints into later as needed
export default createApi({
  baseQuery: baseQueryWithInterceptors,
  tagTypes: [
    "BulkLicenses",
    "Licenses",
    "License",
    "Applications",
    "Application",
    "Invoices",
    "Invoice",
    "Tenants",
    "Tenant",
    "IamUser",
    "Accounts",
    "Config",
    "Fixtures",
    "Documents",
  ],
  endpoints: () => ({}),
});
