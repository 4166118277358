import { createSlice, current } from "@reduxjs/toolkit";
import { getSimpleForm, resetOnChange } from "../services/form.service";

export const requireFieldsInfo = {
  requiredFields: {},
  displayedFields: { form: [] },
  subLicensesProps: [],
  cloneKeys: [],
  actionPanels: {},
  actionPanelFields: {},
  preSubmitActions: {},
};
export const methodRefs = {};
const initialState = {
  formData: {},
  formConfig: {},
  applicationData: {},
  formDocuments: {
    documents: {},
    dgDocuments: {},
  },
  documentList: [],
  dgDocumentList: [],
};

const formSlice = createSlice({
  name: "formProps",
  initialState,
  reducers: {
    appendDocumentList(state, action) {
      (action.payload.documents || []).forEach((_document) => {
        const existingIndex = state.dgDocumentList.findIndex(
          (doc) => doc.versionSeriesId === _document.versionSeriesId
        );
        if (existingIndex !== -1)
          state.dgDocumentList[existingIndex] = _document;
        else state.dgDocumentList.push(_document);
      });
    },
    updateFormProps(state, action) {
      const { formData, formConfig, applicationData } = action.payload;
      state.formData = formData;
      state.formConfig = formConfig;
      state.applicationData = applicationData;
      state.documentList = applicationData?.formDocuments || [];
      state.dgDocumentList = applicationData?.formDocuments || [];
    },
    updateFormFields(_state, action) {
      const { form, type, field, datagridKey, recordId } = action.payload;
      if (form) {
        const simpleForm = getSimpleForm(form);
        requireFieldsInfo.requiredFields = simpleForm.requiredFields;
        requireFieldsInfo.subLicensesProps = simpleForm.subLicensesProps;
        requireFieldsInfo.cloneKeys = simpleForm.cloneKeys;
        requireFieldsInfo.actionPanels = simpleForm.actionPanels;
        requireFieldsInfo.actionPanelFields = simpleForm.actionPanelFields;
        requireFieldsInfo.preSubmitActions = simpleForm.preSubmitActions;
      }
      if (type === "reset") {
        requireFieldsInfo.requiredFields = {};
        requireFieldsInfo.displayedFields = { form: [] };
        requireFieldsInfo.subLicensesProps = [];
        requireFieldsInfo.cloneKeys = [];
        requireFieldsInfo.actionPanels = {};
        requireFieldsInfo.actionPanelFields = {};
        requireFieldsInfo.preSubmitActions = {};
      }

      if (!field || !type) return;

      let _fields = requireFieldsInfo.displayedFields;
      if (datagridKey) {
        if (!requireFieldsInfo.displayedFields[datagridKey])
          requireFieldsInfo.displayedFields[datagridKey] = {};
        if (!requireFieldsInfo.displayedFields[datagridKey][recordId])
          requireFieldsInfo.displayedFields[datagridKey][recordId] = [];
        _fields = requireFieldsInfo.displayedFields[datagridKey][recordId];
      } else {
        _fields = requireFieldsInfo.displayedFields.form;
      }

      if (type === "add" && !_fields.includes(field.key))
        _fields.push(field.key);

      if (type === "delete") {
        const index = _fields.indexOf(field.key);
        if (index > -1) _fields.splice(index, 1);
      }
    },
    updateMethodRef(state, action) {
      const { key, methodRef } = action.payload;
      if (!methodRefs[key]) methodRefs[key] = methodRef;
      if (key === "applicationDetailUpdateForm" || key === "formMethods")
        methodRefs[key] = methodRef;
      else {
        state[key] = true;
      }
    },
    appendDg(state, action) {
      const { gridKey, value = [] } = action.payload;
      if (gridKey) state.formData[gridKey] = value;
    },
    updateDatagridRecords(state, action) {
      const { key, value, config } = action.payload;
      if (!state[key]) {
        state[key] = [];
        state[`${key}Config`] = config;
      }
      state[key] = value;
      // loop through current dg array and filter the methodRefs based the current record id
      if (value && value.length) {
        const recordIds = value.map((record) => record.id);
        Object.keys(methodRefs).forEach(
          (methodRefKey) =>
            methodRefKey.startsWith(key) &&
            !recordIds.includes(methodRefKey.replace(key, "")) &&
            delete methodRefs[methodRefKey]
        );
      }
    },
    updateFormDocuments(state, action) {
      const { key, value, dgRecordId, type, requiredDocuments } =
        action.payload;
      if (dgRecordId) {
        if (!state.formDocuments.dgDocuments[dgRecordId]) {
          state.formDocuments.dgDocuments[dgRecordId] = {};
          state.formDocuments.dgDocuments[dgRecordId][key] = [];
        }
        if (type === "delete" && requiredDocuments) {
          state.dgDocumentList = requiredDocuments;
        }
        state.formDocuments.dgDocuments[dgRecordId][key] = value;
      } else {
        if (type === "delete" && requiredDocuments) {
          state.documentList = requiredDocuments;
        }
        state.formDocuments.documents[key] = value;
      }
    },
    resetFormData(state, action) {
      const { condition, setValue, trigger, key, getValues } = action.payload;
      resetOnChange({
        condition,
        formProps: current(state),
        setValue,
        methodRefs,
        trigger,
        key,
        getValues,
      });
    },
    resetFormPropsAndMethodRefs(state) {
      const currentState = current(state);
      const methodRefProps = Object.keys(currentState).filter(
        (prop) => currentState[prop] === true
      );
      if (methodRefProps.length) {
        methodRefProps.forEach((prop) => delete methodRefs[prop]);
      }
      return initialState;
    },
  },
});

export const {
  appendDocumentList,
  updateFormProps,
  updateFormFields,
  updateMethodRef,
  appendDg,
  updateDatagridRecords,
  updateFormDocuments,
  resetFormData,
  resetFormPropsAndMethodRefs,
} = formSlice.actions;
export default formSlice.reducer;
