import React from "react";
import Divider from "@mui/material/Divider";

function DgDivider() {
  const getStyles = (theme) => ({
    position: "relative",
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.mode === "light" ? "#ebbc62" : "#CF9C4C",
    margin: "0.5rem 0",
  });

  return <Divider sx={(theme) => getStyles(theme)} />;
}

export default DgDivider;
