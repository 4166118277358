import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RotateLeft from "@mui/icons-material/RotateLeft";
import RotateRight from "@mui/icons-material/RotateRight";

import getCroppedImg from "./imageCropUtil";
import StyledBox from "./styles";

function ImageCropper({ file, setFile }) {
  const { preview: image, name } = file;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [initialZoom, setInitialZoom] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedImage, setCroppedImage] = useState(image);

  const cropSize = { width: 180, height: 180 };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      try {
        const croppedImg = await getCroppedImg(
          image,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImg);
        setFile({ preview: image, name, blobUrl: croppedImg });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("getCroppedImg", e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rotation, file]
  );

  const handleCropChange = (cropVal) => {
    setCrop(cropVal);
  };

  const handleZoomChange = (zoomVal) => {
    setZoom(zoomVal);
  };

  const handleRotationChange = (angle) => {
    setRotation(angle);
  };

  const onMediaLoaded = useCallback((mediaSize) => {
    // calculate the aspect ratio of the media and the crop area
    const mediaAspectRatio = mediaSize.width / mediaSize.height;
    const cropAspectRatio = 180 / 180;
    let newZoom = 1;

    // adjust the zoom based on whether the media is wider or taller than the crop area
    if (mediaAspectRatio > cropAspectRatio) {
      // media is wider than crop area
      newZoom = 180 / mediaSize.height;
    } else {
      // media is taller than crop area
      newZoom = 180 / mediaSize.width;
    }

    setZoom(newZoom);
    setInitialZoom(newZoom);
  }, []);

  return (
    <StyledBox>
      <Box className="cropContainer">
        <Box className="cropperWrapper">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            showGrid={false}
            rotation={rotation}
            cropSize={cropSize}
            minZoom={initialZoom}
            maxZoom={initialZoom + 1}
            onCropChange={handleCropChange}
            onZoomChange={handleZoomChange}
            onCropComplete={onCropComplete}
            onMediaLoaded={onMediaLoaded}
          />
        </Box>
        <Box className="sliderWrapper">
          <Slider
            size="small"
            value={zoom}
            min={initialZoom}
            max={initialZoom + 1}
            step={0.05}
            onChange={(e, zoomVal) => handleZoomChange(zoomVal)}
            aria-labelledby="Zoom"
          />
        </Box>
        <Box className="controls">
          <IconButton
            onClick={() => handleRotationChange(rotation - 90)}
            aria-label="Rotate Left"
          >
            <RotateLeft />
          </IconButton>
          <IconButton
            onClick={() => handleRotationChange(rotation + 90)}
            aria-label="Rotate Right"
          >
            <RotateRight />
          </IconButton>
        </Box>
      </Box>
      <Box className="previewContainer">
        <Typography>Preview</Typography>
        <img src={croppedImage} alt="Cropper Preview" />
      </Box>
    </StyledBox>
  );
}

ImageCropper.propTypes = {
  file: PropTypes.shape({
    preview: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  setFile: PropTypes.func.isRequired,
};

export default ImageCropper;
