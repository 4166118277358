/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  type: "",
};
const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog(state, { payload }) {
      state.show = true;
      state.type = payload.type;
    },
    closeDialog(state) {
      state.show = initialState.show;
      state.type = initialState.type;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
