/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { Box, Tooltip } from "@mui/material";

const accordion = {
  ".accordion .MuiAccordionSummary-root": {
    "&.Mui-expanded": {
      margin: "2px",
      minHeight: "unset",
    },
    borderBottom: "3px solid #43b73f",
    borderTop: "3px solid #43b73f",
    minHeight: "unset",
    "& .MuiAccordionSummary-content": {
      margin: "0rem",
      "&.Mui-expanded": { margin: "0rem" },
    },
  },
};
export default function AlertDialog(props) {
  const {
    openPopup,
    onCancel,
    title,
    content,
    buttonArray,
    onOk,
    icon: Icon,
    maxWidth,
    paperWidth,
    showTitle,
    showActionBtn,
    height,
    hideIcon,
    customBgColor,
    progress,
  } = props;

  const defaultButtons = [
    {
      id: 1,
      name: "No",
      icon: undefined,
      variant: "contained",
      onClickHandler: onCancel,
      show: true,
      disabled: progress,
    },
    {
      id: 2,
      name: "Yes",
      icon: undefined,
      variant: "contained",
      onClickHandler: onOk,
      show: true,
      disabled: progress,
    },
  ];

  const actionButtons = buttonArray.length > 0 ? buttonArray : defaultButtons;
  const bgColor = (theme) => {
    if (!showTitle) return "transparent";
    if (theme.palette.mode === "light") {
      if (customBgColor) return customBgColor.header;
      return theme.palette.primary.light;
    }
    if (customBgColor) return customBgColor.header;
    return theme.palette.primary.dark;
  };
  return (
    <Dialog
      open={openPopup}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={maxWidth}
      PaperProps={{
        sx: (theme) => ({
          ...accordion,
          width: theme.breakpoints.down("sm") ? "90%" : paperWidth,
          height,
          backgroundColor: customBgColor ? customBgColor.body : undefined,
        }),
        tabIndex: showTitle ? 0 : -1,
      }}
    >
      <div
        role="alert"
        aria-label="Dialog opened click tab to navigate between Dialog"
      />
      {title && (
        <Box
          sx={(theme) => ({
            backgroundColor: bgColor(theme),
            mb: showTitle ? 2 : 0,
            color: customBgColor ? "#000" : undefined,
            pr: 3,
            display: "flex",
            alignItems: "center",
          })}
        >
          <Tooltip title={title}>
            <DialogTitle
              sx={{
                width: "90%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                minHeight: "25px",
              }}
            >
              {Icon}
              {title}
            </DialogTitle>
          </Tooltip>
          {showTitle && (
            <IconButton
              aria-label="Close dialog"
              onClick={onCancel}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
                display: hideIcon,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
      {content && (
        <DialogContent
          id="dialog-description"
          sx={{ py: "0.8em", px: "0.8em", minHeight: "3rem" }}
        >
          {content}
        </DialogContent>
      )}
      <Divider aria-hidden="true" />
      {(showActionBtn || !showTitle) && (
        <DialogActions
          sx={(theme) => ({
            my: 1,
            "& .MuiButton-startIcon>*": {
              fontSize: "1.7em",
              mr: "-0.2em",
            },
            [theme.breakpoints.down("sm")]: {
              justifyContent: "center",
              flexDirection: "column",
              "& .MuiButton-root": {
                minWidth: "100%",
                mx: "0px",
              },
              "& .MuiButton-root:first-of-type": {
                mb: 1.5,
              },
            },
          })}
        >
          {actionButtons
            .filter((item) => item.show)
            .map(({ id, name, icon, variant, onClickHandler, disabled }) => (
              <Button
                key={id}
                onClick={onClickHandler || undefined}
                variant={variant}
                startIcon={icon}
                disabled={disabled || undefined}
              >
                {name}
              </Button>
            ))}
        </DialogActions>
      )}
    </Dialog>
  );
}

AlertDialog.propTypes = {
  progress: PropTypes.bool,
  openPopup: PropTypes.bool,
  title: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonArray: PropTypes.oneOfType([PropTypes.array]),
  icon: PropTypes.oneOfType([PropTypes.element]),
  maxWidth: PropTypes.string,
  paperWidth: PropTypes.string,
  showTitle: PropTypes.bool,
  showActionBtn: PropTypes.bool,
  height: PropTypes.string,
  hideIcon: PropTypes.string,
  customBgColor: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
  }),
};
AlertDialog.defaultProps = {
  progress: false,
  openPopup: false,
  title: "",
  content: "",
  onCancel: () => {},
  onOk: () => {},
  buttonArray: [],
  maxWidth: "sm",
  paperWidth: "60%",
  showTitle: true,
  showActionBtn: false,
  height: undefined,
  hideIcon: undefined,
  customBgColor: undefined,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  icon: <></>,
};
