import React, { useReducer, useContext, useMemo } from "react";
import PropTypes from "prop-types";

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      if (action.payload.value === state[action.payload.key]) {
        return state;
      }
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return { ...state };
  }
};
const defaultState = {
  record: null,
  records: null,
  formMethods: null,
  isEditable: true,
};
const DatagridContext = React.createContext(defaultState);
function DatagridPropsProvider(props) {
  const { initialState, children } = props;
  const formState = initialState || defaultState;
  const [datagridFormState, dispatch] = useReducer(reducer, formState);
  const obj = useMemo(
    () => ({
      datagridFormState,
      dispatch,
    }),
    [datagridFormState, dispatch]
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DatagridContext.Provider value={obj}>{children}</DatagridContext.Provider>
  );
}
DatagridPropsProvider.propTypes = {
  initialState: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node.isRequired,
};
DatagridPropsProvider.defaultProps = {
  initialState: {},
};

function useDatagridContextValue() {
  return useContext(DatagridContext);
}

function useGetDgKey() {
  const { datagridFormState } = useContext(DatagridContext);
  return datagridFormState?.datagridKey;
}
export {
  useDatagridContextValue,
  DatagridPropsProvider,
  DatagridContext,
  useGetDgKey,
};
