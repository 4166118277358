import React from "react";
import { useWatch } from "react-hook-form";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import { getSourceRecordName } from "../../services/form.relationalDg.service";

function CategoryTitle(props) {
  const { dgConfigRef, identifier, associatedDgLabel, recordCount } = props;
  const { sourceDatagrid, identifierLabel } = dgConfigRef?.current || {};
  const sourceDg = useWatch({
    name: sourceDatagrid || null,
    disabled: sourceDatagrid === undefined,
    defaultValue: [],
  });

  let label;
  if (Array.isArray(sourceDg) && identifierLabel) {
    const sourceRecordName = getSourceRecordName(
      sourceDg,
      dgConfigRef?.current || {},
      identifier
    );
    if (sourceRecordName && associatedDgLabel) {
      label = `${sourceRecordName}'s ${associatedDgLabel} (${recordCount})`;
    }
  }

  if (!label) return null;
  return (
    <Typography variant="h6" color="primary" className="categoryTitle">
      {label}
    </Typography>
  );
}

export default CategoryTitle;
CategoryTitle.propTypes = {
  dgConfigRef: PropTypes.shape({ current: PropTypes.shape({}) }).isRequired,
  identifier: PropTypes.string.isRequired,
  associatedDgLabel: PropTypes.string.isRequired,
  recordCount: PropTypes.number.isRequired,
};
