import { useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

let updateHeightMethodRef = "";

const useUpdatePanelHeight = () => {
  const updatePanelHeight = useCallback(() => {
    if (updateHeightMethodRef) {
      updateHeightMethodRef();
    }
  }, []);

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      updatePanelHeight();
    }, 100);

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [updatePanelHeight]);

  const setUpdateHeightMethodRef = (updateHeight) => {
    if (updateHeight) {
      updateHeightMethodRef = updateHeight;
    }
  };
  const updateHeight = () => {
    if (updateHeightMethodRef) {
      updateHeightMethodRef();
    }
  };
  return { setUpdateHeightMethodRef, updateHeight };
};

export default useUpdatePanelHeight;
