import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import SignInIcon from "@mui/icons-material/Input";
import ForgotPwdIcon from "@mui/icons-material/LockOpen";
import RenewIcon from "@mui/icons-material/Autorenew";

import TermsConditionsCheckBox from "../../features/auth/components/TermsConditionsLink";
import ReCaptchaComp from "../../features/auth/components/ReCaptcha";
import Form from "../../components/Form/Form";
import FormAlert from "../../components/Form/Field/FormAlert";
import { RegisterFormSkeleton } from "../../components/Skeleton";
import LocalLoader from "../../components/Loaders/Local";
import StatusPage from "./StatusPage";

import { notifyError } from "../../utils/helpers";
import { showNotification } from "../../features/core/slices/notification.slice";
import { fieldsVerified } from "../../components/Form/services/form.service";
import {
  useCaptchaConfigMutation,
  useRegisterConfigQuery,
  useRegisterFormQuery,
  useRegisterMutation,
} from "../../api/auth.api.slice";
import Styled from "./styles";

const warningMsg = `WARNING: Please be sure that the information provided during registration is
    100% accurate. This data will be used in your application, and you CANNOT
    modify this information after you register.`;

export const errorMap = {
  common:
    "Please make sure you fill all required fields & accept terms to proceed.",
  termsConditions: "Please make sure you accept terms to proceed.",
  captchaToken: `Please check the "I am not a robot" checkbox`,
};

function RegisterPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const methods = useForm({ mode: "onBlur" });

  const [captchaConfigData, setCaptchaConfigData] = useState({});
  const [regAccSuccess, setRegAccSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const {
    data: configData,
    error: configError,
    isFetching: configLoading,
  } = useRegisterConfigQuery();
  const {
    data: registerFormConfig,
    error: formError,
    isFetching,
  } = useRegisterFormQuery();

  const [captchaConfig, { isLoading: captchaLoading, error: captchaError }] =
    useCaptchaConfigMutation();
  const [registerAcc, { isLoading }] = useRegisterMutation();
  const { control, handleSubmit } = methods;

  const initialState = {
    isAddAccountPage: false,
    isBusinessAccount: false,
  };

  useEffect(() => {
    const getCaptchaConfig = async () => {
      try {
        const data = await captchaConfig().unwrap();
        if (data) {
          setCaptchaConfigData(data);
        }
      } catch (err) {
        notifyError(dispatch, showNotification, err);
      }
    };
    getCaptchaConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = (error) => {
    const errItem = Object.keys(error).length === 1;
    if (errItem && error.termsConditions) setErrorMsg(errorMap.termsConditions);
    else if (errItem && error.captchaToken) setErrorMsg(errorMap.captchaToken);
    else setErrorMsg(errorMap.common);
  };

  useEffect(() => {
    if (formError || captchaError) {
      const errorObj = formError || captchaError || configError;
      notifyError(dispatch, showNotification, errorObj);
    }
  }, [dispatch, formError, captchaError, configError]);

  const onSubmit = async (data) => {
    const errorText = fieldsVerified(data, "register");
    if (errorText) setErrorMsg(errorText);
    else {
      setErrorMsg("");
      const body = Object.keys(data).reduce(
        (acc, cur) =>
          cur !== "termsConditions" && cur !== "captchaToken"
            ? { ...acc, [cur]: data[cur] }
            : acc,
        {}
      );
      const payload = {
        captchaToken: captchaConfigData.captchaRegister
          ? data.captchaToken
          : "",
        body,
      };
      try {
        const res = await registerAcc(payload).unwrap();
        if (res) setRegAccSuccess(true);
      } catch (error) {
        notifyError(dispatch, showNotification, error);
      }
    }
  };

  const warningMessageElem = (
    <span style={{ fontWeight: 600, fontSize: "0.9rem" }}>
      {configData?.warningMessage || warningMsg}
    </span>
  );

  const memoForm = useMemo(
    () =>
      registerFormConfig &&
      registerFormConfig.components &&
      registerFormConfig.components.length > 0 ? (
        <Form
          formTemplate={registerFormConfig}
          methods={methods}
          applicationData={initialState}
        />
      ) : (
        ""
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerFormConfig]
  );

  const registerFormLayout =
    registerFormConfig &&
    registerFormConfig.components &&
    registerFormConfig.components.length > 0 ? (
      <Styled>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justifyContent="center"
          sx={{ position: "relative" }}
        >
          <Grid
            item
            xs={11}
            sm={11}
            md={7}
            sx={{ my: 4, position: "relative" }}
          >
            <Card raised>
              <CardHeader
                title={configData?.heading || "Register"}
                className="cardHeader"
                tabIndex="0"
              >
                Register
              </CardHeader>
              <Grid container justifyContent="center" sx={{ mt: "1.5rem" }}>
                <Grid item xs={11}>
                  <FormAlert severity="warning" message={warningMessageElem} />
                </Grid>
              </Grid>
              <CardContent>{memoForm}</CardContent>
              <CardActions disableSpacing sx={{ flexDirection: "column" }}>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <TermsConditionsCheckBox
                    register={methods.register}
                    type="register"
                  />
                </Grid>
                {captchaConfigData?.captchaRegister && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ReCaptchaComp
                      name="captchaToken"
                      control={control}
                      theme={theme.palette.mode}
                      error={false}
                    />
                  </Grid>
                )}
                <Grid item container className="btnContainer">
                  <Button variant="contained" component={Link} to="/login">
                    <SignInIcon />
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    <ForgotPwdIcon />
                    REGISTER
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  className="verifyLink"
                >
                  <Button
                    component={Link}
                    variant="text"
                    className="customLink"
                    to="/resendVerifyAccountEmail"
                  >
                    <RenewIcon />
                    {`If you didn't receive your verification email, please
                      click here.`}
                  </Button>
                </Grid>
              </CardActions>
              {errorMsg && (
                <Grid container justifyContent="center" className="errorItem">
                  <Grid item xs={11}>
                    <FormAlert
                      severity="error"
                      message={<span>{errorMsg}</span>}
                    />
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
          <LocalLoader
            progress={
              isLoading || isFetching || configLoading || captchaLoading
            }
          />
        </Grid>
      </Styled>
    ) : (
      <RegisterFormSkeleton />
    );

  if (window.location.hostname === "ar-dfa-public-sandbox.nls.egov.com") {
    return null;
  }

  return (
    <>
      {!formError && !regAccSuccess && registerFormLayout}
      {!formError && regAccSuccess && <StatusPage />}
    </>
  );
}
export default RegisterPage;
