/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import ReCaptcha from "react-google-recaptcha";
import { Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function ReCaptchaComp(props) {
  const { name, control, required, theme, error } = props;

  return (
    <Grid flexDirection="column" alignItems="center" justifyContent="center">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <ReCaptcha
            {...field}
            onChange={(captchaToken) => {
              onChange(captchaToken);
            }}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            theme={theme}
          />
        )}
        rules={{ required }}
      />
      {error && (
        <Typography variant="p" color="error" sx={{ mb: "1em" }}>
          {`Please check the "I am not a robot" checkbox`}
        </Typography>
      )}
    </Grid>
  );
}
ReCaptchaComp.propTypes = {
  name: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]).isRequired,
};
ReCaptchaComp.defaultProps = {
  required: true,
};

export default ReCaptchaComp;
