import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import PdfIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ZipIcon from "@mui/icons-material/FolderZipOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { documentIcon, getFileLink } from "../services/formDocument.service";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import { StyledTable } from "./styles";

const iconMap = {
  pdf: <PdfIcon sx={{ color: "red" }} />,
  zip: <ZipIcon sx={{ color: "yellow" }} />,
  file: <FileIcon />,
  excel: <FileIcon sx={{ color: "green" }} />,
};

function DocumentTable(props) {
  const { documents, customProperties, isLicenseForm, handleActionBtnClick } =
    props;
  const { domainName } = useSelector((state) => state.formProps.formConfig);
  const { updateHeight } = useUpdatePanelHeight();

  useLayoutEffect(() => {
    updateHeight();
    setTimeout(() => updateHeight(), 300);
    return () => {
      updateHeight();
      setTimeout(() => updateHeight(), 100);
    };
  }, [updateHeight]);

  const getLink = (document) => {
    const filePayload = {
      domainName,
      versionSeriesId: document.versionSeriesId,
      id: document._id,
    };
    const fileLink = getFileLink(filePayload);
    return fileLink;
  };

  return (
    <StyledTable>
      <TableContainer>
        <Table aria-label="document table">
          <TableHead>
            <TableRow>
              <TableCell className="visuallyHidden">Icon</TableCell>
              <TableCell className="actions">Actions</TableCell>
              {customProperties.length > 0 &&
                customProperties.map((item) => (
                  <TableCell key={item.key}>{item.label}</TableCell>
                ))}
              <TableCell>File Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow key={document._id}>
                <TableCell>
                  {iconMap[documentIcon(document.mimeType)]}
                </TableCell>
                <TableCell>
                  {!isLicenseForm && (
                    <>
                      <Tooltip title="Delete Document">
                        <IconButton
                          onClick={() =>
                            handleActionBtnClick("delete", document)
                          }
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Update this document">
                        <IconButton
                          onClick={() =>
                            handleActionBtnClick("update", document)
                          }
                        >
                          <UploadIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Download">
                    <IconButton
                      component={Link}
                      target="_blank"
                      href={getLink(document)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {customProperties.length > 0 &&
                  customProperties.map((item) => (
                    <TableCell key={item.key}>
                      {document.customProperties
                        ? document.customProperties[item.key]
                        : ""}
                    </TableCell>
                  ))}
                <TableCell>
                  <Button
                    sx={{ pointerEvents: "auto" }}
                    component={Link}
                    variant="text"
                    className="customLink"
                    onClick={() => handleActionBtnClick("preview", document)}
                    to="#"
                  >
                    {document.fileName}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledTable>
  );
}
DocumentTable.whyDidYouRender = true;

DocumentTable.propTypes = {
  customProperties: PropTypes.arrayOf(PropTypes.shape({})),
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLicenseForm: PropTypes.bool.isRequired,
  handleActionBtnClick: PropTypes.func.isRequired,
};
DocumentTable.defaultProps = {
  customProperties: [],
};
export default DocumentTable;
