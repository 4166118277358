import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TableIcon from "@mui/icons-material/CalendarViewMonth";
import DialogPopup from "../../../DialogPopup";
import DataTable from "../../../DataTable";
import { notifyError } from "../../../../utils/helpers";
import { isAuthenticated } from "../../../../services/auth.service";
import {
  useLicensesQuery,
  useSubLicensesMutation,
} from "../../../../api/licenses.api.slice";
import { showNotification } from "../../../../features/core/slices/notification.slice";
import LocalLoader from "../../../Loaders/Local";
import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";
import { useTableConfigQuery } from "../../../../api/applications.api.slice";

const config = {
  rowSelect: true,
  pagination: true,
  compareBy: "licenseNumber",
};

function SelectLicenseBtn(props) {
  const { fieldProps, setValue, trigger, disabled } = props;
  const { subLicensesKey } = fieldProps;
  const {
    licenseType,
    licenseListKey = "",
    sortBy,
    status,
    localPagination,
    limitRange,
    defaultLimit,
    disabledMultiSelect = false,
    showMappedValue = false,
    titleInsensitive = true,
    dialogTitle = "License Selection",
    selectableLicenses = "Approved,Expired",
  } = fieldProps.properties || {};

  if (localPagination) config.localPagination = true;
  if (limitRange) config.limitRange = limitRange.split(",").map(Number);
  if (defaultLimit) config.defaultLimit = Number(defaultLimit);
  // eslint-disable-next-line prefer-destructuring
  if (limitRange && !defaultLimit) config.defaultLimit = config.limitRange[0];
  if (disabledMultiSelect) config.maxSelectRow = 1;
  config.isRowSelectable = (row) => selectableLicenses.includes(row.status);

  const initialReq = { limit: 5, recordType: licenseType };
  if (status) initialReq.status = status;
  if (sortBy) initialReq.sort = sortBy;
  if (config.defaultLimit) initialReq.limit = config.defaultLimit;
  if (localPagination) initialReq.limit = "Infinity";
  if (titleInsensitive) initialReq.titleInsensitive = true;

  const dispatch = useDispatch();
  const dataTableRef = useRef(null);
  const { updateHeight } = useUpdatePanelHeight();
  const { userData } = useSelector((state) => state.auth);
  const formData = useSelector((state) => state.formProps.formData);
  const applicationType = useSelector(
    (state) => state.formProps.applicationData.applicationType
  );

  const [req, setReq] = useState(initialReq);
  const [localReq, setLocalReq] = useState(initialReq);
  const [skip, setSkip] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [disableSelectBtn, setDisableSelectBtn] = useState(false);

  const {
    data: tablesConfig,
    isSuccess,
    isLoading,
  } = useTableConfigQuery(applicationType);

  const {
    data: licenses,
    isFetching,
    error: licenseListError,
  } = useLicensesQuery(req, { skip });

  const defaultValue = formData[licenseListKey] || (showMappedValue ? "" : []);
  const watchedValue = useWatch({
    name: licenseListKey,
    defaultValue, // Use FormProps for DG
  });

  const [
    subLicenseMutation,
    { isLoading: subLicensesIsLoading, error: subLicensesListError },
  ] = useSubLicensesMutation();

  const getSubLicense = (apiSubLicenses, license) => {
    const editedSubLicenses = license.properties[subLicensesKey] || [];
    const sublicenses = apiSubLicenses[license.licenseNumber] || [];

    return sublicenses.map((sublicense) => {
      const alreadyPresent = editedSubLicenses.find(
        ({ licenseNumber }) => licenseNumber === sublicense.licenseNumber
      );
      return {
        ...sublicense,
        isSelected:
          sublicense.isSelected ??
          alreadyPresent?.isSelected ??
          selectableLicenses.includes(sublicense.status),
      };
    });
  };

  const handleSelectLicenses = async () => {
    let rows = dataTableRef?.current?.getSelectedRows() || [];
    if (licenseListKey && !showMappedValue) {
      if (fieldProps.hasSubLicenses) {
        const payload = {
          licenseNumbers: rows.map(({ licenseNumber }) => licenseNumber),
        };
        const subLicenses = await subLicenseMutation(payload).unwrap();
        rows = rows.map((row) => ({
          ...row,
          properties: {
            ...row.properties,
            [subLicensesKey]: getSubLicense(subLicenses, row),
          },
        }));
      }
      setValue(fieldProps.properties.licenseListKey, rows);
      trigger(licenseListKey);
      setTimeout(() => updateHeight(), 400);
    }

    if (licenseListKey && disabledMultiSelect && showMappedValue) {
      const newSelectedRow = rows[0];
      if (newSelectedRow) {
        setValue(licenseListKey, newSelectedRow[config.compareBy]);
        setSelectedRow(newSelectedRow);
      } else {
        setValue(licenseListKey, null);
        setSelectedRow(null);
      }
      trigger(licenseListKey);
      setTimeout(() => updateHeight(), 400);
    }
    setOpenDialog(false);
  };

  const fetchLicenses = async (_payload) => {
    if (userData && isAuthenticated() && openDialog) {
      const payload = { ...req, ..._payload };
      if (localPagination) setLocalReq(payload);
      else setReq(payload);
      setSkip(false);
    }
    if (!openDialog) {
      setLocalReq(initialReq);
      setReq(initialReq);
    }
  };

  const onRowSelect = (rows = []) => {
    if (disabledMultiSelect && rows.length === 0) {
      setDisableSelectBtn(true);
    } else {
      setDisableSelectBtn(false);
    }
  };

  const buttonArray = [
    {
      id: 1,
      name: "CANCEL",
      icon: "",
      variant: "contained",
      onClickHandler: () => setOpenDialog(false),
      disabled: subLicensesIsLoading,
      show: true,
    },
    {
      id: 2,
      name: "Select",
      icon: "",
      variant: "contained",
      onClickHandler: handleSelectLicenses,
      disabled: isFetching || disableSelectBtn || subLicensesIsLoading,
      show: true,
    },
  ];

  useEffect(() => {
    if (
      (!watchedValue || !watchedValue.length) &&
      (!defaultValue || !defaultValue.length)
    )
      setValue(licenseListKey, defaultValue);
    fetchLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, openDialog]);

  useEffect(() => {
    const error = licenseListError || subLicensesListError;
    if (error) notifyError(dispatch, showNotification, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseListError, subLicensesListError, dispatch]);

  const rows = useMemo(
    () =>
      (licenses?.docs || []).map((doc) => ({
        licenseExpiryDate: doc.licenseExpiryDate,
        dateSubmitted: doc.dateSubmitted,
        licenseNumber: doc.licenseNumber,
        recordId: doc.recordId,
        recordType: doc.recordType,
        status: doc.status,
        title: doc.title,
        ...(fieldProps.hasSubLicenses && {
          isBulkRenewParentLicense: true,
          properties: {
            physicalCounty: doc.properties.physicalCounty,
          },
        }),
      })),
    [licenses, fieldProps]
  );

  useEffect(() => {
    if (licenses?.docs && disabledMultiSelect && showMappedValue) {
      const _selectedRow = licenses.docs.find(
        (doc) => doc[config.compareBy] === watchedValue
      );
      if (_selectedRow) setSelectedRow(_selectedRow);
    }
  }, [disabledMultiSelect, licenses?.docs, showMappedValue, watchedValue]);

  const selectedRows = useMemo(() => {
    let _selectedRows = watchedValue;
    if (disabledMultiSelect && showMappedValue) {
      const fallbackSelectedRow = { [config.compareBy]: watchedValue };
      if (watchedValue) _selectedRows = [selectedRow || fallbackSelectedRow];
    }
    return _selectedRows;
  }, [disabledMultiSelect, selectedRow, showMappedValue, watchedValue]);

  const licenseList = (
    <Paper
      container="true"
      spacing={2}
      sx={{
        position: "relative",
        "& .MuiCircularProgress-root": { position: "relative", top: "1rem" },
      }}
    >
      {licenses && isSuccess && (
        <DataTable
          ref={dataTableRef}
          progress={isFetching}
          config={config}
          rows={rows}
          pagination={licenses.pagination}
          updateList={fetchLicenses}
          tableHeaders={tablesConfig.licenseTable.columns}
          filters={tablesConfig.licenseTable.filters}
          elevation={3}
          req={localPagination ? localReq : req}
          models={{ ...tablesConfig.licenseTable.filterModels }}
          selectedRows={selectedRows}
          onRowSelect={onRowSelect}
        />
      )}
      <LocalLoader progress={isFetching || isLoading || subLicensesIsLoading} />
    </Paper>
  );

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpenDialog(true)}
        startIcon={<TableIcon />}
        disabled={disabled}
        sx={{ mb: 2, mt: 1 }}
      >
        {fieldProps.label}
      </Button>
      <DialogPopup
        title={dialogTitle}
        showActionBtn
        content={licenseList}
        openPopup={openDialog}
        onCancel={subLicensesIsLoading ? undefined : () => setOpenDialog(false)}
        buttonArray={buttonArray}
        maxWidth="md"
      />
    </>
  );
}
SelectLicenseBtn.propTypes = {
  fieldProps: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectLicenseBtn;
