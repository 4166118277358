import React, { useContext, useLayoutEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import Field from "../Field";
import CustomTitle from "./FormTitle";

import { DatagridContext } from "../context/datagridContext";
import { ReviewContext } from "../context/reviewItemContext";
import { methodRefs } from "../context/formSlice";

import {
  formConditional,
  getDatagridFormMethods,
  getWatchFieldName,
} from "../services/form.service";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import useGetFormStateValues from "../hooks/useGetFormProps";

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "applyCustomClass",
})(({ applyCustomClass }) => ({
  padding: "0px 20px",
  "& .questionClass": applyCustomClass
    ? {
        borderBottom: "1.5px solid #d8d8d8",
        "& .MuiTypography-root": {
          display: "flex !important",
          "& span:first-of-type": {
            width: "80%",
            flex: 1,
          },
          "& .wrapper": {
            display: "inline-block",
            width: "15%",
            textAlign: "center",
          },
        },
      }
    : null,
}));

function Table(props) {
  const { formData, formConfig } = useGetFormStateValues();
  const { datagridFormState } = useContext(DatagridContext);
  const { reviewProps } = useContext(ReviewContext);
  const formMethods = getDatagridFormMethods(
    methodRefs,
    datagridFormState,
    reviewProps
  );
  const { control, getValues: formGetValues } = useFormContext();
  const { updateHeight } = useUpdatePanelHeight();

  const { components } = props;
  const { rows = [], customClass } = components;
  const { isSinglePageLayout, domainName } = formConfig || {};

  const isDgControl =
    datagridFormState &&
    (!components.properties || components.properties.reference !== "form");

  let watchFieldValue = useWatch({
    name: getWatchFieldName(components, datagridFormState),
    disabled: !components.conditional || !components.conditional.when,
    control: isDgControl ? formMethods?.control : control,
  });
  if (
    components.conditional &&
    watchFieldValue === undefined &&
    (formData[components.conditional.when] !== undefined ||
      formGetValues(components.conditional.when) !== undefined)
  ) {
    if (
      !datagridFormState ||
      (datagridFormState &&
        components.properties &&
        components.properties.reference === "form")
    ) {
      watchFieldValue =
        formGetValues(components.conditional.when) === undefined
          ? formData[components.conditional.when]
          : formGetValues(components.conditional.when);
    }
  } else if (
    components.conditional &&
    watchFieldValue === undefined &&
    datagridFormState &&
    datagridFormState.record[components.conditional?.when?.split(".")[1]] !==
      undefined
  ) {
    if (!components.properties || components.properties.reference !== "form") {
      watchFieldValue =
        datagridFormState.record[components.conditional.when.split(".")[1]];
    }
  }

  const getValues = datagridFormState ? formMethods.getValues : formGetValues;
  const shouldDisplay = formConditional(
    components,
    watchFieldValue,
    getValues()
  );

  useLayoutEffect(() => {
    if (components.conditional) {
      setTimeout(() => updateHeight(), 50);
    }
    return () => {
      if (components.conditional) {
        setTimeout(() => updateHeight(), 5);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplay]);

  const applyCustomClass =
    (reviewProps || isSinglePageLayout || domainName === "LicenseForm") &&
    customClass === "full-width-row";

  return (
    shouldDisplay && (
      <>
        {components.properties?.heading && (
          <CustomTitle
            title={components.properties.heading}
            marginTop
            showProTip={components?.tags && components?.tags.includes("proTip")}
            fieldKey={components.key}
          />
        )}
        <StyledGrid
          container
          item
          direction="row"
          xs={12}
          alignItems="flex-start"
          applyCustomClass={applyCustomClass}
        >
          {Array.isArray(rows) && rows.length > 0
            ? rows.map((row, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  columnSpacing={3}
                  alignItems="flex-start"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {Array.isArray(row) &&
                    row.map((column, subIndex, arr) => (
                      <Grid
                        item
                        // eslint-disable-next-line react/no-array-index-key
                        key={subIndex}
                        xs={12}
                        sm={arr.length === 1 ? 12 : 6}
                        // eslint-disable-next-line no-nested-ternary
                        md={arr.length === 1 ? 12 : arr.length === 2 ? 6 : 4}
                      >
                        {Array.isArray(column?.components)
                          ? column.components.map((field, i, array) => (
                              <Field
                                key={field.key}
                                fieldProps={field}
                                index={i}
                                length={array.length - 1}
                              />
                            ))
                          : null}
                      </Grid>
                    ))}
                </Grid>
              ))
            : null}
        </StyledGrid>
      </>
    )
  );
}
Table.propTypes = {
  components: PropTypes.shape({
    rows: PropTypes.oneOfType([PropTypes.array]).isRequired,
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    properties: PropTypes.shape({
      heading: PropTypes.string,
      reference: PropTypes.string,
    }),
  }).isRequired,
};
Table.whyDidYouRender = true;
export default Table;
