/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Controller, useFormState } from "react-hook-form";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import { useValidateEmailMutation } from "../../../api/applications.api.slice";
import { showNotification } from "../../../features/core/slices/notification.slice";
import { notifyError } from "../../../utils/helpers";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import { generateFormFieldRules } from "../services/form.service";

function FormEmailField(props) {
  const { fieldProps, formProps, register, control, disabled, setValue } =
    props;
  const { errors } = useFormState({ control, name: fieldProps.key });
  const theme = useTheme();
  const dispatch = useDispatch();
  const [
    validateEmail,
    { data: validateEmailData, isLoading: isValidatingEmail },
  ] = useValidateEmailMutation();
  const { updateHeight } = useUpdatePanelHeight();

  const defaultValue =
    formProps[fieldProps.key] || fieldProps.defaultValue || "";

  const setValidationMessage = (isValid = false, message = "") => {
    setValue(`${fieldProps.key}Valid`, isValid);
    setValue(`${fieldProps.key}validationMsg__DUMMY`, message);
  };

  const removeExtraSpace = (s) => s.trim().split(/ +/).join("");
  const validateEmailAddress = async (fieldValue) => {
    if (!fieldProps.properties?.carbonCopy) return;
    if (fieldValue) {
      try {
        const { data } = await validateEmail({
          email: fieldValue,
          source: "form",
        });
        setValidationMessage(data.send, data.message);
        if (data.message) updateHeight();
      } catch (error) {
        setValidationMessage();
        notifyError(dispatch, showNotification, error);
      }
    } else {
      setValidationMessage();
    }
  };

  useEffect(() => {
    if (fieldProps.properties?.carbonCopy) {
      register(`${fieldProps.key}Valid`);
      register(`${fieldProps.key}validationMsg__DUMMY`);
      validateEmailAddress(formProps[fieldProps.key]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const protectField = (e) => {
    e.preventDefault();
  };

  const rules = generateFormFieldRules(fieldProps);

  return (
    <FormControl fullWidth margin="dense" sx={{ height: "75px" }}>
      <Controller
        name={fieldProps.key}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, ...field } }) => (
          <TextField
            {...field}
            id={fieldProps.key}
            name={fieldProps.key}
            label={fieldProps.label}
            onBlur={(e) => {
              onBlur(e);
              validateEmailAddress(e.target.value);
            }}
            onChange={(e) => {
              const trimmedValue = removeExtraSpace(e.target.value);
              // eslint-disable-next-line no-param-reassign
              e.target.value = trimmedValue.toLowerCase();
              onChange(e);
            }}
            onCut={fieldProps.protected ? protectField : undefined}
            onCopy={fieldProps.protected ? protectField : undefined}
            onPaste={fieldProps.protected ? protectField : undefined}
            required={fieldProps.validate?.required}
            disabled={disabled}
            variant="standard"
          />
        )}
        rules={rules}
      />
      {errors[fieldProps.key] && (
        <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
          {errors[fieldProps.key]?.message
            ? errors[fieldProps.key].message
            : "This is required."}
        </FormHelperText>
      )}
      {!errors[fieldProps.key] &&
        (isValidatingEmail || (!isValidatingEmail && validateEmailData)) && (
          <FormHelperText
            id="{fieldProps.key}-verify-email-text"
            error={isValidatingEmail ? false : !validateEmailData?.send}
            sx={{
              margin: 0,
              color: theme.palette.mode === "light" ? "#31708f" : "#71cbf5",
            }}
          >
            {isValidatingEmail && "Validating email..."}
            {!isValidatingEmail && validateEmailData?.message}
          </FormHelperText>
        )}
    </FormControl>
  );
}

FormEmailField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    protected: PropTypes.bool,
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
    properties: PropTypes.shape({
      carbonCopy: PropTypes.string,
    }),
    defaultValue: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default FormEmailField;
