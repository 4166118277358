import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isReviewTab",
})(({ isReviewTab, theme }) => ({
  "& .tabList": {
    "& .MuiTab-root": {
      marginRight: ".8rem",
      fontSize: ".75em",
      padding: "5px 10px",
      borderRadius: "8px",
      maxHeight: "55px",
      minWidth: "100px",
      flex: 1,
      backgroundColor:
        theme.palette.mode === "light" ? "#fff" : "rgb(75, 75, 75)",
      color:
        theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.8)" : undefined,
      "&.Mui-selected": {
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.primary.light
            : theme.palette.primary.dark,
        color: "#ffff",
      },
      "&.Mui-disabled": {
        color:
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(0, 0, 0, 0.38)",
      },
      "&.MuiTab-wrapped": {
        minWidth: "180px",
      },
    },
    "& .MuiTabs-scrollButtons": {
      width: "30px",
      transition: "width 0.3s ease-out",
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.light
          : theme.palette.primary.dark,
      "& .MuiSvgIcon-root": {
        fontSize: "1.8rem",
      },
      "&.Mui-disabled": {
        width: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "20px",
      },
    },
  },

  "& .paper": {
    margin: "12px 0",
    borderRadius: 0,
    borderTopColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
    borderTopStyle: "solid",
    borderTopWidth: 4,
    marginBottom: "5rem",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: isReviewTab ? undefined : "12rem",
    },
    "& .recordWrapper": {
      margin: "0.3rem 1rem .8rem",
      borderRadius: "7px",
      [theme.breakpoints.down("sm")]: {
        margin: "0.3rem .7rem .8rem",
      },
    },
    "& .categoryTitle": {
      fontSize: "1.1rem",
      paddingLeft: ".6rem",
    },
  },
  "& #buttonContainer": {
    position: "absolute",
    margin: "2rem 0 1rem",
    "& .MuiButton-root": {
      margin: "0.5rem",
      minWidth: "auto",
    },
    "& .MuiButton-startIcon>*": {
      fontSize: "25px",
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& .MuiButton-root": {
        margin: "0.5rem 0",
        minWidth: "100%",
      },
    },
  },
  "& .reviewTabBtnContainer": {
    margin: "2rem 0 0",
    "& .MuiButton-startIcon>*": {
      fontSize: "23px",
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& .MuiButton-root": {
        margin: "0.5rem 0",
        minWidth: "100%",
      },
    },
  },
}));

export default Styled;
