/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useFormState, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { generateFormFieldRules } from "../services/form.service";

function FormPasswordField(props) {
  const { fieldProps, control } = props;
  const { errors } = useFormState({ control, name: fieldProps.key });
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const protectField = (e) => {
    e.preventDefault();
  };

  const rules = generateFormFieldRules(fieldProps);

  return (
    <FormControl fullWidth margin="dense" sx={{ height: "75px" }}>
      <Controller
        name={fieldProps.key}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...field } }) => (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              title={fieldProps.description || ""}
              arrow
              open={open}
              onClose={handleTooltipClose}
              disableHoverListener
              disableTouchListener
            >
              <TextField
                {...field}
                type={!showPassword ? "password" : "text"}
                id={fieldProps.key}
                name={fieldProps.key}
                label={fieldProps.label}
                required={fieldProps.validate?.required}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                onFocus={handleTooltipOpen}
                onCut={fieldProps.protected ? protectField : undefined}
                onCopy={fieldProps.protected ? protectField : undefined}
                onPaste={fieldProps.protected ? protectField : undefined}
                variant="standard"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </ClickAwayListener>
        )}
        rules={rules}
      />
      {errors[fieldProps.key] && (
        <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
          This is required.
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormPasswordField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    description: PropTypes.string,
    defaultValue: PropTypes.string,
    protected: PropTypes.bool,
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
      pattern: PropTypes.string,
    }),
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
};
export default FormPasswordField;
