import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-unresolved
import GettingStartedContent from "@regional/templates/GettingStarted";
import SwipeableViews from "react-swipeable-views";
import Typography from "@mui/material/Typography";
// import { autoPlay } from "react-swipeable-views-utils";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LinearProgress from "@mui/material/LinearProgress";

const steps = [
  {
    id: 0,
    description: (
      <Typography>
        <strong>Introducing Our Enhanced User Interface (UI) Upgrade</strong>
        <br />
        We are thrilled to inform you that we have recently updated our user
        interface (UI) to enhance your browsing experience. Our team has worked
        hard to create a fresh and modern design that is both visually appealing
        and user-friendly. With these updates, we aim to make it easier for you
        to navigate the site and find what you&apos;re looking for quickly.
        <br />
        The new UI features optimized functionality, improved visuals, and
        streamlined navigation. We are confident that these updates will improve
        your overall experience and make your time on our website more
        enjoyable. Our team is committed to continuously improving the user
        experience, and we hope that you find these updates helpful.
        <br />
      </Typography>
    ),
  },
  {
    id: 1,
    description: <GettingStartedContent />,
  },
];

const autoPlayInterval = 10000;
export default function GettingStartedUpdate() {
  const _now = Date.now();
  const [index, setIndex] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const [progressTo, setProgressTo] = useState(0); // 0 [middle values] 100
  const [startTime, setStartTime] = useState(_now);
  const [timeLeft, setTimeLeft] = useState(autoPlayInterval);
  const [interactionStartTime, setInteractionStartTime] = useState(_now); // onProgressStop

  const resetProgress = (value = 0) => {
    const now = Date.now();
    setProgressTo(value);
    setWaitTime(0);
    setStartTime(now);
    setTimeLeft(autoPlayInterval);
    setInteractionStartTime(now);
  };
  const handleChangeIndex = (i) => {
    let newIndex = i;
    if (i < 0) newIndex = 0;
    if (i > steps.length) newIndex = steps.length;
    resetProgress();
    setIndex(newIndex);
  };
  const handleNext = () => {
    resetProgress();
    setIndex((prevActiveStep) => {
      if (prevActiveStep === steps.length - 1) return 0;
      return prevActiveStep + 1;
    });
  };
  const handleBack = () => {
    resetProgress();
    setIndex((prevActiveStep) => {
      if (prevActiveStep === 0) return steps.length - 1;
      return prevActiveStep - 1;
    });
  };
  const startAutoPlay = () => {
    const now = Date.now();
    const timeDifference = now - interactionStartTime;
    const totalWaitTime = waitTime + timeDifference;
    const progressRunTime = now - (startTime - totalWaitTime);
    const newTimeLeft = autoPlayInterval - progressRunTime;
    setProgressTo(100);
    setWaitTime(totalWaitTime);
    setTimeLeft(newTimeLeft < 0 ? 0 : newTimeLeft);
  };
  const stopAutoPlay = () => {
    const now = Date.now();
    const progressRunTime = now - (startTime - waitTime);
    const progressPercent = Math.round(
      (progressRunTime / autoPlayInterval) * 100
    );
    setProgressTo(progressPercent);
    setInteractionStartTime(now);
    setTimeLeft(null);
  };

  useEffect(() => {
    let timer = null;
    if (typeof timeLeft !== "object") {
      timer = setTimeout(handleNext, timeLeft);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, timeLeft]);

  useEffect(() => {
    resetProgress();
    const timer = setTimeout(() => {
      resetProgress(100);
    }, 0);

    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <Box
      sx={{
        "& .MuiLinearProgress-bar": {
          transition:
            progressTo <= 0 || !timeLeft
              ? "none"
              : `transform ${timeLeft}ms linear`,
        },
      }}
    >
      <LinearProgress
        sx={{ top: "-0.8em" }}
        variant="determinate"
        value={progressTo}
      />
      <SwipeableViews axis="x" index={index} onChangeIndex={handleChangeIndex}>
        {steps.map((step) => (
          <Box
            sx={{ maxHeight: "400px ", cursor: "pointer" }}
            key={step.id}
            onMouseEnter={stopAutoPlay}
            onMouseLeave={startAutoPlay}
          >
            {step.description}
          </Box>
        ))}
      </SwipeableViews>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button size="small" onClick={handleBack} disabled={index === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        </Box>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          {steps.map((step) => (
            <IconButton
              key={step.id}
              onClick={() => {
                handleChangeIndex(step.id);
              }}
            >
              <Brightness1Icon
                sx={{
                  color: index === step.id ? "#68C565" : "grey",
                  fontSize: "16px",
                }}
              />
            </IconButton>
          ))}
        </Box>
        <Box>
          <Button
            size="small"
            onClick={handleNext}
            disabled={steps.length - 1 === index}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
