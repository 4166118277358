import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import DialogPopup from "../../../DialogPopup";
import LocalLoader from "../../../Loaders/Local";
import { hideFormDialog } from "../../context/dialogSlice";
import { TableSkeleton } from "../../../Skeleton";
import Template from "./Template";
import Styled from "./styles";

import { notifyError } from "../../../../utils/helpers";
import { showNotification } from "../../../../features/core/slices/notification.slice";
import {
  filterRecordSelectionList,
  formRecordSelectionReq,
  setRecordValuesToFormFields,
} from "../../services/form.service";
import { useLinkedLicensesQuery } from "../../../../api/licenses.api.slice";

function SelectRecord({ handleFormSave }) {
  const dispatch = useDispatch();
  const { setValue, getValues, trigger } = useFormContext();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordList, setRecordList] = useState([]);

  const {
    formDialog: { fieldProps },
  } = useSelector((state) => state.formDialog);

  const [req, setReq] = useState("");
  const [skip, setSkip] = useState(true);

  const {
    data: licenseList,
    error: licenseError,
    isLoading,
  } = useLinkedLicensesQuery(req, { skip });

  const { properties } = fieldProps || {};

  useEffect(() => {
    if (properties) {
      const payload = formRecordSelectionReq(properties, getValues());
      if (!isEmpty(payload)) {
        setReq(payload);
        setSkip(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  useEffect(() => {
    if (licenseList && properties) {
      const filteredRecord = filterRecordSelectionList(
        properties,
        getValues(),
        licenseList
      );
      setRecordList(filteredRecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseList]);

  useEffect(() => {
    if (licenseError) {
      notifyError(dispatch, showNotification, licenseError);
    }
  }, [licenseError, dispatch]);

  const handleRecordSelection = (record) => {
    if (record?._id !== selectedRecord?._id) {
      setSelectedRecord(record);
    }
  };

  const closeDialog = () => {
    dispatch(hideFormDialog());
  };

  const updateFormValues = async () => {
    const props = {
      fieldProps,
      setValue,
      getValues,
      dispatch,
      showNotification,
      recordProps: selectedRecord?.properties || null,
      trigger,
    };
    if (setRecordValuesToFormFields(props)) {
      const result = await handleFormSave(null, null, true);
      if (result) {
        closeDialog();
      }
    }
  };

  const modalContent = (
    <Styled>
      {isLoading ? (
        <Grid>
          <TableSkeleton />
        </Grid>
      ) : (
        <Grid container className="gridContainer">
          {recordList && recordList.length ? (
            <>
              <Grid item xs={10} sm={9} md={10}>
                <Alert severity="info" tabIndex={0}>
                  {`Please select the ${properties?.title} by clicking on respective sections below.`}
                </Alert>
              </Grid>
              {recordList.map((record) => (
                <Grid key={record?._id} item xs={12}>
                  <Template
                    record={record}
                    properties={properties}
                    selectedRecordId={selectedRecord?._id}
                    handleRecordSelection={handleRecordSelection}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12} sm={8} md={6}>
              <Alert
                severity="warning"
                tabIndex={0}
              >{`No ${properties?.title} found.`}</Alert>
            </Grid>
          )}
        </Grid>
      )}
      <LocalLoader progress={isLoading} />
    </Styled>
  );

  const buttonArray = [
    {
      id: 1,
      name: "Cancel",
      variant: "contained",
      onClickHandler: closeDialog,
      show: true,
    },
    {
      id: 2,
      name: "Update",
      variant: "contained",
      onClickHandler: updateFormValues,
      show: true,
      disabled: !selectedRecord,
    },
  ];

  return (
    <DialogPopup
      title={`${properties?.title} Selection`}
      content={modalContent}
      buttonArray={buttonArray}
      openPopup
      onCancel={undefined}
      showActionBtn
      maxWidth="md"
      hideIcon="none"
      height={!recordList || !recordList.length ? "60%" : undefined}
    />
  );
}
SelectRecord.propTypes = {
  handleFormSave: PropTypes.func.isRequired,
};
export default SelectRecord;
