/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useWatch, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import FormReadOnlyField from "./FormReadOnlyField";
import FormEditableField from "./FormEditableField";
import FormReadOnlyMapping from "./FormReadOnlyMapping";
import FormDocumentField from "../Document";
import FormInfoField from "./FormInfo";

import { useDatagridContextValue } from "../context/datagridContext";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import {
  methodRefs,
  resetFormData,
  updateFormFields,
} from "../context/formSlice";
import {
  formConditional,
  getDatagridFormMethods,
  getWatchFieldName,
  conditionalDisable,
  disableCheck,
  disableInput,
  getDgWatchFieldName,
  getParsedValue,
  optionListChange,
} from "../services/form.service";
import { showNotification } from "../../../features/core/slices/notification.slice";
import { getDocumentVisibility } from "../services/formDocument.service";
import { useReviewPageCheck } from "../context/reviewItemContext";
import useGetFormStateValues from "../hooks/useGetFormProps";

const statusList = ["Approved", "Submitted", "Paid", "Processing"];

function Field(props) {
  const dispatch = useDispatch();
  const { datagridFormState } = useDatagridContextValue();
  const isReviewContext = useReviewPageCheck();
  const {
    register: formRegister,
    control: formControl,
    getValues: formGetValues,
    setValue: formSetValue,
    trigger: formTrigger,
    watch: formWatch,
  } = useFormContext();
  const formStateValues = useGetFormStateValues();
  const { updateHeight } = useUpdatePanelHeight();
  // to track the disabled state
  const [disabled, setDisabled] = useState(false);
  const [controllingFieldList, setControllingFieldList] = useState(undefined);
  const [fieldPropsCopy, setFieldPropsCopy] = useState(undefined);
  const formMethods = getDatagridFormMethods(
    methodRefs,
    datagridFormState,
    isReviewContext
  );

  const { fieldProps, index, length } = props;
  const { formData, formConfig, applicationData } = formStateValues;
  const { type, conditional, customConditional, properties } = fieldProps;
  // if (!isReviewContext) {
  //   // poiIssueStateStateId, poiIdIssueStateDl, poiIdDocument
  //   console.log(fieldProps.key, isReviewPage);
  // }
  const isDgControl =
    datagridFormState && (!properties || properties.reference !== "form");

  let watchFieldValue = useWatch({
    name: getWatchFieldName(fieldProps, datagridFormState),
    disabled: !((conditional && conditional.when) || customConditional),
    control: isDgControl ? formMethods?.control : formControl,
  });

  const isDocumentField = type === "document";

  // useForm hook reference
  const register = datagridFormState ? formMethods.register : formRegister;
  const control = datagridFormState ? formMethods.control : formControl;
  const getValues = datagridFormState ? formMethods.getValues : formGetValues;
  const trigger = datagridFormState ? formMethods.trigger : formTrigger;
  const watch = datagridFormState ? formMethods.watch : formWatch;
  const setValue = datagridFormState ? formMethods.setValue : formSetValue;

  if (conditional && watchFieldValue === undefined && !isDocumentField) {
    if (
      formData[conditional.when] !== undefined &&
      (!datagridFormState ||
        (datagridFormState && properties && properties.reference === "form"))
    ) {
      if (conditional.when && conditional.when.includes(".")) {
        watchFieldValue = formData[conditional.when.split(".")[0]];
      } else {
        watchFieldValue = formData[conditional.when];
      }
    }

    if (
      datagridFormState &&
      datagridFormState.record[getDgWatchFieldName(conditional)] !==
        undefined &&
      (!properties || properties.reference !== "form")
    ) {
      watchFieldValue =
        datagridFormState.record[getDgWatchFieldName(conditional)];
    }
  }

  if (isDocumentField) {
    if (
      (conditional || customConditional) &&
      Array.isArray(watchFieldValue) &&
      watchFieldValue.every((item) => item === undefined)
    ) {
      if (
        !datagridFormState ||
        (datagridFormState && properties && properties.reference === "form")
      ) {
        watchFieldValue = formData;
      } else watchFieldValue = datagridFormState.record;
    } else if (
      (conditional || customConditional) &&
      watchFieldValue !== undefined &&
      Array.isArray(watchFieldValue)
    ) {
      if (
        !datagridFormState ||
        (datagridFormState && properties && properties.reference === "form")
      ) {
        watchFieldValue = formConfig?.readOnly ? formData : formGetValues();
      } else watchFieldValue = formMethods?.getValues();
    }
  }

  const shouldDisplay = () => {
    if (isDocumentField)
      return getDocumentVisibility(watchFieldValue, fieldProps);
    return formConditional(fieldProps, watchFieldValue, getValues());
  };

  // checking whether it's datagrid field or not
  const newFormProps = datagridFormState
    ? { ...datagridFormState.record, applicationData }
    : { ...formData, applicationData };

  // to check whether readOnly or editable field
  const isEditableField =
    (!formConfig.readOnly &&
      !isReviewContext &&
      datagridFormState &&
      datagridFormState.isEditable) ||
    (!formConfig.readOnly && !isReviewContext && !datagridFormState);

  // to watch controlling fields to disable/enable the field
  const watchControllingFieldValues = useWatch({
    name: controllingFieldList,
    control,
    disabled: controllingFieldList === undefined,
  });
  // to update the disable state when controlling field's value changes
  useEffect(() => {
    const shouldDisable = (fieldValue) => {
      let defaultWatchValues = [];
      if (!Array.isArray(fieldValue) && controllingFieldList) {
        defaultWatchValues = getValues(fieldPropsCopy.controllingFieldKey);
      }
      const result = conditionalDisable(
        fieldValue,
        fieldPropsCopy,
        controllingFieldList,
        defaultWatchValues
      );
      return result;
    };
    if (
      (Array.isArray(watchControllingFieldValues) &&
        fieldPropsCopy &&
        fieldPropsCopy.controllingFieldValue) ||
      (!Array.isArray(watchControllingFieldValues) && controllingFieldList)
    ) {
      const res = shouldDisable(watchControllingFieldValues);
      setDisabled(res);
    }
  }, [
    watchControllingFieldValues,
    fieldPropsCopy,
    controllingFieldList,
    getValues,
  ]);

  // for setting initial disable state and to watch for the controlling fields if available
  useEffect(() => {
    if (isEditableField || !statusList.includes(applicationData.status)) {
      const isConditionalDisableItem = disableCheck(fieldProps);
      if (isConditionalDisableItem) {
        const referenceData =
          fieldProps.properties?.disableReference === "form"
            ? { ...formData, applicationData }
            : newFormProps;
        const updatedFieldProps = disableInput(fieldProps, referenceData);
        if (updatedFieldProps.disabled !== undefined) {
          setDisabled(updatedFieldProps.disabled);
        }
        if (updatedFieldProps.controllingFieldKey) {
          setFieldPropsCopy(updatedFieldProps);
          setControllingFieldList(updatedFieldProps.controllingFieldKey);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset fields
  const resetHandler = () => {
    if (fieldProps.validate && fieldProps.validate.custom) {
      const { key } = fieldProps;
      const condition = getParsedValue(fieldProps.validate.custom, key);
      if (condition && condition.reset) {
        const payload = { condition, setValue, trigger, key, getValues };
        dispatch(resetFormData(payload));
      }
    }
  };

  const onOptionListChange = (value) => {
    const result = optionListChange(fieldProps, setValue, value);
    if (result) {
      dispatch(
        showNotification({
          type: "success",
          msg: "Information updated successfully.",
          show: true,
        })
      );
    }
  };

  const showField = shouldDisplay();

  useLayoutEffect(() => {
    if (fieldProps.conditional) {
      updateHeight();
    }
    if (showField) {
      dispatch(
        updateFormFields({
          type: "add",
          field: fieldProps,
          datagridKey: datagridFormState?.datagridKey,
          recordId: datagridFormState?.record?.id,
        })
      );
    }
    return () => {
      if (fieldProps.conditional) {
        updateHeight();
        setTimeout(() => updateHeight(), 50);
      }
      updateFormFields({
        type: "delete",
        field: fieldProps,
        datagridKey: datagridFormState?.datagridKey,
        recordId: datagridFormState?.record?.id,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showField]);

  if (showField) {
    if (isDocumentField) {
      return (
        <FormDocumentField
          formProps={newFormProps}
          fieldProps={{
            ...fieldProps,
            isFirstItem: index === 0,
            isLastItem: index === length,
          }}
        />
      );
    }

    if (fieldProps.type === "readOnlyMapping") {
      return (
        <FormReadOnlyMapping
          control={control}
          trigger={trigger}
          setValue={setValue}
          fieldProps={fieldProps}
          getValues={getValues}
          register={register}
          newFormProps={newFormProps}
        />
      );
    }
    if (isEditableField) {
      return (
        <FormEditableField
          fieldProps={fieldProps}
          formProps={newFormProps}
          control={control}
          register={register}
          getValues={getValues}
          disabled={disabled}
          trigger={trigger}
          watch={watch}
          resetHandler={resetHandler}
          onOptionListChange={onOptionListChange}
          setValue={setValue}
          datagridFormState={datagridFormState}
        />
      );
    }
    if (fieldProps.type === "note")
      return <FormInfoField fieldProps={fieldProps} />;

    if (fieldProps.type !== "info" && fieldProps.type !== "button") {
      return (
        <FormReadOnlyField
          fieldProps={fieldProps}
          newFormProps={newFormProps}
          getValues={getValues}
          setValue={setValue}
          register={register}
          control={control}
          trigger={trigger}
        />
      );
    }
  }
  return "";
}
Field.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    properties: PropTypes.shape({
      reference: PropTypes.string,
      disableReference: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }),
  }).isRequired,
};
export default Field;
