import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";
import { blueGrey, blue, orange, red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import DialogPopup from "../../../../components/DialogPopup";
import { hideAppHealthDialog } from "../../slices/appHealth.slice";

function AppHealthDialog() {
  const dispatch = useDispatch();
  const muiTheme = useTheme();
  const lightMode = muiTheme.palette.mode === "light";
  const { appHealth } = useSelector((state) => state.appHealth);
  const { isOkay, message, type, interactive, link } = appHealth;

  const Icon = type === "info" ? InfoTwoToneIcon : ErrorOutlineTwoToneIcon;
  const colorMap = {
    info: blue[200],
    warning: orange[200],
    error: red[300],
  };
  const titleMap = {
    info: "INFORMATION",
    warning: "WARNING",
    error: "ERROR",
  };
  const customColor = {
    /* eslint-disable no-nested-ternary */
    header: colorMap[type]
      ? colorMap[type]
      : lightMode
      ? muiTheme.palette.primary.light
      : muiTheme.palette.primary.dark,
    body: lightMode ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.8)",
  };
  const dialogContent = (
    <Box
      sx={(theme) => ({
        px: ".5em",
        mb: "2rem",
        display: "flex",
        flexDirection: "column",
        "& .MuiButton-root": {
          mt: "1em",
          backgroundColor: blueGrey[200],
          borderRadius: "15px",
          border: "1px solid #f5f5f5",
          boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)",
        },
        "& .MuiButton-root:hover": {
          backgroundColor: blueGrey[100],
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
        },
      })}
    >
      <Typography variant="p">{message}</Typography>
      {link && (
        <Box>
          <Button
            component={Link}
            color="secondary"
            target="_blank"
            href={link}
          >
            More Information
          </Button>
        </Box>
      )}
    </Box>
  );
  return (
    <DialogPopup
      title={`SYSTEM USABILITY ${titleMap[type]}`}
      onCancel={interactive ? () => dispatch(hideAppHealthDialog()) : undefined}
      icon={<Icon fontSize="medium" sx={{ mr: "0.5em" }} />}
      openPopup={!isOkay}
      content={dialogContent}
      hideIcon={interactive ? undefined : "none"}
      customBgColor={customColor}
      // height="35%"
    />
  );
}

export default AppHealthDialog;
