import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "readonly",
})(({ theme, readonly }) => ({
  "& .MuiTableBody-root .MuiTableCell-root": {
    paddingTop: readonly ? undefined : 0,
    paddingBottom: readonly ? undefined : 0,
  },
  "& .subTable": {
    "& .MuiTableCell-root": {
      padding: "0 .5rem",
      "&:first-of-type": { padding: 0 },
      "&:not(:first-of-type)": { minWidth: "100px" },
    },
    "& .MuiTableCell-body": {
      padding: "0.25rem .5rem",
      "&:first-of-type": { padding: 0 },
      "&:not(:first-of-type)": { minWidth: "100px" },
    },
    "& .infoGrid": { marginTop: 0 },
    "& .infoGrid > div": { padding: 0, marginBottom: 0 },
    "& > div > .MuiPaper-root": {
      background: theme.palette.mode === "light" ? "#f6f6f6" : undefined,
    },
  },
  // theme.palette.mode === "light" ? theme.palette.primary.light
  "& .licenseCards": {
    "& .MuiCard-root": {
      minWidth: 250,
      maxWidth: 700,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      backgroundColor: theme.palette.mode === "light" ? undefined : "#252525",
    },
    "& .MuiCardContent-root": !readonly
      ? {
          flex: 1,
          border: 0,
          borderBottom: 1,
        }
      : undefined,
    "& .MuiCardActions-root": {
      justifyContent: "flex-end",
      border: 0,
      borderTop: 1,
      borderColor: theme.palette.text.disabled,
      borderStyle: "solid",
    },
    "& .headingContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .dateContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    "& .MuiDivider-root": {
      marginTop: "0.5rem",
      borderBottomWidth: "medium",
      borderColor: "#76c836",
    },
    "& .MuiCheckbox-root:not(.Mui-disabled)": {
      color: "#76c836",
    },
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      overflow: "auto",
      marginLeft: "-0.5rem",
      marginRight: "-0.5rem",
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
      maxHeight: "21rem",
      "&::-webkit-scrollbar": {
        width: "0.3rem",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "1rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor:
          theme.palette.mode === "light"
            ? "#d9d9d9"
            : theme.palette.text.disabled,
        borderRadius: "1rem",
      },
    },
  },
}));

export default StyledBox;
