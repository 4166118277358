import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DialogPopup from "../../DialogPopup";
import { showNotification } from "../../../features/core/slices/notification.slice";
import LocalLoader from "../../Loaders/Local";
import { hideFormDialog } from "../context/dialogSlice";

const defaultProTipData = {
  title: " ",
  showTitle: true,
  content: <LocalLoader progress />,
  actionButton: [
    { value: "notUseful", label: "NOT USEFUL" },
    { value: "useful", label: "USEFUL" },
  ],
  showActionBtn: true,
};

function FormProTip(props) {
  const { fieldKey } = props;
  const [proTipData, setProTipData] = useState(defaultProTipData);
  const dispatch = useDispatch();
  useEffect(() => {
    import(`@regional/templates/ProTip/${fieldKey}`)
      .then((proTip) => {
        setProTipData(proTip.default);
      })
      .catch((error) => {
        dispatch(hideFormDialog());
        dispatch(
          showNotification({
            type: "error",
            msg: error?.data?.message || "ProTip is missing",
            show: true,
          })
        );
        setProTipData(defaultProTipData);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeDialog = () => {
    dispatch(hideFormDialog());
  };
  return (
    <DialogPopup
      title={proTipData.title}
      showTitle={proTipData.showTitle}
      openPopup
      onCancel={closeDialog}
      content={proTipData.content}
      buttonArray={proTipData?.actionButton?.map((btn) => ({
        id: btn.value,
        name: btn.label,
        variant: "outlined",
        onClickHandler: closeDialog,
        show: true,
      }))}
      showActionBtn={proTipData.showActionBtn}
    />
  );
}

FormProTip.propTypes = {
  fieldKey: PropTypes.string.isRequired,
};

export default FormProTip;
