/* eslint-disable import/no-unresolved */
import React from "react";
import { useSelector } from "react-redux";
import buildConfig from "@regional/config/build.config.json";
import headerLogo from "@regional/img/brand-logo.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "props",
})(({ props, theme }) => ({
  position: "relative",
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  paddingLeft: props.title && props.user ? "5px" : "15px",
  [theme.breakpoints.down("sm")]: {
    "& img": {
      maxWidth: props.title ? "55px !important" : undefined,
    },
  },
  "& .textContainer": {
    margin: "3px 0px 0px 6px",
    display: "grid",
    "& .MuiTypography-root": {
      lineHeight: "1.2em",
      fontWeight: "500",
      textTransform: "uppercase",
      maxWidth: "166px",
    },
  },
}));
function HeaderLogo() {
  const { showLogoTitle, logoImgAlt, logoTextPrimary, logoTextSecondary } =
    buildConfig;
  const user = useSelector((state) => state.auth.userData);
  const colorMap = {
    default: "#4662b4",
    "al-amcc": "#506d57",
    "usvi-ocr": "#506d57",
  };
  const textColor =
    colorMap[process.env.REACT_APP_COMPLIA_INSTALL] || colorMap.default;

  const props = { title: showLogoTitle, user };
  const linkToHomePage = user ? "/licenses" : "/login";
  return (
    <Styled
      props={props}
      component={Link}
      to={linkToHomePage}
      sx={{ textDecoration: "none" }}
    >
      <img src={headerLogo} alt={logoImgAlt} />
      {showLogoTitle && (
        <Box
          className="textContainer"
          sx={{
            "& .MuiTypography-root": {
              fontSize: { xs: ".69rem", sm: "0.8rem", lg: "0.75rem" },
              color: textColor,
            },
            "& .MuiTypography-root:first-of-type": logoTextPrimary
              ? {
                  fontSize: { xs: ".6rem", sm: "0.7rem" },
                  pt: { lg: "0px", md: "2px", sm: "0px", xs: "0px" },
                  mb: { lg: "2px", sm: "2px", xs: "4px " },
                  width: { xs: "95%", md: "100%" },
                }
              : {},
            "& .MuiTypography-root:nth-of-type(2)": {
              whiteSpace: "pre-wrap",
              pt: "1.2px",
              width: { xs: "95%", md: "100%" },
            },
          }}
        >
          <Typography variant="subtitle2" component="h1">
            {logoTextPrimary}
          </Typography>
          <Typography variant="subtitle1" component="h1">
            {logoTextSecondary}
          </Typography>
        </Box>
      )}
    </Styled>
  );
}

export default HeaderLogo;
