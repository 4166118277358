// { dgKey: true}
let dgRenderState = {};

export default function useDgRenderState() {
  const getDgRenderInfo = () => dgRenderState;

  const updateDgRenderState = (key) => {
    if (key && !dgRenderState[key]) dgRenderState[key] = true;
  };

  const resetDgRenderState = () => {
    dgRenderState = {};
  };

  return { getDgRenderInfo, updateDgRenderState, resetDgRenderState };
}
