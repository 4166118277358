import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import DialogPopup from "../../../DialogPopup";
import { hideFormDialog } from "../../context/dialogSlice";
import saveRecordImgLight from "../../../../img/save-record-light.png";
import saveRecordImgDark from "../../../../img/save-record-dark.png";

function WarningDialog({ handleDialogAction, tabIndexRef }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { type, unsavedDgList } = tabIndexRef.current || {};
  const isDarkMode = theme?.palette?.mode === "dark";
  const severity = type === "save" ? "warning" : "info";
  const dgLabel = (unsavedDgList || []).join(", ");

  const closeDialog = (allowUserAction) => {
    dispatch(hideFormDialog());
    if (allowUserAction) handleDialogAction();
  };

  const content = (
    <Grid container flexDirection="column" alignItems="center" rowGap={2}>
      <Grid item xs={10}>
        <Alert role="definition" severity={severity} tabIndex={0}>
          Please save the open entries under <b>{dgLabel}</b> by clicking on the
          save button, as shown below.
        </Alert>
      </Grid>
      <Grid>
        {isDarkMode ? (
          <img src={saveRecordImgDark} alt="save-record" />
        ) : (
          <img src={saveRecordImgLight} alt="save-record" />
        )}
      </Grid>
    </Grid>
  );

  const buttonArray = [
    {
      id: 1,
      name: "Close",
      variant: "contained",
      onClickHandler: () => closeDialog(null),
      show: true,
    },
    {
      id: 2,
      name: "continue without saving",
      variant: "contained",
      onClickHandler: () => closeDialog(true),
      show: type !== "save",
    },
  ];

  return (
    <DialogPopup
      title="You have unsaved changes!"
      content={content}
      buttonArray={buttonArray}
      openPopup
      onCancel={undefined}
      showActionBtn
      maxWidth="sm"
      hideIcon="none"
    />
  );
}
WarningDialog.propTypes = {
  handleDialogAction: PropTypes.func.isRequired,
  tabIndexRef: PropTypes.shape({
    current: PropTypes.shape({
      type: PropTypes.string,
      unsavedDgList: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};
export default WarningDialog;
