import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import EditableDocument from "./EditableDocument";
import ReadOnlyDocument from "./ReadOnlyDocument";

import { Styled } from "./styles";
import ProTip from "../../ProTip";
import { useReviewPageCheck } from "../context/reviewItemContext";
import { getDatagridFormMethods, getUniqueId } from "../services/form.service";
import { methodRefs, updateFormDocuments } from "../context/formSlice";
import { useDatagridContextValue } from "../context/datagridContext";
import {
  getFilteredDgDocuments,
  getInitialDocs,
  updateFormDocumentList,
} from "../services/formDocument.service";

function DgDocument(props) {
  const {
    fieldProps,
    isLoading,
    documentAction,
    allDocument,
    trigger,
    recordIdRef,
    uploadProgress,
    setUploadProgress,
  } = props;
  const { properties, documentTypeId } = fieldProps;
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const isReviewContext = useReviewPageCheck();
  const { datagridFormState } = useDatagridContextValue();

  const dgDocumentList = useSelector((state) => state.formProps.dgDocumentList);
  const { domainName, isSinglePageLayout } = useSelector(
    (state) => state.formProps.formConfig
  );
  const dgDocuments = useSelector(
    ({ formProps }) => formProps.formDocuments.dgDocuments
  );

  const isLicenseForm = domainName === "LicenseForm";

  const formMethods = getDatagridFormMethods(
    methodRefs,
    datagridFormState,
    isReviewContext
  );

  useEffect(() => {
    if (!formMethods.getValues("docRefId")) {
      const dgRecordId = getUniqueId();
      formMethods.register("docRefId");
      formMethods.setValue("docRefId", dgRecordId);
      recordIdRef.current = dgRecordId;
    } else recordIdRef.current = formMethods.getValues("docRefId");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dgRecordId = formMethods?.getValues("docRefId");
  const isRecordSaved = formMethods?.getValues("status") === "saved";
  const documents =
    (dgDocuments[dgRecordId] && dgDocuments[dgRecordId][documentTypeId]) || [];

  const getDefaultPayload = () => ({
    dispatch,
    isReviewContext,
    updateFormDocuments,
    documentTypeId,
    dgRecordId,
  });

  useEffect(() => {
    if (isLicenseForm) {
      const initialDocList = getInitialDocs(
        documentTypeId,
        dgDocumentList,
        dgRecordId
      );
      const payload = {
        ...getDefaultPayload(),
        documents: initialDocList,
      };
      updateFormDocumentList(payload);
    } else if (applicationId && applicationId !== "new" && !isReviewContext) {
      trigger(applicationId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allDocument?.documents && dgDocumentList && !isLicenseForm) {
      const filteredDocs = getFilteredDgDocuments(
        documentTypeId,
        dgRecordId,
        dgDocumentList,
        allDocument
      );
      if (filteredDocs.length) {
        const payload = {
          ...getDefaultPayload(),
          documents: filteredDocs,
        };
        updateFormDocumentList(payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocument]);

  const memoizedEditableDocument = useMemo(
    () => (
      <EditableDocument
        fieldProps={fieldProps}
        isLoading={isLoading}
        documents={documents}
        formMethods={formMethods}
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}
        documentActionHandler={documentAction}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents, isLoading]
  );

  // for license details page, ignore "optional" fields when there is no document
  if (isLicenseForm && !documents.length) {
    return "";
  }

  return (
    <Styled>
      {properties && properties.title && (
        <Box className="heading">
          <Typography>
            {properties.heading}
            {properties?.proTip && <ProTip fieldKey={properties?.proTip} />}
          </Typography>
        </Box>
      )}
      {!isReviewContext && !isSinglePageLayout && !isRecordSaved ? (
        memoizedEditableDocument
      ) : (
        <ReadOnlyDocument fieldProps={fieldProps} documents={documents} />
      )}
    </Styled>
  );
}
DgDocument.propTypes = {
  fieldProps: PropTypes.shape({
    key: PropTypes.string.isRequired,
    documentTypeId: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      title: PropTypes.bool,
      heading: PropTypes.string,
    }),
  }).isRequired,
  documentAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allDocument: PropTypes.PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  trigger: PropTypes.func.isRequired,
  recordIdRef: PropTypes.shape({ current: PropTypes.string }).isRequired,
};
DgDocument.defaultProps = {
  allDocument: {},
};
export default DgDocument;
