/**
 * Created by nagib on 10/31/2018.
 */
import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { browserName } from "react-device-detect";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-unresolved
import buildConfig from "@regional/config/build.config.json";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAdd from "@mui/icons-material/PersonAdd";
import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/material/Alert";

import HeaderLogo from "../HeaderLogo";
import HelpDialog from "../HelpDialog";
import classes from "./header.module.css";
import UserMenu from "./UserMenu";
import RejectionAlert from "../RejectionAlert";
import GettingStartedDialog from "../GettingStartedDialog";
import { CustomThemeContext } from "../../../../theme/CustomThemeProvider";
import { useLeftNavConfigMutation } from "../../../../api/core.api.slice";
import { setNavConfig } from "../../slices/leftNav.slice";
import { showNotification } from "../../slices/notification.slice";
import { notifyError, isElevatedEnv } from "../../../../utils/helpers";

function Header(props) {
  const { user, isMaintenance } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { drawerVisible, setDrawer } = useContext(CustomThemeContext);
  const [showWarning, setShowWarning] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [navConfigData, setNavConfigData] = useState();
  const [getNavConfig] = useLeftNavConfigMutation();
  let menuIcon;
  let registerBtn;
  let userMenu;
  let helpBtn;
  let isSandBox;

  const logoClassMap = {
    default: {},
    moLogo: {
      padding: "2px !important",
    },
  };
  const logoStyles =
    logoClassMap[buildConfig?.logoClass] || logoClassMap.default;

  React.useEffect(() => {
    if (
      browserName !== "Chrome" &&
      browserName !== "Safari" &&
      browserName !== "Mobile Safari" &&
      browserName !== "Edge" &&
      browserName !== "Firefox"
    ) {
      setShowWarning(true);
    }
  }, []);

  React.useEffect(() => {
    if (!navConfigData && user) {
      const fetchNavConfig = async () => {
        try {
          const { data } = await getNavConfig();
          if (data) {
            dispatch(setNavConfig(data));
            setNavConfigData(data);
          }
        } catch (err) {
          notifyError(dispatch, showNotification, err);
        }
      };
      fetchNavConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const toggleDrawer = () => {
    setDrawer(!drawerVisible);
  };
  const toggleHelpDialog = () => {
    setShowHelpDialog(!showHelpDialog);
  };

  if (user && !pathname.includes("browser-support")) {
    menuIcon = (
      <Hidden mdUp>
        <IconButton
          className={classes.menuButton}
          aria-label="Menu"
          onClick={toggleDrawer}
        >
          <MenuIcon sx={{ fontSize: { xs: "1.3rem", sm: "1.5rem" } }} />
        </IconButton>
      </Hidden>
    );

    userMenu = <UserMenu toggleHelpDialog={toggleHelpDialog} />;
    helpBtn = (
      <Button onClick={toggleHelpDialog} className={classes.helpBtn}>
        <InfoIcon sx={{ fontSize: ".85rem" }} />
        {process.env.REACT_APP_COMPLIA_INSTALL === "nj-crc"
          ? "Help/FAQ"
          : "Help"}
      </Button>
    );
  }
  if (
    !user &&
    !isMaintenance &&
    window.location.hostname !== "ar-dfa-public-sandbox.nls.egov.com"
  ) {
    registerBtn = (
      <Button component={Link} to="/register" className={classes.registerLink}>
        <PersonAdd className={classes.headerIcon} />
        <Typography color="primary">Register</Typography>
      </Button>
    );
  }
  if (isElevatedEnv) {
    isSandBox = (
      <Grid className={classes.testIndicator} item lg={1} md={1} sm={1} xs={1}>
        <span className={classes.testIndicatorTitle}>TEST</span>
        <span className={classes.testIndicatorBg} />
      </Grid>
    );
  }
  return (
    <div className={classes.root}>
      <AppBar position="relative" color="secondary">
        <Toolbar sx={{ height: "64px" }} disableGutters>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Grid item md={3} sm={5} xs={8} className={classes.headerLogo}>
              <Grid item>{menuIcon}</Grid>
              <Grid item className={classes.logoImage} sx={logoStyles}>
                <HeaderLogo />
              </Grid>
            </Grid>
            <Grid item xs />
            {!pathname.includes("browser-support") && (
              <>
                <Hidden smDown>
                  <Grid
                    item
                    sm={2.2}
                    md={2}
                    lg={1.2}
                    container
                    justifyContent="end"
                  >
                    {helpBtn}
                  </Grid>
                </Hidden>
                <Grid
                  className={isSandBox ? classes.testTagToBackground : null}
                  item
                  lg={1.5}
                  md={2.1}
                  sm={3}
                  xs={isSandBox ? 3 : 4}
                  container
                  justifyContent="center"
                  sx={() => ({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  })}
                >
                  {userMenu}
                  {registerBtn}
                </Grid>
                {isSandBox && isSandBox}
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {showWarning && !pathname.includes("browser-support") && !isMaintenance && (
        <Grid
          container
          justifyContent="right"
          sx={(theme) => ({
            position: pathname.includes("login") ? "absolute" : undefined,
            [theme.breakpoints.down("md")]: {
              position: "static",
            },
          })}
        >
          <Grid
            item
            xs={12}
            md={pathname.includes("login") ? 8 : 12}
            zIndex="1000"
          >
            <Alert
              severity="warning"
              onClose={() => setShowWarning(false)}
              sx={(theme) => ({
                borderRadius: 0,
                ml: user ? "285.5px" : 0,
                [theme.breakpoints.down("md")]: {
                  ml: "0px",
                },
              })}
            >
              Please use a supported browser for best performance. Please click{" "}
              <Typography
                component={Link}
                variant="p"
                className="customLink"
                target="_blank"
                to="/browser-support"
              >
                here for a list of supported browsers
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      )}
      {user && navConfigData?.showGettingStarted && <GettingStartedDialog />}
      {showHelpDialog && (
        <HelpDialog show={showHelpDialog} setShow={toggleHelpDialog} />
      )}
      {user && navConfigData?.showRejectionAlert && <RejectionAlert />}
    </div>
  );
}

Header.whyDidYouRender = true;
Header.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  isMaintenance: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
};
Header.defaultProps = {
  user: null,
};
export default Header;
