/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formDialog: {
    show: false,
  },
  verifiedAddress: {},
};
const formDialogSlice = createSlice({
  name: "formDialog",
  initialState,
  reducers: {
    showFormDialog(state, { payload }) {
      state.formDialog = payload;
    },
    hideFormDialog(state) {
      state.formDialog = initialState.formDialog;
    },
  },
});

export const { showFormDialog, hideFormDialog } = formDialogSlice.actions;
export default formDialogSlice.reducer;
