import React from "react";

export default function ReviewPageBanner() {
  return (
    <span>
      Please review the application for accuracy and completeness. If you have
      any items marked with a red X, your application will not be accepted.
      Please review these items to ensure accuracy
      <br />
      <span style={{ paddingLeft: "20px" }}>
        WARNING: Once your application is submitted, it cannot be modified.
        Please make sure your application is final and complete before
        submitting.
      </span>
    </span>
  );
}
