/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useFormState, Controller, useWatch } from "react-hook-form";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

import LinearLoader from "../../Loaders/Global";
import { useGetAutoCompListQuery } from "../../../api/forms.api.slice";
import { showNotification } from "../../../features/core/slices/notification.slice";
import {
  constructSearchUrl,
  getUpdatedOptionList,
} from "../services/form.service";
import { notifyError } from "../../../utils/helpers";

const Styled = styled(Box)({
  width: "100%",
  "& .formControl": {
    height: "75px",
    "& div:not(.Mui-disabled):before": { borderBottom: "none" },
    "& div:hover:not(.Mui-disabled):before": { borderBottom: "none" },
    justifyContent: "center",
  },
  "& .label": {
    left: "-12px",
    top: "-28px",
  },
  "& .MuiAutocomplete-input": {
    marginLeft: "10px",
    paddingTop: "25px",
  },
  "& .MuiAutocomplete-clearIndicator": {
    marginLeft: "5px",
  },
  "& .field": {
    boxShadow:
      "0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%)",
  },
  // "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
  //   backgroundColor: "red",
  // },
});

function FormAutoCompleteField(props) {
  const { fieldProps, formProps, control, onAutoCmpSelect } = props;
  const dispatch = useDispatch();
  const { errors } = useFormState({
    control,
    name: fieldProps.key,
  });
  const { controllingField, controllingFieldLabel, controllingFieldReqKey } =
    fieldProps.properties || {};
  const controllingFieldValue = useWatch({
    name: controllingField,
    control,
    defaultValue: formProps[controllingField],
    disabled: !controllingField,
  });

  const [autoCmpText, setAutoCmpText] = useState(
    (typeof formProps[fieldProps.key] === "string"
      ? formProps[fieldProps.key]
      : formProps[fieldProps.key]?.display) || ""
  );
  const [skip, setSkip] = useState(true);
  const [sourceType, setSourceType] = useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState([]);

  const params = fieldProps.properties?.formatType
    ? { formatType: fieldProps.properties.formatType }
    : undefined;

  const initialProps = {
    url: "",
    params,
  };
  const [autoCompQuery, setAutoCompQuery] = useState(initialProps);
  const {
    data: autoCompData,
    isFetching: loading,
    error,
  } = useGetAutoCompListQuery(autoCompQuery, {
    skip: skip || autoCompQuery.url.length < 2,
  });

  const handleAutoCmpSelect = (ev, value, source) => {
    if (source === "selectOption") setSkip(true);
    if (source === "selectOption" || source === "clear") {
      const newVal = source === "selectOption" ? value : null;
      onAutoCmpSelect(newVal);
    }
  };
  const handleInputChange = (ev, value, source) => {
    if (!open && source === "input") setOpen(true);
    if (value || source === "input") {
      setAutoCmpText(value);
    }
    if (source === "clear") {
      setAutoCmpText("");
      setOpen(false);
    }
    setSourceType(source);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAutoCompQuery = useCallback(
    debounce((autoCompleteQuery) => setAutoCompQuery(autoCompleteQuery), 700),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => debouncedSetAutoCompQuery.cancel(), []);

  useEffect(() => {
    if (autoCompData && autoCompData.length > 0) {
      const autoCompItems = getUpdatedOptionList(autoCompData, fieldProps);
      setOptions(autoCompItems);
    }
    if (error) {
      notifyError(dispatch, showNotification, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCompData]);

  useEffect(() => {
    if (autoCmpText.length < 3) {
      setSkip(true);
      setAutoCompQuery(initialProps);
      debouncedSetAutoCompQuery.cancel();
      return undefined;
    }
    if (autoCmpText && sourceType !== "reset" && sourceType !== "") {
      setSkip(false);
    }
    const url = constructSearchUrl(
      fieldProps.url,
      fieldProps.properties,
      autoCmpText
    );

    const query = { ...autoCompQuery, url };
    if (fieldProps.properties && controllingField && controllingFieldValue) {
      let keyAlias = controllingField;
      if (controllingFieldReqKey) keyAlias = controllingFieldReqKey;
      if (
        !controllingFieldReqKey &&
        fieldProps.properties[controllingField] &&
        fieldProps.properties[controllingField].startsWith("@")
      ) {
        keyAlias = fieldProps.properties[controllingField].substring(1);
      }
      query.params = {
        ...query.params,
        [`properties.${keyAlias}`]: controllingFieldValue,
      };
    }
    if (url) {
      debouncedSetAutoCompQuery(query);
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCmpText]);

  useEffect(() => {
    debouncedSetAutoCompQuery.cancel();
    setAutoCmpText("");
    setOptions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllingFieldValue]);

  const noOptionsText =
    autoCmpText && autoCmpText.length > 2 && sourceType
      ? `No accounts matching "${autoCmpText}" were found.`
      : "No accounts found";

  return (
    <Styled>
      <FormControl fullWidth margin="dense" className="formControl">
        <InputLabel className="label">{fieldProps.label}</InputLabel>
        <Controller
          control={control}
          name={fieldProps.key}
          defaultValue={formProps[fieldProps.key] || ""}
          render={({ field: { onChange, ...field } }) => (
            <Autocomplete
              open={open}
              onOpen={() => {
                if (sourceType !== "reset") setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              clearOnBlur
              blurOnSelect
              loading={loading}
              options={options}
              renderOption={(optionProps, option) => (
                <Box component="li" {...optionProps} key={option.value}>
                  {option.label}
                </Box>
              )}
              inputValue={autoCmpText}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              onChange={(e, value, source) => {
                const val = value || "";
                handleAutoCmpSelect(e, value, source);
                onChange(val);
              }}
              onInputChange={handleInputChange}
              popupIcon={false}
              noOptionsText={noOptionsText}
              componentsProps={{
                paper: { square: true },
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                value === "" ||
                option.display === value.display
              }
              {...field}
              id={fieldProps.key}
              renderInput={(param) => (
                <>
                  <TextField
                    // {...field}
                    {...param}
                    fullWidth
                    placeholder={fieldProps.placeholder}
                    variant="standard"
                    className="field"
                    InputProps={{
                      ...param.InputProps,
                      disableUnderline: true,
                      // endAdornment: (
                      //   <>
                      //     {loading ? (
                      //       <LocalLoader progress={loading} size={10} />
                      //     ) : null}
                      //     {params.InputProps?.endAdornment}
                      //   </>
                      // ),
                    }}
                  />
                  {loading && <LinearLoader custom />}
                </>
              )}
              disabled={controllingField && !controllingFieldValue}
            />
          )}
        />
        {errors[fieldProps.key] && (
          <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
            This is required.
          </FormHelperText>
        )}
        {controllingField && !controllingFieldValue && (
          <FormHelperText error id="{fieldProps.key}-text" sx={{ margin: 0 }}>
            {controllingFieldLabel} is required.
          </FormHelperText>
        )}
      </FormControl>
    </Styled>
  );
}
FormAutoCompleteField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
    placeholder: PropTypes.string,
    properties: PropTypes.shape({
      formatType: PropTypes.string,
      itemValue: PropTypes.string,
    }),
    tags: PropTypes.oneOfType([PropTypes.array]),
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  onAutoCmpSelect: PropTypes.func.isRequired,
};
export default FormAutoCompleteField;
