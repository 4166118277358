import { requireFieldsInfo } from "../components/Form/context/formSlice";
import {
  getSanitizedFormData,
  updateDatagridStatus,
} from "../components/Form/services/form.service";
import { axiosUploadHelper } from "./api";
import splitApi from "./emptySplitApi";

const applicationsApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    applications: build.query({
      query: (props) => ({
        url: "/api/applicationForms",
        params: props,
      }),
      transformResponse(baseQueryReturnValue) {
        const { pages, total, limit, page } = baseQueryReturnValue;
        // eslint-disable-next-line no-param-reassign
        baseQueryReturnValue.pagination = { pages, total, limit, page };
        return baseQueryReturnValue;
      },
      providesTags: ["Applications"],
    }),
    applicationRecord: build.query({
      query: (applicationId) => `api/applicationForms/${applicationId}`,
      providesTags: ["Application"],
    }),
    createApplication: build.mutation({
      query: (body) => ({
        url: "/api/applicationForms",
        method: "POST",
        body: getSanitizedFormData(body, null, { ...requireFieldsInfo }),
      }),
      invalidatesTags: ["Application", "Applications"],
    }),
    cloneApplication: build.mutation({
      query: (body) => ({
        url: "/api/applicationForms/clone",
        method: "POST",
        body: getSanitizedFormData(body, null, { ...requireFieldsInfo }),
      }),
    }),

    getRejectedApplications: build.query({
      query: (props) => ({
        url: "/api/applicationForms",
        params: props,
        method: "GET",
      }),
      providesTags: ["RejectedApplications"],
    }),

    getApplication: build.mutation({
      query: ({ id }) => ({
        url: `/api/applicationForms/${id}`,
        method: "GET",
      }),
      transformResponse(baseQueryReturnValue, meta, arg = {}) {
        const { allowDataTransform, dgProps } = arg;
        if (allowDataTransform) {
          const { formProperties } = baseQueryReturnValue;
          updateDatagridStatus(formProperties, dgProps);
        }
        return baseQueryReturnValue;
      },
    }),
    getFormConfig: build.mutation({
      query: (params) => ({
        url: "api/config/formConfig",
        method: "GET",
        params,
      }),
    }),
    listConfig: build.query({
      query: () => ({
        url: "/dist/config/applications.json",
      }),
      providesTags: ["Config"],
    }),
    updateApplicationForm: build.mutation({
      query: ({ id, body, originalFormProps }) => ({
        url: `/api/applicationForms/${id}`,
        method: "PUT",
        body: getSanitizedFormData(body, originalFormProps, {
          ...requireFieldsInfo,
        }),
      }),
      invalidatesTags: ["Applications"],
    }),
    validateEmail: build.mutation({
      query: (body) => ({
        url: "/v1/validate/email",
        method: "POST",
        body,
      }),
    }),
    verifyAddress: build.mutation({
      query: (body) => ({
        url: "/api/address/validate",
        method: "POST",
        body,
      }),
    }),
    deleteApplication: build.mutation({
      query: ({ id }) => ({
        url: `api/applicationForms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Applications"],
    }),
    restoreApplication: build.mutation({
      query: ({ id }) => ({
        url: `/api/applicationForms/restore/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Applications"],
    }),
    saveApplicationForm: build.mutation({
      query: ({ id, body, originalFormProps }) => ({
        url: `/api/applicationForms/${id}/efile`,
        method: "POST",
        body: getSanitizedFormData(body, originalFormProps, {
          ...requireFieldsInfo,
          applicationStatus: "Open",
        }),
      }),
      invalidatesTags: ["Application", "Applications"],
    }),
    getDocuments: build.query({
      query: (applicationId) => ({
        url: `/api/applicationForms/${applicationId}/documents`,
      }),
      providesTags: ["Documents"],
    }),
    uploadDocument: build.mutation({
      // to track upload progress
      queryFn: async ({ body, setUploadProgress }) =>
        axiosUploadHelper({
          url: "/api/documents/upload",
          method: "POST",
          body,
          setUploadProgress,
        }),
      invalidatesTags: ["Documents"],
    }),
    updateDocument: build.mutation({
      // to track upload progress
      queryFn: async ({ versionSeriesId, body, setUploadProgress }) =>
        axiosUploadHelper({
          url: `/api/documents/${versionSeriesId}/updateVersion`,
          method: "POST",
          body,
          setUploadProgress,
        }),
      invalidatesTags: ["Documents"],
    }),
    deleteDocument: build.mutation({
      query: ({ applicationId, versionSeriesId }) => ({
        url: `/api/applicationForms/${applicationId}/documents/${versionSeriesId}`,
        method: "DELETE",
      }),
      // invalidatesTags: [],
    }),
    tableConfig: build.query({
      query: (applicationType) => ({
        url: `/dist/config/${applicationType.toLowerCase()}-tableConfig.json`,
      }),
      providesTags: ["Config"],
    }),
    forfeitApplication: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/applicationForms/${id}/forfeit`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Applications"],
    }),
    getPublicStatus: build.query({
      query: (applicationId) => ({
        url: `/api/applicationForms/${applicationId}/publicStatus`,
      }),
      providesTags: ["Applications"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useApplicationsQuery,
  useTableConfigQuery,
  useCreateApplicationMutation,
  useCloneApplicationMutation,
  useListConfigQuery,
  useApplicationRecordQuery,
  useUpdateApplicationFormMutation,
  useValidateEmailMutation,
  useVerifyAddressMutation,
  useSaveApplicationFormMutation,
  useGetApplicationMutation,
  useGetFormConfigMutation,
  useLazyGetDocumentsQuery,
  useUploadDocumentMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteApplicationMutation,
  useRestoreApplicationMutation,
  useGetRejectedApplicationsQuery,
  useForfeitApplicationMutation,
  useGetPublicStatusQuery,
} = applicationsApi;
