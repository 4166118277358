import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Styled = styled(Box)(({ theme }) => ({
  "& .MuiAlert-icon": { display: "none" },
  "& .gridItem": { textAlign: "center" },
  "& .warningText": {
    fontSize: "0.85rem",
    fontWeight: theme.palette.mode === "light" ? 700 : 500,
    lineHeight: 1.5,
    letterSpacing: 0.4,
    color: theme.palette.mode === "light" ? "#31708f" : undefined,
    "& .MuiSvgIcon-root": {
      marginRight: "2px",
      verticalAlign: "sub",
      fontSize: "0.95rem",
    },
  },
  "& .message": {
    fontSize: "0.85rem",
    fontWeight: 400,
    lineHeight: 1.5,
    margin: "1rem",
    color: theme.palette.mode === "light" ? "#31708f" : undefined,
    textAlign: "center",
  },
}));

export default Styled;
