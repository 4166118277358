import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Styled = styled(Box)(({ theme }) => ({
  "& .heading": {
    borderTop: "3px solid",
    borderBottom: "3px solid",
    borderColor: theme.palette.primary.main,
    padding: "10px",
    marginTop: "15px",
    "& .MuiTypography-root": {
      fontWeight: "600",
    },
  },
  "& .docContainer": {
    position: "relative",
    transition: "all 0.3s ease-out",
    padding: ".7rem 0.5rem 0.7rem 1rem",
    "&:hover": {
      "& .clickable": {
        cursor: "pointer",
        "& .MuiTypography-root": {
          color: theme.palette.mode === "light" ? "#337ab7" : "#71cbf5",
        },
      },
    },
  },
  "& .label": {
    fontSize: "0.90rem",
    padding: "8px 0",
    textShadow: "none",
    color: theme.palette.mode === "light" ? "#000" : "unset",
    "& .attachFileIcon": {
      fontSize: "1.2rem",
      transform: "rotate(140deg)",
      verticalAlign: "sub",
      marginRight: "5px",
    },
    "& .protipIcon": {
      margin: "0 0 4px 8px",
      padding: "0px",
    },
  },
  "& .uploadBtn": {
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)",
    margin: 0,
  },
  "& .toggleBtn": {
    "& .MuiSvgIcon-root": {
      color: theme.palette.mode === "light" ? "#337ab7" : "#71cbf5",
    },
    top: "12%",
    right: "-2px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .container": { padding: ".7rem 0.5rem 0.7rem 0.2rem" },
    "& .uploadBtn": { width: "100%", marginTop: "0.8rem" },
    "& .toggleBtn": { position: "absolute" },
  },
}));

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "marginLeft",
})(({ marginLeft, theme }) => ({
  borderBottom: "1.5px solid #d8d8d8",
  padding: "0.75rem 0",
  "& .labelContainer": {
    display: "flex",
    "& .MuiSvgIcon-root": {
      marginRight: ".5rem",
    },
  },
  "& .textWrapper": {
    marginLeft: marginLeft ? "2rem" : "unset",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root:first-of-type": {
      fontSize: "0.88rem",
      fontWeight: theme.palette.mode === "light" ? 600 : 500,
      textShadow: "none",
      flex: 2.2,
    },
    "& .MuiTypography-root:nth-of-type(2)": {
      color: theme.palette.mode === "light" ? undefined : "#71cbf5",
      fontSize: "0.88rem",
      flex: 1,
    },
  },
  "& .statusItem": {
    flex: 1,
    "& .MuiTypography-root": {
      fontSize: "0.88rem",
      fontWeight: "400 !important",
      color: theme.palette.mode === "light" ? undefined : "#71cbf5",
    },
  },
  "& .docToggleBtn": {
    fontSize: "0.88rem",
    padding: "0",
    color: theme.palette.mode === "light" ? "#337ab7" : undefined,
    textTransform: "unset",
    fontWeight: 400,
    "&:hover": {
      background: "transparent",
    },
  },
  "& .collapseWrapper": {
    display: "block",
    width: "60%",
    "& .muiStack": {
      paddingTop: ".5rem",
      flexWrap: "wrap",
      "& .MuiChip-root": {
        marginRight: ".5rem",
        marginBottom: "10px",
        color: theme.palette.mode === "light" ? "#337ab7" : "#71cbf5",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
    },
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0.1rem 0",
    "& .textWrapper": { display: "block" },
    "& .docToggleBtn": { padding: "4px 0" },
    "& .collapseWrapper": { width: "100%" },
  },
}));

export const StyledTable = styled(Box)(({ theme }) => ({
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      whiteSpace: "nowrap",
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:last-child td, &:last-child th": { border: 0 },
    },
    "& .MuiTableCell-root": { padding: "8px", paddingLeft: "0" },

    "& .MuiIconButton-root": {
      padding: "0 4px 0 0",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .customLink": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "& .MuiTableCell-root": {
    paddingLeft: "0",
    fontSize: "12px",
  },
  "& .visuallyHidden": {
    width: "180px",
    visibility: "hidden",
  },
  "& .actions": {
    width: "180px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .visuallyHidden": { width: "unset" },
    "& .actions": { width: "unset", minWidth: "95px" },
  },
}));

export const StyledPreviewContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isLoading",
})(({ isLoading }) => ({
  height: "99%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: isLoading ? "relative" : "unset",
  "& .wrapper": {
    display: isLoading ? "none" : "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      maxHeight: "100%",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
  "& .info": {
    display: "flex",
    justifyContent: "center",
  },
}));

export const StyledDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",

  "& .imgContainer": {
    display: "flex",
    "& .imgLabel": {
      fontSize: "0.88rem",
      fontWeight: theme.palette.mode === "light" ? 600 : 500,
      textShadow: "none",
      paddingTop: "2px",
    },
    "& img": {
      maxHeight: "200px",
      margin: "0 auto",
      display: "block",
    },
  },
  "& .MuiFormControlLabel-root": {
    alignItems: "flex-start",
    "& .MuiCheckbox-root": {
      padding: "0 10px 0 0",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-checked": {
        color:
          theme.palette.mode === "light"
            ? "rgba(255,87,34,0.87)"
            : theme.palette.primary.main,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    marginTop: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    "& .imgContainer": {
      display: "block",
      "& img": {
        margin: "10px auto",
      },
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "-2rem",
    },
  },
}));
