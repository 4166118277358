import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import {
  selectFormData,
  selectFormConfig,
  selectFormAppData,
} from "../services/form.service";

const useGetFormStateValues = () => {
  const { isDialogForm } = useFormContext();

  const formData = useSelector((state) => selectFormData(state, isDialogForm));
  const formConfig = useSelector((state) =>
    selectFormConfig(state, isDialogForm)
  );
  const applicationData = useSelector((state) =>
    selectFormAppData(state, isDialogForm)
  );

  return { formData, formConfig, applicationData };
};

export default useGetFormStateValues;
