import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";

import FormRecordSubTable from "./FormRecordSubTable";
import DataTable from "../../../DataTable";
import LocalLoader from "../../../Loaders/Local";
import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";
import { useTableConfigQuery } from "../../../../api/applications.api.slice";
import { EditButton, RemoveButton } from "./FormRecordActionBtns";

function FormRecordTable(props) {
  const {
    licenses,
    onDelete,
    fieldProps,
    getValues,
    setValue,
    trigger,
    readonly,
    hideFilters,
  } = props;
  const subLicensesKey = fieldProps.subLicensesKey || "subLicenses";
  const { selectableLicenses = "Approved,Expired" } =
    fieldProps.properties || {};
  const { applicationType, recordType } = useParams();
  const [rowsInfo, setRowsInfo] = useState({ selectedRows: 0, totalRows: 0 });
  const [req, setReq] = useState({ limit: 5, page: 1 });
  const [filteredLicenses, setFilteredLicenses] = useState([]);
  const tableRef = useRef(null);
  const subTableRefs = useRef({});
  const subLicenseParentRefs = useRef({});
  const { updateHeight } = useUpdatePanelHeight();

  const { data: tablesConfig, isSuccess } = useTableConfigQuery(
    applicationType || recordType
  );

  const updateList = (_payload) => {
    // using getValues because this fn (updateList) is memoized by DataTable,
    // and licenses variable always has initial state values.
    let requiredLicenses = getValues(fieldProps.key) || licenses;
    const payload = {
      ..._payload,
      ...(!_payload.subLicenseType && { subLicenseType: [] }),
      ...(!_payload.subLicenseStatus && { subLicenseStatus: [] }),
    };
    if (payload.licenseNumber) {
      requiredLicenses = licenses.filter((license) =>
        license.licenseNumber
          .toString()
          .includes(payload.licenseNumber.toString())
      );
    }

    requiredLicenses = requiredLicenses.map((license) => {
      const subLicenses = (
        (license.properties && license.properties[subLicensesKey]) ||
        []
      ).filter(
        (subLicense) =>
          (payload.subLicenseType.length === 0 ||
            payload.subLicenseType.includes(subLicense.licenseType)) &&
          (payload.subLicenseStatus.length === 0 ||
            payload.subLicenseStatus.includes(subLicense.status))
      );
      return {
        ...license,
        properties: {
          ...license.properties,
          [subLicensesKey]: subLicenses || [],
        },
      };
    });

    setFilteredLicenses(requiredLicenses);
    setReq(payload);
    setTimeout(() => updateHeight(), 400);
  };

  const handleSelectAllSubTables = (event) => {
    if (readonly) return;
    const { limit, page } = req;
    const startIndex = limit * (page - 1);
    const endIndex = startIndex + limit;

    const updatedLicenses = licenses.map((license) => {
      const filterLicense = filteredLicenses.find(
        (lic, index) =>
          lic.licenseNumber === license.licenseNumber &&
          index >= startIndex &&
          index < endIndex
      );
      if (!filterLicense) return license;
      const updatedSubLicenses = (
        (license.properties && license.properties[subLicensesKey]) ||
        []
      ).map((subLicense) => {
        const isFilteredSubLicense = (
          (filterLicense.properties &&
            filterLicense.properties[subLicensesKey]) ||
          []
        ).find(
          (lic) =>
            lic.licenseNumber === subLicense.licenseNumber &&
            selectableLicenses.includes(subLicense.status)
        );
        if (!isFilteredSubLicense) return subLicense;
        return { ...subLicense, isSelected: event.target.checked };
      });
      return {
        ...license,
        properties: {
          ...license.properties,
          [subLicensesKey]: updatedSubLicenses,
        },
      };
    });
    setValue(fieldProps.key, updatedLicenses);
    trigger(fieldProps.key);
  };

  const handleSingleSubRowSelect = (selected, parentLicense, subLicense) => {
    if (readonly) return;
    const updatedLicenses = licenses.map((license) => {
      if (parentLicense.licenseNumber === license.licenseNumber) {
        const subLicenses = (
          (license.properties && license.properties[subLicensesKey]) ||
          []
        ).map((subLic) => {
          if (
            subLic.licenseNumber === subLicense.licenseNumber &&
            selectableLicenses.includes(subLic.status)
          )
            return { ...subLic, isSelected: selected };
          return subLic;
        });
        return {
          ...license,
          properties: { ...license.properties, [subLicensesKey]: subLicenses },
        };
      }
      return license;
    });
    setValue(fieldProps.key, updatedLicenses);
    trigger(fieldProps.key);
  };

  const handleSubRowSelectAll = (selected, parentLicense) => {
    if (readonly) return;
    const updatedLicenses = licenses.map((license) => {
      if (parentLicense.licenseNumber === license.licenseNumber) {
        const filteredLicense = filteredLicenses.find(
          (lic) => lic.licenseNumber === license.licenseNumber
        );
        const allSubLicenses =
          (license.properties && license.properties[subLicensesKey]) || [];
        const filteredSubLicense =
          (filteredLicense.properties &&
            filteredLicense.properties[subLicensesKey]) ||
          [];
        const updatedSubLicenses = allSubLicenses.map((subLic) => {
          if (
            filteredSubLicense.find(
              (subLicense) =>
                subLic.licenseNumber === subLicense.licenseNumber &&
                selectableLicenses.includes(subLicense.status)
            )
          )
            return { ...subLic, isSelected: selected };
          return subLic;
        });
        return {
          ...license,
          properties: {
            ...license.properties,
            [subLicensesKey]: updatedSubLicenses,
          },
        };
      }
      return license;
    });
    setValue(fieldProps.key, updatedLicenses);
    trigger(fieldProps.key);
  };

  const subTableRenderer = (license) => (
    <FormRecordSubTable
      license={license}
      subLicenses={
        (license.properties && license.properties[subLicensesKey]) || []
      }
      onSelect={(selected, subLicense) =>
        handleSingleSubRowSelect(selected, license, subLicense)
      }
      onSelectAll={(selected, subLicenses) =>
        handleSubRowSelectAll(selected, license, subLicenses)
      }
      ref={(el) => {
        subTableRefs.current[license.licenseNumber] = el;
        (
          (license.properties && license.properties[subLicensesKey]) ||
          []
        ).forEach((sub) => {
          subLicenseParentRefs.current[sub.licenseNumber] =
            license.licenseNumber;
        });
      }}
      readonly={readonly}
      fieldProps={fieldProps}
    />
  );

  const { selectedRows = 0, totalRows = 0 } = rowsInfo;
  const controlRenderer = () => (
    <Checkbox
      color="primary"
      indeterminate={selectedRows > 0 && selectedRows < totalRows}
      checked={totalRows > 0 && selectedRows === totalRows}
      onClick={readonly ? undefined : handleSelectAllSubTables}
      disabled={readonly}
    />
  );

  const actionButtonResolver = (data) => (
    <Box>
      <RemoveButton license={data} readonly={readonly} onDelete={onDelete} />
      <EditButton
        license={data}
        subLicense={null}
        fieldProps={fieldProps}
        readonly={readonly}
      />
    </Box>
  );

  const total = filteredLicenses.length;
  const pages = Math.ceil(total / req.limit) || 1;
  const startIndex = (req.page - 1) * req.limit;
  const endIndex = req.page * req.limit;
  const data = useMemo(
    () => ({
      pagination: { limit: req.limit, page: req.page, pages, total },
      rows: filteredLicenses.slice(startIndex, endIndex),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredLicenses, req]
  );

  useEffect(() => {
    if (isSuccess) updateList(req); // Filter & Updated Licenses
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenses, isSuccess]);

  useEffect(() => {
    let rowSelected = 0;
    let totalNoOfRows = 0;

    filteredLicenses.forEach((filterLicense, index) => {
      if (!(index >= startIndex && index < endIndex)) return;
      (
        (filterLicense.properties &&
          filterLicense.properties[subLicensesKey]) ||
        []
      ).forEach((subLicense) => {
        if (subLicense.isSelected) rowSelected += 1;
        totalNoOfRows += 1;
      });
    });

    setRowsInfo({ selectedRows: rowSelected, totalRows: totalNoOfRows });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLicenses]);

  useLayoutEffect(() => {
    updateHeight();
  });

  if (!isSuccess)
    return (
      <Box sx={{ height: "5rem", my: 2 }}>
        <LocalLoader progress />
      </Box>
    );

  const columns = tablesConfig?.masterTable?.columns || [];
  const filters = hideFilters ? null : tablesConfig?.masterTable?.filters;

  return (
    <Paper
      container="true"
      spacing={2}
      sx={{ position: "relative", width: "100%" }}
    >
      <DataTable
        ref={tableRef}
        progress={false}
        config={{
          rowSelect: fieldProps.isSelectable,
          pagination: true,
          hasSubTable: fieldProps.hasSubLicenses,
          compareBy: "licenseNumber",
          subLicensesKey,
        }}
        subTableRenderer={subTableRenderer}
        controlRenderer={controlRenderer}
        rows={data.rows}
        pagination={data.pagination}
        updateList={updateList}
        tableHeaders={columns}
        filters={filters}
        elevation={3}
        req={req}
        models={{ ...(tablesConfig?.masterTable.filterModels || {}) }}
        resolvers={{ action: actionButtonResolver }}
      />
      <LocalLoader progress={false} />
    </Paper>
  );
}
FormRecordTable.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
  onDelete: PropTypes.func,
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      licenseExpiryDate: PropTypes.string,
      licenseNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      recordType: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func,
  readonly: PropTypes.bool,
  hideFilters: PropTypes.bool,
};

FormRecordTable.defaultProps = {
  onDelete: undefined,
  readonly: false,
  hideFilters: false,
  trigger: () => null,
};

export default FormRecordTable;
