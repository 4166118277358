import React from "react";
// eslint-disable-next-line import/no-unresolved
import FAQ from "@regional/templates/FAQ";
import GridContainer from "../../../components/GridContainer";

function FAQPage() {
  const title = "Complia OMMA Technical Support FAQ";
  return (
    <GridContainer title={title} width="full">
      <FAQ />
    </GridContainer>
  );
}

export default FAQPage;
