import React, { useState, useRef, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import DocumentTable from "./DocumentTable";
import DialogComponent from "./Dialog";
import {
  defaultDocUploadCount,
  getStyle,
} from "../services/formDocument.service";
import useUpdatePanelHeight from "../hooks/useUpdatePanelHeight";
import ProTip from "../../ProTip";

function EditableDocument(props) {
  const {
    fieldProps,
    isLoading,
    documents,
    formMethods,
    uploadProgress,
    setUploadProgress,
    documentActionHandler,
  } = props;
  const { domainName } = useSelector((state) => state.formProps.formConfig);
  const { updateHeight } = useUpdatePanelHeight();
  const [showDocTable, setShowDocTable] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const selectedDocRef = useRef();
  const { register, setValue, unregister, getValues } = formMethods;

  const {
    documentTypeId,
    docUploadCount,
    customProperties,
    multipleDocUpload,
    optional,
  } = fieldProps;
  const isLicenseForm = domainName === "LicenseForm";
  const _docUploadCount =
    multipleDocUpload && !docUploadCount
      ? defaultDocUploadCount
      : docUploadCount;
  const showDocUploadBtn =
    (multipleDocUpload && documents.length < _docUploadCount) ||
    (!multipleDocUpload && documents.length === 0);

  const resetDialogProps = () => {
    setDialogProps({});
    setUploadProgress(0);
  };

  const getCustomStyle = (type) => {
    const inputs = {
      type,
      fieldProps,
      showDocTable,
      documents,
    };
    const style = getStyle(inputs);
    return style;
  };

  const toggleDocumentPane = (_, value) => {
    if (value) setShowDocTable(value);
    else setShowDocTable(!showDocTable);
    setTimeout(() => updateHeight(), 400);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleDocumentPane();
    }
  };

  const handleActionBtnClick = (type, document) => {
    setDialogProps({
      show: true,
      type,
    });
    selectedDocRef.current = document;
  };

  const handleDocumentAction = async (type, fileData) => {
    const payloadData = {
      type,
      fileData,
      versionSeriesId: selectedDocRef.current?.versionSeriesId,
      documents,
    };
    const result = await documentActionHandler(payloadData);
    if (result) {
      if (result === "open") toggleDocumentPane("", true);
      resetDialogProps();
    }
    selectedDocRef.current = {};
  };

  // to utilize the useForm hook's validation
  useEffect(() => {
    if (!optional && !isLicenseForm) {
      register(`${documentTypeId}__DUMMY`, { required: true });
    }
    return () => {
      unregister(`${documentTypeId}__DUMMY`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const docKeys = (documents || []).map((doc) => doc._id).join("");

  useEffect(() => {
    if (!optional && !isLicenseForm) {
      if (documents.length && !getValues(`${documentTypeId}__DUMMY`))
        setValue(`${documentTypeId}__DUMMY`, true);
      if (documents.length === 0) setValue(`${documentTypeId}__DUMMY`, "");
    }
    if (!documents.length) setShowDocTable(false);
    setTimeout(() => updateHeight(), 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docKeys]);

  const memoizedDocumentTable = useMemo(
    () => (
      <DocumentTable
        documents={documents}
        isLicenseForm={isLicenseForm}
        customProperties={customProperties}
        handleActionBtnClick={handleActionBtnClick}
        handleDocumentAction={handleDocumentAction}
        documentTypeId={documentTypeId}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [docKeys]
  );

  return (
    <Grid
      container
      alignItems="center"
      className="docContainer"
      sx={{
        borderBottom: "2px solid #d8d8d8",
        pb: getCustomStyle("pb"),
      }}
    >
      <Grid
        item
        xs={1.5}
        sm={0.6}
        className="clickable"
        onClick={toggleDocumentPane}
        onKeyDown={handleKeyDown}
      >
        <Tooltip
          title={
            documents.length
              ? "Document Present"
              : "Document Missing, please upload"
          }
        >
          <CircleIcon
            color={documents.length ? "success" : "action"}
            sx={{ verticalAlign: "middle" }}
            tabIndex={0}
          />
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={10}
        sm={7.5}
        className="clickable"
        tabIndex={0}
        onClick={toggleDocumentPane}
        onKeyDown={handleKeyDown}
      >
        <Typography className="label">
          <AttachFileIcon className="attachFileIcon" />
          {fieldProps.documentLabel}
          {!optional && !isLicenseForm ? <span>&nbsp;*</span> : ""}
          {fieldProps?.tags && fieldProps?.tags.includes("proTip") && (
            <ProTip fieldKey={fieldProps.key} />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3.2}
        justifyContent="center"
        className="clickable"
        onClick={toggleDocumentPane}
      >
        {domainName !== "LicenseForm" && showDocUploadBtn && (
          <Button
            startIcon={<UploadIcon />}
            variant="text"
            className="uploadBtn"
            onClick={(e) => {
              e.stopPropagation();
              handleActionBtnClick("upload");
            }}
          >
            upload
          </Button>
        )}
      </Grid>
      <Grid item className="toggleBtn">
        <IconButton
          aria-label="toggle pane"
          onClick={toggleDocumentPane}
          edge="end"
        >
          {showDocTable ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showDocTable}>
          {documents.length > 0 ? memoizedDocumentTable : ""}
        </Collapse>
      </Grid>
      {dialogProps.show && (
        <DialogComponent
          isLoading={isLoading}
          fieldProps={fieldProps}
          dialogProps={dialogProps}
          resetDialogProps={resetDialogProps}
          uploadProgress={uploadProgress}
          handleDocumentAction={handleDocumentAction}
          selectedDocument={selectedDocRef.current}
        />
      )}
    </Grid>
  );
}

EditableDocument.whyDidYouRender = true;
EditableDocument.propTypes = {
  fieldProps: PropTypes.shape({
    documentLabel: PropTypes.string.isRequired,
    documentTypeId: PropTypes.string.isRequired,
    multipleDocUpload: PropTypes.bool,
    docUploadCount: PropTypes.number,
    optional: PropTypes.bool,
    customProperties: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  documentActionHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formMethods: PropTypes.shape({
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
  }).isRequired,
};
export default EditableDocument;
