import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import DgDocument from "./DgDocument";
import Document from "./Document";
import {
  useDeleteDocumentMutation,
  useLazyGetDocumentsQuery,
  useUpdateDocumentMutation,
  useUploadDocumentMutation,
} from "../../../api/applications.api.slice";
import { updateFormDocuments } from "../context/formSlice";
import { useGetDgKey } from "../context/datagridContext";
import { showNotification } from "../../../features/core/slices/notification.slice";
import {
  dispatchHandler,
  getDocumentPayload,
} from "../services/formDocument.service";
import { notifyError } from "../../../utils/helpers";

function FormDocument(props) {
  const { fieldProps, index } = props;
  const { documentTypeId } = fieldProps;
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const recordIdRef = useRef(null);
  const dgKey = useGetDgKey();

  const [trigger, { data: allDocument, error: allDocumentError }] =
    useLazyGetDocumentsQuery(); // for parallel api call
  const [uploadDocument, { error: uploadError, isLoading: uploadLoading }] =
    useUploadDocumentMutation();
  const [deleteDocument, { error: deleteError, isLoading: deleteLoading }] =
    useDeleteDocumentMutation();
  const [updateDocument, { error: updateError, isLoading: updateLoading }] =
    useUpdateDocumentMutation();

  const [uploadProgress, setUploadProgress] = React.useState(0);

  useEffect(() => {
    const errorObj =
      allDocumentError || deleteError || uploadError || updateError;
    if (errorObj) {
      notifyError(dispatch, showNotification, errorObj);
      if (uploadError || updateError) setUploadProgress(0);
    }
  }, [allDocumentError, deleteError, uploadError, updateError, dispatch]);

  const getDispatchPayload = () => ({
    dispatch,
    updateFormDocuments,
    key: documentTypeId,
    ...(dgKey && { dgRecordId: recordIdRef.current }),
  });

  const handleUploadDocument = async (fileData, documents) => {
    const { file, customPropsData = {} } = fileData;
    const payloadData = {
      file,
      parentType: "ApplicationForm",
      documentTypeId,
      parentId: applicationId,
    };
    if (dgKey && recordIdRef.current) {
      customPropsData.dgObjectRefId = recordIdRef.current;
      customPropsData.dgKey = dgKey;
    }

    const documentPayload = {
      body: getDocumentPayload(payloadData, customPropsData),
      setUploadProgress,
    };
    const newDoc = await uploadDocument(documentPayload).unwrap();
    if (newDoc) {
      const msg = "Document uploaded successfully.";
      dispatch(showNotification({ type: "success", msg, show: true }));
      const newDocList = [...documents, newDoc];
      dispatchHandler({ ...getDispatchPayload(), value: newDocList });
    }
    return "open";
  };

  const handleUpdateDocument = async (fileData, versionSeriesId, documents) => {
    const { file, customPropsData = {} } = fileData;
    const payloadData = {
      file,
      parentType: "ApplicationForm",
      documentTypeId,
      parentId: applicationId,
      versionSeriesId,
    };
    if (dgKey && recordIdRef.current) {
      customPropsData.dgObjectRefId = recordIdRef.current;
      customPropsData.dgKey = dgKey;
    }

    const documentPayload = getDocumentPayload(payloadData, customPropsData);
    const payload = {
      versionSeriesId,
      body: documentPayload,
      setUploadProgress,
    };
    const updatedDoc = await updateDocument(payload).unwrap();
    if (updatedDoc) {
      const msg = "Document updated successfully.";
      dispatch(showNotification({ type: "success", msg, show: true }));
      const updatedDocList = documents.map((doc) =>
        doc.versionSeriesId === updatedDoc.versionSeriesId ? updatedDoc : doc
      );
      dispatchHandler({ ...getDispatchPayload(), value: updatedDocList });
    }
    return true;
  };

  const handleDeleteDocument = async (versionSeriesId, documents) => {
    const payload = { applicationId, versionSeriesId };
    const result = await deleteDocument(payload).unwrap();
    if (result) {
      const msg = "Document deleted successfully.";
      dispatch(showNotification({ type: "success", msg, show: true }));
      const newDocList = documents.filter(
        (doc) => doc.versionSeriesId !== versionSeriesId
      );
      dispatchHandler({
        ...getDispatchPayload(),
        value: newDocList,
        type: "delete",
        requiredDocuments: result.requiredDocuments,
      });
    }
    return true;
  };

  const documentAction = async (payloadData) => {
    const { fileData, type, versionSeriesId, documents } = payloadData;
    switch (type) {
      case "delete": {
        const result = await handleDeleteDocument(versionSeriesId, documents);
        return result;
      }
      case "upload": {
        const result = await handleUploadDocument(fileData, documents);
        return result;
      }
      case "update": {
        const result = await handleUpdateDocument(
          fileData,
          versionSeriesId,
          documents
        );
        return result;
      }
      default:
        return false;
    }
  };

  const commonProps = {
    trigger,
    allDocument,
    fieldProps,
    documentAction,
    isLoading: uploadLoading || deleteLoading || updateLoading,
    index,
    uploadProgress,
    setUploadProgress,
  };

  if (dgKey) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DgDocument recordIdRef={recordIdRef} {...commonProps} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Document {...commonProps} />;
}
FormDocument.propTypes = {
  fieldProps: PropTypes.shape({
    documentTypeId: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      title: PropTypes.bool,
      heading: PropTypes.string,
    }),
  }).isRequired,
};
FormDocument.whyDidYouRender = true;
export default FormDocument;
