import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExclamationIcon from "@mui/icons-material/Error";
import BlockIcon from "@mui/icons-material/Block";
import StyledLicenseInfo from "./styles";
import { useFormConfigQuery } from "../../../../api/licenses.api.slice";
import { getLicenseInfoMapping } from "../../services/form.service";

const iconMap = [
  {
    systemName: "Approved",
    label: "Approved",
    icon: <CheckCircleIcon color="success" />,
  },
  {
    systemName: "Expired",
    label: "Expired",
    icon: <ExclamationIcon color="error" />,
  },
  {
    systemName: "Deactivated",
    label: "Deactivated",
    icon: <BlockIcon />,
  },
];

function StatusIcon(props) {
  const { status } = props;
  const iconObj = iconMap.find((iconInfo) => iconInfo.systemName === status);

  if (!iconObj) return null;
  return <Tooltip title={iconObj.label}>{iconObj.icon}</Tooltip>;
}

function BannerText(props) {
  const { item, mappedValues, formProps } = props;

  let value = mappedValues[item.systemName] || formProps[item.systemName];
  if (item.type === "date") value = moment(value).format("MM/DD/YYYY");
  if (item.label && item.cssClass?.row !== "flex-col") value = `: ${value}`;

  return (
    <Typography className={item.cssClass.row} tabIndex="0">
      {item.label && (
        <span className={`label ${item.cssClass.label}`}>{item.label}</span>
      )}
      <span className={`text ${item.cssClass.text}`}>{value}</span>
    </Typography>
  );
}

BannerText.propTypes = {
  item: PropTypes.shape({}).isRequired,
  mappedValues: PropTypes.shape({}).isRequired,
  formProps: PropTypes.shape({}).isRequired,
};

function FormLicenseInfo(props) {
  const { fieldProps, formProps } = props;
  const licenseStatus = formProps?.licenseProps?.status;
  const allFormProps = { ...formProps, ...formProps?.licenseProps };
  const { recordType } = useParams();
  const { data: formConfig } = useFormConfigQuery({
    formType: "record",
    recordType,
  });

  const { left = [], right = [] } = fieldProps?.columns || {};
  const fields = [...left, ...right].map((x) => x.systemName);
  const mappedValues = getLicenseInfoMapping(formConfig, fields, allFormProps);

  return (
    <StyledLicenseInfo>
      <Paper className="header" elevation={0}>
        <Box className="icon">
          <StatusIcon status={licenseStatus} />
        </Box>
        <Grid container className="container">
          <Grid item xs={12} sm={6} lg={6}>
            <Box className="left-column">
              {left.map((item) => (
                <BannerText
                  key={item.systemName}
                  item={item}
                  formProps={allFormProps}
                  mappedValues={mappedValues}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Box className="right-column">
              {right.map((item) => (
                <BannerText
                  key={item.systemName}
                  item={item}
                  formProps={allFormProps}
                  mappedValues={mappedValues}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </StyledLicenseInfo>
  );
}

FormLicenseInfo.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    columns: PropTypes.shape({
      left: PropTypes.arrayOf(PropTypes.shape({})),
      right: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
};

export default FormLicenseInfo;
