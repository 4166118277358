import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
// eslint-disable-next-line import/no-unresolved
import HelpDialogContent from "@regional//templates/Help";
import DialogPopup from "../../../../components/DialogPopup";

function HelpDialog({ show, setShow }) {
  const dialogContent = (
    <Box
      sx={{
        "& .MuiTypography-root": {
          mb: "0.8em",
          fontSize: "0.88rem",
        },
      }}
    >
      <HelpDialogContent />
      <Box>
        <Alert
          role="definition"
          tabIndex={0}
          severity="info"
          elevation={2}
          sx={{ width: { xs: "85%", sm: "70%" }, m: "0 auto 1em auto" }}
        >
          If you do not receive email notifications, please check your spam
          folder.
        </Alert>
      </Box>
    </Box>
  );
  return (
    <DialogPopup
      title="Help"
      onCancel={setShow}
      openPopup={show}
      content={dialogContent}
    />
  );
}
HelpDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
export default HelpDialog;
