import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";

// eslint-disable-next-line import/no-unresolved
import buildConfig from "@regional/config/build.config.json";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Hidden from "@mui/material/Hidden";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DescriptionIcon from "@mui/icons-material/Description";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import TuneIcon from "@mui/icons-material/Tune";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import HeaderLogo from "../HeaderLogo";
import LocalLoader from "../../../../components/Loaders/Local";
import AccountCard from "./AccountCard";
import classes from "./navigation.module.css";

import { useUserTenantsQuery } from "../../../../api/tenants.api.slice";
import { CustomThemeContext } from "../../../../theme/CustomThemeProvider";
import { isAuthenticated } from "../../../../services/auth.service";
import { useSwitchAccountMutation } from "../../../../api/auth.api.slice";
import { getLogin } from "../../../auth/slices/auth.slice";
import { openDialog } from "../../slices/dialog.slice";
import { showNotification } from "../../slices/notification.slice";

const switchAccPermission = "switch_account";

function NavItems(props) {
  const iconMap = {
    CardMembershipIcon: <CardMembershipIcon color="primary" />,
    DescriptionIcon: <DescriptionIcon color="primary" />,
    MoneyIcon: <MoneyIcon color="primary" />,
  };
  const { listItems, drawerHandler, pathName } = props;
  const sortedListItems = [...listItems].sort(
    (arg1, arg2) => arg1.order - arg2.order
  );
  return (
    <List
      sx={(theme) => ({
        "&& .Mui-selected, && .Mui-selected:hover": {
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.primary.light
              : theme.palette.primary.dark,
          "&, & .MuiSvgIcon-root": {
            color: "white",
          },
        },
      })}
    >
      {sortedListItems.map((item) => (
        <ListItem disablePadding key={item.systemName}>
          <ListItemButton
            component={Link}
            to={item.link}
            onClick={drawerHandler}
            onKeyDown={drawerHandler}
            // selected={pathName !== "" && item.link.includes(pathName)}
            selected={pathName !== "" && pathName.includes(item.link.slice(1))}
          >
            <ListItemIcon sx={{ color: "primary.contrastText" }}>
              {iconMap[item.icon]}
            </ListItemIcon>
            <ListItemText primary={item.displayName} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
NavItems.propTypes = {
  listItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  drawerHandler: PropTypes.func.isRequired,
  pathName: PropTypes.string.isRequired,
};

export default function Navigation(props) {
  const { currentTenant } = props;
  const [skip, setSkip] = useState(true);
  const { navConfig } = useSelector((state) => state.navConfig);
  const { permissionList: permissions, userData } = useSelector(
    (state) => state.auth
  );
  const { data: tenantList } = useUserTenantsQuery(userData?.href, { skip });
  const [switchAccount, { isLoading }] = useSwitchAccountMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { drawerVisible, setDrawer, tabBelow } = useContext(CustomThemeContext);
  const [navState, setNavState] = React.useState({});

  const logoClassMap = {
    default: {},
    moLogo: {
      padding: "3px 0",
    },
  };
  const logoStyles =
    logoClassMap[buildConfig?.logoClass] || logoClassMap.default;

  const switchTenantHandler = useCallback(async (id) => {
    try {
      await switchAccount(id).unwrap();
      dispatch(getLogin());
      dispatch(
        showNotification({
          type: "success",
          msg: "Account switched Successfully.",
          show: true,
        })
      );

      navigate("/licenses", { replace: true });
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          msg:
            error?.data?.message ||
            "Could not process request, please try later.",
          show: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDrawer = useCallback(
    (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setDrawer(!tabBelow);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setDrawer, tabBelow]
  );

  const handleClick = (item) => {
    setNavState({ ...navState, [item]: !navState[item] });
  };
  const accountCard = useMemo(
    () => (
      <AccountCard
        tenantList={tenantList}
        permissions={permissions}
        currentTenant={currentTenant}
        switchTenantHandler={switchTenantHandler}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantList, currentTenant]
  );

  useEffect(() => {
    if (isAuthenticated()) {
      // dispatch(getNavigation());
      if (permissions && permissions.indexOf(switchAccPermission) === -1) {
        setSkip(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname.includes("accounts")) {
      setNavState({ ...navState, account: true });
    }
    if (
      pathname.includes("tenants") ||
      pathname.replace(/[^\w\s]/gi, "") === "users" ||
      pathname.includes("iamusers")
    ) {
      setNavState({ ...navState, "control-panel": true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      variant={tabBelow ? "temporary" : "persistent"}
      open={drawerVisible}
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div role="navigation">
        <List disablePadding aria-label="go to home" role="banner">
          <ListItemButton className={classes.navHeaderLogo} sx={logoStyles}>
            <HeaderLogo />
            <Hidden mdUp>
              <HighlightOffIcon
                onClick={closeDrawer}
                color="secondary"
                sx={{ position: "absolute", top: "8px", right: "10px" }}
              />
            </Hidden>
          </ListItemButton>
        </List>
        <Divider aria-hidden="true" />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div id="mainSideNav" tabIndex="0">
          <List>
            <ListItem
              alignItems="center"
              tabIndex="-1"
              aria-label="account Card"
            >
              {currentTenant && permissions?.length > 0 && accountCard}
            </ListItem>
          </List>
          {navConfig && navConfig.items && (
            <NavItems
              listItems={navConfig.items.filter((item) => item?.enabled)}
              drawerHandler={closeDrawer}
              pathName={pathname.replace(/[^\w\s]/gi, "")}
            />
          )}
          <Divider aria-hidden="true" />
          <List>
            <ListItem disablePadding>
              <ListItemButton
                aria-label="Account expand"
                onClick={() => handleClick("account")}
              >
                <ListItemIcon sx={{ color: "primary.contrastText" }}>
                  <AccountBalanceWalletIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Account" />
                {navState.account ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={navState.account}
              timeout="auto"
              unmountOnExit
              component="li"
            >
              <List
                disablePadding
                sx={(theme) => ({
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark,
                  },
                })}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to={`/accounts/${currentTenant?._id}/profile`}
                    sx={{ color: "white", pl: 3 }}
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                    selected={pathname.includes("accounts")}
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </ListItem>
                {navConfig && navConfig?.createAccount?.Individual && (
                  <ListItem disablePadding>
                    <ListItemButton
                      name="individual"
                      sx={{ color: "white", pl: 3 }}
                      onClick={(e) => {
                        dispatch(
                          openDialog({ type: "Individual", show: true })
                        );
                        closeDrawer(e);
                      }}
                      onKeyDown={closeDrawer}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Individual" />
                    </ListItemButton>
                  </ListItem>
                )}
                {navConfig && navConfig?.createAccount?.Business && (
                  <ListItem disablePadding>
                    <ListItemButton
                      name="business"
                      sx={{ color: "white", pl: 3 }}
                      onClick={(e) => {
                        dispatch(openDialog({ type: "Business", show: true }));
                        closeDrawer(e);
                      }}
                      onKeyDown={closeDrawer}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add Business" />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Collapse>
            {permissions && permissions.indexOf("view_control_panel") !== -1 && (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    aria-label="control Panel expand"
                    onClick={() => handleClick("control-panel")}
                  >
                    <ListItemIcon sx={{ color: "primary.contrastText" }}>
                      <TuneIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Control Panel" />
                    {navState["control-panel"] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>

                <Collapse
                  in={navState["control-panel"]}
                  timeout="auto"
                  unmountOnExit
                  component="li"
                >
                  <List
                    disablePadding
                    sx={(theme) => ({
                      "&& .Mui-selected, && .Mui-selected:hover": {
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? theme.palette.primary.light
                            : theme.palette.primary.dark,
                      },
                    })}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        component={Link}
                        to="/tenants"
                        sx={{ color: "white", pl: 3 }}
                        onClick={closeDrawer}
                        onKeyDown={closeDrawer}
                        selected={pathname.includes("tenants")}
                      >
                        <ListItemIcon sx={{ color: "white" }}>
                          <AllInboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Accounts" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        component={Link}
                        to="/users"
                        sx={{ color: "white", pl: 3 }}
                        onClick={closeDrawer}
                        onKeyDown={closeDrawer}
                        selected={pathname.replace(/[^\w\s]/gi, "") === "users"}
                      >
                        <ListItemIcon sx={{ color: "white" }}>
                          <AccountTreeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Relationships" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        component={Link}
                        to="/iamusers"
                        sx={{ color: "white", pl: 3 }}
                        onClick={closeDrawer}
                        onKeyDown={closeDrawer}
                        selected={pathname.includes("iamusers")}
                      >
                        <ListItemIcon sx={{ color: "white" }}>
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </div>
      </div>
      <LocalLoader progress={isLoading} />
    </Drawer>
  );
}
Navigation.propTypes = {
  currentTenant: PropTypes.oneOfType([PropTypes.object]),
};
Navigation.defaultProps = {
  currentTenant: null,
};
