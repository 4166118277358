import React, { memo } from "react";
import PropTypes from "prop-types";
import Panel from "./Panel";
import Table from "./Table";
import Datagrid from "./Datagrid";
import NestedPanel from "./NestedPanel";

function Layout(props) {
  const { components, nested, handleTabChange, formTemplate } = props;
  const tableGrids =
    components &&
    components.filter(
      (component) =>
        component.type === "table" ||
        component.type === "datagrid" ||
        component.type === "panel"
    );

  return (
    <div>
      {tableGrids.length > 0 &&
        tableGrids.map((tableGrid) => {
          if (tableGrid.type === "panel" && !nested) {
            return (
              <Panel
                handleTabChange={handleTabChange}
                key={`${tableGrid.key}-${tableGrid.title}`}
                components={tableGrid}
                formTemplate={formTemplate}
              />
            );
          }
          if (tableGrid.type === "panel" && nested) {
            return (
              <NestedPanel
                key={`${tableGrid.key}-${tableGrid.title}`}
                tableGrid={tableGrid}
              />
            );
          }
          if (tableGrid.type === "table") {
            return <Table key={tableGrid.key} components={tableGrid} />;
          }
          if (tableGrid.type === "datagrid") {
            return <Datagrid key={tableGrid.key} gridItem={tableGrid} />;
          }
          return <div>Invalid element</div>;
        })}
    </div>
  );
}
Layout.whyDidYouRender = true;
Layout.propTypes = {
  formTemplate: PropTypes.shape({}),
  handleTabChange: PropTypes.func,
  components: PropTypes.oneOfType([PropTypes.array]).isRequired,
  nested: PropTypes.bool,
};
Layout.defaultProps = {
  nested: false,
  formTemplate: {},
  handleTabChange: () => {},
};
export default memo(Layout);
