/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navConfig: {},
};
const leftNavSlice = createSlice({
  name: "navConfig",
  initialState,
  reducers: {
    setNavConfig(state, { payload }) {
      state.navConfig = payload;
    },
    resetNavConfig(state) {
      state.navConfig = initialState.navConfig;
    },
  },
});

export const { setNavConfig, resetNavConfig } = leftNavSlice.actions;
export default leftNavSlice.reducer;
