import React from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import classes from "./App.module.css";
import Layout from "./features/auth/components/Layout";
import routesChildren from "./routes-config";

function RoutesWrapper({ user }) {
  const { pathname } = useLocation();
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: routesChildren,
    },
    {
      path: "*",
      element: <Navigate replace to="/login" />,
    },
  ]);
  return (
    <Paper
      sx={(theme) => ({
        borderRadius: 0,
        backgroundColor: theme.palette.mode === "light" ? "#f3f2f2" : undefined,
      })}
      className={`${classes.wrapper} ${
        user && !pathname.includes("browser-support")
          ? classes.stateful
          : classes.stateless
      }`}
    >
      {/* render a router for the protected pages if there is a logged in user
            or the public pages if there is no logged in user
            protected pages should never be rendered in any way if there is no logged in user */}
      {routes}
    </Paper>
  );
}
RoutesWrapper.propTypes = {
  user: PropTypes.shape({}),
};
RoutesWrapper.defaultProps = {
  user: null,
};
export default RoutesWrapper;
