import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

/* eslint-disable import/no-unresolved */
import buildConfig from "@regional/config/build.config.json";
import classes from "./maintenance.module.css";
import maintenanceLogo from "../../img/under-maintenance.png";

function MaintenancePage() {
  const { start, end } = useSelector((state) => state.maintenance);

  useEffect(() => {
    document.title = "Under Maintenance";
  }, []);

  const dateTimeFormatter = (date) => {
    const format = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    if (date) {
      const newDate = new Date(date);
      return newDate.toLocaleDateString("en-US", format);
    }
    return null;
  };
  return (
    <Grid
      className={classes.bodyContainer}
      sx={{ minHeight: "calc(100% - 143px)", textAlign: "center" }}
    >
      <Card
        className={classes.wrapper}
        sx={(theme) => ({
          "& .MuiTypography-root": {
            color: theme.palette.mode === "dark" ? "gray" : undefined,
          },
        })}
      >
        <CardMedia
          component="img"
          sx={{ width: 350, m: "0 auto" }}
          image={maintenanceLogo}
          alt="Under Maintenance logo"
        />
        <CardContent>
          <div className={classes.schedule}>
            <Box>
              <Typography className={classes.scheduleLabel}>START</Typography>
              <Typography className={classes.scheduleTime}>
                {dateTimeFormatter(start)}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.scheduleLabel}>END</Typography>
              <Typography className={classes.scheduleTime}>
                {dateTimeFormatter(end)}
              </Typography>
            </Box>
          </div>
          <Typography sx={{ margin: "auto", paddingTop: "20px" }}>
            {buildConfig?.maintenanceMsg}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default MaintenancePage;
