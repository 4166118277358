import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Styled = styled(Box)(({ theme }) => ({
  "& .gridContainer": {
    padding: "0 1.5rem 1rem",
    justifyContent: "center",
    position: "relative",
    "& .MuiAlert-root": { justifyContent: "center", marginBottom: "10px" },
    "& .MuiAlert-icon": { display: "none" },
    "& .MuiCard-root": {
      cursor: "pointer",
      margin: ".5rem",
    },
    "& .MuiCardHeader-root": {
      padding: "8px 15px 8px 12px",
      "& .MuiTypography-root": {
        fontSize: "1rem",
        fontWeight: theme.palette.mode === "light" ? 600 : 500,
      },
    },
    "& .MuiCardHeader-action": {
      color:
        theme.palette.mode === "light" ? theme.palette.primary.main : "#fff",
    },
    "& .MuiCardContent-root": {
      padding: "8px 15px",
      verticalAlign: "middle",
    },
    "& .card": {
      wordWrap: "break-word",
      "&:hover": {
        backgroundColor: theme.palette.mode === "light" ? "#b1e580" : "#6B8E23",
      },
    },
    "& .body1": {
      marginRight: "3.5rem",
      [theme.breakpoints.down("sm")]: {
        marginRight: "1rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0rem 1rem",
    },
  },
}));
export default Styled;
