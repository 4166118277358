/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};
const newApplication = createSlice({
  name: "newApplication",
  initialState,
  reducers: {
    openNewAppDialog(state) {
      state.show = true;
    },
    closeNewAppDialog(state) {
      state.show = initialState.show;
    },
  },
});

export const { openNewAppDialog, closeNewAppDialog } = newApplication.actions;
export default newApplication.reducer;
