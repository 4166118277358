import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import classes from "./support.module.css";
import chrome from "../../img/chrome.png";
import firefox from "../../img/firefox.png";
import IE_11 from "../../img/IE-11.png";
import ieEdge from "../../img/IE-Edge.png";
import safari from "../../img/safari.png";

function BrowserSupportPage() {
  React.useEffect(() => {
    document.title = "Supported Browsers";
  }, []);

  return (
    <Grid className={classes.bodyContainer}>
      <Card className={classes.wrapper} raised>
        <CardContent>
          <Box className={classes.browsers}>
            <Typography>The following browsers are supported:</Typography>
            <Typography>
              <img src={chrome} width="30" alt="Chrome Icon" />
              Chrome - High Performance
            </Typography>
            <Typography>
              <img src={safari} width="30" alt="Saffari Icon" />
              Safari - High Performance
            </Typography>
            <Typography>
              <img src={firefox} width="30" alt="Firefox Icon" />
              Firefox - Moderate Performance
            </Typography>
            <Typography>
              <img src={ieEdge} width="30" alt="Edge Icon" />
              IE Edge - Moderate Performance
            </Typography>
            <Typography>
              <img src={IE_11} width="30" alt="IE Icon" />
              IE 11 - Moderate Performance
            </Typography>
            <Typography>
              {`We're sorry for any inconvenience and thank you for your
              understanding.`}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default BrowserSupportPage;
