import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { isAuthenticated } from "../../../../services/auth.service";
import { showNotification } from "../../../core/slices/notification.slice";
import { notifyError } from "../../../../utils/helpers";

function RequireAuth(props) {
  const {
    showWhenUserLoggedOut,
    navConfigItem,
    allowedItems,
    conditionalNavConfigItem,
    // navConfig,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { navConfig } = useSelector((state) => state.navConfig);
  const { isMaintenance } = useSelector((state) => state.maintenance);
  const { permissionList } = useSelector((state) => state.auth);

  let auth;

  const getAuthData = () => {
    try {
      auth = isAuthenticated();
      // permissionList = getUserPermissions();
    } catch (error) {
      notifyError(dispatch, showNotification, error);
    }
  };
  getAuthData();

  const validateNavConfigItems = () => {
    const isPresent = navConfig?.items?.find(
      (item) =>
        item.enabled &&
        // item.link.includes(location.pathname) &&
        location.pathname.includes(item.link.slice(1))
      // item.permissions.some((p) => permissionList?.includes(p))
    );
    return isPresent;
  };
  if (isMaintenance) return <Outlet />;
  if (auth) {
    if (
      (navConfigItem && validateNavConfigItems()) ||
      permissionList?.find((role) => allowedItems?.includes(role)) ||
      (conditionalNavConfigItem && validateNavConfigItems())
    )
      return <Outlet />;
    if (showWhenUserLoggedOut)
      return <Navigate to="/licenses" state={{ from: location }} replace />;
    if (navConfig && navConfig.items) {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
    return null;
  }
  if (showWhenUserLoggedOut && !auth) return <Outlet />;
  return <Navigate to="/login" state={{ from: location }} replace />;
}

RequireAuth.propTypes = {
  showWhenUserLoggedOut: PropTypes.bool,
  conditionalNavConfigItem: PropTypes.bool,
  navConfigItem: PropTypes.bool,
  allowedItems: PropTypes.arrayOf(PropTypes.string),
};
RequireAuth.defaultProps = {
  showWhenUserLoggedOut: false,
  conditionalNavConfigItem: false,
  navConfigItem: false,
  allowedItems: [],
};

export default RequireAuth;
