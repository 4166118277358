import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import EditableDocument from "./EditableDocument";
import ReadOnlyDocument from "./ReadOnlyDocument";

import { Styled } from "./styles";
import ProTip from "../../ProTip";
import { useReviewPageCheck } from "../context/reviewItemContext";
import {
  getFilteredDocuments,
  getInitialDocs,
  updateFormDocumentList,
} from "../services/formDocument.service";
import { updateFormDocuments } from "../context/formSlice";

function Document(props) {
  const {
    fieldProps,
    isLoading,
    documentAction,
    allDocument,
    trigger,
    uploadProgress,
    setUploadProgress,
  } = props;
  const { properties, documentTypeId } = fieldProps;
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const formMethods = useFormContext();
  const isReviewContext = useReviewPageCheck();

  const { domainName, isSinglePageLayout } = useSelector(
    (state) => state.formProps.formConfig
  );
  const documentList = useSelector((state) => state.formProps.documentList);

  const documentsMap = useSelector(
    ({ formProps }) => formProps.formDocuments.documents
  );
  const documents = documentsMap[documentTypeId] || [];
  const isLicenseForm = domainName === "LicenseForm";

  const getDefaultPayload = () => ({
    dispatch,
    isReviewContext,
    updateFormDocuments,
    documentTypeId,
  });

  useEffect(() => {
    if (isLicenseForm) {
      const initialDocList = getInitialDocs(documentTypeId, documentList);
      const payload = { ...getDefaultPayload(), documents: initialDocList };
      updateFormDocumentList(payload);
    } else if (applicationId && applicationId !== "new" && !isReviewContext) {
      trigger(applicationId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allDocument?.documents && documentList && !isLicenseForm) {
      const filteredDocs = getFilteredDocuments(
        documentTypeId,
        documentList,
        allDocument
      );
      if (filteredDocs.length) {
        const payload = { ...getDefaultPayload(), documents: filteredDocs };
        updateFormDocumentList(payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocument]);

  const memoizedEditableDocument = useMemo(
    () => (
      <EditableDocument
        fieldProps={fieldProps}
        isLoading={isLoading}
        documents={documents}
        formMethods={formMethods}
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}
        documentActionHandler={documentAction}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents, isLoading, uploadProgress]
  );

  // for license details page, ignore "optional" fields when there is no document
  if (isLicenseForm && !documents.length) {
    return "";
  }
  return (
    <Styled>
      {properties && properties.title && (
        <Box className="heading">
          <Typography>
            {properties.heading}
            {properties?.proTip && <ProTip fieldKey={properties?.proTip} />}
          </Typography>
        </Box>
      )}
      {!isReviewContext && !isSinglePageLayout ? (
        memoizedEditableDocument
      ) : (
        <ReadOnlyDocument fieldProps={fieldProps} documents={documents} />
      )}
    </Styled>
  );
}
Document.propTypes = {
  fieldProps: PropTypes.shape({
    documentTypeId: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      title: PropTypes.bool,
      heading: PropTypes.string,
    }),
  }).isRequired,
  documentAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allDocument: PropTypes.PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  trigger: PropTypes.func.isRequired,
};
Document.defaultProps = {
  allDocument: {},
};
export default Document;
