import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

// eslint-disable-next-line import/no-unresolved
import ApplicationPageBanner from "@regional/templates/Application/banner";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { BannerStyled } from "../../../features/application/components/PaymentTable/styles";
import Form from "../../../components/Form/Form";
import { FormDetailsSkeleton } from "../../../components/Skeleton";
import LocalLoader from "../../../components/Loaders/Local";
import DialogPopup from "../../../components/DialogPopup";

import { useCoreApplicationConfigQuery } from "../../../api/core.api.slice";
import { showNotification } from "../../../features/core/slices/notification.slice";
import {
  useGetFormConfigMutation,
  useCloneApplicationMutation,
  useCreateApplicationMutation,
  useGetApplicationMutation,
  useSaveApplicationFormMutation,
  useUpdateApplicationFormMutation,
} from "../../../api/applications.api.slice";
import {
  generateApp,
  getSubmitAction,
  isCloningRequired,
  saveOnCreateRequired,
} from "../../../services/application.service";
import { saveForm } from "../../../components/Form/services/form.service";
import { isElevatedEnv, isLowerEnv, notifyError } from "../../../utils/helpers";
import { updateMethodRef } from "../../../components/Form/context/formSlice";
import configData from "./testData";
import PaymentTable from "../../../features/application/components/PaymentTable";

const { applicationFormConfig: customForm } = configData;
const isLocalhost = /localhost/.test(window.location.host);
const idMap = isLocalhost
  ? {
      "nj-crc": "621c8378f6f83d3d001dbb00",
      wv: "5de84e7f7ef37204cbd1fe2c",
      ok: "5dbb25fa4420801af3b2f0bf",
      "al-amcc": "64743b0daa82485c2c586bf7",
      "ms-doh": "634f8c203c70b72dc40ca9d1",
      "il-doa": "64af9c7c3827b7192ccf7937",
      "al-abc": "6501510a62a8eb00083756d2",
    }
  : {
      "nj-crc": "6152c8214f746f16bcae9750",
      wv: "63a52d39f43572000be189ea",
      ok: "5be97ded2c857d000fba1a0e",
    };
const applicationTypeMap = {
  "nj-crc": "NJ-CRC-NBL",
  wv: "WV-NBL",
  ok: "OK-NBL",
  "al-amcc": "AL-AMCC-RBA",
  "ms-doh": "MS-DOH-NBL",
  "il-doa": "IL-DOA-SE",
  "al-abc": "AL-ABC-NBL",
};
const marketName = process.env.REACT_APP_COMPLIA_INSTALL;
const applicationId = idMap[marketName] || idMap["nj-crc"];
const applicationType = applicationTypeMap[marketName] || "NJ-CRC-NBL";

function ApplicationDetailsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading: formLoader } = useSelector((state) => state.formLoader);
  const permissionList = useSelector((state) => state.auth.permissionList);
  const [searchParams] = useSearchParams();
  // const { applicationType, applicationId } = useParams();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data: coreAppConfig, isFetching: coreAppLoading } =
    useCoreApplicationConfigQuery();

  const [
    getApplication,
    { data: recordData, isLoading: recordLoading, error: recordError },
  ] = useGetApplicationMutation();
  const methods = useForm({ mode: "onBlur" });

  const [
    getFormConfig,
    {
      data: applicationFormConfig,
      error: formConfigError,
      isLoading: formConfigLoading,
    },
  ] = useGetFormConfigMutation();

  const [saveApplicationForm, { isLoading, error }] =
    useSaveApplicationFormMutation();
  const [createApplication, { isLoading: createAppLoading }] =
    useCreateApplicationMutation();
  const [cloneApp, { isLoading: cloneAppLoading }] =
    useCloneApplicationMutation();
  const [updateForm, { isLoading: updateFormLoading }] =
    useUpdateApplicationFormMutation();

  const application =
    coreAppConfig &&
    coreAppConfig?.applications.find(
      (app) => app.applicationType === applicationType
    );
  const showFormFields =
    (recordData &&
      (recordData.status === "Rejected" || recordData.status === "Open")) ||
    applicationId === "new";

  const singlePageLayoutIcon =
    recordData &&
    (recordData.status === "Submitted" ||
      recordData.status === "Paid" ||
      recordData.status === "Processing");

  const formConfig = {
    readOnly: !showFormFields,
    singlePageLayoutIcon,
  };

  const appPayload = {
    navigate,
    notifyError,
    dispatch,
    showNotification,
    generateApplication: createApplication,
  };

  const getFormConfigData = async (recordType) => {
    await getFormConfig({ recordType }).unwrap();
  };
  const getApplicationDetails = async (id, recordType) => {
    await getApplication({ id }).unwrap();
    await getFormConfigData(recordType);
    methods.reset();
  };

  useEffect(() => {
    const enableAutoFill =
      isLowerEnv ||
      (isElevatedEnv && permissionList.includes("edit_locked_fields"));

    if (enableAutoFill) {
      dispatch(
        updateMethodRef({
          key: "applicationDetailUpdateForm",
          methodRef: updateForm,
        })
      );
      dispatch(
        updateMethodRef({
          key: "formMethods",
          methodRef: methods,
        })
      );
    }
  }, [updateForm, dispatch, methods, permissionList]);

  useEffect(() => {
    if (applicationId === "new" && coreAppConfig) {
      const genInfoLicenseNumber = searchParams.get("licenseNumber");
      methods.reset(genInfoLicenseNumber ? { genInfoLicenseNumber } : {});
      if (coreAppConfig) {
        const saveOnCreate = saveOnCreateRequired(
          coreAppConfig.applications,
          applicationType
        );
        if (saveOnCreate) {
          generateApp({
            ...appPayload,
            applicationType,
          });
        } else getFormConfigData(applicationType);
      }
    } else if (applicationId !== "new" && coreAppConfig) {
      getApplicationDetails(applicationId, applicationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, applicationType, coreAppConfig]);

  useEffect(() => {
    const errorObj = recordError || formConfigError || error;
    if (errorObj) {
      if (isRedirecting) {
        setIsRedirecting(false);
      }
      notifyError(dispatch, showNotification, errorObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formConfigError, error, dispatch]);

  const onSubmit = async (data) => {
    const payload = {
      id: recordData._id,
      body: { formProperties: data },
      originalFormProps: recordData?.formProperties,
    };
    const msg = "Application Form Submitted Successfully";
    if (
      recordData.status === "Open" &&
      data.paymentMethod &&
      (data.paymentMethod === "CC" || data.paymentMethod === "ACH")
    ) {
      // redirect
      setIsRedirecting(true);
    }
    const { data: response } = await saveApplicationForm(payload);
    if (response?.redirectUrl) {
      window.location.href = response.redirectUrl;
    } else if (
      // this condition will be updated after seeing the mailpayment response
      response?.formProperties?.paymentMethod === "skipPayment" ||
      (response && data.paymentMethod !== "CC" && data.paymentMethod !== "ACH")
    ) {
      dispatch(showNotification({ type: "success", msg, show: true }));
    }
  };

  const onError = (err) => {
    // eslint-disable-next-line no-console
    console.log(err);
    const msg =
      "Please address the items marked with a red X in the review section.";
    dispatch(showNotification({ type: "warning", msg, show: true }));
  };

  const cloneApplication = async () => {
    const formData = methods.getValues();
    if (formData.genInfoLicenseNumber) {
      const submitAction =
        getSubmitAction(coreAppConfig.applications, applicationType) ===
        "update";
      if (submitAction && !formData.genInfoTypeOfUpdate) {
        const msg = "Please select the type of update.";
        dispatch(showNotification({ type: "warning", msg, show: true }));
      } else {
        const payload = {
          applicationType,
          formData,
          generateApplication: cloneApp,
        };
        // resetAppData();
        await generateApp({ ...appPayload, ...payload });
      }
    } else {
      const msg = "Please enter a valid License Number.";
      dispatch(showNotification({ type: "warning", msg, show: true }));
    }
    return false;
  };

  const onFormSave = async () => {
    const payload = {
      id: applicationId,
      formProperties: { ...methods.getValues() },
      updateForm,
      notifyError,
      dispatch,
      showNotification,
      originalFormProps: recordData?.formProperties,
    };
    const result = await saveForm(payload);
    return result;
  };

  const formSaveHandler = useCallback(async () => {
    if (applicationId === "new") {
      if (coreAppConfig) {
        if (isCloningRequired(coreAppConfig.applications, applicationType)) {
          return cloneApplication();
        }
        await generateApp({
          ...appPayload,
          applicationType,
          formData: methods.getValues(),
        });
      }
    } else {
      return onFormSave();
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreAppConfig, applicationId, applicationType, recordData]);

  const applicationData = applicationId === "new" ? undefined : recordData;
  const renderWithPaper =
    applicationData &&
    applicationData.status !== "Rejected" &&
    applicationData.status !== "Open";

  const ApplicationDetailsLayout = useMemo(
    () => (
      <Grid container direction="row" alignItems="stretch">
        {applicationFormConfig &&
        applicationFormConfig.components &&
        applicationFormConfig.components.length > 0 ? (
          <Grid item xs={12} sm={12}>
            {!renderWithPaper ? (
              <>
                {applicationData &&
                  applicationData.status !== "Open" &&
                  application && (
                    <BannerStyled>
                      <ApplicationPageBanner
                        applicationData={applicationData}
                      />
                      application={application}
                    </BannerStyled>
                  )}
                <Form
                  formTemplate={customForm}
                  formConfig={formConfig}
                  methods={methods}
                  applicationData={applicationData}
                  showReviewTab={showFormFields}
                  isSinglePageLayout={!showFormFields}
                  onSubmit={onSubmit}
                  onError={onError}
                  formSaveHandler={formSaveHandler}
                />
              </>
            ) : (
              <Paper sx={{ p: "1rem" }} elevation={2}>
                <BannerStyled>
                  <ApplicationPageBanner
                    applicationData={applicationData}
                    application={application}
                  />
                </BannerStyled>
                <Form
                  formTemplate={customForm}
                  formConfig={formConfig}
                  methods={methods}
                  applicationData={applicationData}
                  showReviewTab={showFormFields}
                  isSinglePageLayout={!showFormFields}
                  onSubmit={onSubmit}
                  onError={onError}
                  formSaveHandler={formSaveHandler}
                />

                {applicationData && applicationData.status === "Submitted" && (
                  <PaymentTable />
                )}
              </Paper>
            )}
          </Grid>
        ) : (
          <FormDetailsSkeleton />
        )}
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationFormConfig]
  );

  const modalContent = (
    <Grid container>
      <Typography>
        Your application is being processed. If a payment is due, you will be
        redirected to a third party payment processor to complete the
        application. Please do not refresh the page.
      </Typography>
    </Grid>
  );
  return (
    <Box sx={{ m: "1rem 1.2rem", position: "relative" }}>
      {!recordError && !formConfigError && ApplicationDetailsLayout}
      {recordError && recordError.status === 403 && (
        <Alert severity="error">Try to login with Complia LLC</Alert>
      )}
      <LocalLoader
        progress={
          recordLoading ||
          formConfigLoading ||
          createAppLoading ||
          isLoading ||
          cloneAppLoading ||
          updateFormLoading ||
          coreAppLoading ||
          formLoader
        }
      />
      <DialogPopup
        title="Please wait..."
        content={modalContent}
        openPopup={isRedirecting}
        onCancel={null}
        hideIcon="none"
        maxWidth="md"
      />
    </Box>
  );
}
export default ApplicationDetailsPage;
