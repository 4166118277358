import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import DialogPopup from "../../../DialogPopup";
import LocalLoader from "../../../Loaders/Local";
import FileUpload from "../FileUpload";
import ImageCropper from "../ImageCropper";
import { getFileFromBlob } from "../../services/formDocument.service";

function DocumentDialog(props) {
  const {
    dialogProps,
    resetDialogProps,
    fieldProps,
    handleDocumentAction,
    isLoading,
    uploadProgress,
  } = props;
  const { customProperties, isImage, enableCrop, allowedTypes, maxSize } =
    fieldProps;
  const { type, show } = dialogProps;

  const [file, setFile] = useState(null);
  const [requiredItemKeys, setRequiredItemKeys] = useState(null);
  const isDeleteView = type === "delete";

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const titleMap = {
    delete: "Would you like to delete ?",
    upload: "Upload Document",
    update: "Upload Document",
  };
  const dialogTitle = titleMap[type];
  const fileUploadLabel = isImage
    ? "Drop an Image here or click to select"
    : "Drop a file here or click to select";

  let fileType = "default";
  if (isImage) {
    fileType = "image";
  } else if (allowedTypes) {
    fileType = allowedTypes;
  }

  useEffect(() => {
    if (type !== "delete" && customProperties && customProperties.length) {
      const requiredItems =
        customProperties &&
        customProperties.length > 0 &&
        customProperties.reduce(
          (acc, cur) => (cur.required ? [...acc, cur.key] : acc),
          []
        );
      if (requiredItems) {
        setRequiredItemKeys(requiredItems);
        setTimeout(() => trigger(), 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => resetDialogProps();

  const onSubmit = async (customPropsData) => {
    let selectedFile = null;
    if (isImage && enableCrop && file) {
      try {
        selectedFile = await getFileFromBlob(file.blobUrl, file.name);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("getFileFromBlob", e);
      }
    } else selectedFile = file;

    if (!isEmpty(customPropsData))
      handleDocumentAction(type, { customPropsData, file: selectedFile });
    else handleDocumentAction(type, { file: selectedFile });
  };

  const docUploadContent = (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8}>
        <Alert
          severity="warning"
          elevation={2}
          sx={(theme) => ({
            "& .MuiAlert-icon": { display: "none" },
            color: theme.palette.mode === "light" ? undefined : "#fff",
            justifyContent: "center",
          })}
        >
          {isImage
            ? "Supported file formats : .jpg, .png or .gif"
            : `Please select a file which is less than ${
                maxSize || 10
              }MB in size.`}
        </Alert>
      </Grid>
      <Grid item xs={10} sm={9}>
        <FileUpload
          file={file}
          setFile={setFile}
          label={fileUploadLabel}
          fileType={fileType}
          maxSize={maxSize || 10}
          shouldCompress={!!enableCrop}
        />
      </Grid>
      {isLoading && (
        <Grid item xs={12} sm={11} display="flex" alignItems="center">
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              uploadProgress
            )}%`}</Typography>
          </Box>
        </Grid>
      )}
      {isImage && enableCrop && file && (
        <Grid item xs={12}>
          <ImageCropper file={file} setFile={setFile} />
        </Grid>
      )}
      {isImage && enableCrop && file && (
        <Grid item xs={12} sm={10}>
          <Alert
            severity="info"
            elevation={2}
            sx={{
              "& .MuiAlert-icon": { display: "none" },
              textAlign: "center",
            }}
          >
            Drag the image to move the photo into place. A rectangular image may
            appear cropped at first view. This image is still movable.
          </Alert>
        </Grid>
      )}
      {customProperties && customProperties.length > 0
        ? customProperties.map((property) => (
            <Grid key={property.key} item xs={12} sm={7} sx={{ my: "2em" }}>
              <TextField
                label={property.label}
                id={property.key}
                error={errors[property.key] !== undefined}
                name={property.key}
                required={property.required}
                type="text"
                margin="normal"
                variant="standard"
                helperText={errors[property.key] ? "This is required." : ""}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(property.key, {
                  required: property.required,
                  maxLength: 100,
                })}
                fullWidth
                defaultValue=""
              />
            </Grid>
          ))
        : null}
      <LocalLoader progress={isLoading} />
    </Grid>
  );
  const deleteViewContent = <LocalLoader progress={isLoading} />;
  const shouldDisableUploadBtn =
    !file || (requiredItemKeys && requiredItemKeys.some((key) => errors[key]));

  const buttonArray = [
    {
      id: 1,
      name: "Cancel",
      variant: "contained",
      onClickHandler: onCancel,
      show: true,
      disabled: isLoading,
    },
    {
      id: 2,
      name: "Upload",
      variant: "contained",
      onClickHandler: handleSubmit(onSubmit),
      show: true,
      disabled: shouldDisableUploadBtn || isLoading,
    },
  ];

  const height = isImage && enableCrop && file ? "85%" : "75%";
  return (
    <Box sx={{ position: "relative" }}>
      <DialogPopup
        showActionBtn
        hideIcon="none"
        openPopup={show}
        onCancel={isLoading ? undefined : onCancel}
        title={dialogTitle}
        showTitle={!isDeleteView}
        maxWidth={isDeleteView ? "xs" : "sm"}
        height={isDeleteView ? undefined : height}
        buttonArray={isDeleteView ? undefined : buttonArray}
        content={isDeleteView ? deleteViewContent : docUploadContent}
        onOk={isDeleteView ? () => handleDocumentAction("delete") : undefined}
        progress={isLoading}
      />
    </Box>
  );
}

DocumentDialog.propTypes = {
  dialogProps: PropTypes.shape({
    type: PropTypes.string,
    show: PropTypes.bool,
  }).isRequired,
  resetDialogProps: PropTypes.func.isRequired,
  handleDocumentAction: PropTypes.func.isRequired,
  fieldProps: PropTypes.shape({
    customProperties: PropTypes.arrayOf(PropTypes.shape({})),
    isImage: PropTypes.bool,
    maxSize: PropTypes.number,
    allowedTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default DocumentDialog;
