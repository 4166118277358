import React from "react";
import PropTypes from "prop-types";

import Table from "../../Table";
import DgDivider from "../DgDivider";

function ReviewRecord(props) {
  const { gridItem } = props;
  const renderTable = (gridList) =>
    gridList &&
    gridList.components.map((tableGrid) => (
      <Table key={tableGrid.key} components={tableGrid} />
    ));
  return (
    <>
      {renderTable(gridItem)}
      <DgDivider />
    </>
  );
}
ReviewRecord.propTypes = {
  gridItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
    components: PropTypes.oneOfType([PropTypes.array]).isRequired,
  }).isRequired,
};

export default ReviewRecord;
