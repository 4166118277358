/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import CustomTitle from "../FormTitle";
import LocalLoader from "../../../Loaders/Local";
import RecordRenderer from "./RecordRenderer";

import { useGetApiDataQuery } from "../../../../api/forms.api.slice";
import { useUpdateApplicationFormMutation } from "../../../../api/applications.api.slice";
import { ReviewContext } from "../../context/reviewItemContext";
import {
  appendDg,
  appendDocumentList,
  updateDatagridRecords,
} from "../../context/formSlice";
import { showNotification } from "../../../../features/core/slices/notification.slice";

import useDatagridState from "../../hooks/useDatagridState";
import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";
import useDgRenderState from "../../hooks/useDgRenderState";
import useScrollPosition from "../../../../hooks/useScrollPosition";
import {
  getFormattedDataForDg,
  constructSearchUrl,
  formConditional,
  getParsedValue,
  generateUrlParams,
  getWatchFieldName,
} from "../../services/form.service";
import { notifyError } from "../../../../utils/helpers";
import { StyledBox } from "./styles";

function DynamicView(props) {
  const dispatch = useDispatch();
  const formConfig = useSelector((state) => state.formProps.formConfig);
  const applicationStatus = useSelector(
    (state) => state.formProps.applicationData?.status
  );
  const { gridItem, formData, dgConfigRef, isCategorizedView } = props;
  const { control } = useFormContext();
  const {
    fields: records,
    append,
    remove,
    update,
    replace,
  } = useFieldArray({
    control,
    name: gridItem.key,
  });
  const [updateForm, { isLoading }] = useUpdateApplicationFormMutation();
  const { updateDgState } = useDatagridState();
  const { getDgRenderInfo, updateDgRenderState } = useDgRenderState();
  const { updateHeight } = useUpdatePanelHeight();
  const [captureCurrentPosition, scrollToCapturedPosition] =
    useScrollPosition();

  const allowBtnAction = useRef(true);
  const showBtnForCategorizedView = ["Open", "Rejected"].includes(
    applicationStatus
  );

  const appendRecord = () => {
    append({});
    allowBtnAction.current = false;
  };
  const removeRecord = (index) => {
    remove(index);
    allowBtnAction.current = true;
  };
  const updateRecord = (index, data) => {
    update(index, data);
    allowBtnAction.current = true;
  };
  useEffect(() => {
    const datagridList = formData[gridItem.key];
    const dgRenderInfo = getDgRenderInfo();
    const isEmptyList = records.length === 0;
    if (datagridList?.length && isEmptyList && !dgRenderInfo[gridItem.key]) {
      replace(datagridList);
    } else if (
      isEmptyList &&
      !gridItem.initEmpty &&
      (datagridList === undefined ||
        datagridList.length === 0 ||
        dgRenderInfo[gridItem.key])
    ) {
      appendRecord();
    }
    updateDgRenderState(gridItem.key);
    const dgConfig = {
      title: gridItem.label,
      initEmpty: gridItem.initEmpty,
      custom: gridItem.validate?.custom,
    };
    updateDgState({
      key: gridItem.key,
      config: dgConfig,
    });
    return () => {
      dispatch(updateDatagridRecords({ key: gridItem.key, value: [] }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      updateDatagridRecords({
        key: gridItem.key,
        value: records,
        config: { initEmpty: gridItem.initEmpty },
      })
    );
    const hasOpenRecord = records.find((record) => record.status !== "saved");
    allowBtnAction.current = !hasOpenRecord;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  const handleAppendRecord = () => {
    const msg =
      "Please save all the record(s) which are currently being edited before performing this action.";
    if (allowBtnAction.current) {
      captureCurrentPosition();
      appendRecord();
      updateHeight();
      scrollToCapturedPosition();
      setTimeout(() => updateHeight(), 50);
    } else dispatch(showNotification({ type: "warning", msg, show: true }));
  };

  if (formConfig.readOnly && (!records || records.length === 0)) {
    return (
      <Typography align="center" sx={{ marginTop: "2rem" }}>
        No information provided.
      </Typography>
    );
  }
  const recordProps = {
    records,
    gridItem,
    updateForm,
    allowBtnAction,
    updateRecord,
    removeRecord,
    appendRecord,
    dgConfigRef,
  };

  return (
    <StyledBox>
      <RecordRenderer {...recordProps} isCategorizedView={isCategorizedView} />
      <Grid container className="addBtnContainer">
        {!formConfig.readOnly && records.length === 0 && (
          <Button onClick={appendRecord} startIcon={<AddIcon />}>
            Add {gridItem.label}
          </Button>
        )}
        {isCategorizedView && showBtnForCategorizedView && (
          <Button
            variant="outlined"
            onClick={handleAppendRecord}
            startIcon={<AddIcon />}
            fullWidth
            className="newRecordBtn"
          >
            Add New Record
          </Button>
        )}
      </Grid>
      <LocalLoader progress={isLoading} />
    </StyledBox>
  );
}
DynamicView.propTypes = {
  gridItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    initEmpty: PropTypes.bool,
    validate: PropTypes.shape({
      custom: PropTypes.string,
    }),
  }).isRequired,
  formData: PropTypes.shape({}).isRequired,
  isCategorizedView: PropTypes.bool.isRequired,
};

function StaticView(props) {
  const { gridItem } = props;
  // get main form values
  const gridRecords = useSelector((state) => state.formProps[gridItem.key]);

  return (
    <>
      <RecordRenderer {...props} records={gridRecords} />
      {(!gridRecords || gridRecords.length === 0) && (
        <Typography align="center" sx={{ marginTop: "2rem" }}>
          No information provided.
        </Typography>
      )}
    </>
  );
}
StaticView.propTypes = {
  gridItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
};

function Datagrid(props) {
  const { gridItem } = props;
  const { key, properties } = gridItem;
  const params = useParams();
  const dispatch = useDispatch();
  const { reviewProps } = useContext(ReviewContext);
  const formConfig = useSelector((state) => state.formProps.formConfig);
  const formData = useSelector((state) => state.formProps.formData);
  const applicationStatus = useSelector(
    (state) => state.formProps.applicationData.status
  );
  const { updateHeight } = useUpdatePanelHeight();
  const { getValues, setValue } = useFormContext();
  const [req, setReq] = useState("");
  const [skip, setSkip] = useState(true);
  const {
    data: apiData,
    isFetching,
    isSuccess,
    error,
  } = useGetApiDataQuery(req, { skip });
  const dgConfigRef = useRef();

  if (!dgConfigRef.current && gridItem.validate?.custom) {
    dgConfigRef.current = getParsedValue(
      gridItem.validate.custom,
      gridItem.key
    );
  }
  const { sourceDatagrid, identifierLabel } = dgConfigRef.current || {};
  const isCategorizedView = !!(sourceDatagrid && identifierLabel);

  let watchFieldValue = useWatch({
    name: getWatchFieldName(gridItem),
    disabled: !gridItem.conditional || !gridItem.conditional.when,
  });
  // hook form wont reference field not present in form
  if (
    watchFieldValue === undefined &&
    formData[gridItem.conditional.when] !== undefined
  ) {
    watchFieldValue = formData[gridItem.conditional.when];
  }
  const shouldDisplay = formConditional(gridItem, watchFieldValue, getValues());

  useLayoutEffect(() => {
    if (gridItem.conditional) {
      updateHeight();
    }
    return () => {
      if (gridItem.conditional) {
        updateHeight();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplay]);

  useEffect(() => {
    const { url, type, fetchOnStatus, cloneIndicator } = properties || {};

    let canFetchRecord = false;
    if (!fetchOnStatus) {
      canFetchRecord = true;
    }
    if (fetchOnStatus) {
      canFetchRecord = fetchOnStatus.split(",").includes(applicationStatus);
    }

    canFetchRecord =
      canFetchRecord &&
      (!cloneIndicator || (cloneIndicator && !formData[cloneIndicator]));

    if (canFetchRecord && url && type) {
      const searchUri = constructSearchUrl(url, { ...formData, ...params });
      const urlParamsObject = generateUrlParams(searchUri);
      setReq(urlParamsObject);
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { url, type, prefix, cloneIndicator } = properties || {};
    if (url && type && isSuccess) {
      const dgData = getFormattedDataForDg(apiData, type, prefix);
      if (["Approved", "Deactivated", "Expired"].includes(applicationStatus))
        dispatch(appendDocumentList({ documents: dgData.documents }));
      setValue(key, dgData.formData);
      if (formConfig.readOnly)
        dispatch(appendDg({ gridKey: key, value: dgData.formData }));
      if (cloneIndicator) setValue(cloneIndicator, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridItem.key, apiData, isSuccess]);

  useEffect(() => {
    if (error) {
      if (properties && properties.url && properties.type) setValue(key, []);
      notifyError(dispatch, showNotification, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error]);

  if (shouldDisplay) {
    return (
      <>
        {!gridItem.hideLabel && gridItem.label !== "" && (
          <CustomTitle
            title={gridItem.label}
            showProTip={gridItem?.tags && gridItem?.tags.includes("proTip")}
            fieldKey={gridItem.key}
            header
          />
        )}
        <Box position="relative" sx={{ minHeight: "4rem" }}>
          <LocalLoader progress={isFetching} />
          {!isFetching &&
            (!reviewProps ? (
              <DynamicView
                gridItem={gridItem}
                formData={formData}
                dgConfigRef={dgConfigRef}
                isCategorizedView={isCategorizedView}
              />
            ) : (
              <StaticView
                gridItem={gridItem}
                dgConfigRef={dgConfigRef}
                isCategorizedView={isCategorizedView}
              />
            ))}
        </Box>
      </>
    );
  }
  return "";
}
Datagrid.propTypes = {
  gridItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    components: PropTypes.oneOfType([PropTypes.array]).isRequired,
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
  }).isRequired,
};
// Datagrid.whyDidYouRender = true;
export default Datagrid;
