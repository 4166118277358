import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";

import DialogPopup from "../../../../components/DialogPopup";
import Form from "../../../../components/Form/Form";
import { NewAccountSkeleton } from "../../../../components/Skeleton";
import LocalLoader from "../../../../components/Loaders/Local";

import accountImage from "../../../../img/selected-account.png";
import alAbocbImage from "../../../../img/selected-al-abocb.png";
import warningImage from "../../../../img/warning-big.png";
import { closeDialog } from "../../slices/dialog.slice";
import {
  useRegisterFormQuery,
  useSwitchAccountMutation,
} from "../../../../api/auth.api.slice";
import Styled from "./styles";
import { useCreateAccountMutation } from "../../../../api/tenants.api.slice";
import { fieldsVerified } from "../../../../components/Form/services/form.service";
import { showNotification } from "../../slices/notification.slice";
import { getLogin } from "../../../auth/slices/auth.slice";
import { notifyError } from "../../../../utils/helpers";

const defaultState = {
  isAddAccountPage: true,
  isBusinessAccount: false,
  type: "",
};

function NewAccountPage(props) {
  const { type, openDialog } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({ mode: "onBlur" });
  const [skip, setSkip] = useState(true);
  const {
    data: registerFormConfig,
    isFetching,
    error: formError,
  } = useRegisterFormQuery("", {
    skip,
  });
  const [createAccount, { isLoading }] = useCreateAccountMutation();
  const [switchAccount, { isLoading: switchAccountLoading }] =
    useSwitchAccountMutation();

  const formConfig = {
    readOnly: false,
  };
  const [initialState, setInitialState] = useState(defaultState);
  const [showForm, setShowForm] = useState(false);

  const stateCode = process.env.REACT_APP_COMPLIA_INSTALL;

  const onDialogCancel = () => dispatch(closeDialog());

  useEffect(() => {
    if (type === "Individual")
      return setInitialState({
        isAddAccountPage: true,
        isBusinessAccount: false,
        type,
      });
    if (type === "Business")
      return setInitialState({
        isAddAccountPage: true,
        isBusinessAccount: true,
        type,
      });
    return null;
  }, [type]);

  useEffect(() => {
    if (formError) {
      notifyError(dispatch, showNotification, formError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  const switchTenantHandler = async (id) => {
    try {
      await switchAccount(id).unwrap();
      dispatch(getLogin());
      dispatch(
        showNotification({
          type: "success",
          msg: "Account switched Successfully.",
          show: true,
        })
      );
      navigate("/licenses", { replace: true });
    } catch (err) {
      notifyError(dispatch, showNotification, err);
    }
  };

  const onSubmit = async (data) => {
    const errorMsg = fieldsVerified(data);
    if (!errorMsg) {
      let payload = {
        type,
        addCreatorToTenant: true,
        email: data.accountEmail,
        ...data,
        ...initialState,
      };
      if (type === "Business" && data.businessName) {
        payload = { ...payload, name: data.businessName };
      }
      try {
        const res = await createAccount(payload).unwrap();
        if (res && res._id) {
          switchTenantHandler(res._id);
          onDialogCancel();
          const msg = "Account created successfully.";
          dispatch(showNotification({ type: "success", msg, show: true }));
        }
      } catch (err) {
        notifyError(dispatch, showNotification, err);
      }
    } else {
      dispatch(
        showNotification({ type: "warning", msg: errorMsg, show: true })
      );
    }
  };

  const onError = () => {
    const msg = `Please enter all required fields marked with a "*"`;
    dispatch(showNotification({ type: "warning", msg, show: true }));
  };

  const alertDialogContent = (
    <Styled>
      <Grid container justifyContent="center" alignItems="center">
        <Alert role="definition" tabIndex="0" severity="info" elevation={2}>
          <Grid
            item
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item container justifyContent="center" alignItems="center">
              <Grid item sm={12} md={2} className="gridItem">
                <img
                  alt="Complia Selected Account Graphic"
                  src={warningImage}
                  width="100"
                  height="100"
                />
              </Grid>
              <Grid item sm={12} md={10}>
                <Typography variant="h6" className="warningText" tabIndex={0}>
                  <WarningIcon />
                  WARNING: You are about to create an additional account. If you
                  just registered, you do <u>NOT</u> need to create another
                  account. Please close this box and click the Create New
                  Application button to start your application. The account you
                  are currently working in is displayed in the left navigation:
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center">
              <img
                alt="Complia Selected Account Graphic"
                src={stateCode !== "al-abocb" ? accountImage : alAbocbImage}
                width="250"
              />
            </Grid>

            <Grid item container justifyContent="center" alignItems="center">
              <Button
                sx={{ mt: "10px" }}
                variant="contained"
                onClick={() => {
                  setSkip(false);
                  setShowForm(true);
                }}
              >
                Continue to Account Creation
              </Button>
            </Grid>
          </Grid>
        </Alert>
      </Grid>
    </Styled>
  );

  const memoForm = useMemo(
    () =>
      registerFormConfig &&
      registerFormConfig.components &&
      registerFormConfig.components.length > 0 ? (
        <Grid
          container
          direction="row"
          alignItems="stretch"
          sx={{ mb: "0.8rem" }}
        >
          <Grid item xs={12} sm={12}>
            <Form
              formTemplate={registerFormConfig}
              methods={methods}
              formConfig={formConfig}
              applicationData={initialState}
              isDialogForm
            />
          </Grid>
        </Grid>
      ) : (
        <NewAccountSkeleton />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerFormConfig]
  );
  const registerFormContent = (
    <Box sx={{ position: "relative" }}>
      {memoForm}
      <LocalLoader progress={isFetching || isLoading || switchAccountLoading} />
    </Box>
  );

  const buttonArray = [
    {
      id: 1,
      name: "CANCEL",
      icon: "",
      variant: "contained",
      onClickHandler: () => {
        onDialogCancel();
      },
      show: true,
    },
    {
      id: 2,
      name: "CREATE ACCOUNT",
      icon: "",
      variant: "contained",
      onClickHandler: methods.handleSubmit(onSubmit, onError),
      show: true,
    },
  ];
  return (
    <DialogPopup
      title={type ? `Create New Account - ${type}` : ""}
      showActionBtn={
        // this additional condition is for testing the behavior on edge cases
        showForm && initialState.type ? true : undefined
      }
      content={!showForm ? alertDialogContent : registerFormContent}
      openPopup={openDialog}
      onCancel={onDialogCancel}
      buttonArray={buttonArray}
      maxWidth="md"
      height={isFetching ? "70%" : undefined}
    />
  );
}

NewAccountPage.propTypes = {
  type: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
};
export default NewAccountPage;
