/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
/* router */
import { useNavigate, Link } from "react-router-dom";
/* redux */
import { useDispatch } from "react-redux";
/* hook form, yup */
import { useForm } from "react-hook-form";

/* material */
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SignInIcon from "@mui/icons-material/Input";
import ForgotPwdIcon from "@mui/icons-material/LockOpen";
import TextField from "@mui/material/TextField";

/* app */
import TermsConditionsCheckBox from "../../features/auth/components/TermsConditionsLink";
import ReCaptchaComp from "../../features/auth/components/ReCaptcha";
import LocalLoader from "../../components/Loaders/Local";
import FormAlert from "../../components/Form/Field/FormAlert";

import { getLogin, logout } from "../../features/auth/slices/auth.slice";
import {
  useLoginMutation,
  useCaptchaConfigMutation,
} from "../../api/auth.api.slice";
import { showNotification } from "../../features/core/slices/notification.slice";
import { notifyError } from "../../utils/helpers";
import { errorMap } from "../Register";
import classes from "./Login.module.css";

function LoginPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [captchaConfigData, setCaptchaConfigData] = useState({});

  const [login, { isLoading }] = useLoginMutation();
  const [captchaConfig] = useCaptchaConfigMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    dispatch(logout());
    const getCaptchaConfig = async () => {
      try {
        const data = await captchaConfig().unwrap();
        if (data) {
          setCaptchaConfigData(data);
        }
      } catch (err) {
        notifyError(dispatch, showNotification, err);
      }
    };
    getCaptchaConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doLogin = async (values) => {
    const res = await login(values);
    if (res.error) {
      setLoginError(
        res.error.data.message
          ? res.error.data.message
          : "Error while logging in, please try again later."
      );
    } else {
      await dispatch(getLogin());
      navigate("/licenses", { replace: true });
    }
  };
  const onError = (error) => {
    const errItem = Object.keys(error);
    if (errItem.includes("username") || errItem.includes("password"))
      setLoginError(errorMap.common);
    else if (error.termsConditions) setLoginError(errorMap.termsConditions);
    else if (error.captchaToken) setLoginError(errorMap.captchaToken);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className={classes.loginGrid}
      >
        <Grid
          item
          xs={12}
          sm={8}
          className={`${classes.flexCenter} ${classes.loginForm}`}
        >
          <Card className={classes.card} raised>
            <form onSubmit={handleSubmit(doLogin, onError)} noValidate>
              <CardHeader title="Sign-in" className={classes.cardHeader}>
                Sign-in
              </CardHeader>
              <CardContent>
                <TextField
                  label="Email"
                  id="username"
                  error={errors.username !== undefined}
                  name="username"
                  required
                  type="text"
                  margin="normal"
                  variant="outlined"
                  helperText={errors.username ? errors.username.message : ""}
                  {...register("username", {
                    required: "Email is required",
                    maxLength: {
                      value: 50,
                      message: "Email must not exceed 50 characters",
                    },
                  })}
                  fullWidth
                />
                <TextField
                  label="Password"
                  id="password"
                  name="password"
                  required
                  error={errors.password !== undefined}
                  type="password"
                  margin="normal"
                  variant="outlined"
                  helperText={errors.password ? errors.password.message : ""}
                  {...register("password", {
                    required: "Password is required",
                    maxLength: {
                      value: 50,
                      message: "password must not exceed 50 characters",
                    },
                  })}
                  fullWidth
                />
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <TermsConditionsCheckBox register={register} type="signIn" />
                </Grid>
                {captchaConfigData?.captchaLogin && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ReCaptchaComp
                      name="captchaToken"
                      control={control}
                      theme={theme.palette.mode}
                      error={false}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  container
                  sx={{
                    justifyContent: "center",
                    mt: "0.5em",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                    "& .MuiButton-root": {
                      my: 1,
                      "& .MuiSvgIcon-root": {
                        mr: "0.2em",
                      },
                    },
                  }}
                >
                  <Button variant="contained" type="submit">
                    <SignInIcon />
                    SIGN IN
                  </Button>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/forgot-password"
                  >
                    <ForgotPwdIcon />
                    FORGOT PASSWORD
                  </Button>
                </Grid>
              </CardActions>
            </form>
            {loginError && (
              <Grid
                container
                justifyContent="center"
                className={classes.errorItem}
              >
                <Grid item xs={11}>
                  <FormAlert
                    severity="error"
                    message={<span>{loginError}</span>}
                  />
                </Grid>
              </Grid>
            )}
            <LocalLoader progress={isLoading} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginPage;
