import React, { useEffect, useMemo, useState } from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  useTheme,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import getTheme from "./complia.material.theme";

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext({
  darkMode: false,
  drawerVisible: true,
  tabBelow: false,
  setTheme: null,
  setDrawer: null,
});

function CustomThemeProvider(props) {
  const muiTheme = useTheme();
  const matches = useMediaQuery(muiTheme.breakpoints.up("md"));

  // eslint-disable-next-line react/prop-types
  const { children } = props;

  // Read current theme from localStorage or maybe from an api
  const currentMode = localStorage.getItem("darkMode") === "true";

  // State to hold the selected theme name
  const [darkMode, _setDarkMode] = useState(currentMode);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Retrieve the theme object by theme name
  const theme = getTheme(darkMode);

  // Wrap _setThemeName to store new theme names in localStorage
  const setDarkMode = (mode) => {
    localStorage.setItem("darkMode", mode);
    _setDarkMode(mode);
  };
  const setDrawer = (visible) => {
    setDrawerVisible(visible);
  };

  const contextValue = useMemo(
    () => ({
      darkMode,
      drawerVisible,
      tabBelow: !matches,
      setTheme: setDarkMode,
      setDrawer,
    }),
    [darkMode, drawerVisible, matches]
  );
  useEffect(() => {
    setDrawerVisible(matches);
  }, [matches]);

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
}

export default CustomThemeProvider;
