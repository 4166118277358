import React, { useState, useContext, useEffect } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { cloneDeep } from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Label from "./Label";
import FormRecordList from "../FormRecordList";
import FormLicenseInfo from "../FormLicenseInfo";
import { ReviewContext } from "../../context/reviewItemContext";
import {
  useDropdownListMutation,
  useLazyGetDropdownListQuery,
} from "../../../../api/forms.api.slice";
import {
  getFieldValue,
  getFormattedValue,
  getParsedValue,
  getInvoiceUrl,
} from "../../services/form.service";
import {
  getDgOptionList,
  isAssociateField,
} from "../../services/form.relationalDg.service";
import { getSocialSecurityNumber } from "../../../../utils/helpers";
import useGetFormStateValues from "../../hooks/useGetFormProps";

const getInfoColor = (isError, theme) => {
  if (isError) return "#ea7371";
  return theme.palette.mode === "light" ? "#31708f" : "#254250";
};

const getInfoBackgroundColor = (isError, theme) => {
  if (isError) return "#f2dede";
  return theme.palette.mode === "light" ? "#d9edf7" : "#b2c3ca";
};

export const Info = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "condition",
})(({ condition, theme }) => ({
  marginTop: condition.isDg ? 0 : "-0.5rem",
  marginBottom: "1rem",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "4px",
  color: getInfoColor(condition.isError, theme),
  backgroundColor: getInfoBackgroundColor(condition.isError, theme),
  borderColor: condition.isError ? "#ebccd1" : "#bce8f1",
  padding: "10px 20px",
}));

const filterMap = {
  currency: "$",
  percentage: "%",
};
function FormReadOnlyField(props) {
  const {
    getValues,
    setValue,
    newFormProps,
    control,
    register,
    fieldProps: originalFieldProps,
    trigger: triggerValidation,
  } = props;
  const { formConfig = {} } = useGetFormStateValues();
  const { isSinglePageLayout, singlePageLayoutIcon, domainName } = formConfig;
  const { reviewProps } = useContext(ReviewContext);

  const fieldValue = useWatch({
    name: originalFieldProps.key,
    defaultValue: newFormProps[originalFieldProps.key],
    control,
    exact: true,
  });

  const labelFieldValue = useWatch({
    name: `${originalFieldProps.key}Label`,
    defaultValue: newFormProps[`${originalFieldProps.key}Label`],
    control,
    disabled: !originalFieldProps?.properties?.skipOptionMapping,
    exact: true,
  });

  const emailValidationMsg = useWatch({
    name: `${originalFieldProps.key}validationMsg__DUMMY`,
    defaultValue: newFormProps[`${originalFieldProps.key}validationMsg__DUMMY`],
    control,
    disabled: !originalFieldProps.properties?.carbonCopy,
    exact: true,
  });

  let defaultValues = {
    value: reviewProps ? fieldValue : newFormProps[originalFieldProps.key],
    customClass: false,
  };

  const [lazyTrigger, lazyResult] = useLazyGetDropdownListQuery();
  const [mutationTrigger, mutationResult] = useDropdownListMutation();
  const defautlFieldProps = {
    ...originalFieldProps,
    ...(defaultValues.value &&
      (originalFieldProps.type === "select" ||
        originalFieldProps.type === "selectboxes") &&
      originalFieldProps.dataSrc &&
      originalFieldProps.dataSrc === "url" &&
      originalFieldProps.data &&
      originalFieldProps.data.url && {
        urlValues: [{ label: "Loading ...", value: defaultValues.value }],
      }),
  };
  const [fieldProps, setFieldProps] = useState(defautlFieldProps);
  const { errors } = useFormState({
    control,
    name: fieldProps.key,
    exact: true,
  });
  const { dataSrc, data, properties } = fieldProps;
  const { skipOptionMapping, customFilter, type, displayAs } = properties || {};
  // Temp Fix
  const skipCache = data?.url && data.url.includes("licenses");
  const result = skipCache ? mutationResult : lazyResult;
  const isValueMasked = useWatch({
    name: `${fieldProps.key}isMasked__DUMMY`,
    defaultValue: true,
    control,
    exact: true,
    disabled: fieldProps.type !== "encrypted",
  });

  const condition = originalFieldProps?.validate?.custom
    ? getParsedValue(originalFieldProps.validate.custom, originalFieldProps.key)
    : undefined;

  const sourceDg = useWatch({
    name: condition?.sourceDatagrid || null,
    disabled: condition?.sourceDatagrid === undefined,
  });

  useEffect(() => {
    let timeout = null;
    if (fieldProps.type === "select") {
      if (dataSrc === "url" && data && data.url && !skipOptionMapping) {
        if (skipCache) {
          mutationTrigger(data.url);
        } else {
          lazyTrigger(data.url, true);
        }
      }
    }
    if (fieldProps.type === "selectboxes") {
      if (properties?.dataSrc) {
        if (skipCache) {
          mutationTrigger(properties?.dataSrc);
        } else {
          lazyTrigger(properties?.dataSrc, true);
        }
      }
    }
    if (fieldProps.type === "recordList") {
      const userPrefTableView =
        JSON.parse(localStorage.getItem(`${fieldProps.key}TableView`)) ?? false;
      register(`${fieldProps.key}TableView__DUMMY`);
      setValue(`${fieldProps.key}TableView__DUMMY`, userPrefTableView);
    }
    if (!!reviewProps && defaultValues.value) {
      if (fieldProps.key.includes("-dt"))
        timeout = setTimeout(() => triggerValidation(fieldProps.key), 10);
      else triggerValidation(fieldProps.key);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (result && result.data) {
      setFieldProps((prev) => ({ ...prev, urlValues: result.data }));
    }
    if (result && result.isError) {
      setFieldProps(originalFieldProps);
    }
  }, [result, originalFieldProps]);

  const formData = reviewProps ? getValues() : newFormProps;

  if (customFilter && fieldProps.type === "textfield") {
    defaultValues.value = getFormattedValue(
      filterMap[customFilter],
      defaultValues.value
    );
  }

  if (fieldProps.type === "select" || fieldProps.type === "selectboxes") {
    if (!skipOptionMapping) {
      const newFieldProps = cloneDeep(fieldProps);
      if (isAssociateField(condition) && Array.isArray(sourceDg)) {
        newFieldProps.data.values = getDgOptionList(sourceDg, condition);
      }
      const value = getFieldValue(newFieldProps, formData);
      defaultValues = { value };
    } else
      defaultValues.value = reviewProps
        ? labelFieldValue
        : newFormProps[`${originalFieldProps.key}Label`];
  }
  if (fieldProps.type === "radio") {
    const fieldData = getFieldValue(fieldProps, formData);
    defaultValues = fieldData;
  }
  if (fieldProps.type === "checkbox") {
    if (!defaultValues.value) defaultValues.value = "No";
    else defaultValues.value = "Yes";
    if (fieldProps.validate?.required && defaultValues.value === "No") {
      defaultValues.isError = true;
    }
  }

  let labelValue = defaultValues.value;
  if (fieldProps.type === "encrypted") {
    labelValue = getSocialSecurityNumber({
      isMasked: isValueMasked,
      value: defaultValues.value,
      fieldProps,
      isFormatted: true,
    });
  }
  if (fieldProps.type === "number") {
    const { prefix, suffix } = fieldProps;
    if (prefix || suffix) {
      labelValue = getFormattedValue(prefix || suffix, defaultValues.value);
    }
  }
  if (fieldProps.type === "readOnlyMapping") {
    const { validate } = fieldProps;
    if (validate?.custom) {
      const { prefix, suffix } = validate?.custom || {};
      if (prefix || suffix)
        labelValue = getFormattedValue(prefix || suffix, defaultValues.value);
    }
  }
  if (fieldProps.type === "autocomplete" && labelValue) {
    if (typeof labelValue === "object") labelValue = labelValue.display || "";
  }

  const handleMaskedToggle = () => {
    setValue(`${fieldProps.key}isMasked__DUMMY`, !isValueMasked);
  };

  const requiredFieldCheck =
    (!!reviewProps || singlePageLayoutIcon) &&
    fieldProps.validate &&
    fieldProps.validate.required &&
    (Array.isArray(labelValue) ? labelValue.length === 0 : !labelValue);

  const isValidLabelValue = Array.isArray(labelValue)
    ? labelValue.length > 0
    : !!labelValue;
  const showIcon =
    requiredFieldCheck ||
    ((!!reviewProps || singlePageLayoutIcon) && isValidLabelValue);

  const isError =
    requiredFieldCheck ||
    (isValidLabelValue && !!errors[fieldProps.key]) ||
    defaultValues.isError;

  const questionsPanelClass =
    reviewProps || isSinglePageLayout || domainName === "LicenseForm"
      ? "questionClass"
      : undefined;

  let recordList = null;
  if (fieldProps.type === "recordList") {
    recordList = (
      <FormRecordList
        setValue={setValue}
        fieldProps={fieldProps}
        formProps={newFormProps}
        control={control}
        trigger={triggerValidation}
        getValues={getValues}
        readonly
      />
    );
    labelValue = `( ${labelValue?.length ?? 0} )`;
  }

  let linkUrl = null;
  if (type === "invoice" && displayAs === "link") {
    linkUrl = getInvoiceUrl(newFormProps);
  }

  if (fieldProps.type === "licenseInfo") {
    return (
      <FormLicenseInfo
        fieldProps={fieldProps}
        getValues={getValues}
        formProps={formData}
      />
    );
  }

  return (
    <Box position="relative" className={questionsPanelClass}>
      <Label
        label={fieldProps.label}
        value={labelValue}
        hasMaxWidth={fieldProps.type === "encrypted"}
        customClass={defaultValues.customClass}
        showIcon={showIcon}
        isError={isError}
        isDg={!reviewProps && !isSinglePageLayout}
        singlePageLayoutIcon={!!singlePageLayoutIcon}
        isSinglePageLayout={isSinglePageLayout}
        isMasked={!isValueMasked}
        showMaskedIcon={fieldProps.type === "encrypted"}
        handleMaskedToggle={handleMaskedToggle}
        errorBg={defaultValues.errorBg}
        tabIndex={0}
        linkUrl={linkUrl}
      />
      {recordList}
      {fieldProps.properties?.carbonCopy && emailValidationMsg && (
        <Info
          variant="body2"
          condition={{
            isError: !formData[`${fieldProps.key}Valid`],
            isDg: !reviewProps && !isSinglePageLayout,
          }}
        >
          {emailValidationMsg}
        </Info>
      )}
    </Box>
  );
}
FormReadOnlyField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
    properties: PropTypes.shape({
      skipOptionMapping: PropTypes.string,
    }),
  }).isRequired,
  newFormProps: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
};

export default FormReadOnlyField;
