import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import classes from "./gridContainer.module.css";

function GridContainer(props) {
  const { width, title, children } = props;
  const widthConfig = {
    full: 11,
    mid: 6,
  };
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        item
        xs={11}
        sm={widthConfig[width]}
        className={classes.gridContainer}
      >
        <Card raised>
          <CardHeader title={title} className={classes.cardHeader} tabIndex={0}>
            {title}
          </CardHeader>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
GridContainer.propTypes = {
  width: PropTypes.oneOf(["full", "mid"]).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default GridContainer;
