/* eslint-disable import/prefer-default-export */
import { cloneDeep, filter, isEmpty, intersection, isArray } from "lodash";
import moment from "moment";
/* eslint-disable import/no-unresolved */
import { handleMaxCountValidation } from "@regional/services/form.service";
import { getUserPermissions } from "../../../services/auth.service";
import {
  convertNumberToOrdinal,
  validatePasswords,
} from "../../../utils/helpers";
import maskMap from "../Field/FormTextField/maskMap";

export const getParsedValue = (str, key) => {
  let parsedValue = "";
  try {
    parsedValue = JSON.parse(str);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("parse error: ", key);
  }
  return parsedValue;
};

export const stringify = (obj, key) => {
  let value = "";
  try {
    value = JSON.stringify(obj);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("stringify error: ", key);
  }
  return value;
};

export function fieldsVerified(data, type) {
  const {
    ssn,
    confirmSsn,
    password,
    reenterPassword,
    dob,
    confirmDob,
    email,
    confirmEmail,
  } = data;
  if ((ssn || confirmSsn) && ssn !== confirmSsn) {
    return "Entered SSN does not match.";
  }
  if (dob && confirmDob && dob !== confirmDob) {
    return "Entered Date of Birth does not match.";
  }
  if (email && confirmEmail && email !== confirmEmail) {
    return "Entered Email IDs does not match.";
  }
  if (type === "register") {
    const pwdError = validatePasswords(password, reenterPassword, "register");
    if (pwdError) return pwdError;
  }
  return false;
}

export const getSerializeInvoiceProperty = (obj, prefix = "") => {
  if (!obj) return {};
  const invoiceProperty = Object.keys(obj).reduce((acc, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      return { ...acc, ...getSerializeInvoiceProperty(obj[key], newKey) };
    }
    return { ...acc, [newKey.split(".").pop()]: obj[key] };
  }, {});
  return invoiceProperty;
};

export const isValidNumber = (val) => {
  if (val === 0 || val === "0") return true;
  if (val !== "" && Number(val)) return true;
  return false;
};

const getVisibilityStatus = (value, conditional) => {
  const { eq = "", show = "" } = conditional;
  const type = eq.indexOf("=") !== -1 ? eq.slice(0, 2) : eq[0];
  const compareValue = eq.replace(type, "");
  if (!isValidNumber(value) || !isValidNumber(compareValue)) return false;
  const getValue = (result) =>
    result ? show.toString() === "true" : show.toString() === "false";
  switch (type) {
    case ">":
      return getValue(Number(value) > Number(compareValue));
    case "<":
      return getValue(Number(value) < Number(compareValue));
    case ">=":
      return getValue(Number(value) >= Number(compareValue));
    case "<=":
      return getValue(Number(value) <= Number(compareValue));
    default:
      return false;
  }
};

const getFieldVisibility = (conditional, value) => {
  if (!conditional || !conditional.when) return true;
  const operators = [">=", "<=", ">", "<"];
  if (conditional.eq && operators.some((op) => conditional.eq.startsWith(op))) {
    return getVisibilityStatus(value, conditional);
  }
  return conditional.eq.indexOf(
    value?.toString() ? value?.toString() : null
  ) !== -1
    ? conditional.show.toString() === "true"
    : conditional.show.toString() === "false";
};

export const getConditionResult = (condition, formData) => {
  const { eq, when } = condition;
  if (when && when.includes(".")) {
    const [dgName, dgProp] = when.split(".");
    const dgPropValues = (formData[dgName] || []).map((dgGrid) =>
      dgGrid[dgProp]?.toString()
    );
    return dgPropValues.some((value) => eq?.indexOf(value) !== -1);
  }
  const value = formData[when]?.toString() ? formData[when]?.toString() : null;
  return eq?.indexOf(value) !== -1;
};

const getMultiConditionResult = (conditional, customCondition, formData) => {
  const { type } = customCondition;

  const primaryConditionResult = getConditionResult(conditional, formData);
  const secondaryConditionResult = getConditionResult(
    customCondition,
    formData
  );

  if (type === "or")
    return primaryConditionResult || secondaryConditionResult
      ? conditional?.show?.toString() === "true"
      : conditional?.show?.toString() === "false";

  if (type === "and")
    return primaryConditionResult && secondaryConditionResult
      ? conditional?.show?.toString() === "true"
      : conditional?.show?.toString() === "false";
  return null;
};

const getTabDisabledState = (tab, formData) =>
  tab.conditional &&
  tab.conditional.when &&
  (getConditionResult(tab.conditional, formData)
    ? tab.conditional?.show?.toString() === "true"
    : tab.conditional?.show?.toString() === "false");

export const getTabState = (tab, formData) => {
  const { conditional, validate, key } = tab || {};
  const { customCondition } = validate?.custom
    ? getParsedValue(validate.custom, key)
    : {};
  if (customCondition) {
    return getMultiConditionResult(conditional, customCondition, formData);
  }
  return getTabDisabledState(tab, formData);
};

export const mergeDgRecordsToMainForm = (props) => {
  const { methodRefs, getDgState, setValue } = props;
  const { dgKeys, ...rest } = getDgState();
  let updatedDg = [];
  if (dgKeys && dgKeys.length) {
    dgKeys.forEach((key) => {
      if (rest[key] && rest[key].length) {
        rest[key].forEach((record) => {
          if (methodRefs && methodRefs[`${key}${record.id}`]) {
            const dataGridFormMethods = methodRefs[`${key}${record.id}`];
            updatedDg.push(dataGridFormMethods.getValues());
          }
        });
      }
      if (updatedDg.length) {
        updatedDg.forEach((record, recordIndex) =>
          setValue(`${key}.${recordIndex}`, record)
        );
        updatedDg = [];
      }
    });
  }
};

export const saveForm = async ({
  id,
  formProperties,
  updateForm,
  notifyError,
  dispatch,
  showNotification,
  originalFormProps,
}) => {
  const payload = {
    id,
    body: { formProperties },
    originalFormProps,
  };
  try {
    await updateForm(payload).unwrap();
    return true;
  } catch (error) {
    notifyError(dispatch, showNotification, error);
    return false;
  }
};

const getDocumentWatchList = (fieldProps) => {
  const { customConditional: docCustomCondition, conditional } = fieldProps;
  const list = [];
  if (conditional && conditional.when) {
    const when = (conditional?.when || "").split(".")[0];
    list.push(when);
  }

  if (fieldProps.customConditional) {
    if (docCustomCondition.or && docCustomCondition.or.length > 0) {
      docCustomCondition.or.forEach((condition) => {
        list.push((condition?.when || "").split(".")[0]);
      });
    }
    if (docCustomCondition.and && docCustomCondition.and.length > 0) {
      docCustomCondition.and.forEach((condition) => {
        list.push((condition?.when || "").split(".")[0]);
      });
    }
  }
  if (list.length) return [...new Set(list)];
  return null;
};

const shouldWatchMultipleField = (customCondition) => {
  const { type, when, eq } = customCondition || {};
  return Boolean(type && when && eq);
};

export const formConditional = (component, currentValue, formData = {}) => {
  const { conditional, validate, key } = component || {};
  const { customCondition } = validate?.custom
    ? getParsedValue(validate.custom, key)
    : {};

  if (customCondition) {
    return getMultiConditionResult(conditional, customCondition, formData);
  }
  if (Array.isArray(currentValue) && !customCondition) {
    const dgPropKey =
      (conditional &&
        conditional.when &&
        conditional.when.includes(".") &&
        conditional.when.split(".")[1]) ||
      null;
    const eqs = conditional?.eq?.split(",") || [];

    let someValueMatched = currentValue.some((item) =>
      eqs.includes(
        dgPropKey && typeof item === "object" ? item[dgPropKey] : item
      )
    );
    if (conditional?.eq === "*") someValueMatched = !!currentValue.toString();

    return someValueMatched
      ? conditional?.show?.toString() === "true"
      : conditional?.show?.toString() === "false";
  }

  return getFieldVisibility(conditional, currentValue);
};

export const getDgWatchFieldName = (fieldCondition = {}) =>
  fieldCondition.when?.includes(".")
    ? fieldCondition.when.split(".")[1]
    : fieldCondition.when;

const isMainFormReference = (conditional, dg, props) =>
  conditional && (!dg || (props && props.reference === "form"));

export const getWatchFieldName = (component, datagrid) => {
  const { key, type, conditional, properties, validate } = component || {};
  if (type !== "document") {
    const { customCondition } = validate?.custom
      ? getParsedValue(validate.custom, key)
      : {};

    if (isMainFormReference(conditional, datagrid, properties)) {
      const when = (conditional?.when || "").split(".")[0];
      const customWhen = (customCondition?.when || "").split(".")[0];
      if (shouldWatchMultipleField(customCondition)) {
        if (when === customWhen) return when;
        return [when, customWhen];
      }
      return when;
    }
    if (!isMainFormReference(conditional, datagrid, properties)) {
      if (shouldWatchMultipleField(customCondition))
        return [
          getDgWatchFieldName(conditional),
          getDgWatchFieldName(customCondition),
        ];

      return getDgWatchFieldName(conditional);
    }
  }
  return getDocumentWatchList(component);
};

export const getDatagridFormMethods = (
  formContext,
  datagridContext,
  reviewContext
) => {
  let methods = null;
  if (datagridContext) {
    const { datagridKey, record } = datagridContext;
    if (reviewContext) {
      methods = formContext[`${datagridKey}${record.id}`];
    } else {
      methods = datagridContext?.methods;
    }
  }
  return methods;
};

export const mappedValue = (list, value) => {
  if (!list || list.length === 0) return value;
  const selected = filter(list, { value: String(value) });
  return selected?.length > 0 ? selected[0].label : value;
};

// review page
export const getFieldValue = (fieldProps, formProps) => {
  const {
    type,
    key,
    data,
    dataSrc,
    customClass,
    values: listValues,
    defaultValue,
    properties,
  } = fieldProps;

  const value = formProps[key];
  if (value === undefined && defaultValue) {
    if (type === "radio")
      return { value: mappedValue(listValues, defaultValue) };
    if (type === "selectboxes") {
      let defaultList = defaultValue;
      if (!Array.isArray(defaultValue) && typeof defaultValue === "object") {
        defaultList = [];
      }
      return mappedValue(listValues, defaultList);
    }
    return mappedValue(listValues, defaultValue);
  }

  if (value !== undefined && value !== "" && type === "select") {
    if (
      (!dataSrc || dataSrc === "values") &&
      data.values &&
      data.values[0]?.value
    ) {
      return mappedValue(data.values, value);
    }
    if (dataSrc === "url" && data.url) {
      if (fieldProps.urlValues) {
        return mappedValue(fieldProps.urlValues, value);
      }
    }
    if (dataSrc === "json" && data.json) {
      return mappedValue(data.json, value);
    }
  }
  if (value !== undefined && type === "selectboxes") {
    if (Array.isArray(value)) {
      const mappedArray = [];
      if (properties?.dataSrc) {
        if (fieldProps.urlValues) {
          value.forEach((item) =>
            mappedArray.push(mappedValue(fieldProps.urlValues, item))
          );
        }
        return mappedArray.join(", ");
      }
      if (listValues && listValues[0]?.value && listValues[0]?.label) {
        value.forEach((item) =>
          mappedArray.push(mappedValue(listValues, item))
        );
        return mappedArray.join(", ");
      }
      return value.join(", ");
    }
    if (listValues && listValues[0]?.value) {
      return mappedValue(listValues, value);
    }
  }
  if (type === "radio") {
    if (
      customClass &&
      (customClass === "positive" || customClass === "negative")
    ) {
      let errorBg;
      if (customClass === "positive")
        errorBg = "no".includes(value?.toLowerCase());
      else if (customClass === "negative")
        errorBg = "yes".includes(value?.toLowerCase());
      return {
        value: mappedValue(listValues, value),
        customClass: true,
        errorBg,
      };
    }
    if (!["yes", "no"].includes(value?.toLowerCase())) {
      const val = mappedValue(listValues, value);
      return { value: val === "skipPayment" ? "Skip Payment" : val };
    }
    return { value };
  }
  return null;
};

// autocomplete
export const constructSearchUrl = (url, prop, searchTerm) => {
  let formedUrl = url;
  const params = url.match(/:[A-Za-z]+/g);
  if (params && params.length > 0) {
    params.forEach((param) => {
      const newParam = param.substring(1);
      if (newParam !== "searchTerm" && prop[newParam]) {
        formedUrl = formedUrl.replace(`:${newParam}`, prop[newParam]);
      } else if (newParam === "searchTerm") {
        formedUrl = formedUrl.replace(":searchTerm", searchTerm);
      }
    });
  }
  return formedUrl;
};

export const generateUrlParams = (uri) => {
  const uriObj = { url: "", params: null };
  if (!uri) return uriObj;
  const [url, queryParams] = uri.split("?");
  uriObj.url = url;
  if (!queryParams) return uriObj;
  uriObj.params = {};
  queryParams.split("&").forEach((queryParam) => {
    const keyValueQuery = queryParam.split("=");
    // eslint-disable-next-line prefer-destructuring
    uriObj.params[keyValueQuery[0]] = keyValueQuery[1];
  });
  return uriObj;
};

// autocomplete, optionlist with controllingField
function generateItemLabel(prop, data) {
  let labelStr = "";
  let itemLabels = prop.itemLabel;
  if (prop.itemLabel) {
    if (typeof prop.itemLabel === "string") {
      itemLabels = prop.itemLabel.split(",").map((label) => label.trim());
    }
    itemLabels.forEach((item, index) => {
      const separator = index === 0 ? "" : " - ";
      if (item.indexOf(".") !== -1) {
        const parts = item.split(".");
        const newProp = data[parts[0]];
        labelStr = labelStr + separator + newProp[parts[1]];
      } else {
        labelStr = labelStr + separator + data[item];
      }
    });
  }
  return labelStr;
}

export const getUpdatedOptionList = (
  list,
  fieldProps,
  allowEmptyLabel = true
) => {
  let filteredList = list;
  if (fieldProps.properties?.itemFilter) {
    const [key, value] = fieldProps.properties.itemFilter.split("=");
    filteredList = filteredList.filter((item) => item[key] === value);
  }
  const updatedList = filteredList.map((item) => {
    const generatedLabel = generateItemLabel(fieldProps.properties, item);
    const result = {
      value: item[fieldProps.properties?.itemValue],
      label: generatedLabel,
      display: generatedLabel,
    };
    if (!(allowEmptyLabel || result.label)) result.label = result.value;
    if (fieldProps.tags && fieldProps.tags.length > 0) {
      fieldProps.tags.forEach((tag) => {
        let keyAlias = tag;
        if (
          fieldProps.properties[tag] &&
          fieldProps.properties[tag].startsWith("@")
        ) {
          keyAlias = fieldProps.properties[tag].substring(1);
        }
        if (item.properties && item.properties[keyAlias]) {
          result[tag] = item.properties[keyAlias];
        }
      });
    }
    return result;
  });
  return updatedList;
};

export const optionListChange = (fieldProps, setValue, selected) => {
  if (fieldProps.tags) {
    fieldProps.tags.forEach((tag) => {
      if (selected && selected[tag] !== undefined)
        setValue(tag, selected[tag], { shouldValidate: true });
      if (!selected) setValue(tag, "", { shouldValidate: true });
    });
  }
  if (fieldProps.properties && fieldProps.properties.verifyCheck) {
    if (selected) setValue(`${fieldProps.key}Verified`, true);
    else setValue(`${fieldProps.key}Verified`, false);
  }
  if (fieldProps.properties && fieldProps.properties.skipOptionMapping) {
    if (selected) setValue(`${fieldProps.key}Label`, selected.label);
    else setValue(`${fieldProps.key}Label`, "");
  }
  return selected;
};

export const disableCheck = (field) => {
  if (field.validate && field.validate.custom) {
    const condition = getParsedValue(field.validate.custom, field.key) || {};
    return condition.disabled || condition.isDisabled;
  }
  // TEMP FIX :: Disable Button
  if (field.type === "button" && field.properties?.disabled) return true;
  return false;
};

function getDisabledState(conditions, data) {
  let isDisabled = false;
  if (conditions && !Array.isArray(conditions) && data) {
    // supported only if controlling field is a checkbox, should disabled when none of the keys are true
    isDisabled = true;
    let keys = [];
    keys = conditions.split(",");
    if (keys.length > 0) {
      keys.forEach((key) => {
        if (data[key]) {
          isDisabled = false;
        }
      });
    }
  } else if (
    conditions &&
    Array.isArray(conditions) &&
    conditions.length > 0 &&
    data
  ) {
    conditions.forEach((condition) => {
      if (
        condition.when &&
        data[condition.when] !== undefined &&
        condition.eq.indexOf(data[condition.when]) !== -1
      ) {
        isDisabled = true;
        return "";
      }
      return "";
    });
  }
  return isDisabled;
}

export const disableInput = (fieldProps, formProps) => {
  const isAdmin =
    getUserPermissions() && getUserPermissions().includes("edit_locked_fields");
  const { applicationData, ...formData } = formProps;
  const field = { ...fieldProps };
  // TEMP FIX :: Disable Button
  if (field.type === "button" && field.properties?.disabled) {
    const custom = JSON.stringify({ disabled: field.properties.disabled });
    field.validate = { custom };
  }
  if (field.validate && field.validate.custom) {
    const condition = getParsedValue(field.validate.custom, field.key);
    if (condition.disabled && !isAdmin) {
      if (condition.freezeValue && !applicationData) {
        field.disabled = false;
      } else if (!isEmpty(applicationData)) {
        field.disabled = condition.disabled.includes(applicationData.status);
      } else if (!condition.freezeValue && isEmpty(applicationData)) {
        field.disabled = false;
      }
    }
    if (
      condition.isDisabled &&
      !isAdmin &&
      (!condition.disabled || !field.disabled)
    ) {
      if (!Array.isArray(condition.isDisabled)) {
        field.disabled = getDisabledState(condition.isDisabled, formData);
      } else {
        field.disabled = getDisabledState(condition.isDisabled, formData);
        // update mappings to track on change of controlling fields
        condition.isDisabled.forEach((predicate) => {
          if (!field.controllingFieldKey) {
            field.controllingFieldKey = [predicate.when];
            field.controllingFieldValue = [predicate.eq];
          } else if (
            field.controllingFieldKey &&
            field.controllingFieldKey.indexOf(predicate.when) === -1
          ) {
            field.controllingFieldKey.push(predicate.when);
            field.controllingFieldValue.push(predicate.eq);
          }
        });
      }
    }
    // disable field even if user is a Admin
    if (condition.disabled === "overrideAdmin") field.disabled = true;
  }
  return field;
};

export const conditionalDisable = (
  watchList,
  watchFieldProps,
  defaultWatchList,
  defaultWatchListValues
) => {
  const { controllingFieldValue } = watchFieldProps || {};
  if (Array.isArray(watchList) && controllingFieldValue) {
    return watchList.some((value, i) =>
      controllingFieldValue[i]?.includes(value)
    );
  }
  if (!Array.isArray(watchList) && defaultWatchList) {
    return defaultWatchListValues.some((value, i) =>
      controllingFieldValue[i]?.includes(value)
    );
  }
  return undefined;
};

function getCustomValidity(expectedValue, value) {
  let expected = expectedValue;

  // this condition is applied when we receive array. e.g: for state [!unknown,!AK]
  if (Array.isArray(expectedValue)) {
    expected = value;
    const newExpectedValue = expectedValue.find(
      (item) => item === value || item === `!${value}`
    );
    if (newExpectedValue) expected = newExpectedValue;
  }

  const negative = expected.startsWith("!") || false;
  return !(
    (negative && value === expected.substr(1)) ||
    (!negative && value !== expected)
  );
}

export const customValidation = (field, value, getValues) => {
  if (field.validate && field.validate.custom) {
    const condition = getParsedValue(field.validate.custom, field.key);
    if (condition.required) {
      if (!value) {
        // _this.taskForm[key].$setValidity("validRequiredOption", true); // need to check and update
      } else if (value) {
        return getCustomValidity(condition.required, value);
      }
    }
    // this is a very specific check for question related to 18 years or older?
    if (condition.checkDoB) {
      const dob = getValues(condition.checkDoB);
      let isValid = true;
      if (value && dob) {
        const dobValue = moment.parseZone(dob).format("L");
        const diff = moment().diff(moment(dobValue, "MM/DD/YYYY"), "year");
        const ageCriteria = condition.ageCriteria
          ? Number(condition.ageCriteria)
          : 18;
        if (value === "Yes" && diff >= ageCriteria) {
          isValid = true;
        } else if (value === "No" && diff < ageCriteria) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
      return isValid;
    }
  }
  return true;
};

const isValidDate = (condition, value, getValues) => {
  // expectedDateKey format -->  <targetKey or >targetKey or !targetKey
  const operator = ["<", ">", "!"].includes(condition.expectedDateKey[0])
    ? condition.expectedDateKey[0]
    : "=";
  const targetDate = getValues(
    condition.expectedDateKey.substring(operator === "=" ? 0 : 1)
  );
  if (!targetDate) return true;

  const startDate = moment(targetDate, "MM/DD/YYYY");
  const endDate = moment(value, "MM/DD/YYYY");
  switch (operator) {
    case "!":
      return !startDate.isSame(endDate);
    case "=":
      return startDate.isSame(endDate);
    case ">":
      return startDate.isBefore(endDate);
    case "<":
      return startDate.isAfter(endDate);
    default:
      return false;
  }
};

export const customDateValidation = (condition, type, value, getValues) => {
  if (condition.dob && type === "dob") {
    let newDateValue = value;
    if (!newDateValue) return true;
    newDateValue = moment.parseZone(newDateValue).format("L");
    let minAge = 21;
    let maxAge = 150;
    if (condition.minAge !== undefined) minAge = condition.minAge;
    if (condition.maxAge) maxAge = condition.maxAge;
    const diff = moment().diff(moment(newDateValue, "MM/DD/YYYY"), "year");
    return !(diff < minAge || diff > maxAge);
  }

  if (condition.minDate && type === "min") {
    if (condition.minDate === "today") return new Date();
    let minOffset = null;
    if (condition.minDate.startsWith("-")) {
      minOffset = Number(condition.minDate.substr(1))
        ? Number(condition.minDate.substr(1)) - 1
        : null;
      return minOffset
        ? moment().subtract(minOffset, "d").toDate()
        : new Date(condition.minDate);
    }
  }

  if (condition.maxDate && type === "max") {
    if (condition.maxDate === "today") return new Date();
    let maxOffset = null;
    if (condition.maxDate.startsWith("+")) {
      maxOffset = Number(condition.maxDate.substr(1))
        ? Number(condition.maxDate.substr(1)) - 1
        : null;
    }
    return maxOffset
      ? moment().add(maxOffset, "d").toDate()
      : new Date(condition.maxDate);
  }
  if (condition.expectedDateKey && type === "expectedDate")
    return isValidDate(condition, value, getValues);

  return undefined;
};

function resetDatagrid(resetInfo, formProps, setValue, methodRefs, trigger) {
  if (
    formProps[resetInfo.datagrid] &&
    formProps[resetInfo.datagrid].length > 0
  ) {
    if (resetInfo.fields) {
      const updatedDg = [];
      formProps[resetInfo.datagrid].forEach((record) => {
        if (methodRefs && methodRefs[`${resetInfo.datagrid}${record.id}`]) {
          const dataGridFormMethods =
            methodRefs[`${resetInfo.datagrid}${record.id}`];
          resetInfo.fields.forEach((field) => {
            if (field.includes("-dt"))
              dataGridFormMethods.setValue(field, null);
            else dataGridFormMethods.setValue(field, "");
            trigger(field);
          });
          // use replace
          updatedDg.push(dataGridFormMethods.getValues());
        }
      });
      if (updatedDg.length) {
        setValue(resetInfo.datagrid, updatedDg);
        // updatedDg.forEach((record, recordIndex) =>
        //   setValue(`${resetInfo.datagrid}.${recordIndex}`, record)
        // );
      }
    } else {
      const { initEmpty } = formProps[`${resetInfo.datagrid}Config`] || {};
      const value = initEmpty ? [] : [{}];
      setValue(resetInfo.datagrid, value);
    }
  }
}

function isPredicateValid(predicate, formData) {
  let isValid = false;
  if (!predicate) {
    isValid = true;
  } else if (predicate.length > 0) {
    isValid = predicate.every((item) => formData[item.key] === item.value);
  }
  return isValid;
}

function isVerifyFlag(key) {
  const verificationFlags = ["Verified", "Marked"];
  let isVerificationFlag = false;
  verificationFlags.forEach((flag) => {
    if (key.includes(flag)) {
      isVerificationFlag = true;
    }
  });
  return isVerificationFlag;
}

function getResetValue(value, key) {
  let resetValue = "";
  if (typeof value === "boolean" && !isVerifyFlag(key)) {
    resetValue = false;
  } else {
    resetValue = "";
  }
  return resetValue;
}

function getLastDateOfMonth(startDate, endMonth) {
  if (!startDate || !endMonth) return "";
  const startDateObj = moment(startDate, "MM/DD/YYYY");
  const startYear = startDateObj.year();
  const startMonth = startDateObj.month() + 1;

  const endMonthIndex =
    moment(`${endMonth} 1, ${startYear}`, "MMMM D, YYYY").month() + 1;
  const endYear = endMonthIndex < startMonth ? startYear + 1 : startYear;
  const endDateObj = moment(`${endYear}-${endMonthIndex}-01`).endOf("month");
  const difference = endDateObj.diff(startDateObj, "days");

  if (difference > 365) {
    endDateObj.subtract(1, "month").endOf("month");
  }
  return endDateObj.format("MM/DD/YYYY");
}

export const resetOnChange = (props) => {
  const {
    condition,
    formProps,
    setValue,
    methodRefs,
    key,
    trigger,
    getValues,
  } = props;
  const { reset } = condition || {};
  const selectedVal = getValues(key);
  if (Array.isArray(reset) && reset.length > 0) {
    reset.forEach((item) => {
      if (item.constructor.name === "Object") {
        if (item.datagrid) {
          resetDatagrid(item, formProps, setValue, methodRefs, trigger);
        } else {
          const validValue =
            item.value !== undefined ? item.value : selectedVal;
          if (
            selectedVal !== undefined &&
            (item.when === undefined || item.when === null) &&
            isPredicateValid(item.predicate, getValues())
          ) {
            setValue(item.target, validValue);
          } else if (
            selectedVal !== undefined &&
            item.when !== undefined &&
            item.when !== null &&
            isPredicateValid(item.predicate, getValues())
          ) {
            if (Array.isArray(item.when)) {
              const isMatching = item.when.some(
                (value) => value === selectedVal
              );
              if (isMatching) setValue(item.target, validValue);
            } else if (selectedVal === item.when)
              setValue(item.target, validValue);
            else if (!item.skipDefault) setValue(item.target, "");
            // to update the form error state
            trigger(item.target);
          }
          if (item.target && item.customCalculation) {
            const { type, data } = item.customCalculation;
            if (type === "lastDateOfMonth" && data.startDate) {
              const lastDateOfMonth = getLastDateOfMonth(
                getValues(data.startDate),
                selectedVal
              );
              setValue(item.target, lastDateOfMonth);
              trigger(item.target);
            }
          }
        }
      } else if (item.includes("-dt")) setValue(item, null);
      else {
        const resetValue = getResetValue(getValues(item), item);
        setValue(item, resetValue);
      }
      // if (item.constructor.name !== "Object") {
      //   trigger(item);
      // }
    });
  }
};

export const disableOptions = (fieldProps, getValues) => {
  const formProps = { ...getValues() };
  let optionsList = fieldProps.values;
  let warningMessage = "";
  if (
    formProps[fieldProps.key] &&
    formProps[fieldProps.key].length > 0 &&
    fieldProps.validate &&
    fieldProps.validate.custom
  ) {
    const condition = getParsedValue(
      fieldProps.validate.custom,
      fieldProps.key
    );
    if (condition && condition.disableOptions) {
      const intersectionResult =
        intersection(
          condition.disableOptions.mutuallyExcl,
          formProps[fieldProps.key]
        ).length > 0;
      if (intersectionResult) {
        optionsList = optionsList.map((item) => ({
          ...item,
          disabled:
            condition.disableOptions.mutuallyExcl.indexOf(item.value) === -1,
        }));
      } else {
        const mutuallyExclVal = condition.disableOptions.mutuallyExcl;
        optionsList = optionsList.map((item) => ({
          ...item,
          disabled:
            mutuallyExclVal === "self"
              ? getValues(fieldProps.key)[0] !== item.value
              : mutuallyExclVal.indexOf(item.value) !== -1,
        }));
      }
      warningMessage = condition.disableOptions.message;
    }
  } else {
    optionsList.forEach((item) => ({
      ...item,
      disabled: false,
    }));
  }
  return [optionsList, warningMessage];
};

function updateLicenseProps(props, response) {
  const { fieldProps: button, payload } = props;
  const { setValue, dispatch, showNotification, trigger } = payload || {};
  if (response && response.licenseNumber) {
    button.tags?.forEach((tag) => {
      let keyAlias = tag;
      if (button.properties[tag] && button.properties[tag].startsWith("@")) {
        keyAlias = button.properties[tag].substring(1);
      }
      const value =
        response.properties[keyAlias] !== undefined
          ? response.properties[keyAlias]
          : "";
      setValue(tag, value);
      trigger(tag);
    });
    const msg = "Information updated successfully.";
    dispatch(showNotification({ type: "success", msg, show: true }));
    if (button.properties && button.properties.verifyCheck) {
      setValue(`${button.key}Verified`, true);
    }
  } else if (button.properties && button.properties.verifyCheck) {
    setValue(`${button.key}Verified`, "");
  }
  trigger(`${button.key}Verified`);
}

async function getLicenseDetails(args) {
  const { licenseType, licenseNumber, licenseMutation, props, ...rest } = args;

  const licensePayload = { licenseType, licenseNumber, params: { ...rest } };
  const { data, error } = await licenseMutation(licensePayload);
  if (data) {
    updateLicenseProps(props, data);
  }
  if (error) {
    const { payload } = props;
    const { dispatch, showNotification } = payload;
    const msg =
      "Entered License Number is either invalid or isn't active. Please enter a valid license number.";
    dispatch(showNotification({ type: "error", msg, show: true }));
  }
}

export const fetchAndLoadRecord = (props) => {
  const { fieldProps: button, getValues, payload, formProps } = props;
  if (button.properties && button.properties.filterKey) {
    const {
      filterKey,
      recordType,
      formatType,
      propertyFilters,
      internalLicense,
      regEx,
      filterType,
      filterLabel,
      status,
    } = button.properties;
    const { dispatch, showNotification, licenseLookup, getLicense } = payload;
    const filterKeyValue =
      getValues(filterKey) === undefined
        ? formProps[filterKey]
        : getValues(filterKey);
    if (filterKeyValue) {
      if (!regEx || RegExp(regEx).test(filterKeyValue)) {
        const requestProps = {};
        requestProps[filterType] = filterKeyValue;
        requestProps.licenseType = recordType;
        requestProps.formatType = formatType;
        requestProps.status = status;
        if (propertyFilters) {
          const propFilter = getParsedValue(propertyFilters, button.key);
          requestProps.propertyFilters = stringify(propFilter);
        }
        const licenseMutation = internalLicense ? getLicense : licenseLookup;
        getLicenseDetails({ ...requestProps, licenseMutation, props });
      } else {
        const msg = `Entered ${filterLabel} is not in a valid format.`;
        dispatch(showNotification({ type: "error", msg, show: true }));
      }
    } else {
      const msg = `Please enter a valid  ${filterLabel}`;
      dispatch(showNotification({ type: "error", msg, show: true }));
    }
  }
};

export const copyAddressFromSource = (
  properties,
  sourceFields,
  setValue,
  trigger
) => {
  Object.entries(properties).forEach(([key, value]) => {
    if (key !== "source" && sourceFields[value] !== undefined) {
      setValue(key, sourceFields[value]);
      // trigger is called to update the status icon on review page
      trigger(key);
    }
  });
};

export const verifyLicenseNumber = (props) => {
  const { fieldProps, getValues, setValue } = props;
  let success = "";
  let error = "";
  const replaceAt = (str, index, chars) => {
    const response =
      str.substr(0, index) + chars + str.substr(Number(index) + chars.length);
    return response;
  };

  if (fieldProps && fieldProps.properties.source) {
    const pattern = RegExp(fieldProps.properties.regex);
    let licenseNumber = getValues(fieldProps.properties.source);
    if (licenseNumber && pattern.test(licenseNumber)) {
      switch (fieldProps.properties.action) {
        case "replace":
          licenseNumber = replaceAt(
            licenseNumber,
            fieldProps.properties.startIndex,
            fieldProps.properties.replaceStr
          );
          if (pattern.test(licenseNumber)) {
            setValue(fieldProps.properties.source, licenseNumber.toUpperCase());
            success = "License Number verified successfully";
          }
          break;
        default:
          break;
      }
    } else {
      error =
        "Invalid License Number format, please enter valid License Number.";
    }
  } else {
    error = "Could not find license number, please try again later.";
  }
  return [success, error];
};

export const validateAddressFields = (props) => {
  const { tags, errors, source, btnType } = props;
  const errorTextMap = {
    street: "street information",
    zip: "zip code",
    city: "city information",
    state: "state information",
    verified: "",
  };

  const getErrorFieldKey = (fields, err) =>
    fields.find(
      (key) =>
        err[key] &&
        Object.keys(errorTextMap).some((item) =>
          key.toLowerCase().includes(item)
        )
    );

  const getErrorText = (errMap, errFieldKey) =>
    Object.keys(errMap).find((key) => errFieldKey.toLowerCase().includes(key));

  if (btnType === "copy") {
    const isSourceFieldsValueEmpty = tags.every(
      (key) => Boolean(errors[key]) || key.includes("UnitNo")
    );
    if (isSourceFieldsValueEmpty)
      return `Please fill the required fields for ${source} & verify it.`;
    const errorFieldKey = getErrorFieldKey(tags, errors);
    if (errorFieldKey && errorFieldKey.toLowerCase().includes("verified"))
      return `Please verify the address for ${source}`;
    if (errorFieldKey) {
      const errorText = getErrorText(errorTextMap, errorFieldKey);
      if (errorTextMap[errorText])
        return `Please provide a valid ${errorTextMap[errorText]} for ${source}`;
    }
  }
  if (btnType === "verify") {
    const isRequiredFieldsValueEmpty = tags.every(
      (key) => Boolean(errors[key]) || key.includes("UnitNo")
    );
    if (isRequiredFieldsValueEmpty) return `Please fill the required fields.`;
    const errorFieldKey = getErrorFieldKey(tags, errors);
    if (errorFieldKey) {
      const errorText = getErrorText(errorTextMap, errorFieldKey);
      if (errorTextMap[errorText])
        return `Please provide a valid ${errorTextMap[errorText]}.`;
    }
  }
  return null;
};

export const validQueryParams = (queryString, data) => {
  // input : key1=@formKey1,key2=@formKey2
  let isValid = true;
  if (queryString && data) {
    const params = queryString.split(",");
    params.forEach((param) => {
      const key = param.split("@")[1];
      if (data[key] === undefined || data[key] === "") {
        isValid = false;
      }
    });
  } else {
    isValid = false;
  }
  return isValid;
};

export const filterRecordSelectionList = (props, data, list) => {
  let filtered = list.docs ? list.docs : [];
  if (
    !filtered ||
    filtered.length === 0 ||
    !props ||
    !props.compareValueKey ||
    !data[props.compareValueKey]
  ) {
    return filtered;
  }
  filtered = filtered.filter(
    (listItem) =>
      listItem.properties[props.sourceValueKey] !== data[props.compareValueKey]
  );
  return filtered;
};

export const formRecordSelectionReq = (props, data) => {
  const reqPayload = {};
  if (props && data) {
    reqPayload.licenseType = props.recordType;
  }
  if (props && props.queryParamKeys) {
    const params = props.queryParamKeys.split(",");
    params.forEach((param) => {
      const pair = param.split("=@");
      reqPayload[pair[0]] = data[pair[1]];
    });
  }
  return reqPayload;
};

export const setRecordValuesToFormFields = (props) => {
  const {
    recordProps,
    fieldProps,
    getValues,
    setValue,
    dispatch,
    showNotification,
    trigger,
  } = props;

  if (recordProps && fieldProps.tags) {
    fieldProps.tags.forEach((tag) => {
      let sourceKey = tag;
      // check if tag exists in properties, if so, get alias and update
      if (fieldProps.properties && fieldProps.properties[tag]) {
        sourceKey = fieldProps.properties[tag].substring(1);
      }
      if (
        recordProps[sourceKey] !== undefined &&
        recordProps[sourceKey] !== ""
      ) {
        setValue(tag, recordProps[sourceKey]);
      } else if (getValues(tag)) {
        setValue(tag, "");
      }
      trigger(tag);
    });
    setValue(`${fieldProps.key}Selected`, true);
    trigger(`${fieldProps.key}Selected`);
    return true;
  }
  const msg = `Could not update ${fieldProps?.properties?.title} please try again later.`;
  dispatch(showNotification({ type: "error", msg, show: true }));
  return false;
};

function fixConditional(condition) {
  if (!condition) return;
  const { conditions, json } = condition;
  if (conditions && conditions.length > 0) {
    const conditionsObj = condition.conditions[0] ?? {};
    const { component = null, value = null } = conditionsObj;
    if (component && value) {
      // eslint-disable-next-line no-param-reassign
      condition.when = component;
      // eslint-disable-next-line no-param-reassign
      condition.eq = value;
    }
  } else if (json) {
    Object.keys(json).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      condition[key] = json[key];
    });
  }
}

const nextNavigateFormConfig = (component, config) => {
  if (!component) return config;
  const parent = component.type === "datagrid" ? component : config.parent;
  const actionPanelParent =
    component.type === "panel" && component.properties?.group
      ? component
      : config.actionPanelParent;
  return { ...config, parent, actionPanelParent };
};

const setFieldsStore = (component = {}, config = {}) => {
  const { key, type, validate = {}, properties = {} } = component;
  const { store = {}, parent, actionPanelParent } = config;
  const excludedComponent =
    type === "datagrid" ||
    type === "panel" ||
    type === "table" ||
    type === "licenseInfo";

  if (properties.type === "subLicenses")
    store.subLicensesProps.push({
      key,
      fetchOnStatus: properties.fetchOnStatus,
      cloneIndicator: properties.cloneIndicator,
    });
  if (properties.validateSomeOnClone) store.cloneKeys.push(key);
  if (properties.preSubmitActions) {
    properties.preSubmitActions.split(",").forEach((action) => {
      if (!store.preSubmitActions[action])
        store.preSubmitActions[action] = { keys: [] };
      store.preSubmitActions[action].keys.push(key);
    });
  }
  if (type === "panel" && properties.panel && properties.group) {
    const { panel, group, overrideIndicator } = properties;
    if (!store.actionPanels[panel]) store.actionPanels[panel] = [];
    store.actionPanels[panel].push(component);
    if (!store.actionPanelFields[group]) store.actionPanelFields[group] = {};
    if (!store.actionPanelFields[group][key])
      store.actionPanelFields[group][key] = [];
    if (!store.actionPanelFields[group][key].overrideIndicator)
      store.actionPanelFields[group][key].overrideIndicator = {};
    store.actionPanelFields[group][key].overrideIndicator = overrideIndicator;
  }
  if (!(key && type && !excludedComponent)) return;

  if (actionPanelParent) {
    const {
      properties: { group },
      key: currPanelKey,
    } = actionPanelParent;

    if (!store.actionPanelFields[group]) store.actionPanelFields[group] = {};
    if (!store.actionPanelFields[group][currPanelKey])
      store.actionPanelFields[group][currPanelKey] = [];

    store.actionPanelFields[group][currPanelKey].push(key);
  }

  let _fields = store.fields;
  let _requiredFields = store.requiredFields;
  if (parent) {
    if (!store.fields[parent.key]) store.fields[parent.key] = {};
    if (!store.requiredFields[parent.key])
      store.requiredFields[parent.key] = {};
    _fields = store.fields[parent.key];
    _requiredFields = store.requiredFields[parent.key];
  }

  _requiredFields[key] = validate.required;
  const newComponent = cloneDeep(component);
  delete newComponent.components;
  _fields[key] = newComponent;
};

const navigateForm = (
  formData,
  config = { shouldFixConditional: true, overrideKeyForDialog: true }
) => {
  if (!formData) return;

  if (Array.isArray(formData)) {
    formData.forEach((data) => {
      if (typeof data === "object")
        navigateForm(data, nextNavigateFormConfig(data, config));
    });
  } else if (typeof formData === "object") {
    if (
      config.shouldFixConditional &&
      formData.key &&
      formData.type &&
      formData.conditional
    ) {
      fixConditional(formData.conditional);
    }
    if (
      config.overrideKeyForDialog &&
      formData.key &&
      formData.properties &&
      formData.properties.overrideKeyForDialog
    ) {
      // eslint-disable-next-line no-param-reassign
      formData.key = formData.properties.overrideKeyForDialog;
    }
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object") {
        const data = formData[key];
        navigateForm(data, nextNavigateFormConfig(data, config));
      }
    });
  }

  if (config.store) setFieldsStore(formData, config);
};

export const updateFormConditions = (formData) => {
  const cloneFormData = cloneDeep(formData);
  navigateForm(cloneFormData);
  return cloneFormData;
};

const getRequiredFields = (formConfig, fieldKeys, requiredFields) => {
  if (!formConfig) return;
  if (Array.isArray(formConfig)) {
    formConfig.forEach((data) => {
      if (typeof data === "object")
        getRequiredFields(data, fieldKeys, requiredFields);
    });
  } else if (typeof formConfig === "object") {
    if (fieldKeys.includes(formConfig.key)) requiredFields.push(formConfig);
    Object.keys(formConfig).forEach((key) => {
      if (typeof formConfig[key] === "object") {
        getRequiredFields(formConfig[key], fieldKeys, requiredFields);
      }
    });
  }
};

const getFieldProps = (formConfig, fieldKeys) => {
  const requiredFields = [];
  getRequiredFields(formConfig, fieldKeys, requiredFields);
  return requiredFields;
};

export const getLicenseInfoMapping = (formConfig, fieldKeys, formData) => {
  const mappedValues = {};
  const fields = getFieldProps(formConfig, fieldKeys);
  fields.forEach((fieldProps) => {
    mappedValues[fieldProps.key] = getFieldValue(fieldProps, formData);
  });
  return mappedValues;
};

export const mergeMainFormAndActionPanelData = (data, config, methods) => {
  const parentPanels = (config.actionPanelFields || {}).parent;
  const mergedData = { ...data };
  Object.entries(parentPanels || {}).forEach(([panelKey, panelFields]) => {
    const { overrideIndicator } = parentPanels[panelKey];
    if (data[overrideIndicator] !== "Yes")
      panelFields.forEach((key) => {
        mergedData[key] = methods.getValues(key);
      });
  });
  return mergedData;
};

const updateBulkRenewActionDialog = (formData, config) => {
  const { isBulkRenewParentLicense, properties = {} } = formData;
  // Add Action Panel data to parent license properties
  if (isBulkRenewParentLicense) {
    const parentPanels = (config.actionPanelFields || {}).parent;
    const parentPanelsData = (config.actionPanelFieldsData || {}).parent;

    let extraProperties = {};
    Object.entries(parentPanelsData || {}).forEach(
      ([panelKey, panelProperties]) => {
        const { overrideIndicator } = parentPanels[panelKey];
        const showPanel = properties[overrideIndicator] === "Yes";
        if (!showPanel)
          extraProperties = { ...extraProperties, ...panelProperties };
      },
      {}
    );
    // eslint-disable-next-line no-param-reassign
    if (!formData.properties) formData.properties = {};
    // eslint-disable-next-line no-param-reassign
    formData.properties = { ...formData.properties, ...extraProperties };
  }
};

// Remove all object keys that ends with __DUMMY
export const sanitizeFormData = (formData, config = {}) => {
  const { itemsToSkip = [], subLicensesProps = [], parentKey = "" } = config;
  if (!formData) return;
  if (Array.isArray(formData)) {
    formData.forEach((data) => {
      if (typeof data === "object") sanitizeFormData(data, config);
    });
  } else if (typeof formData === "object") {
    const subLicenseProp = subLicensesProps.find(
      (subLicProp) => subLicProp.key === parentKey
    );
    const shouldFormatSubLicenses =
      subLicenseProp &&
      ((subLicenseProp.cloneIndicator &&
        ["Open", "Submitted"].includes(config.applicationStatus)) ||
        subLicenseProp.fetchOnStatus?.includes(config.applicationStatus));
    // Format subLicenses
    if (shouldFormatSubLicenses) {
      const { recordId, licenseNumber, recordType } =
        formData?.licenseProps || formData;
      const subLicenseData = cloneDeep(formData);
      delete subLicenseData.properties;
      delete subLicenseData.licenseProps;
      // eslint-disable-next-line no-param-reassign
      Object.keys(formData || {}).forEach((key) => delete formData[key]);
      // eslint-disable-next-line no-param-reassign
      formData.recordId = recordId;
      // eslint-disable-next-line no-param-reassign
      formData.recordType = recordType;
      // eslint-disable-next-line no-param-reassign
      formData.licenseNumber = licenseNumber;
      // eslint-disable-next-line no-param-reassign
      formData.properties = subLicenseData;
    }

    updateBulkRenewActionDialog(formData, config);

    Object.keys(formData).forEach((key) => {
      if (key.endsWith("Valid") && !itemsToSkip.includes("Valid")) {
        const fieldKey = key.substring(0, key.length - 5);
        // eslint-disable-next-line no-param-reassign
        if (!formData[fieldKey]) delete formData[key];
      }
      if (key.endsWith("__DUMMY")) {
        // eslint-disable-next-line no-param-reassign
        delete formData[key];
      } else if (typeof formData[key] === "object") {
        sanitizeFormData(formData[key], { ...config, parentKey: key });
      }
    });
  }
};

/**
 * @param {Object} formData current formProps
 * @param {Object} originalFormData original formProps
 * @param {Array} config.subLicensesProps Collection of sub licenses key.
 * @param {Array} config.itemsToSkip ["Valid"] skip key ends with "Valid".
 */
export const getSanitizedFormData = (
  formData,
  originalFormProps = null,
  config = {}
) => {
  const { itemsToSkip, actionPanelFields = {} } = config;
  const actionPanelFieldsData = {};
  Object.entries(actionPanelFields).forEach(([groupType, groups]) => {
    Object.entries(groups).forEach(([groupKey, fieldKeys]) => {
      fieldKeys.forEach((fieldKey) => {
        if (!actionPanelFieldsData[groupType])
          actionPanelFieldsData[groupType] = {};
        if (!actionPanelFieldsData[groupType][groupKey])
          actionPanelFieldsData[groupType][groupKey] = {};
        actionPanelFieldsData[groupType][groupKey][fieldKey] =
          formData.formProperties
            ? formData.formProperties[fieldKey]
            : formData[fieldKey];
      });
    });
  });
  const cloneAndSanitize = (data, skipItems) => {
    if (!data) return {};
    const _itemsToSkip = Array.isArray(skipItems) ? skipItems : [];
    const clonedData = cloneDeep(data);

    sanitizeFormData(clonedData, {
      ...config,
      itemsToSkip: _itemsToSkip,
      actionPanelFieldsData,
    });

    return clonedData;
  };
  const sanitizedFormData = cloneAndSanitize(formData, itemsToSkip);
  const sanitizedOriginalFormProps = originalFormProps
    ? cloneAndSanitize(originalFormProps)
    : null;

  return originalFormProps
    ? {
        formProperties: {
          ...(sanitizedOriginalFormProps || {}),
          ...(sanitizedFormData.formProperties || {}),
        },
      }
    : sanitizedFormData;
};

/**
 * Flattens a formConfig object into a FlattenedObject with bool value or obj with key-value pair with bool values.
 *
 * @param {Object} formConfig The nested object to flatten.
 * @param {boolean} getAllProperties (optional) The flag to return required fields or whole object.
 *
 * @example
 * ```javascript
 * const nestedObj = {
 *  components: [
 *      {
 *        key: "key1",
 *        validate: { required: true },
 *        components: [
 *          { key: "nestedKey1" },
 *          { key: "nestedKey2", validate: { required: true }},
 *          {
 *            key: "nestedDatagrid",
 *            type: "datagrid",
 *            children: [{ key: "nestedDgKey1", validate: { required: true }}, { key: "nestedDgKey2" }],
 *          },
 *        ],
 *      },
 *      { key: "key2" },
 *      {
 *        key: "datagrid",
 *        type: "datagrid",
 *        children: [{ key: "dgKey1" }, { key: "dgKey2", validate: { required: true } }],
 *      },
 *    ],
 *  };
 *
 * const { requiredFields, fields } = getSimpleForm(nestedObj);
 * // requiredFields will be
 *  {
 *    datagrid: { childKey1: undefined, childKey2: true },
 *    key1: true,
 *    key2: undefined,
 *    nestedDatagrid: { nestedChildKey1: true, nestedChildKey2: undefined },
 *    nestedKey1: undefined,
 *    nestedKey2: true,
 *  }
 * // fields will be if getAllProperties is true
 *  {
 *    datagrid: { childKey1: { ... }, childKey2: { ... } },
 *    key1: { ... },
 *    key2: { ... },
 *    nestedDatagrid: { nestedChildKey1: { ... }, nestedChildKey2: { ... } },
 *    nestedKey1: { ... },
 *    nestedKey2: { ... },
 *  }
 * where { ... } is object without components key
 * ```
 */
export const getSimpleForm = (formConfig) => {
  const store = {
    requiredFields: {},
    fields: {},
    subLicensesProps: [],
    cloneKeys: [],
    actionPanels: {},
    actionPanelFields: {},
    preSubmitActions: {},
  };
  navigateForm(cloneDeep(formConfig.components), { store });
  return store;
};

function doReduce(records, rules) {
  let total = null;
  if (rules.action === "add") {
    records.forEach((record) => {
      if (!isArray(rules.field) && !Number.isNaN(record[rules.field])) {
        total += Number(record[rules.field]);
      } else {
        rules.field.forEach((key) => {
          if (record[key] && record[key] !== "" && !Number.isNaN(record[key])) {
            total += Number(record[key]);
          }
        });
      }
    });
  }
  return total ? parseFloat(total.toFixed(3)) : null;
}

function checkEquality(computed, validateConf) {
  let isValid = false;
  if (computed) {
    if (validateConf.equalTo) {
      isValid = computed === validateConf.equalTo;
    }
    if (validateConf.ltEqualTo) {
      isValid = computed <= validateConf.ltEqualTo;
    }
    if (validateConf.gtEqualTo) {
      isValid = computed >= validateConf.ltEqualTo;
    }
    if (validateConf.gt) {
      isValid = computed > validateConf.gt;
    }
    if (validateConf.lt) {
      isValid = computed < validateConf.lt;
    }
  }
  return isValid;
}

// given a datagrid form and form data, validate the data against custom validation object
export const dgCustomValidation = (data, custom, dgKey) => {
  const result = {
    isValid: true,
  };
  const condition = getParsedValue(custom, dgKey);

  if (data && data.length && condition) {
    switch (condition.type) {
      case "reduce":
        {
          const computedValue = doReduce(data, condition);
          if (!checkEquality(computedValue, condition)) {
            result.isValid = false;
            result.errMsg = condition.errMsg;
          }
        }
        break;
      default:
        break;
    }
  }
  return result;
};

const dgTabDisableCheck = (dgKey, dgKeysWithTabIndex, tabState = {}) => {
  const tabIndex = Object.entries(dgKeysWithTabIndex).find(([, keys]) =>
    keys.includes(dgKey)
  )?.[0];
  if (tabIndex !== undefined) return tabState[tabIndex];
  return false;
};

// verifying dg records on submitting the application form
export const verifyDgFields = (
  currentDgState,
  methodRefs,
  tabState,
  requireFieldsInfo,
  dgDocuments = {}
) => {
  const { dgKeys, dgConfigs, dgKeysWithTabIndex, ...dgState } = currentDgState;
  const result = {
    isError: false,
    errMsg: "",
    multiErrMsg: [
      {
        type: "open",
        errMsg: `Please save entries under the following sections by clicking on "Save Record" button before submitting the application.`,
        dgLabels: [],
      },
      {
        type: "close",
        errMsg: `Please fill the required entries under the following section by clicking on "Edit Record" button.`,
        dgLabels: [],
      },
    ],
  };
  if (dgKeys.length) {
    dgKeys.forEach((dgKey) => {
      // skip validation if dg's tab is disabled
      const skipValidation = dgTabDisableCheck(
        dgKey,
        dgKeysWithTabIndex,
        tabState
      );
      if (!skipValidation) {
        if (dgState[dgKey] && dgState[dgKey].length) {
          dgState[dgKey].forEach((record, recordIndex) => {
            const dataGridFormMethods = methodRefs[dgKey + record.id];
            if (dataGridFormMethods) {
              const datagridValues = dataGridFormMethods.getValues() || {};
              const { id: dgId, docRefId } = datagridValues;
              // if record is in editable mode
              const isRecordOpen = datagridValues.status !== "saved";
              if (isRecordOpen) {
                dataGridFormMethods.trigger();
                result.isError = true;
                const dgLabel = dgConfigs[dgKey]?.title || "";
                if (dgLabel) {
                  result.multiErrMsg[0].dgLabels.push(dgLabel);
                }
              } else {
                const dgDisplayedFields =
                  (requireFieldsInfo.displayedFields[dgKey] || {})[dgId] || [];
                const allDgFields =
                  requireFieldsInfo.requiredFields[dgKey] || {};
                const dgRequiredFields = Object.keys(allDgFields).filter(
                  (key) => allDgFields[key]
                );
                const requiredAndDisplayedFields = dgDisplayedFields.filter(
                  (key) => dgRequiredFields.includes(key)
                );

                const hasEmptyField = requiredAndDisplayedFields.find((key) => {
                  const hasDocuments =
                    dgDocuments[docRefId] &&
                    dgDocuments[docRefId][key] &&
                    dgDocuments[docRefId][key].length > 0;
                  if (hasDocuments) return false;

                  return !(datagridValues[key] ?? "").toString();
                });
                if (hasEmptyField) {
                  result.isError = true;
                  const dgLabel = dgConfigs[dgKey]?.title || "";
                  if (dgLabel) {
                    const dgNumber = convertNumberToOrdinal(recordIndex + 1);
                    result.multiErrMsg[1].dgLabels.push(
                      `${dgLabel} (${dgNumber})`
                    );
                  }
                }
              }
            }
          });
          if (!result.isError && !result.errMsg) {
            // ownership validation
            const conditionStr = dgConfigs[dgKey]?.custom;
            if (conditionStr !== undefined) {
              const ownershipValidation = dgCustomValidation(
                dgState[dgKey],
                conditionStr,
                dgKey
              );
              if (!ownershipValidation.isValid) {
                result.isError = true;
                result.errMsg = ownershipValidation.errMsg;
              }
            }
          }
        }
      }
    });
  }
  return result;
};

export const performArithmetic = (sourceList, operator, decimalCount) => {
  const numbers = (sourceList || [])
    .filter((value) => isValidNumber(value) && typeof value !== "boolean")
    .map((value) => Number(value));

  if (operator === "/" && numbers[1] === 0) {
    return 0;
  }

  // Perform the arithmetic operation based on the operator
  let result;
  switch (operator) {
    case "+":
      result = numbers.length ? numbers.reduce((acc, val) => acc + val, 0) : "";
      break;
    case "-":
      result = numbers.length ? numbers.reduce((acc, val) => acc - val) : "";
      break;
    case "*":
      result = numbers.length ? numbers.reduce((acc, val) => acc * val, 1) : "";
      break;
    case "/":
      result = numbers.length ? numbers.reduce((acc, val) => acc / val) : "";
      break;
    default:
      return "";
  }
  return isValidNumber(result) && decimalCount
    ? result.toFixed(decimalCount)
    : result;
};

export const getValueFromRangeList = (
  inputValue,
  rangeList,
  decimalCount = 5
) => {
  const currentValue = inputValue !== "" ? Number(inputValue) : null;

  if (typeof currentValue !== "number" || Number.isNaN(currentValue)) {
    return "";
  }
  const range = rangeList.find(
    (item) =>
      (item.min === undefined || currentValue >= item.min) &&
      (item.max === undefined || currentValue < item.max)
  );

  return isValidNumber(range?.value) ? range.value.toFixed(decimalCount) : "";
};

export const getRemainingDays = (startDate, endDate) => {
  if (!startDate || !endDate) return "";
  return moment(endDate, "MM/DD/YYYY").diff(
    moment(startDate, "MM/DD/YYYY"),
    "days"
  );
};

const convertToDollar = (num, digit) => {
  const formattedAmount = parseFloat(num).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: digit || 0,
  });
  return formattedAmount;
};

export const getDollarFormattedValue = (val, decimalPoints) =>
  isValidNumber(val) ? convertToDollar(Number(val), decimalPoints) : val;

export const getFormattedValue = (type, val, decimalPoints) => {
  switch (type) {
    case "$":
      return getDollarFormattedValue(val, decimalPoints);
    case "%":
    case "g":
    case "mg":
      return isValidNumber(val) ? `${val}${type}` : val;
    default:
      return val;
  }
};

function getPrivilegeTaxAmount(value, data, getValues) {
  const { min, max } = data;
  if (value < min) return min;
  if (getValues("familyLleElection") && value > max.familyLleElection) {
    return max.familyLleElection;
  }
  if (
    getValues("genInfoBusinessType") in max &&
    value > max[getValues("genInfoBusinessType")]
  ) {
    return max[getValues("genInfoBusinessType")];
  }
  return value;
}

export const getComputedValue = (props) => {
  const { key, currentValues, customProps, setValue, getValues } = props;
  const {
    operator,
    customCalculation,
    decimalPoints,
    percentageToNumber,
    percentageValue,
  } = customProps || {};
  const { type, data } = customCalculation || {};

  let value = "";
  if (currentValues && operator === "rangeIdentifier" && data?.rangeList) {
    value = getValueFromRangeList(currentValues[0], data.rangeList);
  } else if (
    currentValues &&
    type === "remainingDaysRatio" &&
    data?.totalDays
  ) {
    const [start, end] = currentValues;
    const remainingDaysOfTaxYear = getRemainingDays(start, end);
    if (remainingDaysOfTaxYear === 0) {
      value = remainingDaysOfTaxYear;
    } else if (remainingDaysOfTaxYear) {
      value = performArithmetic(
        [remainingDaysOfTaxYear, data.totalDays],
        operator,
        decimalPoints
      );
      if (percentageValue && value)
        value = (value * 100).toFixed(decimalPoints - 2);
    }
  } else if (["+", "-", "*", "/"].includes(operator)) {
    value = performArithmetic(currentValues, operator, decimalPoints);
    if (percentageToNumber && value) value /= 100;
  }

  if (!decimalPoints && value) value = Math.round(value);
  // validation
  if (type === "calculatePrivilegeTax") {
    value = getPrivilegeTaxAmount(value, data, getValues);
  }
  setValue(key, isValidNumber(value) ? value : "");
  return value;
};

export const getFormattedComputedValue = (props) => {
  const { value, customProps } = props;
  let formattedValue = "";
  const { prefix, suffix, decimalPoints } = customProps || {};
  if (isValidNumber(value) && (prefix || suffix)) {
    formattedValue = getFormattedValue(prefix || suffix, value, decimalPoints);
  } else formattedValue = value;

  return formattedValue;
};

function removePrependInvoiceFromKey(data) {
  const newData = {};
  Object.keys(data || {}).forEach((key) => {
    if (key.startsWith("invoice-")) {
      const newKey = key.substring(8);
      newData[newKey] = data[key];
    } else {
      newData[key] = data[key];
    }
  });
  return newData;
}

export const getInvoiceUrl = (formProps) => {
  const invoiceUrlFormat = "/invoices/:invoiceId";
  const newFormProps = removePrependInvoiceFromKey(formProps);
  const invoiceUrl = constructSearchUrl(invoiceUrlFormat, newFormProps);
  return invoiceUrl;
};

const convertStrToRegex = (input) => {
  let regexStr = input;
  try {
    if (regexStr.startsWith("/") && regexStr.endsWith("/"))
      regexStr = input.slice(1, -1);
    return new RegExp(regexStr);
  } catch (error) {
    return false;
  }
};

export const getMaskProps = (fieldProps) => {
  const { inputMask, validate } = fieldProps;
  const maskProps = { mask: "" };
  const { maskConfig } = validate?.custom
    ? getParsedValue(validate.custom, "getMaskProps")
    : {};

  if (maskConfig) {
    const { inputMask: customMask, isRegex, ...rest } = maskConfig;
    const result = isRegex ? convertStrToRegex(customMask) : customMask;
    if (result) {
      maskProps.mask = result;
      maskProps.minLength = rest?.minLength;
      maskProps.customMessage = rest?.customMessage;
      maskProps.showPlaceholder = rest?.showPlaceholder;
    }
  } else if (inputMask) {
    const maskInfo = maskMap[inputMask];
    maskProps.mask = maskInfo?.mask || "**********";
    maskProps.minLength = maskInfo?.minLength;
    maskProps.customMessage = maskInfo?.customMessage;
    maskProps.showPlaceholder = maskInfo?.showPlaceholder;
  }
  return maskProps;
};

const extractOperator = (str) => {
  const operatorRegex = /^(<=|>=|<|>|!|=)/;
  const match = str.match(operatorRegex);
  if (match) {
    return {
      operator: match[0],
      value: str.slice(match[0].length),
    };
  }
  // If no operator found, return default operator and original string
  return { operator: "=", value: str };
};

const getNumberFieldErrorMsg = (key, value) => {
  const msgMap = {
    "!": `value must not be ${value}`,
    "=": `value must be ${value}`,
    ">": `minimum value must be ${value}`,
    "<": `maximum value must be ${value}`,
    ">=": `minimum value must be ${value}`,
    "<=": `maximum value must be ${value}`,
  };
  return msgMap[key] || "Invalid value";
};

const getRangeLimitResult = (targetFieldVal, currentVal, operator) => {
  const currentValue = Number(currentVal);
  const targetValue = Number(targetFieldVal);
  let errorKey = null;
  switch (operator) {
    case "!":
      if (currentValue === targetValue) errorKey = operator;
      break;
    case "=":
      if (currentValue !== targetValue) errorKey = operator;
      break;
    case ">":
      if (currentValue < targetValue) errorKey = operator;
      break;
    case "<":
      if (currentValue > targetValue) errorKey = operator;
      break;
    case ">=":
      if (currentValue < targetValue && currentValue !== targetValue)
        errorKey = operator;
      break;
    case "<=":
      if (currentValue > targetValue && currentValue !== targetValue)
        errorKey = operator;
      break;
    default:
      break;
  }
  if (errorKey) return getNumberFieldErrorMsg(errorKey, targetValue);
  return false;
};

const rangeLimitValidation = (config, currentValue, getValues) => {
  const { when, targetFieldKey, condition = [] } = config || {};
  if (!when && !targetFieldKey) return false;
  // fixed validation, which will trigger always ==> {targetFieldKey: ">targetFieldKey"}
  if (targetFieldKey) {
    const { operator, value: fieldKey } = extractOperator(targetFieldKey);
    const targetFieldVal = getValues(fieldKey);
    if (!isValidNumber(targetFieldVal) || !isValidNumber(currentValue))
      return false;
    return getRangeLimitResult(targetFieldVal, currentValue, operator);
  }
  let isInvalid = false;
  condition?.forEach((cond) => {
    if (cond.eq?.includes(getValues(when)) && cond.value) {
      const [startValue, endValue] = cond.value.split("-"); // denotes the range (e.g., 0-25)
      if (startValue && endValue && isValidNumber(currentValue)) {
        const startVal = Number(startValue);
        const endVal = Number(endValue);
        if (startVal <= currentValue && currentValue <= endVal) {
          isInvalid = false;
        } else {
          isInvalid = `value must be between ${startVal} and ${endVal}`;
        }
      }
    }
  });
  return isInvalid;
};
export const generateFormFieldRules = (
  fieldProps,
  getValues,
  dateError,
  optionList
) => {
  const { key, type, pattern, validate } = fieldProps;
  const rules = {
    minLength: validate?.minLength,
    maxLength: validate?.maxLength,
    required: validate?.required,
    pattern,
    validate: undefined,
    min: validate?.min,
    max: validate?.max,
  };

  let customValidationMap = {
    type: "",
    errorMsg: "",
    condition: "",
  };

  const handleCustomValidation = (value) => {
    const isValid = customValidation(fieldProps, value, getValues);
    return isValid;
  };

  switch (type) {
    case "textfield": {
      rules.pattern = validate?.pattern
        ? new RegExp(`${validate.pattern}$`)
        : undefined;
      rules.validate = {
        mask: (value) => {
          const { minLength } = getMaskProps(fieldProps);
          return minLength && value ? value.length >= minLength : undefined;
        },
      };
      break;
    }
    case "email": {
      rules.pattern = {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email address",
      };
      break;
    }
    case "datetime": {
      const condition =
        validate?.custom && getParsedValue(validate.custom, key);
      rules.validate = {
        defaultValidation: (date) =>
          date !== null
            ? /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$|^$/.test(date) && !dateError
            : undefined,
        dob: (date) =>
          condition?.dob
            ? customDateValidation(condition, "dob", date) ||
              validate?.customMessage ||
              "Age of applicant is not within acceptable range."
            : undefined,
        expectedDate: (date) =>
          condition?.expectedDateKey
            ? customDateValidation(
                condition,
                "expectedDate",
                date,
                getValues
              ) ||
              validate?.customMessage ||
              "Cannot be same as licensed date"
            : undefined,
      };
      break;
    }
    case "encrypted": {
      rules.pattern = /^$|^\s*(\d\s*){9}$/;
      break;
    }
    case "phoneNumber": {
      rules.pattern = /^$|^\s*(\d\s*){10}$/;
      break;
    }
    case "radio": {
      if (validate?.custom) {
        const condition = getParsedValue(
          fieldProps.validate.custom,
          fieldProps.key
        );
        if (condition && condition.checkDoB) {
          customValidationMap = {
            type: "checkDoB",
            errorMsg:
              "The date of birth does not align with this selection. If you believe this is an error, please contact customer support.",
            condition,
          };
        } else if (condition && condition.required) {
          customValidationMap = {
            type: "requiredOutcome",
            errorMsg:
              fieldProps.validate.customMessage ||
              "Application cannot be submitted with this selection.",
            condition,
          };
        }
      }
      rules.validate = customValidationMap.type
        ? {
            checkDoB: (value) =>
              customValidationMap.type === "checkDoB"
                ? handleCustomValidation(value) || customValidationMap.errorMsg
                : undefined,
            validRequiredOption: (value) =>
              customValidationMap.type === "requiredOutcome"
                ? handleCustomValidation(value) || customValidationMap.errorMsg
                : undefined,
          }
        : undefined;
      break;
    }
    case "select": {
      if (fieldProps.validate?.custom) {
        const condition = getParsedValue(
          fieldProps.validate.custom,
          fieldProps.key
        );
        if (condition && condition.required) {
          customValidationMap = {
            type: "requiredOutcome",
            errorMsg:
              fieldProps.validate.customMessage ||
              "Application cannot be submitted with this selection.",
            condition,
          };
        }
      }
      rules.validate = {
        validateLabel: (value) => {
          if (!value || !optionList.length) return true;
          const selectedItem = optionList.find((item) => item.value === value);
          return selectedItem?.label ? true : "Invalid selection";
        },
        validRequiredOption: (value) =>
          customValidationMap.type === "requiredOutcome"
            ? handleCustomValidation(value) || customValidationMap.errorMsg
            : undefined,
      };
      break;
    }
    case "number": {
      const { customCalculation, rangeLimit } = validate?.custom
        ? getParsedValue(validate.custom)
        : {};
      const { type: validationType } = customCalculation || {};
      rules.validate = {
        maxCount: (value) =>
          validationType === "maxCountValidation" && handleMaxCountValidation
            ? handleMaxCountValidation({ value, getValues })
            : undefined,
        rangeLimit: (value) => {
          if (!rangeLimit) return true;
          const result = rangeLimitValidation(rangeLimit, value, getValues);
          return result ? validate.customMessage || result : true;
        },
      };
      break;
    }
    default:
      break;
  }

  return Object.keys(rules).reduce((acc, curr) => {
    if (rules[curr]) return { ...acc, [curr]: rules[curr] };
    return acc;
  }, {});
};

const getDgList = (components = []) => {
  const keys = [];
  components.forEach((item) => {
    if (item.type === "panel") getDgList(item?.components);
    if (item.type === "datagrid") keys.push(item.key);
  });
  return keys;
};

export const createDatagridMap = (tabItems) =>
  tabItems.reduce(
    (acc, cur, i) => ({ ...acc, [i]: getDgList(cur?.components) }),
    {}
  );

export const getFormattedDataForDg = (data, type, prefix = "") => {
  const keysToSkip = [
    "__v",
    "_id",
    "applicants",
    "documents",
    "properties",
    "submittedDocuments",
    "system",
  ];
  const formattedData = { documents: [], formData: [] };
  if (!data || !data.docs) return formattedData;

  data.docs.forEach((dgData) => {
    const formattedDgData = {
      _id: dgData._id,
    };

    Object.keys(dgData || {}).forEach((key) => {
      const newKey = `${prefix}${key}`;
      if (!keysToSkip.includes(key)) {
        if (type === "subLicenses") {
          if (!formattedDgData.licenseProps) formattedDgData.licenseProps = {};
          formattedDgData.licenseProps[newKey] = dgData[key];
        } else {
          formattedDgData[newKey] = dgData[key];
        }
      }
    });
    Object.keys(dgData.properties || {}).forEach((pKey) => {
      if (!keysToSkip.includes(pKey))
        formattedDgData[`${prefix}${pKey}`] = dgData.properties[pKey];
      if (pKey === "status") formattedDgData.status = dgData.properties.status;
    });
    formattedData.documents.push(...(dgData.documents || []));
    formattedData.formData.push(formattedDgData);
  });
  return formattedData;
};

export const getFormattedFormData = (formData = {}) => {
  const newFormData = cloneDeep(formData);
  Object.entries(newFormData).forEach(([key, data]) => {
    if (data && Array.isArray(data)) {
      newFormData[key] = data.map((license) => {
        // is sub license because it has properties object
        if (
          license &&
          license.properties &&
          !license.isBulkRenewParentLicense
        ) {
          const licenseProps = { ...license };
          delete licenseProps.properties;
          return { ...license?.properties, licenseProps };
        }
        return license;
      });
    }
  });
  return newFormData;
};
export function getUniqueId() {
  const timestamp = Date.now().toString();
  const randomDigits = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(5, "0");
  return timestamp + randomDigits;
}

export function updateDatagridStatus(formProperties = {}, dgProps = {}) {
  Object.keys(dgProps).forEach((dgKey) => {
    const autoSave = dgProps[dgKey]?.autosaveOnClone?.toString() === "true";
    if (formProperties[dgKey] && formProperties[dgKey].length > 0 && autoSave) {
      // eslint-disable-next-line no-param-reassign
      formProperties[dgKey] = formProperties[dgKey].map((item) => ({
        ...item,
        status: "saved",
      }));
    }
  });
}

// for useSelector hook
export const selectFormData = (state, isDialogForm) =>
  isDialogForm ? state.dialogFormProps?.formData : state.formProps?.formData;

export const selectFormConfig = (state, isDialogForm) =>
  isDialogForm
    ? state.dialogFormProps?.formConfig
    : state.formProps?.formConfig;

export const selectFormAppData = (state, isDialogForm) =>
  isDialogForm
    ? state.dialogFormProps?.applicationData
    : state.formProps?.applicationData;

export const hasUnsavedDgRecord = (getDgState, currentIndex, methodRefs) => {
  const { dgConfigs, dgKeysWithTabIndex, ...dgState } = getDgState();
  const currentTabDgKeys = dgKeysWithTabIndex[currentIndex] || [];
  const unsavedDgList = [];
  if (currentTabDgKeys.length) {
    currentTabDgKeys.forEach((dgKey) => {
      const currentDg = dgState[dgKey] || [];
      const hasUnsavedRecord = currentDg.some((record) => {
        const dataGridFormMethods = methodRefs[dgKey + record.id];
        return dataGridFormMethods?.getValues()?.status !== "saved";
      });
      if (hasUnsavedRecord)
        unsavedDgList.push(dgConfigs[dgKey]?.title || dgKey);
    });
  }
  return unsavedDgList;
};

const getDgProperties = (components = []) => {
  const dgProps = {};
  components.forEach((item) => {
    if (item.type === "panel") getDgProperties(item?.components);
    if (item.type === "datagrid") dgProps[item.key] = item.properties || {};
  });
  return dgProps;
};

export const createDgConfigMap = (formConfig = {}) =>
  formConfig.components?.reduce(
    (acc, cur) =>
      cur.components ? { ...acc, ...getDgProperties(cur.components) } : acc,
    {}
  );
