import React from "react";
// eslint-disable-next-line import/no-unresolved
import buildConfig from "@regional/config/build.config.json";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const { copyrightText, footerLegalItems, footerLink, footerLinkTitle } =
  buildConfig;
const year = new Date().getFullYear();
const footerLinks = {
  accessibility: (
    <Typography component={Link} href="/accessibility" target="_blank">
      Accessibility
    </Typography>
  ),
  privacyPolicy: (
    <Typography component={Link} href="/privacy-policy" target="_blank">
      Privacy Policy
    </Typography>
  ),
  termsConditions: (
    <Typography component={Link} href="/terms-conditions" target="_blank">
      Terms and Conditions
    </Typography>
  ),
  faq: (
    <Typography component={Link} href="/faq" target="_blank">
      FAQ
    </Typography>
  ),
};

export function FooterLinksLeft() {
  return (
    <>
      <Typography style={{ marginRight: "10px" }}>
        © {year} {copyrightText}
      </Typography>
      {footerLegalItems &&
        footerLegalItems.length > 0 &&
        footerLegalItems.map((item) =>
          footerLinks[item] ? (
            <React.Fragment key={item}>
              <Hidden smDown>|</Hidden>
              {footerLinks[item]}
            </React.Fragment>
          ) : null
        )}
    </>
  );
}

export function FooterLinksRight() {
  return (
    <>
      <Typography
        component={Link}
        target="_blank"
        href="https://www.egov.com/what-we-do/licensing/"
        rel="noopener noreferrer"
      >
        <img
          src="/dist/images/brand/nic/nic-licensing-white-small.png"
          alt="NLS logo"
        />
      </Typography>
      <Hidden smDown>|</Hidden>
      <Typography
        component={Link}
        href={footerLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: "10px" }}
      >
        {footerLinkTitle}
      </Typography>
      <Hidden smDown>|</Hidden>
      <Typography style={{ marginLeft: "10px" }}>
        v.{process.env.REACT_APP_VERSION}
      </Typography>
    </>
  );
}
