import { filter } from "lodash";
import { getConditionResult } from "./form.service";

export const defaultDocUploadCount = 5;

export function documentIcon(mimetype) {
  if (mimetype === "application/pdf") {
    return "pdf";
  }
  if (mimetype === "application/zip") {
    return "zip";
  }

  if (mimetype === "text/csv" || mimetype.match("sheet")) {
    return "excel";
  }
  return "file";
}
export function getStyle(props) {
  const { type, showDocTable, documents } = props;
  if (type === "pb") {
    if (showDocTable && !documents.length) return "4rem !important";
  }
  return "";
}

export function updateFormDocumentList(props) {
  const {
    documents,
    documentTypeId,
    isReviewContext,
    updateFormDocuments,
    dispatch,
    dgRecordId,
  } = props;

  if (!isReviewContext) {
    dispatch(
      updateFormDocuments({
        key: documentTypeId,
        value: documents,
        dgRecordId,
      })
    );
  }
}

function computeDocumentCondition(data, condition) {
  if (!condition || !condition.when) {
    return true;
  }
  if (!data) return condition.show === "false";
  if (condition.when.includes(".")) {
    const eqs = (condition.eq || "").split(",");
    const [dgName, dgProp] = condition.when.split(".");
    const dgPropValues = (data[dgName] || []).map((dgGrid) =>
      dgGrid[dgProp]?.toString()
    );
    const anyDgMatchCondition = dgPropValues.find((value) =>
      eqs.includes(value)
    );
    if (!anyDgMatchCondition) return condition.show === "false";
  } else if (data[condition.when] === undefined) {
    return condition.show === "false";
  }

  return getConditionResult(condition, data)
    ? condition.show.toString() === "true"
    : condition.show.toString() === "false";
}

function mergeConditions(conditional, outcomes, type) {
  const outcomesCopy = { ...outcomes };
  if (type === "or") {
    if (conditional.or) {
      outcomesCopy.orCondition =
        outcomesCopy.primaryCondition || outcomesCopy.orCondition;
    }
    if (conditional.or && conditional.and) {
      return outcomesCopy.or || outcomesCopy.and;
    }
    return conditional.or
      ? outcomesCopy.orCondition
      : outcomesCopy.primaryCondition || outcomesCopy.andCondition;
  }
  if (type === "and") {
    if (conditional.and) {
      outcomesCopy.andCondition =
        outcomesCopy.primaryCondition && outcomesCopy.andCondition;
    }
    if (conditional.or && conditional.and) {
      return outcomesCopy.or && outcomesCopy.and;
    }
    return conditional.and
      ? outcomesCopy.andCondition
      : outcomesCopy.primaryCondition && outcomesCopy.orCondition;
  }
  return false;
}

export function getDocumentVisibility(data, document) {
  let isVisible = true;
  const resolutions = {
    orCondition: false,
    andCondition: true,
    primaryCondition: true,
  };
  if (!document.customConditional) {
    isVisible = computeDocumentCondition(data, document.conditional);
  } else {
    resolutions.primaryCondition = computeDocumentCondition(
      data,
      document.conditional
    );
    const customConditional = { ...document.customConditional };

    if (customConditional.or && customConditional.or.length > 0) {
      customConditional.or.forEach((condition) => {
        if (computeDocumentCondition(data, condition)) {
          resolutions.orCondition = true;
        }
      });
    }
    if (customConditional.and && customConditional.and.length > 0) {
      customConditional.and.forEach((condition) => {
        if (!computeDocumentCondition(data, condition)) {
          resolutions.andCondition = false;
        }
      });
    }
    switch (customConditional.primaryConditionType) {
      case "or":
        isVisible = mergeConditions(customConditional, resolutions, "or");
        break;
      case "and":
        isVisible = mergeConditions(customConditional, resolutions, "and");
        break;
      default:
        break;
    }
  }
  return isVisible;
}

export function getVersionSeriesId(documents, documentTypeId) {
  if (documents.length > 0) {
    const document = filter(documents, { documentTypeId });
    return document[0].versionSeriesId;
  }
  return "";
}

export const getDocumentPayload = (payloadData, customPropsData) => {
  const formData = new FormData();
  if (payloadData) {
    Object.keys(payloadData).forEach((key) => {
      formData.append(key, payloadData[key]);
    });
    if (customPropsData) {
      Object.keys(customPropsData).forEach((key) =>
        formData.append(`customProperties[${key}]`, customPropsData[key])
      );
    }
  }
  return formData;
};

export const getFileLink = (props) => {
  const { domainName, versionSeriesId, id } = props;
  if (domainName === "LicenseForm")
    return `/api/licenses/documents/${versionSeriesId}/download`;
  return `/api/versions/${id}/download`;
};

export const getFileFromBlob = async (blobUrl, fileName) => {
  // Create an image element and load the blob data
  const img = new Image();
  img.src = blobUrl;

  // Wait for the image to load
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  // Create a new canvas with the desired dimensions
  const canvas = document.createElement("canvas");
  canvas.width = 600;
  canvas.height = 600;

  // Calculate the scaling factor to fill the entire canvas
  const scaleFactor = Math.max(
    canvas.width / img.width,
    canvas.height / img.height
  );

  // Calculate the new image dimensions
  const width = img.width * scaleFactor;
  const height = img.height * scaleFactor;

  // Draw the image onto the canvas
  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    img,
    (canvas.width - width) / 2,
    (canvas.height - height) / 2,
    width,
    height
  );

  // Export the canvas as a Blob object
  const newBlob = await new Promise((resolve) => {
    canvas.toBlob(resolve, "image/jpeg", 1);
  });

  // return a new File object with the Blob data
  return new File([newBlob], fileName, { type: newBlob.type });
};

export const getFilteredDocuments = (
  documentTypeId,
  requiredDocument = [],
  docsData = []
) => {
  const filteredDocuments = [];
  requiredDocument.forEach((requiredDoc) => {
    if (!requiredDoc.isDeleted) {
      const document = filter(docsData.documents, {
        versionSeriesId: requiredDoc?.versionSeriesId,
      })[0];

      if (document && documentTypeId === document.documentTypeId) {
        const { customProperties } = document;
        if (!customProperties?.dgObjectRefId) filteredDocuments.push(document);
      }
    }
  });
  const newUploads = docsData.documents
    ?.filter(
      (docs) =>
        !docs.system?.isDeleted &&
        !docs.customProperties?.dgObjectRefId &&
        documentTypeId === docs.documentTypeId &&
        !requiredDocument.find(
          (appDocs) => appDocs.versionSeriesId === docs.versionSeriesId
        )
    )
    .reverse();
  if (newUploads) filteredDocuments.push(...newUploads);
  return filteredDocuments;
};

export const dispatchHandler = (props) => {
  const {
    key,
    value,
    type,
    dispatch,
    updateFormDocuments,
    requiredDocuments,
    dgRecordId = null,
  } = props;

  const payload = {
    key,
    value,
    dgRecordId,
    ...(type && { type, requiredDocuments }),
  };
  dispatch(updateFormDocuments(payload));
};

export const getFilteredDgDocuments = (
  documentTypeId,
  dgRecordId,
  requiredDocument = [],
  docsData = {}
) => {
  const filteredDocuments = [];
  requiredDocument.forEach((requiredDoc) => {
    if (!requiredDoc.isDeleted) {
      const document = filter(docsData.documents, {
        versionSeriesId: requiredDoc.versionSeriesId,
      })[0];
      if (document && documentTypeId === document.documentTypeId) {
        const { customProperties } = document;
        if (dgRecordId === customProperties?.dgObjectRefId)
          filteredDocuments.push(document);
      }
    }
  });
  const newUploads = docsData.documents
    ?.filter(
      (docs) =>
        !docs.system?.isDeleted &&
        docs.customProperties?.dgObjectRefId === dgRecordId &&
        documentTypeId === docs.documentTypeId &&
        !requiredDocument.find(
          (appDocs) => appDocs.versionSeriesId === docs.versionSeriesId
        )
    )
    .reverse();
  if (newUploads) filteredDocuments.push(...newUploads);
  return filteredDocuments;
};

export const getInitialDocs = (docTypeId, documents, dgRecordId) => {
  if (dgRecordId)
    return (documents || []).filter(
      ({ documentTypeId, customProperties }) =>
        documentTypeId === docTypeId &&
        customProperties?.dgObjectRefId === dgRecordId
    );
  return (documents || []).filter(
    ({ documentTypeId, customProperties }) =>
      documentTypeId === docTypeId &&
      (!customProperties || !customProperties.dgObjectRefId)
  );
};

export const getFilteredReqDocs = (reqDocList, docsData, isDgDoc) => {
  const filteredDocuments = [];
  reqDocList?.forEach((requiredDoc) => {
    if (!requiredDoc.isDeleted) {
      const document = filter(docsData.documents, {
        versionSeriesId: requiredDoc?.versionSeriesId,
      })[0];

      if (document) {
        const { customProperties } = document;
        if (customProperties?.dgObjectRefId === undefined && !isDgDoc)
          filteredDocuments.push(document);
        else if (customProperties?.dgObjectRefId && isDgDoc)
          filteredDocuments.push(document);
      }
    }
  });
  return filteredDocuments;
};
