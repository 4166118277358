import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isLoading",
})(({ isLoading }) => ({
  position: isLoading ? "relative" : "unset",
  minHeight: "100%",
  display: "grid",
  alignItems: "center",
  "& .react-pdf__Page__textContent": {
    display: "none",
  },
  "& .react-pdf__Page__annotations.annotationLayer": {
    display: "none",
  },
  "& .react-pdf__Page__canvas": {
    margin: "0 auto",
  },
  "& .footer": {
    position: "absolute",
    bottom: "0px",
    left: "0",
    right: "0",
    backgroundColor: "#343c3e",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: "#fff",
      fontSize: "25px",
    },
    "& .MuiTypography-root": {
      textAlign: "center",
      borderRadius: "5px",
      backgroundColor: "gray",
      fontWeight: 700,
      color: "#fff",
      width: "110px",
    },
  },
}));
export default Styled;
