import React from "react";
import { Alert, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function SwitchAccountBanner() {
  return (
    <Alert role="definition" tabIndex={0} severity="info" elevation={2}>
      <Typography align="center">
        <InfoOutlinedIcon className="icon" />
        Please switch accounts if you want to create an application for a
        different person or business.
      </Typography>
    </Alert>
  );
}
