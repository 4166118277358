import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";

import { useGetDgKey } from "../../context/datagridContext";
import { useReviewPageCheck } from "../../context/reviewItemContext";
import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";
import { getVersionSeriesId } from "../../services/formDocument.service";
import { StyledBox } from "../styles";
import ConfirmPhoto from "./ConfirmPhoto";

function ReadOnlyDocumentField(props) {
  const { fieldProps, documents } = props;
  const {
    documentTypeId,
    documentLabel,
    multipleDocUpload,
    optional,
    preview,
    isLastItem,
  } = fieldProps;
  const [showDocList, setShowDocList] = useState(false);
  const isDatagrid = useGetDgKey();
  const isReviewContext = useReviewPageCheck();
  const { updateHeight } = useUpdatePanelHeight();

  const { domainName, isSinglePageLayout, singlePageLayoutIcon } = useSelector(
    (state) => state.formProps.formConfig
  );

  const isDocAvailable = documents.length > 0;
  const showIcon = isReviewContext || singlePageLayoutIcon;
  const showChip = isSinglePageLayout || (isDatagrid && !isReviewContext);
  const getVersionId = () => getVersionSeriesId(documents, documentTypeId);

  const onBtnClick = () => {
    setShowDocList(!showDocList);
    setTimeout(() => updateHeight(), 400);
  };

  const getLink = (versionSeriesId) => {
    if (domainName === "LicenseForm")
      return `/api/licenses/documents/${versionSeriesId}/download`;
    return `/api/documents/${versionSeriesId}/download`;
  };

  return (
    <StyledBox
      marginLeft={showIcon && !isDocAvailable && optional}
      isLastItem={isLastItem}
    >
      <Box className="labelContainer" tabIndex={0}>
        {showIcon && isDocAvailable && <CheckIcon color="success" />}
        {showIcon && !isDocAvailable && !optional && (
          <ClearIcon color="error" />
        )}
        <Box className="textWrapper">
          <Typography>{documentLabel}:</Typography>
          {!isSinglePageLayout && isReviewContext && (
            <Typography>
              {isDocAvailable ? "Document(s) Uploaded" : "No Document present"}
            </Typography>
          )}
          {showChip && (
            <Box className="statusItem">
              {isDocAvailable &&
                (multipleDocUpload ? (
                  <Tooltip
                    title={showDocList ? "Hide Documents" : "Show Documents"}
                  >
                    <Button
                      variant="text"
                      endIcon={
                        <ExpandDownIcon
                          sx={{
                            transform: showDocList ? "rotate(180deg)" : "unset",
                          }}
                        />
                      }
                      className="docToggleBtn"
                      onClick={onBtnClick}
                    >
                      {documents.length} Document(s)
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={documents[0].fileName}>
                    <Typography
                      component={Link}
                      target="_blank"
                      href={`/api/documents/${getVersionId()}/download`}
                      className="customLink"
                    >
                      {documents[0].fileName}
                    </Typography>
                  </Tooltip>
                ))}
              {!isDocAvailable && <Typography>No Document present</Typography>}
            </Box>
          )}
        </Box>
      </Box>
      {isReviewContext && isDocAvailable && preview && (
        <ConfirmPhoto
          fieldProps={fieldProps}
          src={`/api/documents/${getVersionId()}/download`}
          id={documents[0]._id}
        />
      )}
      <Box className="collapseWrapper">
        <Collapse in={showDocList} unmountOnExit>
          <Stack direction="row" className="muiStack" tabIndex={0}>
            {documents.length > 0 &&
              documents.map((item) => (
                <Chip
                  key={item._id}
                  label={item.fileName}
                  component={Link}
                  target="_blank"
                  href={getLink(item.versionSeriesId)}
                />
              ))}
          </Stack>
        </Collapse>
      </Box>
    </StyledBox>
  );
}
ReadOnlyDocumentField.propTypes = {
  fieldProps: PropTypes.shape({
    documentLabel: PropTypes.string.isRequired,
    documentTypeId: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    multipleDocUpload: PropTypes.bool,
    optional: PropTypes.bool,
    preview: PropTypes.bool,
    isLastItem: PropTypes.bool,
  }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default ReadOnlyDocumentField;
