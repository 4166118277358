import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import BreadCrumbs from "../../../../components/Breadcrumbs";

function Layout() {
  const user = useSelector((state) => state.auth.userData);
  const customStyle = user ? { m: "0 10px", p: "10px" } : {};
  return (
    <Box
      role="main"
      tabIndex="0"
      id="bodyContainer"
      sx={{ ...customStyle, position: "relative" }}
    >
      {user && <BreadCrumbs />}
      <Outlet />
    </Box>
  );
}

export default Layout;
