import customConfigData from "./rangeLimitValidation.json";
import datagridCondition from "./datagrid-condition.json";
// import dgDocCondition from "./al-abc-dgDocCondition.json";
import { updateFormConditions } from "../../../../components/Form/services/form.service";

const urlParams = new URLSearchParams(window.location.search);
const workflow = urlParams.get("workflow");
const configData = {
  // applicationFormConfig: updateFormConditions(dgDocCondition),
  applicationFormConfig: updateFormConditions(customConfigData),
};

if (workflow === "DG-CONDITION") {
  configData.applicationFormConfig = datagridCondition;
}

export default configData;
