/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormState, Controller, useWatch } from "react-hook-form";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import TypeAheadSearch from "./TypeAheadSearch";
import {
  constructSearchUrl,
  generateFormFieldRules,
  getUpdatedOptionList,
  getParsedValue,
} from "../../services/form.service";
import {
  addCustomPropsToRecord,
  getDgOptionList,
  isAssociateField,
} from "../../services/form.relationalDg.service";
import { notifyError } from "../../../../utils/helpers";
import { hideFormLoader, showFormLoader } from "../../context/loaderSlice";
import {
  useDropdownListMutation,
  useLazyGetDropdownListQuery,
} from "../../../../api/forms.api.slice";
import { showNotification } from "../../../../features/core/slices/notification.slice";

function FormSelect(props) {
  const {
    fieldProps,
    formProps,
    control,
    disabled,
    resetHandler,
    getValues,
    setValue,
    register,
    trigger: triggerValidation,
    onOptionListChange,
  } = props;
  const { key, label, defaultValue, validate, dataSrc, data, properties } =
    fieldProps;
  const condition = validate?.custom
    ? getParsedValue(validate.custom, key)
    : undefined;

  const dispatch = useDispatch();
  const { errors } = useFormState({ control, name: key });
  const [
    lazyTrigger,
    { data: lazyOptionsData, isFetching: lazyIsFetching, error: lazyError },
  ] = useLazyGetDropdownListQuery(); // for parallel api call
  const [
    mutationTrigger,
    {
      data: mutationOptionsData,
      isLoading: mutationIsFetching,
      error: mutationError,
    },
  ] = useDropdownListMutation();
  const [optionList, setOptionList] = useState([]);

  const { controllingField } = properties || {};
  // Temp Fix
  const skipCache = data?.url && data.url.includes("licenses");
  const watchFieldTouchedRef = useRef(false);
  const optionsListRef = useRef([]);
  const watchFieldValue = useWatch({
    name: controllingField,
    control,
    defaultValue: formProps[controllingField],
    disabled: controllingField === undefined,
  });
  const sourceDg = useWatch({
    name: condition?.sourceDatagrid || null,
    disabled: condition?.sourceDatagrid === undefined,
  });

  const isFetching = lazyIsFetching || mutationIsFetching;
  const initialValue = (
    formProps[key] ||
    defaultValue ||
    condition?.defaultValue ||
    ""
  ).toString();

  useEffect(() => {
    if (!formProps[key] && initialValue) {
      setValue(key, initialValue);
    }
    if ((!dataSrc || dataSrc === "values") && data.values[0].value !== "") {
      setOptionList(data.values);
      optionsListRef.current = data.values;
    } else if (dataSrc === "json") {
      setOptionList(data.json);
      optionsListRef.current = data.json;
    } else if (dataSrc === "url" && data && data.url && !controllingField) {
      if (skipCache) {
        mutationTrigger(data.url);
      } else {
        lazyTrigger(data.url, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSrc && dataSrc === "url" && data && data.url) {
      const optionsData = lazyOptionsData || mutationOptionsData;
      if (optionsData && !isFetching) {
        let newOptionList = optionsData;
        const allowEmptyLabel = false;
        if (controllingField)
          newOptionList = getUpdatedOptionList(
            optionsData,
            fieldProps,
            allowEmptyLabel
          );

        setOptionList(newOptionList);
        optionsListRef.current = newOptionList;
      }
      const error = lazyError || mutationError;
      if (error) {
        notifyError(dispatch, showNotification, error);
      }
      if (isFetching) dispatch(showFormLoader());
      else dispatch(hideFormLoader());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetching,
    lazyError,
    mutationError,
    lazyOptionsData,
    mutationOptionsData,
  ]);

  useEffect(() => {
    if (
      watchFieldValue &&
      typeof watchFieldValue === "string" &&
      controllingField &&
      data.url
    ) {
      if (watchFieldTouchedRef.current) {
        setValue(key, "");
        onOptionListChange(null);
      }
      if (!watchFieldTouchedRef.current) {
        watchFieldTouchedRef.current = true;
        register(`${key}Label`);
        setValue(`${key}Label`, formProps[`${key}Label`]);
      }
      const url = constructSearchUrl(data.url, getValues());
      if (url)
        if (skipCache) {
          mutationTrigger(url);
        } else {
          lazyTrigger(url);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFieldValue]);

  useEffect(() => {
    if (isAssociateField(condition) && Array.isArray(sourceDg)) {
      setOptionList(getDgOptionList(sourceDg, condition));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDg]);

  const onChangeHandler = (e, menuItem, onChange) => {
    if (controllingField) {
      const newValues = optionList.find(
        (item) => item.value === e.target.value
      );
      onOptionListChange(newValues);
    }
    onChange(e.target.value);
    if (isAssociateField(condition) && menuItem && Array.isArray(sourceDg)) {
      addCustomPropsToRecord(setValue, menuItem);
    }
    resetHandler();
    setTimeout(() => {
      triggerValidation(key);
    }, 100);
  };

  const onCloseHandler = () => {
    if (properties?.enableSearch) {
      setOptionList(optionsListRef.current);
    }
  };

  const rules = generateFormFieldRules(fieldProps, getValues, null, optionList);

  return (
    <FormControl
      fullWidth
      variant="standard"
      margin="dense"
      required={validate?.required}
      sx={{ height: "75px" }}
    >
      <Controller
        name={key}
        control={control}
        defaultValue={initialValue}
        render={({ field: { onChange, ref, ...field } }) => (
          <>
            <InputLabel id={`${key}Label`}>{label}</InputLabel>
            <Select
              name={key}
              labelId={`${key}Label`}
              ref={ref}
              id={key}
              onChange={(e, c) => onChangeHandler(e, c, onChange)}
              label={label}
              onClose={onCloseHandler}
              disabled={disabled || (controllingField && isFetching)}
              MenuProps={{ sx: () => ({ maxHeight: "250px" }) }}
              {...field}
            >
              {properties?.enableSearch && (
                <TypeAheadSearch
                  optionsListRef={optionsListRef}
                  setOptionList={setOptionList}
                />
              )}
              {optionList.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.value}
                  item={item}
                  tabIndex={0}
                >
                  {item.label}
                </MenuItem>
              ))}
              {isFetching && (
                <MenuItem value={initialValue} disabled>
                  Loading...
                </MenuItem>
              )}
            </Select>
          </>
        )}
        rules={rules}
      />
      {errors[key] && (
        <FormHelperText error id="{fieldProps.key}-text">
          {errors[key].message || "This is required."}
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormSelect.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    defaultValue: PropTypes.string,
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
      customMessage: PropTypes.string,
    }),
    dataSrc: PropTypes.string,
    data: PropTypes.shape({
      values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      json: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      url: PropTypes.string,
    }).isRequired,
    properties: PropTypes.shape({
      controllingField: PropTypes.string,
    }),
  }).isRequired,
  formProps: PropTypes.shape({
    updateHeight: PropTypes.func,
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  resetHandler: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  onOptionListChange: PropTypes.func.isRequired,
};

export default FormSelect;
