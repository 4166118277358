/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import SignInIcon from "@mui/icons-material/Input";
import { styled } from "@mui/material/styles";

import LocalLoader from "../../components/Loaders/Local";
import loginClasses from "../Login/Login.module.css";
import { useVerifyAccountMutation } from "../../api/auth.api.slice";

const errorMap = {
  alreadyVerified:
    " This email address has already been verified.  If you are trying to reset your password, please use the Forgot Password button on the sign in page.",
  tokenExpired:
    " The verification token has expired, please request for a new verification token using the link below,",
  tokenNotFound: "Invalid verification token.",
  userNotFound: "User not found in the system.",
  alreadyExist:
    "  There is an account already active in the system with the same information.",
};

export const Styled = styled(Box)({
  "& .wrapper": {
    margin: "1rem 0",
    position: "relative",
    "& .MuiCardHeader-content > .MuiTypography-root": {
      fontSize: "1.5rem",
    },
    "& .MuiAlert-root": {
      "& .MuiAlert-icon": { display: "none" },
      justifyContent: "center",
      fontWeight: 500,
      marginBottom: "1.5rem",
    },
    "& .MuiTypography-root": {
      fontSize: "0.88rem",
    },
    "& .MuiCardActions-root": {
      justifyContent: "center",
      marginBottom: "1rem",
      "& .MuiSvgIcon-root": {
        marginRight: "5px",
      },
    },
  },
});

function ChangePasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { verifyToken } = Object.fromEntries([...searchParams]);
  const [verifyAccount, { isLoading, data }] = useVerifyAccountMutation();

  const [errorType, setErrorType] = useState("");

  const handleError = (err) => {
    const { error } = err?.data || {};

    switch (error) {
      case "ERR:TOKEN_ALREADY_VERIFIED": {
        setErrorType("alreadyVerified");
        break;
      }
      case "ERR:TOKEN_EXPIRED": {
        setErrorType("tokenExpired");
        break;
      }
      case "ERR:TOKEN_NOT_FOUND": {
        setErrorType("tokenNotFound");
        break;
      }
      case "ERR:USER_NOT_FOUND": {
        setErrorType("userNotFound");
        break;
      }
      case "ERR:ERR:TENANT_ALREADY_EXIST": {
        setErrorType("alreadyExist");
        break;
      }
      default:
        setErrorType("");
    }
  };

  useEffect(() => {
    if (verifyToken) {
      const onVerifyAccount = async () => {
        try {
          await verifyAccount({ token: verifyToken }).unwrap();
        } catch (error) {
          handleError(error);
        }
      };
      onVerifyAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={loginClasses.loginGrid}
        alignItems="center"
      >
        <Grid item xs={11} sm={8} md={6} className="wrapper">
          <Card raised>
            <CardHeader
              title="Verify Account"
              className={loginClasses.cardHeader}
            >
              Verify Account
            </CardHeader>
            <CardContent>
              {data && (
                <Alert severity="success">Account Verified Successfully!</Alert>
              )}
              {errorType && (
                <>
                  <Alert severity="error">Account Verification Error</Alert>
                  <Typography>{errorMap[errorType]}</Typography>
                  {errorType === "tokenExpired" && (
                    <Typography
                      component={Link}
                      to="/resendVerifyAccountEmail"
                      className="customLink"
                      onClick={() =>
                        navigate("/resendVerifyAccountEmail", { replace: true })
                      }
                    >
                      Resend Verification Link
                    </Typography>
                  )}
                </>
              )}
            </CardContent>
            <CardActions disableSpacing>
              <Button
                variant="contained"
                component={Link}
                to="/login"
                onClick={() => navigate("/login", { replace: true })}
              >
                <SignInIcon />
                Sign In
              </Button>
            </CardActions>
          </Card>
          <LocalLoader progress={isLoading} />
        </Grid>
      </Grid>
    </Styled>
  );
}
export default ChangePasswordPage;
