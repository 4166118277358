import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",

  "& .cropContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },

  "& .previewContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& > .MuiTypography-root": {
      color: theme.palette.mode === "light" ? "grey" : "#fff",
      fontWeight: theme.palette.mode === "light" ? 700 : 500,
    },
    "& > img": {
      display: "block",
      width: "180px !important",
      height: "180px !important",
    },
  },
  "& .cropperWrapper": {
    position: "relative",
    width: "100%",
    height: "200px",
    maxWidth: "200px",
    "& .reactEasyCrop_CropArea": {
      border: "2px solid #fff",
      boxShadow: "0 0 2000px 2000px rgb(0 0 0 / 50%)",
    },
  },
  "& .sliderWrapper": {
    width: "100%",
    maxWidth: "170px",
    margin: "10px 0",
    "& .MuiSlider-root": {
      padding: "5px 0",
    },
  },
  "& .controls": {
    display: "flex",
    alignItems: "center",
    "& .MuiIconButton-root": {
      padding: "0 8px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "& .cropContainer": {
      marginBottom: "2rem",
    },
  },
}));
export default StyledBox;
