/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appHealth: {
    isOkay: true,
  },
};
const appHealthSlice = createSlice({
  name: "appHealth",
  initialState,
  reducers: {
    showAppHealthDialog(state, { payload }) {
      state.appHealth = payload;
    },
    hideAppHealthDialog(state) {
      state.appHealth = initialState.appHealth;
    },
  },
});

export const { showAppHealthDialog, hideAppHealthDialog } =
  appHealthSlice.actions;
export default appHealthSlice.reducer;
