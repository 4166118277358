/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useFormState, Controller, useWatch } from "react-hook-form";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import {
  generateFormFieldRules,
  getParsedValue,
  isValidNumber,
} from "../services/form.service";

function FormNumberField(props) {
  const {
    fieldProps,
    formProps,
    control,
    disabled,
    resetHandler,
    getValues,
    trigger,
    setValue,
  } = props;
  const { errors } = useFormState({ name: fieldProps.key });
  const initialRenderRef = React.useRef(true);
  const {
    key,
    label,
    prefix,
    suffix,
    validate,
    displayMask,
    properties,
    defaultValue,
  } = fieldProps;
  const { valueAsNumber } = properties || {};

  let _defaultValue = isValidNumber(defaultValue) ? defaultValue : "";
  if (isValidNumber(formProps[key])) _defaultValue = formProps[key];
  _defaultValue = valueAsNumber
    ? parseInt(_defaultValue, 10) || ""
    : _defaultValue.toString() || "";

  const { source, customCalculation, rangeLimit } = validate?.custom
    ? getParsedValue(validate.custom)
    : {};

  let watchFieldName;
  if (!watchFieldName) {
    if (source) watchFieldName = source;
    else if (rangeLimit?.when) watchFieldName = rangeLimit.when;
  }

  const watchFieldValues = useWatch({
    control,
    name: watchFieldName,
    disabled: watchFieldName === undefined,
  });

  const errorMap = {
    min: `minimum value must be ${validate?.min}`,
    max: `maximum value must be ${validate?.max}`,
    required: "This is required",
  };

  const allowedPattern = displayMask ? new RegExp(displayMask) : /^\d+$/;

  const handleOnChange = (e, onChange) => {
    const { value } = e.target;
    if (allowedPattern.test(value) || value === "") {
      onChange(value);
      resetHandler();
    } else e.preventDefault();
  };

  const inputProps = {
    startAdornment: prefix ? (
      <InputAdornment position="start">{prefix}</InputAdornment>
    ) : undefined,
    endAdornment: suffix ? (
      <InputAdornment position="start">{suffix}</InputAdornment>
    ) : undefined,
  };

  const getValidInput = (val) => {
    const value = val.endsWith(".") ? val.replace(".", "") : val;
    return valueAsNumber ? +value : value;
  };

  // to set default value to rhf, so that the readOnlyField get access to this default value
  React.useEffect(() => {
    let mounted = true;
    const currentVal = getValues(key);
    if (mounted) {
      if ([undefined, ""].includes(currentVal) && isValidNumber(defaultValue)) {
        const initialVal = valueAsNumber
          ? parseInt(defaultValue, 10) || ""
          : defaultValue.toString() || "";
        setTimeout(() => setValue(key, initialVal), 40);
      }
    }
    return () => {
      setTimeout(() => trigger(key), 10);
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!initialRenderRef.current && source && customCalculation) {
      trigger(key);
    } else initialRenderRef.current = false;
    const currentVal = getValues(key);
    if (rangeLimit?.when && isValidNumber(currentVal)) {
      setTimeout(() => trigger(key), 70);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFieldValues]);

  const rules = generateFormFieldRules(fieldProps, getValues);

  return (
    <FormControl fullWidth margin="dense" sx={{ height: "75px" }}>
      <Controller
        name={key}
        control={control}
        defaultValue={_defaultValue}
        render={({ field: { onChange, onBlur, value, ...field } }) => (
          <TextField
            {...field}
            id={key}
            name={key}
            label={label}
            required={validate?.required}
            disabled={disabled}
            variant="standard"
            autoComplete="off"
            onChange={(e) => {
              handleOnChange(e, onChange);
            }}
            onBlur={() => {
              onChange(getValidInput(value));
              onBlur();
            }}
            value={value}
            InputProps={inputProps}
          />
        )}
        rules={rules}
      />
      {errors[key] && (
        <FormHelperText error id="{fieldProps.key}-number" sx={{ m: 0 }}>
          {(errors[key].type && errorMap[errors[key].type]) ||
            errors[key].message ||
            "This is required"}
        </FormHelperText>
      )}
    </FormControl>
  );
}
FormNumberField.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    displayMask: PropTypes.string,
    properties: PropTypes.shape({
      valueAsNumber: PropTypes.string,
    }),
  }).isRequired,
  formProps: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  resetHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default FormNumberField;
