import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// eslint-disable-next-line import/no-unresolved
import TermsConditions from "@regional/templates/TermsConditions";
// eslint-disable-next-line import/no-unresolved
import authConfig from "@regional/config/auth.config.json";

import DialogPopup from "../../../../components/DialogPopup";

function CustomCheckBox({ register, type }) {
  const [openDialog, setOpenDialog] = useState(false);

  const labelMap = {
    signIn: {
      startText: `Accept `,
      endText: "",
    },
    register: {
      startText: `Please read and accept `,
      endText: "before proceeding.",
    },
  };

  const buttonArray = [
    {
      id: 1,
      name: "Close",
      icon: "",
      variant: "contained",
      onClickHandler: () => setOpenDialog(false),
      show: true,
    },
  ];

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ pointerEvents: "auto" }}
            defaultChecked={
              type === "signIn" && authConfig.termsAndConditionChecked
            }
          />
        }
        label={
          <Typography sx={{ fontSize: "0.9rem" }}>
            {labelMap[type].startText}
            <Button
              sx={{ pointerEvents: "auto" }}
              component={Link}
              variant="text"
              className="customLink"
              onClick={() => setOpenDialog(true)}
              to="#"
            >
              Terms and Conditions{type === "signIn" ? "." : ""}
            </Button>
            {labelMap[type].endText}
          </Typography>
        }
        sx={{ pointerEvents: "none", mx: 0, mb: "1rem" }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("termsConditions", { required: true })}
      />
      <DialogPopup
        title="Terms And Conditions"
        showActionBtn
        content={<TermsConditions />}
        openPopup={openDialog}
        onCancel={() => setOpenDialog(false)}
        buttonArray={buttonArray}
        maxWidth="md"
      />
    </>
  );
}
CustomCheckBox.propTypes = {
  register: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomCheckBox;
