import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-unresolved
import GettingStartedContent from "@regional/templates/GettingStarted";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogPopup from "../../../../components/DialogPopup";
import AppUpdateInfoContent from "../GettingStartedUpdate";
import { useCoreApplicationConfigQuery } from "../../../../api/core.api.slice";

const dialogMainContents = [
  {
    id: "getStartedPref",
    content: <GettingStartedContent />,
    conditions: [],
    title: "Getting started",
  },
  {
    id: "appUpdateInfo",
    content: <AppUpdateInfoContent />,
    conditions: ["getStartedPref"],
    title: "Updates and New Features",
  },
];

function GettingStartedDialog() {
  const timerRef = useRef(null);
  const [mainContentIndex, setMainContentIndex] = useState(-1);
  const { data: coreAppConfig } = useCoreApplicationConfigQuery();

  const appUpdateInfoVersion =
    coreAppConfig && coreAppConfig.appUpdateInfoVersion;
  const appUpdateInfoKey = `appUpdateInfo_v.${appUpdateInfoVersion}`;

  const getLocalStorageKey = (id) => {
    if (id !== "appUpdateInfo") return id;

    if (
      id === "appUpdateInfo" &&
      process.env.REACT_APP_VERSION === appUpdateInfoVersion
    )
      return appUpdateInfoKey;
    return "";
  };

  const handleChange = (e) => {
    const mainContent = dialogMainContents[mainContentIndex];
    const localStorageKey = getLocalStorageKey(mainContent.id);
    if (!localStorageKey) return;
    if (e.target.checked) localStorage.setItem(localStorageKey, true);
    else localStorage.removeItem(localStorageKey);
  };

  const updateDialogType = () => {
    let currentMainContentIndex = mainContentIndex + 1;
    const mainContentsLength = Object.keys(dialogMainContents).length;

    while (currentMainContentIndex < mainContentsLength) {
      const { id, conditions } = dialogMainContents[currentMainContentIndex];
      const localStorageKey = getLocalStorageKey(id);
      const canShowDialog = conditions.reduce(
        (acc, _id) =>
          Boolean(acc && localStorage.getItem(getLocalStorageKey(_id))),
        true
      );
      const hideDialog = localStorage.getItem(localStorageKey);
      if (localStorageKey && canShowDialog && !hideDialog) {
        setMainContentIndex(currentMainContentIndex);
        break;
      }
      currentMainContentIndex += 1;
    }

    if (currentMainContentIndex >= mainContentsLength) setMainContentIndex(-1);
  };

  const handleCloseDialog = () => {
    setMainContentIndex(-1);
    timerRef.current = setTimeout(() => updateDialogType(), 250);
  };

  useEffect(() => {
    if (coreAppConfig) updateDialogType();
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreAppConfig]);

  useEffect(() => {
    if (coreAppConfig)
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("appUpdateInfo_v.") && key !== appUpdateInfoKey) {
          localStorage.removeItem(key);
        }
      });
  }, [coreAppConfig, appUpdateInfoKey]);

  const show = mainContentIndex > -1;
  const dialogTitle = show ? dialogMainContents[mainContentIndex].title : "";
  const dialogContent = (
    <Box
      sx={{
        px: "1em",
        "& .MuiTypography-root": {
          mb: "0.8em",
          fontSize: "0.88rem",
        },
        "& .buttonContainer": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiFormControlLabel-root": {
          display: "flex",
          alignItems: "end",
        },
      }}
    >
      {show && dialogMainContents[mainContentIndex].content}
      <FormControlLabel
        label="Do not show me this again"
        control={<Checkbox onChange={handleChange} />}
      />
      <Box className="buttonContainer">
        <Button variant="contained" onClick={handleCloseDialog}>
          Continue
        </Button>
      </Box>
    </Box>
  );
  return show ? (
    <DialogPopup
      title={dialogTitle}
      onCancel={handleCloseDialog}
      openPopup={show}
      content={dialogContent}
      maxWidth="md"
    />
  ) : null;
}
export default GettingStartedDialog;
