import splitApi from "./emptySplitApi";

const globalApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    maintenance: build.mutation({
      query: () => ({
        url: "/api/maintenance",
        method: "GET",
      }),
    }),
    isOkay: build.mutation({
      query: () => ({
        url: "/api/isOkay",
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useMaintenanceMutation, useIsOkayMutation } = globalApi;
