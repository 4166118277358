import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import React from "react";
import PropTypes from "prop-types";

function LocalLoader(props) {
  const { progress } = props;

  return (
    <Backdrop
      sx={(theme) => ({
        color: theme.palette.primary.main,
        position: "absolute",
        borderRadius: "5px",
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 50%)"
            : "rgb(255 255 255 / 50%)",
      })}
      open={progress}
    >
      <CircularProgress color="inherit" data-testid="localLoader" />
    </Backdrop>
  );
}

LocalLoader.propTypes = {
  progress: PropTypes.bool.isRequired,
};

export default LocalLoader;
