import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import { EditButton } from "./FormRecordActionBtns";
import useUpdatePanelHeight from "../../hooks/useUpdatePanelHeight";

function LicenseCard(props) {
  const {
    fieldProps,
    data,
    onDelete,
    onEdit,
    onSelect,
    onSelectAll,
    readonly,
    hasSubLicenses,
    subLicensesKey,
  } = props;
  const { selectableLicenses = "Approved,Expired" } =
    fieldProps.properties || {};

  const subLicenses = (data.properties || {})[subLicensesKey] || [];

  const handleSelect = (subLicenseNumber) =>
    onSelect(data.licenseNumber, subLicenseNumber);

  let selectedLicenses = 0;
  const subLicenseList = subLicenses.map((license) => {
    if (license.isSelected) selectedLicenses += 1;
    return (
      <ListItem
        disablePadding
        key={license.licenseNumber}
        sx={{ boxShadow: 2, borderRadius: 3, wordWrap: "break-word" }}
      >
        {license.status === "Approved" && (
          <CheckCircleIcon fontSize="small" sx={{ mx: 1, color: "#76c836" }} />
        )}
        {license.status === "Expired" && (
          <ErrorIcon fontSize="small" sx={{ mx: 1, color: "#d15050" }} />
        )}
        {license.status !== "Approved" && license.status !== "Expired" && (
          <BlockIcon fontSize="small" sx={{ mx: 1, color: "#767676" }} />
        )}
        <ListItemText sx={{ mr: "auto" }} primary={license.licenseType} />
        <EditButton
          license={data}
          subLicense={license}
          fieldProps={fieldProps}
          readonly={readonly}
          onEdit={onEdit}
        />
        <Checkbox
          checked={license.isSelected}
          onChange={() => handleSelect(license.licenseNumber)}
          disabled={readonly || !selectableLicenses.includes(license.status)}
        />
      </ListItem>
    );
  });

  const handleSelectAll = () => {
    const allSelected = selectedLicenses < subLicenseList.length;
    onSelectAll(allSelected, data.licenseNumber);
  };
  return (
    <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
      <CardContent>
        <Box className="headingContainer">
          <Box>
            <Typography variant="h6" component="div">
              {data.licenseNumber}{" "}
              <EditButton
                license={data}
                subLicense={null}
                fieldProps={fieldProps}
                readonly={readonly}
                onEdit={onEdit}
              />
            </Typography>
            <Typography
              color="#646161"
              variant="h6"
              component="div"
              fontWeight="normal"
            >
              {data.title}
            </Typography>
          </Box>
          {data.licenseExpiryDate && (
            <Box className="dateContainer">
              <Typography
                color="#767676"
                variant="body2"
                sx={{ fontWeight: 600, lineHeight: 1.1 }}
                component="div"
              >
                EXPIRY DATE
              </Typography>
              <Typography
                color="#767676"
                variant="body2"
                sx={{ fontWeight: 600, lineHeight: 1.1 }}
                component="div"
              >
                <span>
                  {moment(data.licenseExpiryDate).format("MM/DD/YYYY")}
                </span>
              </Typography>
            </Box>
          )}
        </Box>
        {subLicenses.length > 0 && hasSubLicenses && (
          <>
            <Divider />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">License Types&ensp;</Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontWeight: "bolder" }}
              >
                {`(${selectedLicenses} out of ${subLicenses.length} selected)`}
              </Typography>
              <Checkbox
                checked={selectedLicenses === subLicenses.length}
                indeterminate={
                  selectedLicenses > 0 && selectedLicenses < subLicenses.length
                }
                onChange={() => handleSelectAll(data.licenseNumber)}
                disabled={readonly}
                sx={{ ml: "auto" }}
              />
            </Box>
            <List>{subLicenseList}</List>
          </>
        )}
      </CardContent>
      {!readonly && onDelete && (
        <CardActions sx={{ borderColor: "text.disabled" }}>
          <Button
            size="small"
            aria-label={`Remove ${data.licenseNumber}`}
            onClick={() => onDelete(data)}
          >
            Remove
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

function LicenseCards(props) {
  const {
    licenses,
    onDelete,
    onEdit,
    fieldProps,
    setValue,
    trigger,
    readonly,
  } = props;
  const { updateHeight } = useUpdatePanelHeight();
  const subLicensesKey = fieldProps.subLicensesKey || "subLicenses";
  const { selectableLicenses = "Approved,Expired" } =
    fieldProps.properties || {};

  const handleSelectAllSubLicense = (isSelected, parentLicenseNumber) => {
    if (readonly) return;

    const updatedLicenses = licenses.map((license) => {
      if (parentLicenseNumber === license.licenseNumber) {
        const updatedSubLicenses = (
          license.properties[subLicensesKey] || []
        ).map((subLic) => ({
          ...subLic,
          isSelected: isSelected && selectableLicenses.includes(subLic.status),
        }));
        return {
          ...license,
          properties: {
            ...license.properties,
            [subLicensesKey]: updatedSubLicenses,
          },
        };
      }
      return license;
    });
    setValue(fieldProps.key, updatedLicenses);
    trigger(fieldProps.key);
  };

  const handleSelectSubLicense = (parentLicenseNumber, subLicenseNumber) => {
    if (readonly) return;

    const updatedLicenses = licenses.map((license) => {
      if (parentLicenseNumber === license.licenseNumber) {
        const updatedSubLicenses = (
          license.properties[subLicensesKey] || []
        ).map((subLic) => {
          if (
            subLic.licenseNumber === subLicenseNumber &&
            selectableLicenses.includes(subLic.status)
          ) {
            return { ...subLic, isSelected: !subLic.isSelected };
          }
          return subLic;
        });
        return {
          ...license,
          properties: {
            ...license.properties,
            [subLicensesKey]: updatedSubLicenses,
          },
        };
      }
      return license;
    });
    setValue(fieldProps.key, updatedLicenses);
    trigger(fieldProps.key);
  };

  useLayoutEffect(() => {
    updateHeight();
  });

  return (
    <Grid container className="licenseCards">
      {licenses.map((license) => (
        <Grid
          key={license.licenseNumber}
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          p="0.5rem"
        >
          <LicenseCard
            fieldProps={fieldProps}
            data={license}
            onDelete={onDelete}
            onEdit={onEdit}
            onSelect={handleSelectSubLicense}
            onSelectAll={handleSelectAllSubLicense}
            readonly={readonly}
            hasSubLicenses={Boolean(fieldProps.hasSubLicenses)}
            subLicensesKey={subLicensesKey}
          />
        </Grid>
      ))}
    </Grid>
  );
}

const licenseShape = PropTypes.shape({
  licenseExpiryDate: PropTypes.string,
  licenseNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recordType: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
  subLicenses: PropTypes.arrayOf(
    PropTypes.shape({ licenseNumber: PropTypes.string })
  ),
});
LicenseCard.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  data: licenseShape.isRequired,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  readonly: PropTypes.bool,
  hasSubLicenses: PropTypes.bool,
};

LicenseCard.defaultProps = {
  onDelete: undefined,
  onEdit: undefined,
  onSelect: undefined,
  onSelectAll: undefined,
  readonly: false,
  hasSubLicenses: false,
};

LicenseCards.propTypes = {
  fieldProps: PropTypes.shape({
    conditional: PropTypes.shape({
      when: PropTypes.string.isRequired,
      eq: PropTypes.string.isRequired,
      show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    }),
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.shape({
      required: PropTypes.bool,
      custom: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  licenses: PropTypes.arrayOf(licenseShape).isRequired,
  readonly: PropTypes.bool,
};

LicenseCards.defaultProps = {
  onDelete: undefined,
  onEdit: undefined,
  readonly: false,
};

export default LicenseCards;
