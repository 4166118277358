import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";
import { styled } from "@mui/material/styles";
import { getformattedCellValue } from "../../utils/helpers";

const StyledSpan = styled("span")({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
});
function EnhancedTableHead(props) {
  const {
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    maxSelectRow,
    onRequestSort,
    rowSelect,
    req,
    hasSubTable,
    controlRenderer,
    rowsInfo,
    readonly,
  } = props;
  const createSortHandler = (property, defaultSort) => (event) => {
    onRequestSort(event, property, defaultSort);
  };
  const sortKey = String(req.sort).replace(/-/g, "");
  const filterSort = columns.filter((item) => item.sortBy === sortKey);
  const filterSortSystemName = filterSort[0] ? filterSort[0].systemName : null;

  const getDisplayName = (headCell) => {
    const { displayName, condition, dynamicDisplayName } = headCell;
    return getformattedCellValue(
      displayName,
      condition,
      dynamicDisplayName,
      rowsInfo
    );
  };
  return (
    <TableHead>
      <TableRow>
        {rowSelect && (
          <TableCell padding="checkbox">
            {maxSelectRow === Infinity && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
                disabled={readonly}
              />
            )}
          </TableCell>
        )}
        {hasSubTable && (
          <TableCell scope="row" padding="checkbox">
            {controlRenderer()}
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.systemName + headCell.displayName}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={String(req.sort).includes("-") ? "desc" : "asc"}
          >
            {headCell.isSortable ? (
              <TableSortLabel
                active={filterSortSystemName === headCell.systemName}
                direction={
                  String(req.sort).includes("-") &&
                  filterSortSystemName === headCell.systemName
                    ? "desc"
                    : "asc"
                }
                onClick={createSortHandler(
                  headCell.systemName,
                  filterSortSystemName === headCell.systemName
                )}
                tabIndex={-1}
              >
                {getDisplayName(headCell)}
                {orderBy === headCell.systemName ? (
                  <StyledSpan>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </StyledSpan>
                ) : null}
              </TableSortLabel>
            ) : (
              getDisplayName(headCell)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number,
  maxSelectRow: PropTypes.number,
  rowSelect: PropTypes.bool,
  hasSubTable: PropTypes.bool,
  controlRenderer: PropTypes.func,
  rowsInfo: PropTypes.shape({}).isRequired,
  readonly: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
  rowSelect: false,
  numSelected: 0,
  rowCount: 0,
  maxSelectRow: Infinity,
  onSelectAllClick: () => {},
  hasSubTable: false,
  controlRenderer: () => <TableCell />,
  readonly: false,
};

export default EnhancedTableHead;
