import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const BannerStyled = styled(Box)(() => ({
  "& .MuiAlert-root": {
    "& .MuiAlert-icon": { display: "none" },
    display: "flex",
    justifyContent: "center",
  },
  justifyContent: "center",
  textAlign: "center",
  margin: "8px 8px 20px",
  "& .icon": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Styled = styled(Box)(({ theme }) => ({
  "& .MuiDivider-root": {
    backgroundColor: theme.palette.mode === "light" ? undefined : "#d8d8d8",
  },
  "& .MuiTypography-root": {
    marginBottom: "10px",
    color: theme.palette.mode === "light" ? "rgb(1, 67, 97)" : "#fff",
  },
  "& .feeValue": {
    color: theme.palette.mode === "light" ? undefined : "#71cbf5",
  },
  "& .table": {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "10px",
    flexDirection: "column",
  },
  "& .MuiTableRow-root": {
    "& td, & th":
      theme.palette.mode === "light"
        ? undefined
        : {
            borderColor: "#d8d8d8",
          },
  },
  "& .MuiTable-root": {
    margin: "0 auto",
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
}));

export default Styled;
