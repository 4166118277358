import React from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@mui/material";

import Styled from "./style";

function KeyboardNavLink({ type, addMargin }) {
  const linksMap = {
    default: (
      <>
        <Typography tabIndex="0" component={Link} href="#bodyContainer">
          Jump to Main Content
        </Typography>
        <Typography tabIndex="0" component={Link} href="#mainSideNav">
          Jump to Navigation
        </Typography>
      </>
    ),
    button: (
      <Typography tabIndex="0" component={Link} href="#wizard">
        Go to tabs
      </Typography>
    ),
  };

  return <Styled addMargin={addMargin}>{linksMap[type]}</Styled>;
}

KeyboardNavLink.propTypes = {
  type: PropTypes.string,
  addMargin: PropTypes.bool,
};
KeyboardNavLink.defaultProps = {
  type: "default",
  addMargin: false,
};
export default KeyboardNavLink;
