import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import DialogPopup from "../../../DialogPopup";
import LocalLoader from "../../../Loaders/Local";
import { hideFormDialog } from "../../context/dialogSlice";
import Styled from "./styles";

import { methodRefs } from "../../context/formSlice";
import { useVerifyAddressMutation } from "../../../../api/applications.api.slice";
import { notifyError } from "../../../../utils/helpers";
import { showNotification } from "../../../../features/core/slices/notification.slice";

function VerifyAddress({ handleFormSave }) {
  const dispatch = useDispatch();
  const {
    setValue: formSetValue,
    trigger: formTrigger,
    getFieldState: formGetFieldState,
  } = useFormContext();
  const [selectedItem, setSelectedItem] = useState(0);

  const {
    formDialog: { fieldProps, userEnteredAddress, datagridKey, recordId },
  } = useSelector((state) => state.formDialog);

  const [verifyAddress, { data: verifiedAddress, isLoading, error, isError }] =
    useVerifyAddressMutation();

  useEffect(() => {
    const getVerifiedAddress = async (payload) => {
      try {
        await verifyAddress(payload).unwrap();
      } catch (err) {
        notifyError(dispatch, showNotification, err);
      }
    };
    if (userEnteredAddress && userEnteredAddress.length > 0) {
      const payload = userEnteredAddress.reduce(
        (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
        {}
      );
      getVerifiedAddress(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEnteredAddress]);

  const handleAddressSelection = (index) => {
    if (index !== selectedItem) {
      setSelectedItem(index);
    }
  };

  const closeDialog = () => {
    dispatch(hideFormDialog());
  };

  const updateAddress = async () => {
    const setValue =
      datagridKey && recordId
        ? methodRefs[`${datagridKey}${recordId}`].setValue
        : formSetValue;
    const trigger =
      datagridKey && recordId
        ? methodRefs[`${datagridKey}${recordId}`].trigger
        : formTrigger;
    const getFieldState =
      datagridKey && recordId
        ? methodRefs[`${datagridKey}${recordId}`].getFieldState
        : formGetFieldState;

    let validationPromises;
    if (selectedItem === 1) {
      validationPromises = userEnteredAddress.map(async (field) => {
        setValue(field.key, field.value);
        return trigger(field.key);
      });
    } else if (selectedItem === 2) {
      validationPromises = userEnteredAddress.map(async (field) => {
        setValue(field.key, verifiedAddress[field.key]);
        return trigger(field.key);
      });
    }
    Promise.all(validationPromises)
      .then(() => {
        const isValidField = userEnteredAddress.every((field) => {
          const { error: errorField, invalid } = getFieldState(field.key);
          return !errorField && !invalid;
        });
        setValue(`${fieldProps.key}Verified`, isValidField);
        trigger(`${fieldProps.key}Verified`);

        const saveForm = async () => {
          await handleFormSave();
        };
        if (isValidField) {
          saveForm();
        } else {
          const msg = "Please select a valid address.";
          dispatch(showNotification({ type: "warning", msg, show: true }));
        }
      })
      .catch(() => {
        setValue(`${fieldProps.key}Verified`, false);
        trigger(`${fieldProps.key}Verified`);
        const msg = "Something went wrong. Please try again.";
        dispatch(showNotification({ type: "error", msg, show: true }));
      })
      .finally(() => {
        closeDialog();
      });
  };
  const handleKeyDown = (e, i) => {
    if (e.key === "Enter" || e.key === " ") {
      handleAddressSelection(i);
    }
  };

  const modalContent = (
    <Styled dialogState={{ isError, selectedItem }}>
      <Grid
        container
        className="gridContainer"
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid>
          <Alert role="definition" severity="info" tabIndex={0}>
            Please select the address you wish to update in the application by
            clicking on the respective sections below.
          </Alert>
        </Grid>
        <Grid item xs={12} sm={5.7}>
          <Card
            elevation={2}
            onClick={() => handleAddressSelection(1)}
            onKeyDown={(e) => handleKeyDown(e, 1)}
            className="userAddress"
            tabIndex={0}
          >
            <CardHeader
              title="Entered Address"
              action={
                selectedItem === 1 && <CheckCircleIcon fontSize="small" />
              }
            />
            <Divider />
            <CardContent>
              {userEnteredAddress &&
                userEnteredAddress.length > 0 &&
                userEnteredAddress.map((field) => (
                  <Typography key={field.key} variant="body1">
                    {field.value}
                  </Typography>
                ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5.7}>
          <Card
            elevation={2}
            className="verifyCard"
            onClick={error ? undefined : () => handleAddressSelection(2)}
            onKeyDown={error ? undefined : (e) => handleKeyDown(e, 2)}
            tabIndex={0}
          >
            <CardHeader
              title="Verified Address"
              action={
                selectedItem === 2 && <CheckCircleIcon fontSize="small" />
              }
            />
            <Divider />
            <CardContent>
              {error && (
                <Alert
                  severity="warning"
                  sx={{ width: "fit-content", marginTop: "1.5rem" }}
                >
                  {error?.data?.message ||
                    "Could not process request, please try later."}
                </Alert>
              )}
              {verifiedAddress &&
                userEnteredAddress.length > 0 &&
                userEnteredAddress.map((field) => (
                  <Typography key={field.key} variant="body1">
                    {verifiedAddress[field.key]}
                  </Typography>
                ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <LocalLoader progress={isLoading} />
    </Styled>
  );

  const buttonArray = [
    {
      id: 1,
      name: "Cancel",
      variant: "contained",
      onClickHandler: closeDialog,
      show: true,
    },
    {
      id: 2,
      name: "Done",
      variant: "contained",
      onClickHandler: updateAddress,
      show: true,
      disabled: !selectedItem,
    },
  ];

  return (
    <DialogPopup
      title="Address Selection"
      content={modalContent}
      buttonArray={buttonArray}
      openPopup
      onCancel={undefined}
      showActionBtn
      maxWidth="md"
      hideIcon="none"
    />
  );
}
VerifyAddress.propTypes = {
  handleFormSave: PropTypes.func.isRequired,
};
export default VerifyAddress;
