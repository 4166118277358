import React from "react";
import Typography from "@mui/material/Typography";

function GettingStartedContent() {
  return (
    <Typography>
      <strong>
        {`Welcome to Arkansas' marijuana registration and licensing system powered
        by NIC Licensing Solutions.`}
      </strong>
      <br />
      From this platform, business operators can apply, upload paperwork, submit
      payments, and manage applications on behalf of multiple entities.
      Applications in process are saved and can be finished later. Instructions
      for completing and submitting applications are available{" "}
      <a href="https://www.dfa.arkansas.gov/medical-marijuana-commission/licensing-and-renewal-faq">
        here{" "}
      </a>
      <br />
      If you need additional help, please click on the{" "}
      <a href="https://www.dfa.arkansas.gov/medical-marijuana-commission/licensing-and-renewal-faq">
        Help/FAQ
      </a>{" "}
      icon so we may better suit your needs.
    </Typography>
  );
}
export default GettingStartedContent;
