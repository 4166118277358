/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import { StyledBox, Styled } from "./styles";

const fileTypeMap = {
  default: {
    "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/gzip": [".gz"],
    "application/pdf": [".pdf"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "application/x-rar-compressed": [".rar"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/zip": [".zip"],
    "application/x-7z-compressed": [".7z"],
    "text/csv": [".csv"],
    "text/plain": [".txt"],
    "video/mp4": [".mp4"],
    "video/x-matroska": [".mkv"],
    "video/webm": [".webm"],
    "video/quicktime": [".mov"],
  },
  image: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
  pdf: { "application/pdf": [".pdf"] },
  doc: { "application/msword": [".doc"] },
  docx: {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
  gz: { "application/gzip": [".gz"] },
  ppt: { "application/vnd.ms-powerpoint": [".ppt"] },
  pptx: {
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
  },
  rar: { "application/x-rar-compressed": [".rar"] },
  xls: { "application/vnd.ms-excel": [".xls"] },
  xlsx: {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  },
  zip: { "application/zip": [".zip"] },
  sevenZ: { "application/x-7z-compressed": [".7z"] },
  csv: { "text/csv": [".csv"] },
  txt: { "text/plain": [".txt"] },
  video: {
    "video/mp4": [".mp4"],
    "video/x-matroska": [".mkv"],
    "video/webm": [".webm"],
    "video/quicktime": [".mov"],
  },
};

function FileUpload({
  file,
  setFile,
  label,
  fileType,
  maxSize,
  shouldCompress,
}) {
  const [errorCode, setErrorCode] = useState("");

  const errorMap = {
    "file-invalid-type": "Please select a Valid File type.",
    "file-too-large": `Please select a file which is less than ${maxSize}MB in size.`,
  };

  const getNormalizedFile = (selectedFile) =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(selectedFile, {
        maxWidth: 1000,
        maxHeight: 1000,
        success(normalizedFile) {
          resolve(normalizedFile);
        },
        error(error) {
          reject(error);
        },
      });
    });

  const normalizeFile = useCallback(
    (selectedFile) =>
      new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.onload = () => {
            const normalizedFile = getNormalizedFile(selectedFile);
            normalizedFile.then(() => {
              const result = {
                file: Object.assign(selectedFile, {
                  preview: reader.result,
                }),
              };
              resolve(result.file);
            });
          };
          reader.readAsDataURL(selectedFile);
        } catch (error) {
          reject(error);
        }
      }),
    []
  );

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const err = rejectedFiles[0].errors[0];
      setErrorCode(err?.code);
      setFile(null);
    } else {
      let selectedFile;
      if (shouldCompress) {
        selectedFile = await normalizeFile(acceptedFiles[0]);
      } else {
        const selected = acceptedFiles.map((acceptedFile) =>
          Object.assign(acceptedFile, {
            preview: URL.createObjectURL(acceptedFile),
          })
        )[0];
        selectedFile = selected;
      }
      setErrorCode("");
      setFile(selectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allowedFileType = Array.isArray(fileType)
    ? fileType.reduce((acc, cur) => ({ ...acc, ...fileTypeMap[cur] }), {})
    : fileTypeMap[fileType];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileType,
    maxSize: maxSize * 1048576,
    multiple: false,
    maxFiles: 1,
  });
  return (
    <>
      <Styled {...getRootProps({ isDragActive })}>
        <input {...getInputProps()} />
        <Typography align="center">{label}</Typography>
      </Styled>
      <StyledBox>
        {file && (
          <Typography align="center" noWrap>
            Selected : {file.name}
          </Typography>
        )}
        {errorCode && (
          <Typography align="center" className="errorMsg">
            {errorMap[errorCode]}
          </Typography>
        )}
      </StyledBox>
    </>
  );
}
FileUpload.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }),
  setFile: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  maxSize: PropTypes.number.isRequired,
  shouldCompress: PropTypes.bool.isRequired,
};
FileUpload.defaultProps = {
  file: null,
};
export default FileUpload;
