import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const Styled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "addMargin",
})(({ addMargin }) => ({
  position: "absolute",
  top: "-1000px",
  left: "-1000px",
  height: "1px",
  width: "1px",
  textAlign: "left",
  overflow: "hidden",
  backgroundColor: "#585b5c",
  "&:focus, &:focus-within, &:focus-visible": {
    position: "relative",
    left: "0",
    top: addMargin ? "6rem" : "0",
    width: "auto",
    height: "auto",
    overflow: "visible",
    display: "flex",
  },
  "& ul": {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  "& a": {
    flex: 1,
    margin: "3px",
    fontSize: "0.9rem",
    textAlign: "center",
    color: "white",
    textDecoration: "none",
  },
}));

export default Styled;
